import React from "react";
import Preview from "../../page/content/sale/Preview";
import '../../../css/overbox/PreviewBox.css';

const PreviewBox = props => {
    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox previewSale">
                    <Preview previousLink={props.previousLink} />
                </div>
            </div>
        </div>
    )
}

export default PreviewBox;
