import Entity from "../../../class/abstract/Entity";
import SellerGroup from "../SellerGroups/SellerGroup";
import StringTools from "../../../class/tool/StringTools";
import SellerSetting from "../SellerSettings/SellerSetting";
import Barcode from "../../_barcode/Barcode";

class Seller extends Entity {
    gender = null;
    picture = null;
    firstname = null;
    lastname = null;
    fullname = null;
    fullnameLimited = null;
    reference = null;
    color = null;
    code = null;
    badge = null;
    seller_group_id = null;
    groupName = null;
    seller_group = {};
    keyboardPosition = null;
    url = null;
    urlSync = null;
    settings = null;
    licenses = null;
    screen_id = null;
    barcodes = null;
    store_id = null;
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";
        let item;

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "seller_group")
                    Reflect.set(this, key, new SellerGroup(datas[key]));
                else if(key === "settings")
                    Reflect.set(this, key, new SellerSetting(datas[key]));
                else if(key === "licenses") {
                    let licensesTmp = [];

                    for (let i = 0; i < datas[key].length; i++) {
                        licensesTmp.push(datas[key][i]);
                    }

                    Reflect.set(this, "licenses", licensesTmp);
                }
                else if(key === "barcodes") {
                    this.barcodes = []

                    for (item in datas[key])
                        this.barcodes.push(new Barcode(datas[key][item]));
                }
                else
                    Reflect.set(this, key, datas[key]);
            }
        }

        let firstname = this.firstname !== null ? this.firstname : "";
        let lastname = this.lastname !== null ? this.lastname : "";
        Reflect.set(this, "fullname", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase()).trim());
        Reflect.set(this, "fullnameLimited", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase().charAt(0)).trim());

        if (this.settings !== null) {
            this.screen_id = this.settings.screen_id;
        }
    }
}

export default Seller;
