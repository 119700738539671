import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import $ from "jquery"
import OptionNbItems from "../overbox/option/OptionNbItems"
import InputCheckbox from "../form/input/InputCheckbox"
import SortingArrowIcon from "../icons/SortingArrowIcon"
import Line from "./Line"
import LoaderCircle from "../loader/LoaderCircle"
import LeftArrow from "../icons/LeftArrow"
import RightArrow from "../icons/RightArrow"

const Table = props => {
    const { item, titleNbItems, emptyList, model, list, loading, priceable, priceableValue, storeableValue, priceableChange, checkable, openable, listChecked, pagination, loadingPagePrev, loadingPageNext, showOptionNbItems, sortingParam, sortingDirection, noBorder, handleChangePerPage, handleCheckAll, handleSort, handleCheckLine, handleShowOptionNbItems, noMarginTopWrapList } = props
    const [ matchesMaxWidth1250, setMatchesMaxWidth1250 ] = useState(window.matchMedia('(max-width: 1250px)').matches);
    const [ matchesMaxWidth1100, setMatchesMaxWidth1100 ] = useState(window.matchMedia('(max-width: 1100px)').matches);
    const [ matchesMaxWidth940, setMatchesMaxWidth940 ] = useState(window.matchMedia('(max-width: 940px)').matches);

    const calcColspan = () => {
        let count = 0

        if (checkable) count++
        if (openable) count++

        count += model.length

        return count
    }
    const hasUrl = direction => {
        if(direction === "left")
            return pagination.prev_page_url !== null
        else
            return pagination.next_page_url !== null
    }
    const returnSearchUrl = direction => {
        if(direction === "left")
            return pagination.current_page - 1
        else
            return pagination.current_page + 1
    }
    const buildButtonArrow = direction => {
        if (pagination !== null && hasUrl(direction)) {
            return <Link to={ "/" + item + "/page/" + returnSearchUrl(direction) }>
                <button className="buttonArrow">
                    {
                        ((loadingPagePrev && direction === "left") || (loadingPageNext && direction === "right"))
                            ? <LoaderCircle display="loader loaderButtonArrow" strokeWidth="7" />
                            : (
                                direction === "left"
                                    ? <LeftArrow />
                                    : <RightArrow />
                            )
                    }
                </button>
            </Link>
        }
        else {
            return <button className="buttonArrow disabled">
                {
                    direction === "left"
                        ? <LeftArrow />
                        : <RightArrow />
                }
            </button>
        }
    }
    const buildNbItems = () => {
        if (pagination !== null)
            return (pagination.from !== null ? pagination.from : 0) + " - " + (pagination.to !== null ? pagination.to : 0) + " de " + pagination.total

        return ""
    }
    const buildThs = (column, index) => {
        let pricelist = null

        if (priceable !== null && priceableValue > -1 && ["price", "vat"].includes(column.type))
            pricelist = priceable.find(_ => _.id === priceableValue).object

        return <th key={index}
                   className={column.class + ((sortingParam !== "" && sortingParam === column.sortingParam) ? (" sorted " + sortingDirection) : "") + (column.sortingParam === null ? " unsortable" : "")}
                   style={
                        pricelist !== null
                            ? {
                               backgroundColor: pricelist.color + "22",
                               color: pricelist.color
                            }
                            : {}
                   }>
            <span onClick={(column.sortingParam !== "" && column.sortingParam !== null) ? () => { handleSort(column.sortingParam) } : null}>{column.title}</span>
            <SortingArrowIcon/>
        </th>
    }
    const initMaxHeightTableBody = () => {
        let maxHeight = $(".main.account").height()

        maxHeight -= $(".toolsBar").height()
        maxHeight -= $(".wrapList .pagination").height()
        maxHeight -= $(".wrapList .list thead").height()

        $("tbody.scrollable").css("maxHeight", maxHeight + "px")
    }
    const buildPriceableFilter = () => {
        let indexVat = model.findIndex(_ => _.type === "vat")
        let pricelist = null;
        let spanPrice = 0;
        let spanPricelistFilter = indexVat > -1 ? 2 : 1;

        if (priceable !== null && priceableValue > -1)
            pricelist = priceable.find(_ => _.id === priceableValue).object

        if (matchesMaxWidth1250)
            spanPricelistFilter = 1;

        for (let i in model) {
            if (model[i].class === "catalogName") {
                if (!matchesMaxWidth1100)
                    spanPrice++;
            }
            else if (["reference", "category"].includes(model[i].class)) {
                if (!matchesMaxWidth940)
                    spanPrice++;
            }
            else if (model[i].class === "price")
                break;
            else
                spanPrice++;
        }

        return <tr className={"priceable"}>
            <td colSpan={spanPrice} />
            <td colSpan={spanPricelistFilter} className={"pricelistFilter"} style={pricelist !== null ? {backgroundColor: pricelist.color + "22"} : {}}>
                <select value={priceableValue} onChange={changePriceable} style={pricelist !== null ? {color: pricelist.color} : {}}>
                    {
                        priceable.map((obj, index) => (
                            <option key={ index } value={ obj.id }>
                                {
                                    obj.value
                                }
                            </option>
                        ))
                    }
                </select>
            </td>
        </tr>
    }
    const changePriceable = event => {
        if (priceableChange === null) return
        priceableChange(event.currentTarget.value)
    }
    const resize = () => {
        setMatchesMaxWidth1250(window.matchMedia('(max-width: 1250px)').matches);
        setMatchesMaxWidth1100(window.matchMedia('(max-width: 1100px)').matches);
        setMatchesMaxWidth940(window.matchMedia('(max-width: 940px)').matches);
    }

    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
    }, [])
    useEffect(() => {
        if (pagination === null && list.length > 0)
            initMaxHeightTableBody()
    }, [pagination, list])

    return (
        <div className={ "wrapList" + (noMarginTopWrapList ? " noMarginTop" : "") + (noBorder ? " noBorder" : "" )}>
            <div className="pagination">
                <p className="nbTotal">{ pagination !== null ? pagination.total : list.length }<span>{ titleNbItems }</span></p>
                {
                    pagination !== null
                    && <div className="index">
                        {
                            buildButtonArrow("right")
                        }
                        {
                            buildButtonArrow("left")
                        }
                        <p className="nbItems" id="nbItems_top" onClick={ () => { handleShowOptionNbItems("top") } }>{ buildNbItems() }</p>
                        <OptionNbItems type="optionNbItems" show={ showOptionNbItems } handleClick={ handleChangePerPage } />
                        {
                            (loading && !loadingPagePrev && !loadingPageNext)
                            && <LoaderCircle display="loader restPagination" strokeWidth="10" />
                        }
                    </div>
                }
            </div>
            <table className="list">
                <thead>
                    {
                        priceable !== null
                        && buildPriceableFilter()
                    }
                    <tr className="header">
                    {
                        checkable
                        && <th className="checkbox"><InputCheckbox name="all-selected" checked={ list.length > 0 && listChecked.length === list.length } handleChange={ handleCheckAll } /></th>
                    }
                    {
                        model.map((column, index) => buildThs(column, index))
                    }
                    {
                        openable
                        && <th className="access" />
                    }
                </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0
                            ? list.map((obj, index) => (
                                <Line key={ index } obj={ obj } item={ item } model={ model } priceable={ priceable } priceableValue={ priceableValue } storeableValue={storeableValue} checkable={ checkable } openable={ openable } checked={ listChecked.includes(obj.id) } handleChecked={ handleCheckLine } />
                            ))
                            : <tr><td className="empty" colSpan={ calcColspan() }>{ loading ? "Chargement..." : emptyList }</td></tr>
                    }
                </tbody>
            </table>
            {
                pagination !== null
                && <div className="pagination borderTop">
                    <div className="index">
                        {
                            buildButtonArrow("right")
                        }
                        {
                            buildButtonArrow("left")
                        }
                        <p className="nbItems" id="nbItems_bottom" onClick={ () => {} }>{ buildNbItems() }</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default Table
