import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import LicenseController from "../../../stories/_account/Licenses/LicenseController"

const ChoiceListLicense = props => {
    const { screen, handleClose, handleChoice, saving } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ list, setList ] = useState([])
    const [ selected, setSelected ] = useState([])
    const env = JSON.parse(localStorage.getItem("env"))
    const store = JSON.parse(localStorage.getItem("store"))

    const initSelected = () => {
        let selectedTmp = selected.slice();

        for (let index in screen.licenses) {
            selectedTmp.push(screen.licenses[index].id)
        }

        setSelected(selectedTmp);
    }
    const getLicenses = () => {
        const controller = new LicenseController()
        controller._callback = handleGetLicenses
        controller.index(store)
    }
    const handleGetLicenses = (response, error, status) => {
        switch (status) {
            case 200:
                setList([
                    {
                        store: store,
                        licenses: response
                    }
                ])

                setLoading(false)
                break
            default:
                setError("Une erreur s'est produite lors de la récupération des licences")
                setLoading(false)
                break
        }
    }
    const buildScreen = (object, index) => {
        return <div key={ index } className={"license" + (selected.includes(object.id) ? " selected" : "")} onClick={ () => { selectLicense(object.id) } }>
            <p className="text">{ object.name }</p>
        </div>
    }
    const selectLicense = id => {
        let tmp = selected.slice()

        if (tmp.includes(id))
            tmp.splice(tmp.indexOf(id), 1)
        else
            tmp.push(id)

        setSelected(tmp)
    }
    const valid = () => {
        handleChoice(selected)
    }

    useEffect(() => {
        initSelected()
        getLicenses()
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <div className="form">
                        {error !== null && <p className="error">{error}</p>}
                        <p className="titleForm center">Sélectionner les licenses à attacher</p>
                        {loading ? <LoaderCircle display="loader logWait marginTop" hide="" strokeWidth="8" /> : <div className="clearing">
                            {list.map((item, index) => (<div key={index} className="clearing" style={{margin: "25px 0"}}>
                                {
                                    env.type !== "store" && <p className="storeCatalogs">{item.store.name}</p>
                                }
                                <div className="listCatalogs">
                                    {
                                        item.licenses.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).length > 0
                                            ? item.licenses.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).map((screen, id) => buildScreen(screen, id))
                                            : <p className="empty">Aucune licence disponible</p>
                                    }
                                </div>
                            </div>))}
                        </div>}
                        <button className={"submit " + (saving ? "hide" : "")} onClick={valid}>
                            {saving ? "Enregistrement..." : "Enregistrer"}
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                        </button>
                        <button className="cancel align" onClick={handleClose}>Annuler</button>
                        <div className="clearing"/>
                    </div>
                </div>
            </div>
        </div>)
}

export default ChoiceListLicense
