import React from "react"
import CheckIcon from "../../../icons/CheckIcon"

const ChoiceMultiLine = props => {
    const { item, selected, handleSelect } = props

    return (
        <div className={"choiceMultiLine" + (selected ? " selected" : "")} onClick={ () => { handleSelect(item.id) } }>
            <div className="contChecboxChoice">
                <CheckIcon classname={ "checkboxChoice " + (selected ? "selected" : "") } />
            </div>
            <div className="contTextChoice">
                <p className={ "primary " + (item.secondary === undefined || item.secondary === "" ? "middle" : "") }>{ item.main }</p>
                {
                    (item.secondary !== undefined && item.secondary !== "")
                    && <p className="secondary">{ item.secondary }</p>
                }
            </div>
            <div className="clearing" />
        </div>
    )
}

export default ChoiceMultiLine;
