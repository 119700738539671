import React from "react";
import ReactQuill from "react-quill";
import NumberFormat from "react-number-format";
import EditIcon from "../../../icons/EditIcon";
import 'react-quill/dist/quill.bubble.css';

const NoInput = props => {
    const {
        attribute,
        value,
        inputType,
        devise,
        classname,
        iconColor,
        backgroundColor,
        edit,
        handleEdit
    } = props;

    const displayValue = () => {
        switch (inputType) {
            case "price":
                return <p className="textInput">
                    {
                        value !== null
                            ? <NumberFormat
                                value={value}
                                decimalScale={2}
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                fixedDecimalScale={true}
                                displayType={'text'}
                            />
                            : value
                    }
                    {
                        (devise !== undefined && devise !== "")
                        && <span className="devise">{ devise }</span>
                    }
                </p>
            case "textarea":
                return <div className="textInput">
                    <ReactQuill
                        value={ value }
                        readOnly={ true }
                        theme="bubble"
                    />
                </div>
            case "pricelist":
                let explodeDatas = value.split('|');

                return <div className="textInput">
                    <p className="indice">TVA { explodeDatas[1] }</p>
                    <p className="value">
                        <NumberFormat
                            value={explodeDatas[0].replace('.', ',')}
                            decimalScale={2}
                            thousandSeparator={' '}
                            decimalSeparator={','}
                            fixedDecimalScale={true}
                            displayType={'text'}
                        />
                        {
                            (devise !== undefined && devise !== "")
                            && <span className="devise"> { devise }</span>
                        }
                    </p>
                </div>
            case "color":
                return <p className={"textInput color" + (value === null || value.length === 0 || value === "Aucune" ? " empty" : "")} style={{backgroundColor: value}}>
                    {
                        value
                    }
                </p>
            default:
                return <p className="textInput">
                    {
                        value
                    }
                    {
                        (devise !== undefined && devise !== "")
                        && <span className="devise">{ devise }</span>
                    }
                </p>
        }
    }

    return (
        <div
            className={ "noInput " + (classname !== undefined ? classname : "") + (!edit ? " noEdit" : "") + (inputType === "color" ? " color" : "") }
            style={ (backgroundColor !== undefined ? {backgroundColor: backgroundColor} : {}) }
            onClick={ edit ? () => { handleEdit(attribute) } : () => {} }
        >
            <div className="icons">
                {
                    edit && <EditIcon fill={ iconColor } handleClick={ () => { handleEdit(attribute) } } />
                }
            </div>
            {
                displayValue()
            }
        </div>
    );
}

export default NoInput;
