import React, {useContext, useEffect, useState} from "react"
import { Route, Switch } from "react-router-dom"
import NewObject from "../../../overbox/sheet/screen/NewObject"
import Listing from "../../../table/Listing"
import ScreenCard from "./ScreenCard"
import usePrevious from "../../../../class/tool/usePrevious"
import ConfirmBox from "../../../overbox/asking/ConfirmBox"
import SecureBox from "../../../overbox/asking/SecureBox"
import ChoiceAddScreen from "../../../overbox/screen/ChoiceAddScreen"
import ChoiceListScreen from "../../../overbox/screen/ChoiceListScreen"
import ChoiceListLicense from "../../../overbox/screen/ChoiceListLicense";
import ChoiceListStore from "../../../overbox/screen/ChoiceListStore";
import FormBuilder from "../../../../class/tool/FormBuilder"
import PosScreenController from "../../../../stories/_setting/PosScreens/PosScreenController"
import ListingContext from "../../../../context/ListingContext"
import BackofficeContext from "../../../../context/BackofficeContext";
import '../../../../css/page/content/screen/Screen.css'

const Screen = props => {
    const item = "screens"
    const itemClass = "screen"
    const titleWindow = "Écrans"
    const placeholderSearch = "un écran"
    const titleNbItems = "écrans"
    const emptyList = "Aucun écran"
    const textRemoveButton = "cet écran"
    const activeHistory = false
    const queryParams = new URLSearchParams(window.location.search)
    const env = JSON.parse(localStorage.getItem("env"))
    const settings = JSON.parse(localStorage.getItem("storeSettings"))
    const company = JSON.parse(localStorage.getItem("company"));
    const { page } = props
    const { getScreenDeploymentInProgress, setErrorText } = useContext(BackofficeContext);
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1)
    const [ loading, setLoading ] = useState(true)
    const [ model, setModel ] = useState([])
    const [ loadingPostScreen, setLoadingPostScreen ] = useState(false)
    const [ savingLicense, setSavingLicense ] = useState(false)
    const [ savingStore, setSavingStore ] = useState(false)
    const [ list, setList ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(25)
    const [ sortingName, setSortingName ] = useState("updated_at")
    const [ sortingValue, setSortingValue ] = useState("desc")
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")
    const [ filterRows, setFilterRows ] = useState([])
    const [ filterValues, setFilterValues ] = useState({})
    const [ formChoiceAddScreen, setFormChoiceAddScreen ] = useState(false)
    const [ formChoiceListScreen, setFormChoiceListScreen ] = useState(false)
    const [ formChoiceListLicense, setFormChoiceListLicense ] = useState(null)
    const [ formChoiceListStore, setFormChoiceListStore ] = useState(null)
    const [ screenToUp, setScreenToUp ] = useState(null)
    const [ confirmUpScreen, setConfirmUpScreen ] = useState(false)
    const [ secureUpScreen, setSecureUpScreen ] = useState(false)
    const [ statePostScreens, setStatePostScreens ] = useState([])
    const prevPerPage = usePrevious(perPage)
    const prevInput = usePrevious(input)
    let states = [];
    let withRefreshDeployments = false;

    const initModel = () => {
        let modelTmp = [
            {
                "class": "name",
                "sortingParam": "name",
                "title": "Nom",
                "attributes": ["name"],
                "type": "text"
            }
        ];

        if (env.type === "company") {
            modelTmp.splice(1, 0, {
                "class": "licenses",
                "sortingParam": "",
                "title": "Boutiques",
                "attributes": ["storesString"],
                "type": "storesManagement",
                "action": handleChoiceListStore
            });
        }
        else if (env.type === "store") {
            modelTmp.splice(1, 0, {
                "class": "licenses",
                "sortingParam": "",
                "title": "Licences",
                "attributes": ["licensesString"],
                "type": "licensesManagement",
                "action": handleChoiceListLicense
            });
            modelTmp.splice(2, 0, {
                "class": "origin",
                "sortingParam": "",
                "title": "Origine",
                "attributes": [],
                "type": "catalogOrigin"
            })
        }

        setModel(modelTmp);
    }
    const initOptions = () => {
        if (env.type === "company") {
            setOptions([{
                "class": "add",
                "title": "Créer " + placeholderSearch,
                "action": handleChoiceAddScreen
            }])
        }
        else if (env.type === "store") {
            if (settings === null || company.socialReason === null || (company.socialReason !== null && settings.allowedProductCreation === 1)) {
                setOptions([{
                    "class": "add",
                    "title": "Créer " + placeholderSearch,
                    "link": "/" + item + "/new"
                }])
            }
        }
    }
    const initFilters = () => {
        let filtersTmp = []

        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "text marginTop",
            placeholder: "Rechercher " + placeholderSearch,
        })

        setFilterValues(prev => ({
            ...prev,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const getScreens = (pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller = new PosScreenController()
        let paramInput = pTextInput !== "" ? pTextInput : input
        let paramPage = pPage !== 1 ? pPage : pageSelect
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue

        setLoading(true)

        controller._callback = handleGetScreens
        controller.index(paramInput, paramPage, paramPerPage, false, paramSortingName, paramSortingValue)
    }
    const handleGetScreens = (list, error, status) => {
        switch (status) {
            case 200:
                setList(list)
                break
            default: break
        }

        if (withRefreshDeployments) {
            getScreenDeploymentInProgress();
            withRefreshDeployments = false;
        }

        setLoading(false)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "input":
                setInput(obj.value)
                break
            default: break
        }
    }
    const updatePageSelect = page => {
        setPageSelect(page)
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || input !== prevInput) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getScreens(input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        let keys = Object.keys(listTmp[index]);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh()
    }
    const handleClose = (type, reinitScreen = false) => {
        if (reinitScreen)
            setScreenToUp(null)

        switch (type) {
            case "choiceAddScreen":
                setFormChoiceAddScreen(false)
                break
            case "choiceListScreen":
                setFormChoiceListScreen(false)
                break
            case "choiceListLicense":
                setFormChoiceListLicense(null)
                break
            case "choiceListStore":
                setFormChoiceListStore(null)
                break
            case "confirmUpScreen":
                setConfirmUpScreen(false)
                break
            case "secureUpScreen":
                setSecureUpScreen(false)
                break
            default: break
        }
    }
    const handleChoiceAddScreen = () => {
        if (env.type === "company")
            setFormChoiceAddScreen(true)
        else if (env.type === "store")
            window.location = "/" + item + "/new"
    }
    const handleChoiceListScreen = () => {
        handleClose("choiceAddScreen")
        setFormChoiceListScreen(true)
    }
    const handleChoiceListLicense = idScreen => {
        setFormChoiceListLicense(idScreen)
    }
    const handleChoiceListStore = idScreen => {
        setFormChoiceListStore(idScreen)
    }
    const handleChoiceNewScreen = () => {
        handleClose("choiceAddScreen")
        window.location = item + "/new"
    }
    const handleConfirmUpScreen = catalog => {
        handleClose("choiceListScreen")
        setScreenToUp(catalog)
        setConfirmUpScreen(true)
    }
    const handleConfirmChoiceLicense = licenses => {
        setSavingLicense(true)

        let screen = list.find(_ => _.id === formChoiceListLicense)
        let controller = new PosScreenController()
        controller._callback = returnSavingLicenses
        controller.put(screen, {
            licenses: licenses
        })
    }
    const returnSavingLicenses = (response, error, status) => {
        setSavingLicense(false)

        switch (status) {
            default:
                break
        }

        handleClose("choiceListLicense")
        handleRefresh()
    }
    const handleConfirmChoiceStore = stores => {
        setSavingStore(true);

        let screen = list.find(_ => _.id === formChoiceListStore);
        let controller;

        let storesToAdd = [];
        states = [];

        for (let i in stores) {
            // check if already exist
            if (screen.stores.find(_ => _.id === stores[i]) !== undefined) continue;

            storesToAdd.push(stores[i]);
            states.push({
                error: false,
                loading: true,
                store: stores[i]
            });
        }

        if (storesToAdd.length > 0) {
            for (let i in storesToAdd) {
                controller = new PosScreenController();
                controller._callback = returnSavingStores;
                controller.post(
                    {
                        source_id: screen.id,
                        store_id: storesToAdd[i],
                        sharedUuid: screen.uuid
                    },
                    false,
                    storesToAdd[i]);
            }
        }
        else {
            setSavingStore(false);
            handleClose("choiceListStore");
        }
    }
    const returnSavingStores = (response, error, status, context) => {
        let index = states.findIndex(_ => _.store === context);
        if (index < 0)
            return;

        states[index].loading = false;

        switch (status) {
            case 201:
                states[index].error = false;
                break;
            case 409:
                states[index].error = true;
                alert("Certaines touches sont associées à des éléments inconnus d'un catalogue partagé");
                break;
            default:
                states[index].error = true;
                break
        }

        if (states.length === states.filter(_ => _.loading === false).length) {
            setSavingStore(false);
            handleClose("choiceListStore");
            handleRefresh();
        }
    }
    const handleSecureUpScreen = () => {
        handleClose("confirmUpScreen")
        setSecureUpScreen(true)
    }
    const upScreen = () => {
        setLoadingPostScreen(true)
        postScreen(screenToUp, false)
    }
    const postScreen = (datas, needsKeyboards = true) => {
        const controller = new PosScreenController()
        controller._callback = handlePostScreen
        controller.post(datas, needsKeyboards)
    }
    const handlePostScreen = (response, error, status) => {
        switch (status) {
            case 201:
                if (screenToUp !== null)
                    riseScreen(response.data.id)
                else
                    handleRefresh()

                break
            default:
                handleClose("secureUpScreen", true)
                setLoadingPostScreen(false)
                break
        }
    }
    const riseScreen = idCompanyScreen => {
        const controller = new PosScreenController();
        controller._callback = handleRiseScreen;
        controller.rise(screenToUp.id, idCompanyScreen);
    }
    const handleRiseScreen = (response, error, status, context) => {
        handleClose("secureUpScreen", true);
        setLoadingPostScreen(false);

        switch (status) {
            case 201:
                withRefreshDeployments = true;
                handleRefresh();
                break;
            case 409:
                setErrorText("Certaines touches sont associées à des éléments inconnus d'un catalogue partagé");
                remove(context);
                break;
            default:
                remove(context);
                break;
        }
    }
    const remove = (idScreen) => {
        const controller = new PosScreenController()
        controller._callback = returnRemove
        controller.deleteById(idScreen)
    }
    const returnRemove = (response, error, status) => {
        switch (status) {
            case 204:
                break
            default:
                console.error("Error", status, error)
                break
        }
    }

    const secondaryOptions = [];

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        initModel();
        initOptions();
        initFilters();
    }, []);
    useEffect(() => {
        handleRefresh(false);
    }, [perPage, pageSelect, sortingName, sortingValue]);
    useEffect(() => {
        if (prevInput === undefined) return;

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000);

        return () => clearTimeout(timeoutInput);
    }, [input]);

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={ "/" + item + "/new" }>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={ "/" + item + "/:idScreen" }>
                    <ScreenCard
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={ handleUpdate }
                        handleRemove={ handleRemove } />
                </Route>
            </Switch>
            {
                formChoiceAddScreen
                && <ChoiceAddScreen handleClose={ () => { handleClose("choiceAddScreen", true) } } handleChoiceList={ handleChoiceListScreen } handleChoiceNew={ handleChoiceNewScreen } />
            }
            {
                formChoiceListScreen
                && <ChoiceListScreen handleClose={ () => { handleClose("choiceListScreen", true) } } handleChoice={ handleConfirmUpScreen } />
            }
            {
                formChoiceListLicense !== null
                && <ChoiceListLicense screen={list.find(_ => _.id === formChoiceListLicense)} handleClose={ () => { handleClose("choiceListLicense", true) } } handleChoice={ handleConfirmChoiceLicense } saving={ savingLicense } />
            }
            {
                formChoiceListStore !== null
                && <ChoiceListStore screen={list.find(_ => _.id === formChoiceListStore)} handleClose={ () => { handleClose("choiceListStore", true) } } handleChoice={ handleConfirmChoiceStore } saving={ savingStore } />
            }
            {
                (screenToUp !== null && confirmUpScreen)
                && <ConfirmBox
                    title="Êtes-vous sûr de vouloir remonter l'écran ?"
                    text="Attention, les actions sur cet écran seront autorisées UNIQUEMENT depuis le groupe."
                    textBack="Annuler"
                    textConfirm="Je suis sûr"
                    handleClose={ () => { handleClose("confirmUpScreen", true) } }
                    handleConfirm={ handleSecureUpScreen } />
            }
            {
                (screenToUp !== null && secureUpScreen)
                && <SecureBox
                    obj={ screenToUp }
                    title="Veuillez saisir le code de sécurité"
                    textBack="Annuler"
                    textConfirm="Confirmer"
                    textConfirming="Remontée de l'écran..."
                    loading={ loadingPostScreen }
                    handleClose={ () => { handleClose("secureUpScreen", true) } }
                    handleConfirm={ upScreen } />
            }
        </ListingContext.Provider>
    )
}

export default Screen;