import React, {useContext, useEffect, useState} from "react"
import { Link } from "react-router-dom"
import $ from "jquery"
import moment from "moment"
import NumberFormat from "react-number-format"
import FormBuilder from "../../class/tool/FormBuilder"
import InputCheckbox from "../form/input/InputCheckbox"
import LoaderCircle from "../loader/LoaderCircle"
import ForbiddenIcon from "../icons/ForbiddenIcon"
import Account from "../../stories/_account/Accounts/Account"
import Price from "../../stories/_catalog/Prices/Price"
import Vat from "../../stories/_catalog/Vats/Vat"
import PriceController from "../../stories/_catalog/Prices/PriceController"
import PriceVariationController from "../../stories/_catalog/PriceVariations/PriceVariationController"
import PriceVariation from "../../stories/_catalog/PriceVariations/PriceVariation"
import VatController from "../../stories/_catalog/Vats/VatController"
import ProductController from "../../stories/_catalog/Products/ProductController"
import StringTools from "../../class/tool/StringTools"
import BackofficeContext from "../../context/BackofficeContext";
import ListingContext from "../../context/ListingContext"
import '../../css/page/content/provider/Line.css'
import '../../css/page/content/brand/Line.css'
import '../../css/page/content/category/Line.css'
import '../../css/page/content/subcategory/Line.css'
import '../../css/page/content/menu/Line.css'
import '../../css/page/content/product/Line.css'
import '../../css/page/content/productionmessage/Line.css'
import '../../css/page/content/filter/Line.css'
import '../../css/page/content/group/Line.css'
import '../../css/page/content/client/Line.css'
import '../../css/page/content/sellergroup/Line.css'
import '../../css/page/content/seller/Line.css'
import '../../css/page/content/paymentmethod/Line.css'
import '../../css/page/content/pricelist/Line.css'
import '../../css/page/content/printer/Line.css'
import '../../css/page/content/display/Line.css'
import '../../css/page/content/barcodereader/Line.css'
import '../../css/page/content/paymentterminal/Line.css'
import '../../css/page/content/sale/Line.css'
import '../../css/page/content/account/Line.css'
import '../../css/page/content/catalog/Line.css'
import '../../css/page/content/screen/Line.css'

const Line = props => {
    const env = JSON.parse(localStorage.getItem("env"))
    const company = JSON.parse(localStorage.getItem("company"));
    const { obj, item, model, priceable, priceableValue, storeableValue, checkable, openable, checked, handleChecked } = props
    const { setBubbleText, showDeployingCatalog, showDeployingScreen } = useContext(BackofficeContext);
    const { vatRates } = useContext(ListingContext)
    const [ objUpdated, setObjUpdated ] = useState(Object.assign({}, obj))
    const [ classnameLine, setClassnameLine ] = useState("")
    const [ editPrice, setEditPrice ] = useState(false)
    const [ editVat, setEditVat ] = useState(false)
    const [ loadingPrice, setLoadingPrice ] = useState(false)
    const [ loadingVat, setLoadingVat ] = useState(false)
    const settings = JSON.parse(localStorage.getItem("storeSettings"))
    let localChecked = checked

    const refreshObjUpdated = () => {
        setObjUpdated(obj)
    }
    const handleClickOnCheckbox = (event) => {
        localChecked = !localChecked
        createRipple(event)
        handleChecked(obj.id)
    }
    const colorLine = () => {
        if (localChecked)
            $("#line-" + obj.id).addClass("checked")
        else
            $("#line-" + obj.id).removeClass("checked")
    }
    const getOffset = elmt => {
        const rect = elmt.getBoundingClientRect()
        return {
            left: rect.left + $(".main").scrollLeft(),
            top: rect.top + $(".main").scrollTop()
        }
    }
    const createRipple = (event) => {
        const line = document.getElementById("line-" + obj.id)
        const containerCircle = document.createElement("div")
        const circle = document.createElement("span")
        const diameter = Math.max(line.clientWidth, line.clientHeight)
        const radius = diameter / 2
        const offset = getOffset(line)

        containerCircle.classList.add("containerCircle")
        containerCircle.style.width = `${line.clientWidth}px`
        containerCircle.style.height = `${line.clientHeight}px`
        containerCircle.style.left = `${offset.left - $(".menu").width()}px`
        containerCircle.style.top = `${offset.top - $(".Header").height()}px`
        circle.style.width = circle.style.height = `${diameter}px`
        circle.style.left = `${event.clientX - radius}px`
        circle.style.top = `${line.clientHeight / 2 - radius}px`
        circle.classList.add("ripple")

        if (localChecked)
            circle.classList.add("blue")
        else
            circle.classList.add("gray")

        const ripple = line.getElementsByClassName("containerCircle")[0]

        if (ripple)
            ripple.remove()

        containerCircle.appendChild(circle)
        line.appendChild(containerCircle)

        setTimeout(() => {
            colorLine()
        }, 300)
        setTimeout(() => {
            containerCircle.remove()
            colorLine()
        }, 250)
    }
    const displaySince = strDate => {
        return moment(strDate).fromNow(true)
    }
    const displayDate = strDate => {
        return moment(strDate).format("DD MMM YYYY, LT")
    }
    const getVatByValues = forMain => {
        if (env.type === "store") {
            if (forMain)
                return objUpdated.vats.find(_ => _.priceList.main === 1)
            else
                return objUpdated.vats.find(_ => _.price_list_id === priceableValue)
        }
        else {
            if (storeableValue === null) {
                let vats

                if (forMain)
                    vats = objUpdated.vats.filter(_ => _.priceList.main === 1)
                else
                    vats = objUpdated.vats.filter(_ => _.price_list_id === priceableValue)

                if (sameVatValueInStores(vats))
                    return vats.length > 0 ? vats[0] : undefined
                else
                    return null
            }
            else {
                if (forMain)
                    return objUpdated.vats.find(_ => _.priceList.main === 1 && _.store_id === storeableValue)
                else
                    return objUpdated.vats.find(_ => _.price_list_id === priceableValue && _.store_id === storeableValue)
            }
        }
    }
    const sameVatValueInStores = vats => {
        let value = null

        for (let i in vats) {
            if (value !== null) {
                if (value !== vats[i].vat_rate_id)
                    return false
            }

            value = vats[i].vat_rate_id
        }

        return true
    }
    const getPriceByValues = forMain => {
        if (env.type === "store") {
            if (forMain)
                return objUpdated.prices.find(_ => _.priceList.main === 1)
            else
                return objUpdated.prices.find(_ => _.price_list_id === priceableValue)
        }
        else {
            if (storeableValue === null) {
                let prices

                if (forMain)
                    prices = objUpdated.prices.filter(_ => _.priceList.main === 1)
                else
                    prices = objUpdated.prices.filter(_ => _.price_list_id === priceableValue)

                if (samePriceValueInStores(prices))
                    return prices.length > 0 ? prices[0] : undefined
                else
                    return null
            }
            else {
                if (forMain)
                    return objUpdated.prices.find(_ => _.priceList.main === 1 && _.store_id === storeableValue)
                else
                    return objUpdated.prices.find(_ => _.price_list_id === priceableValue && _.store_id === storeableValue)
            }
        }
    }
    const samePriceValueInStores = prices => {
        let value = null

        for (let i in prices) {
            if (value !== null) {
                if (value !== prices[i].value)
                    return false
            }

            value = prices[i].value
        }

        return true
    }
    const getPriceVariationByValues = () => {
        if (env.type === "store")
            return objUpdated.price_variations.find(_ => _.price_list_id === priceableValue)
        else {
            if (storeableValue === null) {
                let variations = objUpdated.price_variations.filter(_ => _.price_list_id === priceableValue)

                if (samePriceVariationValuesInStores(variations))
                    return variations.length > 0 ? variations[0] : undefined
                else
                    return null
            }
            else
                return objUpdated.price_variations.find(_ => _.price_list_id === priceableValue && _.store_id === storeableValue)
        }
    }
    const samePriceVariationValuesInStores = variations => {
        let variationOperator = null
        let value = null
        let type = null

        for (let i in variations) {
            if (value !== null) {
                if (value !== variations[i].value || variationOperator !== variations[i].variationOperator || type !== variations[i].type)
                    return false
            }

            variationOperator = variations[i].variationOperator
            value = variations[i].value
            type = variations[i].type
        }

        return true
    }
    const buildForType = (attributes, type, action) => {
        switch (type) {
            case "text":
                if (item === "screens") {
                    if (attributes[0] === "name") {
                        return <div className={"withWaiting"}>
                            {
                                classnameLine === "waiting"
                                && <LoaderCircle display="waiting" hide="" strokeWidth="8" />
                            }
                            {
                                obj.hasUpdates
                                && <span className={"hasUpdates"} />
                            }
                            <p>{ obj[attributes[0]] }</p>
                        </div>
                    }
                    else
                        return obj[attributes[0]]
                }
                else {
                    return obj[attributes[0]]
                }
            case "date":
                return displayDate(obj[attributes[0]])
            case "price":
                switch (item) {
                    case "products":
                        let priceFounded = getPriceByValues(false)

                        if (priceFounded === undefined)
                            priceFounded = getPriceByValues(true)

                        if (priceFounded === undefined)
                            return

                        let priceVariationFounded = getPriceVariationByValues()

                        return <div className={"containerPrice"}>
                            {
                                priceFounded !== null
                                    ? <p className={"infoPrice"} style={{color: priceFounded.priceList.color}}>
                                        <NumberFormat value={priceFounded.value} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/> €
                                        {
                                            (priceVariationFounded !== undefined && priceVariationFounded.variationOperator !== "=")
                                            && <span style={{paddingLeft: "5px"}}>
                                                (
                                                    { priceVariationFounded.variationOperator }
                                                    <NumberFormat value={priceVariationFounded.value} decimalScale={(priceVariationFounded.type === "DEVISE" ? 2 : 0)} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                                    {priceVariationFounded.type === "DEVISE" ? " €" : " %"}
                                                )
                                            </span>
                                        }
                                    </p>
                                    : <ForbiddenIcon classname={"forbidden"} fill={"#9C9C9C"} />
                            }
                            {
                                (env.type === "company" || (env.type === "store" && (obj.sharedUuid === null || company.socialReason === null || (obj.sharedUuid !== null && company.socialReason !== null && settings.allowedPriceUpdate))))
                                    ? <div className={"priceOverview" + (editPrice ? " visible" : "")}>
                                        {
                                            editPrice
                                                ? <div className={"background"} style={(priceFounded !== null ? {backgroundColor: priceFounded.priceList.color + "22"} : {})}>
                                            {loadingPrice ? <LoaderCircle display="loader restInLinePrice" strokeWidth="8" stroke="#00355B"/> : <input value={priceFounded.stringValue} onChange={handleChangePrice} onBlur={handleSavePrice} autoFocus={true} onKeyUp={handleKeyDownPrice}/>}
                                        </div>
                                                : <div className={"background"} style={(priceFounded !== null ? {backgroundColor: priceFounded.priceList.color + "22"} : {backgroundColor: "#64646422"})}>
                                            {priceFounded !== null ? <p className={"textPrice"} style={{color: priceFounded.priceList.color}}>
                                                <NumberFormat value={priceFounded.value} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/> €
                                            </p> : <ForbiddenIcon classname={"forbidden"} fill={"#9C9C9C"}/>}
                                            {priceFounded !== null && <div className={"edit"} onClick={handleEditPrice}/>}
                                            <Link className={"linkOpen"} to={"/catalogs/" + obj.catalog_id + "/products/" + obj.id + "/pricelists"}>
                                                <div className={"open"}/>
                                            </Link>
                                        </div>
                                        }
                                    </div>
                                    : <div />
                            }
                        </div>

                    default:
                        return <span>
                            <NumberFormat value={parseFloat(obj[attributes[0]])} decimalScale={2} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>{obj[attributes[0]] !== null ? " €" : ""}
                        </span>
                }
            case "vat":
                switch (item) {
                    case "products":
                        let vatFounded = getVatByValues(false)
                        if (vatFounded === undefined) vatFounded = getVatByValues(true)
                        if (vatFounded === undefined) return

                        return <div className={"containerPrice"}>
                            {
                                vatFounded !== null
                                    ? <p className={"infoPrice"} style={{color: vatFounded.priceList.color}}>{vatRates.find(_ => _.id === vatFounded.vat_rate_id).object.name.replace("%", " %")}</p>
                                    : <ForbiddenIcon classname={"forbidden"} fill={"#9C9C9C"}/>
                            }
                            {
                                (env.type === "company" || (env.type === "store" && (obj.sharedUuid === null || company.socialReason === null || (obj.sharedUuid !== null && company.socialReason !== null && settings.allowedPriceUpdate))))
                                    ? <div className={"priceOverview" + (editVat ? " visible" : "")}>
                                        {
                                            editVat
                                                ? <div className={"background"} style={{backgroundColor: vatFounded.priceList.color + "22"}}>
                                            {loadingVat ? <LoaderCircle display="loader restInLinePrice" strokeWidth="8" stroke="#00355B"/> : <select value={vatFounded.vat_rate_id} onChange={handleChangeVat} onBlur={handleSaveVat} autoFocus={true}>
                                                {vatRates.map((vatRate, index) => (<option key={index} value={vatRate.id}>{vatRate.value.replace('%', ' %')}</option>))}
                                            </select>}
                                        </div>
                                                : <div className={"background"} style={(vatFounded !== null ? {backgroundColor: vatFounded.priceList.color + "22"} : {backgroundColor: "#64646422"})}>
                                            {vatFounded !== null ? <p className={"textPrice"} style={{color: vatFounded.priceList.color}}>
                                                {vatRates.find(_ => _.id === vatFounded.vat_rate_id).object.name.replace("%", " %")}
                                            </p> : <ForbiddenIcon classname={"forbidden"} fill={"#9C9C9C"}/>}
                                            {vatFounded !== null && <div className={"edit"} onClick={handleEditVat}/>}
                                            <Link to={"/catalogs/" + obj.catalog_id + "/products/" + obj.id + "/pricelists"}>
                                                <div className={"open"}/>
                                            </Link>
                                        </div>
                                        }
                                    </div>
                                    : <div />
                            }
                        </div>
                    default:
                        return <span>{obj[attributes[0]] !== null ? obj[attributes[0]].replace(".", ",").replace("%", " %") : ""}</span>
                }
            case "fromNow":
                return <p>
                    Par {((obj[attributes[1]] !== undefined && obj[attributes[1]] !== null) ? buildFirstCharCapitalize(obj[attributes[1]].toLocaleLowerCase()) : "...")}<br/>
                    <span>il y a {displaySince(obj[attributes[0]])}</span>
                </p>
            case "rulesAccount":
                return Account.buildStrRules(obj[attributes[0]])
            case "stateAccount":
                return Account.buildState(obj.status, obj.onlyHivy, obj.created_at, obj.validity)
            case "catalogName":
                let color = "#AAAAAA"

                switch (item) {
                    case "pricelists":
                    case "categories":
                    case "subcategories":
                    case "products":
                        if (obj["catalog"] !== undefined && obj["catalog"] !== null && obj["catalog"].color !== null) color = obj["catalog"].color

                        break
                    case "catalogs":
                        if (obj["color"] !== undefined && obj["color"] !== null) color = obj["color"]

                        break
                    default:
                        break
                }

                if (classnameLine === "waiting" && attributes[0] === "name") {
                    return <div className={"withWaiting"}>
                        <LoaderCircle display="waiting" hide="" strokeWidth="8" />
                        <div style={{borderColor: color}}>
                            <div className="color" style={{backgroundColor: color}}></div>
                            <p className="text">{obj[attributes[0]]}</p>
                        </div>
                    </div>
                } else {
                    return <div style={{borderColor: color}}>
                        <div className="color" style={{backgroundColor: color}}></div>
                        <p className="text">{obj[attributes[0]]}</p>
                    </div>
                }
            case "catalogOrigin":
                return obj.sharedUuid !== null ? "Partagé par le groupe" : "Local"
            case "storesManagement":
                return <div className={"wrapLicenses"}>
                    <p className={"list"}>{ obj[attributes[0]] }</p>
                    <p className={"button" + (classnameLine !== "" ? " locked" : "")} onClick={classnameLine === "" ? () => { action(obj.id) } : () => {}}>Attribuer</p>
                </div>
            case "licensesManagement":
                let locked = false;

                if (item === "screens" && obj.synced_at === null && obj.licenses.length === 0) {
                    locked = true;
                }

                return <div className={"wrapLicenses"}>
                    <p className={"list"}>{ obj[attributes[0]] }</p>
                    <p className={"button" + (locked ? " locked" : "")} onClick={(!locked ? () => { action(obj.id) } : () => {})} onMouseOver={(locked ? () => { setBubbleText("Vous devez d'abord installer votre/vos caisse(s)") } : () => {})} onMouseOut={() => { setBubbleText(null) }}>Attribuer</p>
                </div>
            default:
                break
        }
    }
    const buildFirstCharCapitalize = string => {
        if (string === undefined || string === null || string.length === 0) return string
        let explode = string.split(" ")

        for (let i in explode)
            explode[i] = StringTools.capitalizeFirstLetter(explode[i], i > 0)

        return explode.join(" ")
    }
    const getClassname = () => {
        let classname = "";
        let indexFound;

        switch (item) {
            case "catalogs":
                if (showDeployingCatalog.length > 0) {
                    indexFound = showDeployingCatalog.findIndex(_ => _.id === obj.id);

                    if (indexFound >= 0) {
                        if (showDeployingCatalog[indexFound].planned_deployments_from.length > 0) {
                            for (let i in showDeployingCatalog[indexFound].planned_deployments_from) {
                                if (moment(showDeployingCatalog[indexFound].planned_deployments_from[i].propose_at, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                                    classname = "waiting";
                                    break;
                                }
                            }
                        }

                        if (showDeployingCatalog[indexFound].planned_deployments_to.length > 0) {
                            for (let i in showDeployingCatalog[indexFound].planned_deployments_to) {
                                if (moment(showDeployingCatalog[indexFound].planned_deployments_to[i].propose_at, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                                    classname = "waiting";
                                    break;
                                }
                            }
                        }
                    }
                }

                break;
            case "screens":
                if (showDeployingScreen.length > 0) {
                    indexFound = showDeployingScreen.findIndex(_ => _.id === obj.id);

                    if (indexFound >= 0) {
                        if (showDeployingScreen[indexFound].planned_deployments_from.length > 0) {
                            for (let i in showDeployingScreen[indexFound].planned_deployments_from) {
                                if (moment(showDeployingScreen[indexFound].planned_deployments_from[i].propose_at, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                                    classname = "waiting";
                                    break;
                                }
                            }
                        }

                        if (showDeployingScreen[indexFound].planned_deployments_to.length > 0) {
                            for (let i in showDeployingScreen[indexFound].planned_deployments_to) {
                                if (moment(showDeployingScreen[indexFound].planned_deployments_to[i].propose_at, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
                                    classname = "waiting";
                                    break;
                                }
                            }
                        }
                    }
                }

                break;
            default: break;
        }

        setClassnameLine(classname);
    }
    const buildLineFromModel = () => {
        return model.map((column, index) => (
            <td key={ index } className={ column.class }>
                {
                    buildForType(column.attributes, column.type, (column.action !== undefined ? column.action : null))
                }
            </td>
        ))
    }
    const buildVisibilityOpenable = () => {
        switch (item) {
            case "screens":
                if (openable && classnameLine === "") {
                    if (obj.sharedUuid === null || obj.sharedUuid.length === 0) {
                        return <td className="access"><Link to={buildLinkOpenable()}>
                            <button><span>Voir</span></button>
                        </Link></td>
                    }
                }

                break;
            default:
                if (openable && classnameLine === "") {
                    return <td className="access"><Link to={buildLinkOpenable()}>
                        <button><span>Voir</span></button>
                    </Link></td>
                }

                break;
        }
    }
    const buildLinkOpenable = () => {
        switch (item) {
            case "screens":
            case "sales":
                return "/" + item.toLowerCase() + "/" + obj.id
            case "pricelists":
            case "categories":
            case "subcategories":
            case "products":
                return "/catalogs/" + obj.catalog_id + "/" + item.toLowerCase() + "/" + obj.id + "/information"
            default:
                return "/" + item.toLowerCase() + "/" + obj.id + "/information"
        }
    }
    const handleEditPrice = () => {
        setEditPrice(true)
    }
    const handleEditVat = () => {
        setEditVat(true)

        let idPriceFounded = objUpdated.prices.findIndex(_ => _.price_list_id === priceableValue)

        if (idPriceFounded < 0) {
            let priceFounded = obj.prices.find(_ => _.priceList.main === 1)
            let value = priceFounded !== undefined ? priceFounded.value : 0
            let price = new Price({
                value: value,
                stringValue: value.replace('.', ','),
                price_list_id: priceableValue,
                priceable_id: obj.id,
                priceable_type: "Product",
                variation_id: null,
                product_id : obj.id
            })

            let objUpdatedTmp = Object.assign({}, objUpdated)
            objUpdatedTmp.prices.push(price)
            setObjUpdated(objUpdatedTmp)
        }
    }
    const handleChangePrice = event => {
        let value = event.currentTarget.value.replace(",", ".")
        let regex = /^\d+(?:\.\d{0,2})?$/ // accurency validation
        if (value.length > 0 && !regex.test(value)) return

        value = FormBuilder.buildVal("string", event.currentTarget.value.replace('.', ','))

        let objUpdatedTmp = Object.assign({}, objUpdated)
        let pricelist = priceable.find(_ => _.id === priceableValue).object
        let price = getPriceByValues(false)
        let priceVariation = getPriceVariationByValues()
        let idPriceFounded
        let idPriceVariationFounded
        let stores = []

        if (price === null || priceVariation === null) return

        if (env.type === "company") {
            if (storeableValue === null) {
                for (let i in env.stores) {
                    stores.push(env.stores[i].id)
                }
            }
            else
                stores.push(storeableValue)
        }
        else
            stores.push(env.id)

        for (let i in stores) {
            if (pricelist.main === 1) {
                if (env.type === "company")
                    idPriceFounded = objUpdatedTmp.prices.findIndex(_ => _.price_list_id === price.price_list_id && _.store_id === stores[i])
                else
                    idPriceFounded = objUpdatedTmp.prices.findIndex(_ => _.price_list_id === price.price_list_id)

                objUpdatedTmp.prices[idPriceFounded].value = parseFloat(value.replace(',', '.'))
                objUpdatedTmp.prices[idPriceFounded].stringValue = value
            }
            else {
                if (priceVariation === undefined) {
                    priceVariation = new PriceVariation({
                        store_id: stores[i],
                        catalog_id: obj.catalog_id,
                        price_list_id: priceableValue,
                        variable_id: obj.id,
                        variable_type: "Product",
                        variationOperator: "=",
                        value: parseFloat(value.replace(',', '.')),
                        type: "DEVISE"
                    })

                    objUpdatedTmp.price_variations.push(priceVariation)
                }
                else {
                    if (env.type === "company")
                        idPriceVariationFounded = objUpdatedTmp.price_variations.findIndex(_ => _.price_list_id === priceVariation.price_list_id && _.store_id === stores[i])
                    else
                        idPriceVariationFounded = objUpdatedTmp.price_variations.findIndex(_ => _.price_list_id === priceVariation.price_list_id)

                    objUpdatedTmp.price_variations[idPriceVariationFounded].variationOperator = "="
                    objUpdatedTmp.price_variations[idPriceVariationFounded].value = parseFloat(value.replace(',', '.'))
                    objUpdatedTmp.price_variations[idPriceVariationFounded].type = "DEVISE"
                }
            }
        }

        setObjUpdated(objUpdatedTmp)
    }
    const handleChangeVat = event => {
        let value = parseInt(event.currentTarget.value)
        let objUpdatedTmp = Object.assign({}, objUpdated)
        let idVatFounded = objUpdatedTmp.vats.findIndex(_ => _.price_list_id === priceableValue)
        let vat = getVatByValues(false)
        let stores = []

        if (vat === null) return

        if (env.type === "company") {
            if (storeableValue === null) {
                for (let i in env.stores) {
                    stores.push(env.stores[i].id)
                }
            }
            else
                stores.push(storeableValue)
        }
        else
            stores.push(env.id)

        for (let i in stores) {
            if (env.type === "company")
                idVatFounded = objUpdatedTmp.vats.findIndex(_ => _.price_list_id === priceableValue && _.store_id === stores[i])
            else
                idVatFounded = objUpdatedTmp.vats.findIndex(_ => _.price_list_id === priceableValue)

            if (idVatFounded < 0) {
                let vat = new Vat({
                    store_id: stores[i],
                    price_list_id: priceableValue,
                    applyable_id: obj.id,
                    applyable_type: "Product",
                    vat_rate_id: value
                })

                objUpdatedTmp.vats.push(vat)
            }
            else {
                objUpdatedTmp.vats[idVatFounded].vat_rule_id = null
                objUpdatedTmp.vats[idVatFounded].vat_rate_id = value
            }
        }

        setObjUpdated(objUpdatedTmp)
    }
    const handleKeyDownPrice = event => {
        if (event.key === "Enter") {
            event.preventDefault()
            handleSavePrice()
        }
    }
    const handleSavePrice = () => {
        let price = getPriceByValues(false)
        let priceVariation = getPriceVariationByValues()
        if (price === null || priceVariation === null) return

        setLoadingPrice(true)

        let pricelist = priceable.find(_ => _.id === priceableValue).object
        let controller
        let stores = []

        if (env.type === "company") {
            if (storeableValue === null) {
                for (let i in env.stores) {
                    stores.push(env.stores[i].id)
                }
            }
            else
                stores.push(storeableValue)
        }
        else
            stores.push(env.id)

        for (let i in stores) {
            if (pricelist.main) {
                controller = new PriceController()
                controller._callback = handleReturnSavePrice

                if (env.type === "company")
                    price = objUpdated.prices.find(_ => _.price_list_id === priceableValue && _.store_id === stores[i])
                else
                    price = objUpdated.prices.find(_ => _.price_list_id === priceableValue)

                if (price.id === 0)
                    controller.post(obj.catalog_id, obj.id, {price_list_id: priceableValue, value: price.value})
                else
                    controller.put(obj.catalog_id, obj.id, price, {value: price.value})
            }
            else {
                controller = new PriceVariationController()
                controller._callback = handleReturnSavePrice

                if (env.type === "company") {
                    price = objUpdated.prices.find(_ => _.price_list_id === priceableValue && _.store_id === stores[i])
                    priceVariation = objUpdated.price_variations.find(_ => _.price_list_id === priceableValue && _.store_id === stores[i])
                }
                else {
                    price = objUpdated.prices.find(_ => _.price_list_id === priceableValue)
                    priceVariation = objUpdated.price_variations.find(_ => _.price_list_id === priceableValue)
                }

                if (priceVariation.id === 0) {
                    controller.post(obj.catalog_id, obj.id, {
                        price_list_id: priceableValue,
                        variationOperator: priceVariation.variationOperator,
                        value: priceVariation.value,
                        type: priceVariation.type
                    })
                }
                else {
                    controller.put(obj.catalog_id, obj.id, price, {
                        variationOperator: priceVariation.variationOperator,
                        value: priceVariation.value,
                        type: priceVariation.type
                    })
                }
            }
        }
    }
    const handleReturnSavePrice = (response, error, status) => {
        setLoadingPrice(false)
        setEditPrice(false)

        switch (status) {
            case 204:
                refreshProduct()
                break
            default: break
        }
    }
    const handleSaveVat = () => {
        let vat = getVatByValues(false)
        if (vat === null) return

        setLoadingVat(true)

        let controller
        let stores = []
        let vatFounded

        if (env.type === "company") {
            if (storeableValue === null) {
                for (let i in env.stores) {
                    stores.push(env.stores[i].id)
                }
            }
            else
                stores.push(storeableValue)
        }
        else
            stores.push(env.id)

        for (let i in stores) {
            controller = new VatController()
            controller._callback = handleReturnSaveVat

            if (env.type === "company")
                vatFounded = objUpdated.vats.find(_ => _.price_list_id === priceableValue && _.store_id === stores[i])
            else
                vatFounded = objUpdated.vats.find(_ => _.price_list_id === priceableValue)

            if (vatFounded.id === 0)
                controller.post(obj.catalog_id, obj.id, {price_list_id: priceableValue, vat_rate_id: vatFounded.vat_rate_id})
            else
                controller.put(obj.catalog_id, obj.id, vatFounded, {vat_rate_id: vatFounded.vat_rate_id})
        }
    }
    const handleReturnSaveVat = (response, error, status) => {
        setLoadingVat(false)
        setEditVat(false)

        switch (status) {
            case 204:
                refreshProduct()
                break
            default: break
        }
    }
    const refreshProduct = () => {
        const controller = new ProductController()
        controller._callback = returnRefreshProduct
        controller.show(obj.catalog_id, obj.id)
    }
    const returnRefreshProduct = (object, error, status) => {
        switch (status) {
            case 200:
                setObjUpdated(object)
                break
            default: break
        }
    }

    useEffect(() => {
        colorLine()
    }, [checked])
    useEffect(() => {
        refreshObjUpdated()
    }, [obj])
    useEffect(() => {
        getClassname();
    }, [showDeployingCatalog, showDeployingScreen])

    return(
        <tr id={ "line-" + obj.id } className={classnameLine}>
            {
                checkable
                && <td className="checkbox"><InputCheckbox name={ "selected-" + obj.id } checked={ checked } handleChange={ handleClickOnCheckbox } /></td>
            }
            {
                buildLineFromModel()
            }
            {
                buildVisibilityOpenable()
            }
        </tr>
    )
}

export default Line
