import React from "react";
import Display from "../../../../stories/_setting/Displays/Display";
import FormStart from "../../../form/display/FormStart";
import '../../../../css/overbox/DisplayInformation.css';

const NewObject = props => {
    const {handleIndex} = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Display() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject;
