import React from "react";

function SellerIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 508.008 508.008" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path d="M452.879 103.372c16.352 16.351 25.363 38.137 25.363 61.334s-9.011 44.983-25.363 61.334l21.046 21.046c21.976-21.975 34.083-51.232 34.083-82.38s-12.107-60.404-34.083-82.38z" />
            <path d="M431.834 124.417l-21.046 21.046c10.61 10.61 10.61 27.877 0 38.487l21.046 21.046c22.222-22.209 22.222-58.371 0-80.579zM330.961 60.524l-69.457 208.363h62.759l69.458-208.363zM231.739 298.653h-31.372l-9.921 29.766h-34.864l9.92-29.766H72.7l-29.49 90.345c-9.391 28.875 12.069 58.485 42.454 58.485H115.9l29.761-89.298h66.237zM74.415 268.887h155.723l69.457-208.363H74.415C33.316 60.524 0 93.841 0 134.94v59.532c0 41.099 33.316 74.415 74.415 74.415z" />
        </svg>
    );
}

export default SellerIcon;
