import React from "react";

function PricelistIcon (props) {
    const { fill } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={ fill }>
            <path d="M27.4 15.49L11.51 43a5 5 0 001.83 6.83l15.16 8.76A6.94 6.94 0 0128 56V24.23a11 11 0 013.08-7.64L37.46 10l-4.76 1.34a9 9 0 00-5.3 4.15zM4.85 33.58a5 5 0 000 7.07l4 4A7 7 0 019.78 42C12.11 38 22.41 20.13 28 10.53c-.67.52.55-.63-23.15 23.05zM43.71 39H44v-4h-.29a2 2 0 000 4z" />
            <path d="M51 2.87a13.63 13.63 0 01-2.94 6.4 5 5 0 00-7.17 0L32.52 18A9 9 0 0030 24.23V56a5 5 0 005 5h19a5 5 0 005-5V24.23A9 9 0 0056.48 18l-5.61-5.82a13 13 0 01-4.49 3.17 2 2 0 11-1.88-2.67 2 2 0 011.47.66 10.36 10.36 0 001.75-1 14.06 14.06 0 001.76-1.57A15.22 15.22 0 0053 3.12zM43.71 33H44v-2.43h2V33h.29a4 4 0 014 4h-2a2 2 0 00-2-2H46v4h.29a4 4 0 010 8H46v2.43h-2V47h-.29a4 4 0 01-4-4h2a2 2 0 002 2H44v-4h-.29a4 4 0 010-8z" />
            <path d="M48.29 43a2 2 0 00-2-2H46v4h.29a2 2 0 002-2z" />
        </svg>
    );
}

export default PricelistIcon;
