import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import usePrevious from "../../../../class/tool/usePrevious"
import NewObject from "../../../overbox/sheet/pricelist/NewObject"
import ObjectSheet from "../../../sheet/ObjectSheet"
import Listing from "../../../table/Listing"
import PricelistController from "../../../../stories/_catalog/Pricelists/PricelistController"
import ListingContext from "../../../../context/ListingContext"
import '../../../../css/page/content/pricelist/Pricelist.css'
import CatalogController from "../../../../stories/_catalog/Catalogs/CatalogController"
import FormBuilder from "../../../../class/tool/FormBuilder"

const Pricelists = props => {
    const { page } = props
    const item = "pricelists"
    const itemClass = "pricelist"
    const titleWindow = "Tarifs"
    const placeholderSearch = "un tarif"
    const titleNbItems = "tarifs"
    const emptyList = "Aucun tarif"
    const textRemoveButton = "ce tarif"
    const activeHistory = false
    const settings = JSON.parse(localStorage.getItem("storeSettings"))
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"))
    const company = JSON.parse(localStorage.getItem("company"));
    const queryParams = new URLSearchParams(window.location.search)
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1)
    const [ loading, setLoading ] = useState(true)
    const [ model, setModel ] = useState([
        {
            "class": "name",
            "sortingParam": "name",
            "title": "Nom",
            "attributes": ["name"],
            "type": "text"
        },
        {
            "class": "reference",
            "sortingParam": "reference",
            "title": "Référence",
            "attributes": ["reference"],
            "type": "text"
        }
    ])
    const [ list, setList ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ secondaryOptions, setSecondaryOptions ] = useState([])
    const [ filterValues, setFilterValues ] = useState({})
    const [ filterRows, setFilterRows ] = useState([])
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(25)
    const [ sortingName, setSortingName ] = useState("updated_at")
    const [ sortingValue, setSortingValue ] = useState("desc")
    const [ catalogId, setCatalogId ] = useState(queryParams.get("catalog") !== null ? queryParams.get("catalog") : (Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null))
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")
    const [ catalogs, setCatalogs ] = useState([
        {
            value: "Tous les catalogues",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const prevPerPage = usePrevious(perPage)
    const prevCatalogId = usePrevious(catalogId)
    const prevInput = usePrevious(input)

    const updateModel = () => {
        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        let modelsTmp = [
            {
                "class": "name",
                "sortingParam": "name",
                "title": "Nom",
                "attributes": ["name"],
                "type": "text"
            },
            {
                "class": "reference",
                "sortingParam": "reference",
                "title": "Référence",
                "attributes": ["reference"],
                "type": "text"
            }
        ]

        if (catalogs.length > 1 && catalogId === null) {
            modelsTmp.splice(1, 0, {
                "class": "catalogName",
                "sortingParam": "catalog_name",
                "title": "Catalogue",
                "attributes": ["catalog_name"],
                "type": "catalogName"
            })
        }

        setModel(modelsTmp)
    }
    const initFilters = () => {
        const catalogs = JSON.parse(localStorage.getItem("catalogs"))
        let filtersTmp = []
        let marginLeft = ""

        if (Object.keys(defaultCatalog).length === 0 && catalogs.length > 1) {
            filtersTmp.push({
                attribute: "catalog_id",
                inputType: "select",
                returnType: "int",
                list: catalogs,
                dictionary: null,
                classnameInput: "select marginTop leftAlign"
            })

            marginLeft = " marginLeft"
        }

        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "text marginTop" + marginLeft,
            placeholder: "Rechercher " + placeholderSearch,
        })

        setFilterValues(prev => ({
            ...prev,
            catalog_id: catalogId,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const initOptions = () => {
        let optionsTmp = []
        let secondaryOtionsTmp = []

        if (settings === null || company.socialReason === null || (company.socialReason !== null && settings.allowedProductCreation === 1)) {
            optionsTmp.push({
                "class": "add",
                "title": "Créer " + placeholderSearch,
                "action": "",
                "link": "/" + item + "/new"
            })
        }

        setOptions(optionsTmp)
        setSecondaryOptions(secondaryOtionsTmp)
    }
    const getPricelists = (pCatalog = null, pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller = new PricelistController()
        let paramCatalog = pCatalog !== null ? pCatalog : filterValues.catalog_id
        let paramInput = pTextInput !== "" ? pTextInput : input
        let paramPage = pPage !== 1 ? pPage : pageSelect
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue

        setLoading(true)

        controller._callback = handleGetPricelists
        controller.index(paramCatalog, paramInput, paramPage, paramPerPage, false, paramSortingName, paramSortingValue)
    }
    const handleGetPricelists = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setList(list)
                setPagination(pagination !== undefined ? pagination : null)

                break
            default:
                console.log(error)
                break
        }

        setLoading(false)
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = catalogs.slice()

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setCatalogs(listTmp)
                break
            default: break
        }
    }
    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const updateFilters = type => {
        if (filterRows.length === 0) return
        let filtersTmp = filterRows.slice()

        switch (type) {
            case "catalog":
                let indexCatalogRow = filtersTmp.findIndex(_ => _.attribute === "catalog_id")
                if (indexCatalogRow < 0) return

                filtersTmp[indexCatalogRow].list = catalogs
                break
            default: break
        }

        setFilterRows(filtersTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "catalog_id":
                setCatalogId(obj.value)
                break
            case "input":
                setInput(obj.value)
                break
            default: break
        }
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || catalogId !== prevCatalogId || input !== prevInput) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getPricelists(filterValues.catalog_id, filterValues.input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        var keys = Object.keys(listTmp[index]);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp);
    }
    const handleRemove = () => {
        handleRefresh();
    }

    useEffect(() => {
        document.title = "Back office - " + titleWindow;

        getCatalogs()
        updateModel()
        initFilters()
        initOptions()
    }, [])
    useEffect(() => {
        updateFilters("catalog")
    }, [catalogs])
    useEffect(() => {
        if (catalogId !== prevCatalogId) {
            updateModel()
        }

        handleRefresh()
    }, [perPage, catalogId, pageSelect, sortingName, sortingValue])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={"/" + item + "/new"}>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route path={ "/catalogs/:idCatalog/pricelists/:id/(information|categories)" }>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={ handleUpdate }
                        handleRemove={ handleRemove } />
                </Route>
            </Switch>
        </ListingContext.Provider>
    )
}

export default Pricelists
