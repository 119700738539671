import React from "react"
import StoreItem from "./StoreItem"
import '../../../../../css/page/content/access/CompanyItem.css'

const CompanyItem = () => {
    const env = JSON.parse(localStorage.getItem("env"))

    const listStores = stores => {
        stores = stores.sort((s1, s2) => {
            if (s1.name > s2.name) return 1
            else if (s1.name < s2.name) return -1
            else return 0
        })

        return stores.map(item => (
            <StoreItem key={ item.id } store={ item } />
        ))
    }

    return(
        <div className="wrapCompany">
            <div className="company">
                <p className="title">{ env.name }</p>
            </div>
            <div className="stores">
                {
                    env.stores !== undefined && env.stores.length > 0
                        ? listStores(env.stores)
                        : <p className="empty">Aucune boutique</p>
                }
            </div>
        </div>
    )
}

export default CompanyItem
