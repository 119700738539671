import Rest from "../../../class/tool/Rest"
import moment from "moment"
moment.locale("fr")

class ArchiveController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "archives"

    zip(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id + '/zip',
            {},
            this.handleZip
        )
    }
    handleZip = (response, error, status = 200) => {
        this._callback(response, error, status)
    }

    download(uri, key) {
        this.#_rest._completeUrl = false

        this.#_rest.sendQuery(
            'GET',
            uri.replace("download/", "download?key=") + key,
            {},
            this.handleDownload,
            null,
            "blob"
        )

        this.#_rest._completeUrl = true
    }
    handleDownload = (response, error, status = 200) => {
        this._callback(response, error, status)
    }

    unzip(id, key) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + id + '/unzip',
            {
                key: key
            },
            this.handleUnzip
        )
    }
    handleUnzip = (response, error, status = 200) => {
        this._callback(response, error, status)
    }
}

export default ArchiveController