import React, {useContext, useState} from "react";
import LoaderCircle from "../../../loader/LoaderCircle";
import FormBuilder from "../../../../class/tool/FormBuilder";
import DashboardController from "../../../../stories/_statistic/Dashboard/DashboardController";
import "../../../../css/page/content/analyze/ExportStats.css";
import BackofficeContext from "../../../../context/BackofficeContext";

const ExportStats = props => {
    const analyseExportFormats = [
        {
            id: 1,
            main: "PDF",
            secondary: ""
        },
        {
            id: 2,
            main: "Excel",
            secondary: ""
        },
        {
            id: 3,
            main: "Json",
            secondary: ""
        }
    ];
    const saleExportFormats = [
        {
            id: 1,
            main: "Excel",
            secondary: ""
        },
        {
            id: 2,
            main: "Json",
            secondary: ""
        }
    ];
    const env = JSON.parse(localStorage.getItem("env"));

    const { type, cancel, dates } = props;
    const { setValidText } = useContext(BackofficeContext);
    const [ exporting, setExporting ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ values, setValues ] = useState({
        mails: "",
        formats: [1]
    });
    const [ errors, setErrors ] = useState([]);
    const [ rows, setRows ] = useState([
        {
            label: "Emails supplémentaires (séparer par une virgule)",
            attribute: "mails",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: ""
        },
        {
            label: "Fichiers",
            attribute: "formats",
            inputType: "multiline",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "auto",
            classnameNoInput: "",
            list: type === "analyse" ? analyseExportFormats : saleExportFormats
        }
    ]);

    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        valid()
    }
    const valid = () => {
        setErrors(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const check422Errors = errorDatas => {
        setError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const checkMails = () => {
        const regxp = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        const explodeEmails = values.mails.split(",")
        let mails = [];

        for (let index in explodeEmails) {
            if (regxp.test(explodeEmails[index].trim())) {
                mails.push(explodeEmails[index].trim().toLowerCase());
            }
            else {
                setError("Un ou plusieurs emails ne sont pas valides : \"" + explodeEmails[index].trim() + "\"");
                return null;
            }
        }

        return mails;
    }
    const checkFormats = () => {
        if (values.formats.length < 1) {
            setError("Vous devez saisir au moins un format d'export")
            return null;
        }

        let exportFormats = type === "analyse" ? analyseExportFormats : saleExportFormats;
        let formats = [];

        for (let i in values.formats) {
            formats.push(exportFormats[exportFormats.findIndex(_ => _.id === values.formats[i])].main.toLowerCase());
        }

        return formats;
    }
    const buildDatas = () => {
        let datas = {
            mails: [],
            formats: []
        };

        if (values.mails.length > 0) {
            let mails = checkMails();

            if (mails === null)
                return;

            datas.mails = mails;
        }

        let formats = checkFormats();

        if (formats === null)
            return;

        datas.formats = formats;

        return datas;
    }
    const save = () => {
        if (exporting)
            return;

        let datas = buildDatas();
        if (datas === null)
            return;

        setExporting(true);

        const user = JSON.parse(localStorage.getItem("user"));
        const controller = new DashboardController();
        controller._callback = handleReturnSave;

        switch (type) {
            case "analyse":
                controller.exportPosState(user.email, dates, datas.mails, datas.formats, env.type === "company" ? false : null);
                break;
            case "sale":
                controller.exportSales(user.email, dates, datas.mails, datas.formats);
                break;
            default:
                setExporting(false);
                break;
        }
    }
    const handleReturnSave = (response, error, status) => {
        setExporting(false)

        switch (status) {
            case 200:
                setValidText("La demande d'export est enregistrée");
                cancel();
                break
            case 422:
                check422Errors(error)
                break
            default:
                setError("Une erreur s'est produite lors de la demande d'export")
                break
        }
    }

    return(
        <div className={"overlayer"}>
            <div className={"wrapOverbox"}>
                <div className={"overbox start"}>
                    <div className="form">
                        <p className={"titleForm center"}>{ type === "analyse" ? "Exporter vos analyses" : "Exporter vos ventes" }</p>
                        {
                            error !== null && <p className="globalError">{error}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        row.noLabel === undefined
                                        && <label className={ row.classnameLabel }>{ row.label }</label>
                                    }
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <p className={"submit " + (exporting ? "hide" : "")} onClick={valid}>
                            {exporting ? "Enregistrement de la demande..." : "Envoyer"}
                            <LoaderCircle display="loader submitForm " hide={!exporting ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                        </p>
                        <p className="cancel align" onClick={cancel}>Fermer</p>
                        <div className="clearing"/>
                    </div>
                </div>
            </div>
        </div>)
}

export default ExportStats;
