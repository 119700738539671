import React from "react";

function HelpIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path
                d="M17 2.433H7a4.724 4.724 0 00-5 5v6a4.724 4.724 0 005 5v2.132a1 1 0 001.555.832L13 18.433h4a4.724 4.724 0 005-5v-6a4.724 4.724 0 00-5-5zM12.002 14.6h-.004a.75.75 0 11.004 0zm1.257-4.152c-.387.26-.51.432-.51.706v.207a.75.75 0 01-1.5 0v-.207a2.314 2.314 0 011.172-1.949c.369-.25.488-.415.488-.678a.91.91 0 00-1.818 0 .75.75 0 01-1.5 0 2.41 2.41 0 014.818 0 2.282 2.282 0 01-1.15 1.921z"
                data-name={1}
            />
        </svg>
    );
}

export default HelpIcon;
