import React from "react"
import ReplaceIcon from "../../../icons/ReplaceIcon"
import SwapIcon from "../../../icons/SwapIcon"

const KeyDecision = props => {
    const { object, type, cancel, save } = props

    const valid = code => {
        save(object, code)
    }

    const buildTitle = () => {
        return <p className={"title"}>Que souhaitez-vous faire ?</p>
    }
    const buildContent = () => {
        return <div className={"nav double"}>
            <div className={"choice"} onClick={() => { valid(1) }}>
                <ReplaceIcon />
                <p className={"title"}>Remplacer</p>
                <p className={"description"}>Écraser la touche existante et la remplacer par celle-ci</p>
            </div>
            <div className={"choice" + (type === "erase" ? " disable" : "")} onClick={(type === "reverse" ? () => { valid(2) } : () => {})}>
                <SwapIcon />
                <p className={"title"}>Inverser</p>
                <p className={"description"}>Inverser la touche avec celle-ci</p>
            </div>
        </div>
    }

    return(
        <div className={"actionDefined"}>
            <div className={"box"}>
                { buildTitle() }
                { buildContent() }
                <p className={"close"} onClick={cancel}>Annuler</p>
            </div>
        </div>
    )
}

export default KeyDecision
