import Shareable from "../../../class/abstract/Shareable"
import Catalog from "../Catalogs/Catalog"
import VatRule from "../VatRules/VatRule";
import Vat from "../Vats/Vat";

class SubCategory extends Shareable {
    class_type = "SubCategory";
    name = null;
    label = null;
    reference = null;
    referenceProvider = null;
    sharedUuid = null;
    brand_id = null;
    groupment_id = null;
    parentItem_id = null;
    catalog_id = null;
    catalog = null;
    catalog_name = null;
    category_id = null;
    category_name = "";
    vat_rate_id = null;
    vatRules = [];
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = [
                    "catalog",
                    "vatRules"
                ]

                if (!exceptKeys.includes(key)) {
                    if(key === "last_histo_author")
                        Reflect.set(this, key, datas[key] !== null ? datas[key].toLowerCase() : "");
                    else
                        Reflect.set(this, key, datas[key]);
                }
            }
        }

        if (datas.catalog !== undefined && datas.catalog !== null) {
            this.catalog = new Catalog(datas.catalog)
            this.catalog_name = this.catalog.name
        }
        else if (datas.catalog_id !== null) {
            const catalogs = JSON.parse(localStorage.getItem("catalogs"))
            this.catalog = catalogs.find(_ => _.id === datas.catalog_id)
        }

        if (this.catalog !== undefined && this.catalog !== null)
            this.catalog_name = this.catalog.name

        if (datas.vatRules !== undefined && datas.vatRules.length > 0)
            for (let i in datas.vatRules)
                this.vatRules.push(new VatRule(datas.vatRules[i]))

        if (this.vat_rate_id === null && datas.vatRules !== undefined && datas.vatRules.length > 0)
            this.vat_rate_id = datas.vatRules[0].vat_rate_id
    }
}

export default SubCategory
