class Entity {
    id = 0
    uuid = null
    sharedUuid = null
    created_at = null
    updated_at = null
    deleted_at = null
    synced_at = null

    constructor(datas) {
        if (datas === null) return

        if(this.constructor === Entity)
            throw new TypeError('Abstract class "Entity" cannot be instantiated directly')

        this.id = datas.id !== undefined ? datas.id : 0
        this.uuid = datas.uuid !== undefined ? datas.uuid : null
        this.created_at = datas.created_at !== undefined ? datas.created_at : null
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : null
        this.deleted_at = datas.deleted_at !== undefined ? datas.deleted_at : null
        this.synced_at = datas.synced_at !== undefined ? datas.synced_at : null
    }
}

export default Entity
