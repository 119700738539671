import Rest from "../../../class/tool/Rest"
import Action from "./Action"

class ActionController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "actions"

    index(param, withoutHidden = true) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '?' + param + (withoutHidden ? '&hidden=false' : ""),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(!error) {
            if(response.data !== undefined) {
                const data = response.data

                for(var item in data)
                    listObjects.push(new Action(data[item]))
            }
        }

        this._callback(listObjects, error, status)
    }

    show(idAction) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + idAction,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Action(response.data) : null
        this._callback(object, error, status)
    }
}

export default ActionController
