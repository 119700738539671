import Entity from "../../../class/abstract/Entity";
import Action from "../Actions/Action";

class SellerGroup extends Entity {
    name = "";
    reference = "";
    locked = false;
    url = "";
    urlSync = "";
    forbiddenActions = [];
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "forbiddenActions") {
                    for (let item in datas[key])
                        this.forbiddenActions.push(new Action(datas[key][item]));
                }
                else Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default SellerGroup;
