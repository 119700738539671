import React, { useState } from "react"
import FormBuilder from "../../../class/tool/FormBuilder"

const ProductPricelistsFormSheet = props => {
    const company = JSON.parse(localStorage.getItem("company"));
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, pricelists, vatrates, category, updated, setGlobalError, handleChange, handleAdd, handleRemove, handleRecovery, handleSave } = props
    const [ rows, setRows ] = useState([
        {
            label: "",
            attribute: "pricelist",
            returnType: "pricelist",
            inputType: "pricelist",
            classnameLabel: "label",
            classnameNoInput: "",
            emptyText: "",
            devise: "€",
            pricelists: pricelists,
            vatrates: vatrates,
            category: category,
            shared: object.sharedUuid !== null,
            readOnly: !(object.sharedUuid === null || company.socialReason === null || (object.sharedUuid !== null && storeSettings !== null && company.socialReason !== null && storeSettings.allowedPriceUpdate === 1))
        }
    ])

    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, errors, change, null, recovery, null, add, remove)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const add = (idPricelist, idStore) => {
        handleAdd(idPricelist, idStore)
    }
    const remove = (idPricelist, idStore) => {
        handleRemove(idPricelist, idStore)
    }
    const recovery = (idPricelist, idStore, type, mainPrice) => {
        handleRecovery(idPricelist, idStore, type, mainPrice)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        handleSave()
    }

    return (
        <form className={ "form fullHeight" + (updated ? " withSavingBar" : "") } onSubmit={ handleSubmit }>
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default ProductPricelistsFormSheet
