import React from "react"
import { Link } from "react-router-dom"
import '../../css/Options.css'

const Options = props => {
    const { item, show, options, handleAction } = props

    return (
        <div className={"overOptions " + item + (show ? " show" : "")}>
            <nav className="options">
                {
                    options.map((option, index) => (
                        option.action !== ""
                        ? <p key={ index } className={ "itemOption " + option.class } onClick={ () => { handleAction(option.action) } }>{ option.title }</p>
                        : <Link key={ index } to={ option.link }><p className={ "itemOption " + option.class }>{ option.title }</p></Link>
                    ))
                }
            </nav>
        </div>
    )
}

export default Options
