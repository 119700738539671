import React from "react";

const SilverCupIcon = () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <g>
                        <path
                            d="m137.914 274.383c-4.146 0-8.354-.566-12.534-1.753-7.968-2.264-12.589-10.549-10.322-18.506s10.569-12.57 18.532-10.308c8.415 2.39 17.2-2.498 19.593-10.896 2.393-8.399-2.502-17.175-10.912-19.565-29.498-8.274-56.167-28.74-75.046-57.615-18.985-29.038-29.02-64.042-29.02-101.227v-.116c.052-14.62 5.781-28.295 16.132-38.559 10.304-10.218 23.962-15.838 38.475-15.838.067 0 .131.001.198.001 30.114.107 54.527 24.658 54.421 54.728-.029 8.254-6.74 14.926-14.999 14.926-.018 0-.036 0-.054 0-8.284-.029-14.976-6.759-14.947-15.031.048-13.553-10.955-24.618-24.527-24.666-6.591-.044-12.764 2.511-17.429 7.137s-7.247 10.789-7.271 17.354v.064c0 30.906 8.573 61.04 24.141 84.85 14.906 22.799 35.535 38.839 58.087 45.165 24.37 6.925 38.525 32.305 31.605 56.591-5.73 20.112-24.15 33.262-44.123 33.264z"
                            fill="#c4caf3"></path>
                        <path
                            d="m374.086 274.383c-19.976.002-38.392-13.15-44.123-33.265-6.921-24.285 7.235-49.666 31.556-56.577 22.601-6.34 43.23-22.379 58.136-45.178 15.568-23.811 24.141-53.945 24.141-84.85v-.116c-.023-6.512-2.605-12.675-7.27-17.302-4.644-4.605-10.799-7.137-17.34-7.137-.03 0-.059 0-.089 0-13.572.048-24.574 11.113-24.526 24.666.029 8.272-6.663 15.002-14.947 15.031-.018 0-.036 0-.054 0-8.26 0-14.97-6.671-14.999-14.926-.106-30.07 24.307-54.621 54.42-54.728.066 0 .132-.001.198-.001 14.515 0 28.171 5.62 38.476 15.838 10.352 10.264 16.081 23.939 16.132 38.506v.169c0 37.185-10.035 72.189-29.02 101.227-18.878 28.875-45.547 49.341-75.095 57.629-8.361 2.376-13.256 11.153-10.863 19.551s11.182 13.286 19.593 10.897c7.963-2.264 16.265 2.35 18.532 10.308 2.267 7.957-2.354 16.242-10.322 18.506-4.179 1.186-8.393 1.752-12.536 1.752z"
                            fill="#a8b1ec"></path>
                    </g>
                    <g>
                        <g>
                            <path d="m331.754 422.663h-151.508c-8.284 0-15-6.706-15-14.979 0-32.803 17.664-62.704 45.754-78.709v-62.635c-6.485-2.755-12.7-6.1-18.566-9.995-32.082-21.303-51.234-56.989-51.234-95.46v-145.906c0-8.272 6.716-14.979 15-14.979h199.602c8.284 0 15 6.706 15 14.979v145.906c0 38.472-19.153 74.158-51.234 95.46-5.867 3.895-12.081 7.24-18.566 9.995v62.635c28.09 16.005 45.754 45.906 45.754 78.709-.002 8.273-6.717 14.979-15.002 14.979z" fill="#e0e4f8"></path>
                            <path d="m319.566 256.345c32.082-21.302 51.234-56.988 51.234-95.46v-145.906c0-8.272-6.716-14.979-15-14.979h-99.8v422.662h75.754c8.284 0 15-6.706 15-14.979 0-32.803-17.664-62.704-45.754-78.709v-62.634c6.485-2.755 12.7-6.1 18.566-9.995z" fill="#c4caf3"></path>
                        </g>
                        <g>
                            <path
                                d="m253.453 197.373c-18.721 0-20.216-.498-22.196-1.157-4.792-1.596-8.524-5.383-9.987-10.133-2.473-8.03 1.471-13.187 7.442-20.994 4.736-6.193 13.552-17.72 28.334-38.81 4.748-6.774 6.692-11.528 7.476-14.467l.203-1.59c-.37-4.598-4.236-8.226-8.934-8.226-4.276 0-7.975 3.028-8.795 7.2-1.597 8.117-9.488 13.404-17.61 11.811-8.129-1.595-13.424-9.468-11.828-17.585 3.577-18.185 19.656-31.383 38.233-31.383 21.485 0 38.963 17.454 38.963 38.908 0 .635-.04 1.269-.121 1.899l-.463 3.617c-.064.498-.152.993-.266 1.483-1.84 7.925-5.971 16.508-12.28 25.51-6.912 9.863-12.558 17.691-17.115 23.894 6.15-.036 12.729-.098 19.026-.177 8.241-.092 15.083 6.518 15.188 14.789.103 8.272-6.527 15.062-14.811 15.166-13.409.171-23.222.245-30.459.245z"
                                fill="#c4caf3"></path>
                            <g>
                                <path d="m296 311.372h-80c-8.284 0-15-6.706-15-14.979s6.716-14.979 15-14.979h80c8.284 0 15 6.706 15 14.979s-6.716 14.979-15 14.979z" fill="#c4caf3"></path>
                                <path d="m296 281.414h-40v29.957h40c8.284 0 15-6.706 15-14.979s-6.716-14.978-15-14.978z" fill="#a8b1ec"></path>
                            </g>
                            <path d="m283.535 167.176c-6.297.079-12.876.14-19.026.177 4.558-6.203 10.203-14.032 17.115-23.894 6.31-9.002 10.44-17.585 12.28-25.51.113-.49.202-.985.266-1.483l.463-3.617c.081-.63.121-1.264.121-1.899 0-21.384-17.366-38.79-38.754-38.903v29.962c4.602.107 8.36 3.687 8.725 8.216l-.203 1.59c-.783 2.939-2.728 7.693-7.476 14.467-.358.51-.695.989-1.046 1.488v69.602c6.949-.015 16.012-.09 27.912-.239 8.283-.104 14.914-6.894 14.811-15.166-.105-8.274-6.947-14.883-15.188-14.791z"
                                  fill="#a8b1ec"></path>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="m395.631 512h-279.262c-8.284 0-15-6.706-15-14.979v-89.337c0-8.272 6.716-14.979 15-14.979h279.263c8.284 0 15 6.706 15 14.979v89.337c-.001 8.273-6.717 14.979-15.001 14.979z" fill="#ba602b"></path>
                        <path d="m395.631 392.706h-139.631v119.294h139.631c8.284 0 15-6.706 15-14.979v-89.337c0-8.272-6.716-14.978-15-14.978z" fill="#964c22"></path>
                    </g>
                    <g>
                        <path d="m415.631 512h-319.262c-8.284 0-15-6.706-15-14.979s6.716-14.979 15-14.979h319.263c8.284 0 15 6.706 15 14.979s-6.717 14.979-15.001 14.979z" fill="#964c22"></path>
                        <path d="m415.631 482.043h-159.631v29.957h159.631c8.284 0 15-6.706 15-14.979s-6.716-14.978-15-14.978z" fill="#6f3716"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SilverCupIcon;
