import React, {createRef, useEffect, useRef} from "react";
import $ from "jquery";
import "../../../../css/form/SwitchItem.css";

const SwitchItem = props => {
    const { attribute, returnType, classname, list, value, change } = props;
    const idRandom = Math.floor(Math.random() * 999999);
    const itemRef = createRef();

    const valueChanged = type => {
        if (change === undefined)
            return;

        change(type);
    }
    const placeOverlay = () => {
        let selectedElmt = $("#item_" + idRandom + "_" + value);

        if (selectedElmt.length === 0)
            return;

        let overlayElmt = $("#overlay_" + idRandom);
        let position = getPosition();

        overlayElmt.css({ width: selectedElmt.width(), height: selectedElmt.height(), marginLeft: position.x });

        if (position.first) {
            overlayElmt.css({ borderRadius: "10px 0 0 10px" });
        }
        else if (position.last) {
            overlayElmt.css({ borderRadius: "0 10px 10px 0" });
        }
        else {
            overlayElmt.css({ borderRadius: "0" });
        }
    }
    const getPosition = () => {
        let first = false;
        let last = false;
        let x = 0;

        for (let i in list) {
            if (list[i].value === value) {
                if (parseInt(i) === 0)
                    first = true;
                else if (parseInt(i) === (list.length-1))
                    last = true;

                break;
            }

            x += $("#item_" + idRandom + "_" + list[i].value).width();
        }

        return {
            first,
            last,
            x
        };
    }

    useEffect(() => {
        placeOverlay();
    }, [value, itemRef]);

    return (
        <div className={"SwitchItem" + (classname !== undefined ? " " + classname : "")}>
            <div id={"overlay_" + idRandom} className={"overlay"} />
            {
                list.map((object, index) => (
                    <div key={index} id={"item_" + idRandom + "_" + object.value} ref={object.value === value ? itemRef : undefined} className={"item" + (value === object.value ? " selected" : "")} onClick={() => { valueChanged(object.value) }}>
                        <p className={"label"}>{ object.label }</p>
                    </div>
                ))
            }
        </div>
    )
}

export default SwitchItem;
