import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import SellerGroupController from "../../../stories/_setting/SellerGroups/SellerGroupController";
import FormBuilder from "../../../class/tool/FormBuilder";
import '../../../css/form/Form.css';

const FormStart = props => {
    const item = "sellergroups";
    const controller = new SellerGroupController();

    const { object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ]);

    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError("");
        reinitAllEdits();
        save();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            setReload(true);
            handleIndex();
        }

        setSaving(false);
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.post(datas);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        initValues();
    }, []);

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            <form className="form" onSubmit={handleSubmit}>
                {
                    globalError !== "" && <p className="globalError">{globalError}</p>
                }
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                            }
                        </div>
                    ))
                }
                <button className={"submit " + (saving ? "hide" : "")}>
                    {
                        saving
                            ? "Enregistrement..."
                            : "Enregistrer"
                    }
                    <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
                <Link to={ "/" + item }>
                    <button className="cancel align">Fermer</button>
                </Link>
                <div className="clearing" />
            </form>
        </div>
    )
}

export default FormStart;
