import React, {useEffect, useState} from "react"
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet"
import FormBuilder from "../../../class/tool/FormBuilder"
import AuthController from "../../../stories/_auth/Auth/AuthController"

const CatalogHeaderSheet = props => {
    const { object, values, errors, handleChange, handleSave, handleAction } = props
    const [ edit, setEdit ] = useState("")
    const [ actions, setActions ] = useState([])
    const [ rows, setRows ] = useState([
        {
            label: "",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "",
            classnameInput: "noMarginTop noBackground whiteForeground high",
            classnameNoInput: "noMarginTop noBackground whiteForeground",
            placeholder: "nom",
            emptyText: "Aucun",
            edit: false
        }
    ])

    const buildActions = () => {
        const env = JSON.parse(localStorage.getItem("env"))
        const settings = JSON.parse(localStorage.getItem("storeSettings"))

        if (env.type === "company" || (env.type === "store" && settings.allowedProductCreation && object.sharedUuid === null)) {
            setActions([
                {
                    title: "Supprimer",
                    type: "remove"
                }
            ])
        }
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    useEffect(() => {
        buildActions()
    }, [])

    return (
        <div className="headerSheet">
            <div className="infos">
                <form
                    className="form noPaddingLR"
                    onSubmit={ handleSubmit } >
                    {
                        rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                {
                                    buildRow(row)
                                }
                            </div>
                        ))
                    }
                </form>
            </div>
            <ActionListHeaderSheet actions={ actions } handleAction={ handleAction } />
        </div>
    )
}

export default CatalogHeaderSheet
