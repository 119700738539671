import React from "react"
import PosScreen from "../../../../stories/_setting/PosScreens/PosScreen"
import FormStart from "../../../form/screen/FormStart"

const NewObject = props => {
    const { handleIndex } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new PosScreen() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject
