import Rest from "../../../class/tool/Rest"
import Company from "./Company"

class CompanyController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "companies"

    static checkSocialReason(val) {
        const regxp = /^[\w ]{6,7}$/g

        if(val === "") throw new Error("EMPTY")
        if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }

    checkValues(object) {
        let errors = {}

        try { CompanyController.checkSocialReason(object.socialReason) }
        catch (e) { errors.socialReason = e.message }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data.data

            for(var item in data)
                listObjects.push(new Company(data[item]))
        }

        this._callback(listObjects, error)
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Company(response.data) : null
        this._callback(object, error, status)
    }

    post() {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            {},
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(company) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + company.id,
            company,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    tellMeWhatINeed(details = false) {
        this.#_rest.sendQuery(
            'GET',
            '/screens/tellmewhatineed' + (details ? "?detail" : ""),
            {},
            this.handleTellMeWhatINeed)
    }
    handleTellMeWhatINeed = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default CompanyController
