import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import Store from "../../../../stories/_account/Stores/Store";
import GoldCupIcon from "../../../icons/GoldCupIcon";
import SilverCupIcon from "../../../icons/SilverCupIcon";
import BronzeCupIcon from "../../../icons/BronzeCupIcon";
import ArrowTopIcon from "../../../icons/ArrowTopIcon";
import '../../../../css/page/content/analyze/TableStats.css';

const TableStats = props => {
    const { refreshStats, setRefreshStats, ca, sales } = props;
    const [ datas, setDatas ] = useState(null);
    const [ sortedBy, setSortedBy ] = useState("inclTax");
    const [ sortedValue, setSortedValue ] = useState("desc");
    const env = JSON.parse(localStorage.getItem("env"));

    const extractTableDatas = () => {
        if (!refreshStats || ca === null || sales === null) return;

        setRefreshStats(false);

        let datasTmp = [];
        let store;
        let datasStore;

        if (
            sales["date"] !== undefined
            && sales["date"]["period"] !== undefined
            && Object.keys(sales["date"]["period"]).length === 0
            && sales["versus"] !== undefined
            && sales["versus"]["period"] !== undefined
            && Object.keys(sales["versus"]["period"]).length === 0
            && sales["delta"] !== undefined
            && sales["delta"]["period"] !== undefined
            && Object.keys(sales["delta"]["period"]).length === 0
            && ca["date"] !== undefined
            && ca["date"]["period"] !== undefined
            && Object.keys(ca["date"]["period"]).length === 0
            && ca["versus"] !== undefined
            && ca["versus"]["period"] !== undefined
            && Object.keys(ca["versus"]["period"]).length === 0
            && ca["delta"] !== undefined
            && ca["delta"]["period"] !== undefined
            && Object.keys(ca["delta"]["period"]).length === 0
        ) {
            setDatas(datasTmp);
            return;
        }

        switch (env.type) {
            case "company":
                for (let i in env.stores) {
                    store = new Store(env.stores[i]);

                    if (
                        ca[store.id]["date"]["period"] === undefined
                        || ca[store.id]["versus"]["period"] === undefined
                        || ca[store.id]["delta"]["period"] === undefined
                        || sales[store.id]["date"]["period"] === undefined
                        || sales[store.id]["versus"]["period"] === undefined
                        || sales[store.id]["delta"]["period"] === undefined
                    )
                        continue;

                    datasStore = {
                        date: {
                            ca: ca[store.id]["date"]["period"].datas,
                            sales: sales[store.id]["date"]["period"].datas
                        },
                        versus: {
                            ca: ca[store.id]["versus"]["period"].datas,
                            sales: sales[store.id]["versus"]["period"].datas
                        },
                        delta: {
                            ca: ca[store.id]["delta"]["period"].datas,
                            sales: sales[store.id]["delta"]["period"].datas
                        }
                    };

                    datasTmp.push({
                        label: store.shortName,
                        address: store.postalCode + " " + store.city,
                        data: datasStore
                    });
                }

                break;
            case "store":
                store = new Store(JSON.parse(localStorage.getItem("store")));

                datasStore = {
                    date: {
                        ca: ca["date"]["period"].datas,
                        sales: sales["date"]["period"].datas
                    },
                    versus: {
                        ca: ca["versus"]["period"].datas,
                        sales: sales["versus"]["period"].datas
                    },
                    delta: {
                        ca: ca["delta"]["period"].datas,
                        sales: sales["delta"]["period"].datas
                    }
                };

                datasTmp.push({
                    label: store.shortName,
                    address: store.postalCode + " " + store.city,
                    data: datasStore
                });

                break;
            default: break;
        }

        datasTmp.sort(sorting);

        setDatas(datasTmp);
    }
    const sorting = (a, b) => {
        switch (sortedValue) {
            case "asc":
                if (a.data.date.ca[sortedBy] < b.data.date.ca[sortedBy])
                    return -1;
                if (a.data.date.ca[sortedBy] > b.data.date.ca[sortedBy])
                    return 1;
                else
                    return 0;
            case "desc":
                if (a.data.date.ca[sortedBy] > b.data.date.ca[sortedBy])
                    return -1;
                if (a.data.date.ca[sortedBy] < b.data.date.ca[sortedBy])
                    return 1;
                else
                    return 0;
                break;
            default: break;
        }
    }
    const content = () => {
        if (datas === null)
            return <></>;

        switch (env.type) {
            case "company":
                return <div className={"TableStats"}>
                    <table>
                        <tbody>
                        <tr className={"header"}>
                            <th colSpan={2}></th>
                            <th className={"right"}>CA TTC</th>
                            <th className={"right"}>CA HT</th>
                            <th className={"right"}>NB VENTES</th>
                            <th className={"right"}>PANIER MOYEN</th>
                            <th className={"right"}>PRODUITS VENDUS</th>
                        </tr>
                        {datas.map((store, index) => buildLine(store, index))}
                        </tbody>
                    </table>
                    <div className={"containerInfoBlock"}>
                        {datas.map((store, index) => buildInfoBlock(store, index))}
                    </div>
                </div>;
            case "store":
                return <div className={"TableStats wrapKeys"}>
                    { buildInfoBlock(datas[0], 0) }
                </div>;
            default: return;
        }

    }
    const buildLine = (store, index) => {
        const ranks = [<GoldCupIcon/>, <SilverCupIcon/>, <BronzeCupIcon/>];

        const datas = [{value: parseFloat(store.data.date.ca.inclTax), versus: parseFloat(store.data.versus.ca.inclTax), deltaValue: parseFloat(store.data.delta.ca.inclTaxValue), deltaPercent: parseFloat(store.data.delta.ca.inclTaxPercent), decimal: 2}, {
            value: parseFloat(store.data.date.ca.exclTax),
            versus: parseFloat(store.data.versus.ca.exclTax),
            deltaValue: parseFloat(store.data.delta.ca.exclTaxValue),
            deltaPercent: parseFloat(store.data.delta.ca.exclTaxPercent),
            decimal: 2
        }, {value: parseFloat(store.data.date.sales.nb), versus: parseFloat(store.data.versus.sales.nb), deltaValue: parseFloat(store.data.delta.sales.nbValue), deltaPercent: parseFloat(store.data.delta.sales.nbPercent), decimal: 0 },
            { value: parseFloat(store.data.date.sales.averagePrice), versus: parseFloat(store.data.versus.sales.averagePrice), deltaValue: parseFloat(store.data.delta.sales.averagePriceValue), deltaPercent: parseFloat(store.data.delta.sales.averagePricePercent), decimal: 2 },
            { value: parseFloat(store.data.date.sales.articleNumber), versus: parseFloat(store.data.versus.sales.articleNumber), deltaValue: parseFloat(store.data.delta.sales.articleNumberValue), deltaPercent: parseFloat(store.data.delta.sales.articleNumberPercent), decimal: 0 }
        ];

        return <tr key={index}>
            <td className={"rank"}>{ (index < 3 && store.data.date.ca.inclTax > 0) && ranks[index] }</td>
            <td className={"left"}>{ store.label }</td>
            {
                datas.map((data, index) => (
                    <td key={index}>
                        <div className={"container"}>
                            <div className={"value"}>
                                <ArrowTopIcon classname={"arrow" + (data.deltaValue < 0 ? " down" : (data.deltaValue > 0 ? " up" : ""))} />
                                <NumberFormat className={"value"} value={data.value} decimalScale={data.decimal} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                            </div>
                            <NumberFormat className={"versus"} value={data.versus} decimalScale={data.decimal} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                            <div className={"delta"}>
                                <NumberFormat className={"deltaValue " + (data.deltaValue > 0 ? "more" : (data.deltaValue < 0 ? "less" : ""))} value={data.deltaValue} decimalScale={data.decimal} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                                <NumberFormat className={"deltaPercent " + (data.deltaPercent > 0 ? "more" : (data.deltaPercent < 0 ? "less" : ""))} prefix={(data.deltaPercent >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(data.deltaPercent)} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                            </div>
                        </div>
                    </td>
                ))
            }
        </tr>
    }
    const buildInfoBlock = (store, index) => {
        switch (env.type) {
            case "company":
                return <div key={index} className={"infoBlock"}>
                    <p className={"name"}>{ store.label }</p>
                    <p className={"address"}>{ store.address }</p>
                    <div className={"containerDatas"}>
                        <div className={"column"}>
                            <div className={"value"}>
                                <NumberFormat className={"value"} value={parseFloat(store.data.date.ca.inclTax)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                                <ArrowTopIcon classname={"arrow" + (parseFloat(store.data.delta.ca.inclTaxValue) < 0 ? " down" : (parseFloat(store.data.delta.ca.inclTaxValue) > 0 ? " up" : ""))} />
                            </div>
                            <NumberFormat className={"versus"} value={parseFloat(store.data.versus.ca.inclTax)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                        </div>
                        <div className={"column"}>
                            <div className={"delta"}>
                                <NumberFormat className={"deltaValue " + (parseFloat(store.data.delta.ca.inclTaxValue) > 0 ? "more" : (parseFloat(store.data.delta.ca.inclTaxValue) < 0 ? "less" : ""))} value={parseFloat(store.data.delta.ca.inclTaxValue)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                                <NumberFormat className={"deltaPercent " + (parseFloat(store.data.delta.ca.inclTaxPercent) > 0 ? "more" : (parseFloat(store.data.delta.ca.inclTaxPercent) < 0 ? "less" : ""))} prefix={(parseFloat(store.data.delta.ca.inclTaxPercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseFloat(store.data.delta.ca.inclTaxPercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                            </div>
                        </div>
                    </div>
                </div>;
            case "store":
                return <div className={"containerInfoBlock"}>
                    <div className={"infoBlock"}>
                        <p className={"name"}>CHIFFRE D'AFFAIRES</p>
                        <div className={"containerDatas"}>
                            <div className={"column"}>
                                <div className={"value"}>
                                    <NumberFormat className={"value"} value={parseFloat(store.data.date.ca.inclTax)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <ArrowTopIcon classname={"arrow" + (parseFloat(store.data.delta.ca.inclTaxValue) < 0 ? " down" : (parseFloat(store.data.delta.ca.inclTaxValue) > 0 ? " up" : ""))}/>
                                </div>
                                <NumberFormat className={"versus"} value={parseFloat(store.data.versus.ca.inclTax)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                            </div>
                            <div className={"column"}>
                                <div className={"delta"}>
                                    <NumberFormat className={"deltaValue " + (parseFloat(store.data.delta.ca.inclTaxValue) > 0 ? "more" : (parseFloat(store.data.delta.ca.inclTaxValue) < 0 ? "less" : ""))} value={parseFloat(store.data.delta.ca.inclTaxValue)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <NumberFormat className={"deltaPercent " + (parseFloat(store.data.delta.ca.inclTaxPercent) > 0 ? "more" : (parseFloat(store.data.delta.ca.inclTaxPercent) < 0 ? "less" : ""))} prefix={(parseFloat(store.data.delta.ca.inclTaxPercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseFloat(store.data.delta.ca.inclTaxPercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"infoBlock"}>
                        <p className={"name"}>VENTES</p>
                        <div className={"containerDatas"}>
                            <div className={"column"}>
                                <div className={"value"}>
                                    <NumberFormat className={"value"} value={parseFloat(store.data.date.sales.nb)} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <ArrowTopIcon classname={"arrow" + (parseFloat(store.data.delta.sales.nbValue) < 0 ? " down" : (parseFloat(store.data.delta.sales.nbValue) > 0 ? " up" : ""))}/>
                                </div>
                                <NumberFormat className={"versus"} value={parseFloat(store.data.versus.sales.nb)} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                            </div>
                            <div className={"column"}>
                                <div className={"delta"}>
                                    <NumberFormat className={"deltaValue " + (parseFloat(store.data.delta.sales.nbValue) > 0 ? "more" : (parseFloat(store.data.delta.sales.nbValue) < 0 ? "less" : ""))} value={parseFloat(store.data.delta.sales.nbValue)} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <NumberFormat className={"deltaPercent " + (parseFloat(store.data.delta.sales.nbPercent) > 0 ? "more" : (parseFloat(store.data.delta.sales.nbPercent) < 0 ? "less" : ""))} prefix={(parseFloat(store.data.delta.sales.nbPercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseFloat(store.data.delta.sales.nbPercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"infoBlock"}>
                        <p className={"name"}>PANIER MOYEN</p>
                        <div className={"containerDatas"}>
                            <div className={"column"}>
                                <div className={"value"}>
                                    <NumberFormat className={"value"} value={parseFloat(store.data.date.sales.averagePrice)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <ArrowTopIcon classname={"arrow" + (parseFloat(store.data.delta.sales.averagePriceValue) < 0 ? " down" : (parseFloat(store.data.delta.sales.averagePriceValue) > 0 ? " up" : ""))}/>
                                </div>
                                <NumberFormat className={"versus"} value={parseFloat(store.data.versus.sales.averagePrice)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                            </div>
                            <div className={"column"}>
                                <div className={"delta"}>
                                    <NumberFormat className={"deltaValue " + (parseFloat(store.data.delta.sales.averagePriceValue) > 0 ? "more" : (parseFloat(store.data.delta.sales.averagePriceValue) < 0 ? "less" : ""))} value={parseFloat(store.data.delta.sales.averagePriceValue)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                    <NumberFormat className={"deltaPercent " + (parseFloat(store.data.delta.sales.averagePricePercent) > 0 ? "more" : (parseFloat(store.data.delta.sales.averagePricePercent) < 0 ? "less" : ""))} prefix={(parseFloat(store.data.delta.sales.averagePricePercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseFloat(store.data.delta.sales.averagePricePercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            default: return;
        }
    }

    useEffect(() => {
        extractTableDatas();
    }, [ca, sales]);

    return (content());
}

export default TableStats;
