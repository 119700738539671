import React, { useEffect, useState } from "react"
import {Route, Switch, useParams} from "react-router-dom"
import AccountController from "../../../../stories/_account/Accounts/AccountController"
import Listing from "../../../table/Listing"
import NewObject from "../../../overbox/sheet/account/NewObject"
import ObjectSheet from "../../../sheet/ObjectSheet"
import ListingContext from "../../../../context/ListingContext"
import '../../../../css/page/content/account/AccountList.css'
import '../../../../css/form/Form.css'

const AccountList = props => {
    const { idStore } = props
    const item = "access/stores/" + idStore + "/accounts"
    const itemClass = "account"
    const titleWindow = "Gestion des comptes"
    const placeholderSearch = "un compte"
    const titleNbItems = "comptes"
    const emptyList = "Aucun compte"
    const textRemoveButton = "ce compte"
    const activeHistory = false
    const queryParams = new URLSearchParams(window.location.search)
    const params = useParams()
    const [ pageSelect, setPageSelect ] = useState(1)
    const [ globalError, setGlobalError ] = useState(null)
    const [ loading, setLoading ] = useState(true)
    const [ list, setList ] = useState([])
    const [ listBuilded, setListBuilded ] = useState([])
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(25)
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")

    const getAccounts = () => {
        const controller = new AccountController()

        setLoading(true)

        controller._callback = handleGetAccounts
        controller.index("store", idStore)
    }
    const handleGetAccounts = (list, error, status) => {
        setLoading(false)

        switch (status) {
            case 200:
                setList(list)
                setListBuilded(list)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de la récupération des comptes")
                break
        }
    }
    const reworkList = (pTextInput = "") => {
        setLoading(true)
        let paramInput = pTextInput !== "" ? pTextInput.toLocaleLowerCase() : input.toLocaleLowerCase()

        setListBuilded(list.slice().filter(_ => _.fullname.toLocaleLowerCase().includes(paramInput) || _.email.toLocaleLowerCase().includes(paramInput)))
        setLoading(false)
    }
    const handleChangeInput = event => {
        setInput(event.currentTarget.value)
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = (force) => {
        if (force)
            getAccounts()
        else
            reworkList(input)
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id)
        if (index < 0) return

        let listTmp = list.slice()
        let keys = Object.keys(listTmp[index])
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (object[key] !== undefined)
                listTmp[index][key] = object[key]
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh(true)
    }
    const getAccountFromId = () => {
        let finded = list.filter(_ => _.id === parseInt(params.id))
        return finded.length > 0 ? finded[0] : null
    }

    const model = [
        {
            "class": "name",
            "sortingParam": "fullname",
            "title": "Nom",
            "attributes": ["fullname"],
            "type": "text"
        },
        {
            "class": "email",
            "sortingParam": "email",
            "title": "Email",
            "attributes": ["email"],
            "type": "text"
        },
        {
            "class": "rules",
            "sortingParam": "companyRules",
            "title": "Droits groupe",
            "attributes": ["companyRules"],
            "type": "rulesAccount"
        },
        {
            "class": "rules",
            "sortingParam": "rules",
            "title": "Droits boutique",
            "attributes": ["rules"],
            "type": "rulesAccount"
        },
        {
            "class": "state",
            "sortingParam": "state",
            "title": "État",
            "attributes": ["state"],
            "type": "stateAccount"
        },
        {
            "class": "date",
            "sortingParam": "created_at",
            "title": "Date de création",
            "attributes": ["created_at"],
            "type": "date"
        }
    ]
    const options = [
        {
            "key": 1,
            "class": "add",
            "title": "Créer " + placeholderSearch,
            "action": "",
            "link": "/access/stores/" + idStore + "/accounts/new"
        }
    ]
    const secondaryOptions = []
    const filters = [
        {
            "key": 1,
            "type": "text",
            "class": "marginTop",
            "handleChange": handleChangeInput,
            "content": null,
            "value": input,
            "placeholder": "Rechercher " + placeholderSearch
        }
    ]

    useEffect(() => {
        getAccounts()
    }, [])
    useEffect(() => {
        handleRefresh(false);
    }, [perPage, pageSelect, input])

    return (
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                title={ titleWindow }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filters }
                activeHistory={ activeHistory }
                page={ pageSelect }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ listBuilded }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ null }
                sortingValue={ null }
                setSortingName={ null }
                setSortingValue={ null }
            />
            <Switch>
                <Route exact path={ "/access/stores/" + idStore + "/accounts/new" }>
                    <NewObject idStore={ idStore } handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={ "/access/stores/" + idStore + "/accounts/:id/(information)" }>
                    {
                        list.length > 0
                        && <ObjectSheet
                            objectType={ itemClass }
                            idStore={ idStore }
                            existingObject={ getAccountFromId() }
                            previousLink={ "access/stores/" + idStore + "/accounts" }
                            textRemoveButton={ textRemoveButton }
                            handleUpdate={ handleUpdate }
                            handleRemove={ handleRemove } />
                    }
                </Route>
            </Switch>
        </ListingContext.Provider>
    )
}

export default AccountList
