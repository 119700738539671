import Entity from "../../../class/abstract/Entity"
import PredefinedPaymentMethod from "../PredefinedPaymentMethods/PredefinedPaymentMethod"

class PaymentMethod extends Entity {
    name = null;
    reference = null;
    openCashRegister = null;
    assetManagement = null;
    peripheralTarget = null;
    predefined_payment_method_id = null;
    predefined_payment_method = {};
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if(key === "predefined_payment_method")
                    Reflect.set(this, key, new PredefinedPaymentMethod(datas[key]))
                else
                    Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default PaymentMethod
