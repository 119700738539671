import Rest from "../../../class/tool/Rest"
import StoreSetting from "./StoreSetting"
import FormBuilder from "../../../class/tool/FormBuilder";

class StoreSettingController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "stores/{idStore}/settings"

    static checkTimeSlotBegin(val) {
        return true;
    }

    setFormValues(store, object, setValues) {
        setValues(prev => ({
            ...prev,
            timeSlotBegin: object.timeSlotBegin,
            closingMinimalHour: object.closingMinimalHour,
            assetValidity: object.assetValidity,
            reinitSellerAfterSale: object.reinitSellerAfterSale,
            reinitSellerAfterProduct: object.reinitSellerAfterProduct,
            reinitPricelistAfterSale: object.reinitPricelistAfterSale,
            reinitPricelistAfterProduct: object.reinitPricelistAfterProduct,
            kartPrefix: object.kartPrefix,
            paymentProofLabel: object.paymentProofLabel,
            saleBarcode: object.saleBarcode,
            kartBarcode: object.kartBarcode,
            assetBarcode: object.assetBarcode,
            receiptHeader: JSON.parse(object.receiptHeader),
            receiptFooter: JSON.parse(object.receiptFooter),
            autoBarcodeSellers: object.autoBarcodeSellers,
            barcodePrefixes: JSON.parse(object.barcodePrefixes),
            theme: object.theme,
            fiscalYearMonth: object.fiscalYearMonth,
            accumulateQuantities: object.accumulateQuantities,
            accountantEmail: object.accountantEmail,
            sellerDetailsPrinting: object.sellerDetailsPrinting,
            categoryDetailsPrinting: object.categoryDetailsPrinting,
            productDetailsPrinting: object.productDetailsPrinting,
            printPaymentsDetailUnit: object.printPaymentsDetailUnit,
            forceIdentifiant: object.forceIdentifiant,
            activeDiners: object.activeDiners,
            forceDiners: object.forceDiners,
            timezone: object.timezone,
            keyPressSound: object.keyPressSound,
            saleSound: object.saleSound,
            displayNotification: object.displayNotification,
            delayNotification: object.delayNotification,
            automaticDatetimeControl: object.automaticDatetimeControl,
            automaticNotePrinting: object.automaticNotePrinting,
            sellerInterupt: object.sellerInterupt,
            sellerTimer: object.sellerTimer
        }));
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        // clean header & footer receipt
        if (datas.receiptHeader !== undefined) {
            if (typeof datas.receiptHeader !== "object")
                datas.receiptHeader = JSON.parse(datas.receiptHeader)

            let header = []

            for (let i = 0; i < datas.receiptHeader.length; i++) {
                if (datas.receiptHeader[i].text !== "") {
                    header.push(datas.receiptHeader[i])
                }
            }

            datas.receiptHeader = header
        }
        if (datas.receiptFooter !== undefined) {
            if (typeof datas.receiptFooter !== "object")
                datas.receiptFooter = JSON.parse(datas.receiptFooter)

            let footer = []

            for (let i = 0; i < datas.receiptFooter.length; i++) {
                if (datas.receiptFooter[i].text !== "") {
                    footer.push(datas.receiptFooter[i])
                }
            }

            datas.receiptFooter = footer
        }
        if (datas.barcodePrefixes !== undefined) {
            if (typeof datas.barcodePrefixes !== "object")
                datas.barcodePrefixes = JSON.parse(datas.barcodePrefixes)

            let prefixes = {}

            for (let key in datas.barcodePrefixes) {
                if (datas.barcodePrefixes[key].length > 0) {
                    prefixes[key] = datas.barcodePrefixes[key]
                }
            }

            datas.barcodePrefixes = prefixes
        }

        let obj = Object.assign({}, object)
        obj.receiptHeader = JSON.parse(object.receiptHeader)
        obj.receiptFooter = JSON.parse(object.receiptFooter)
        obj.barcodePrefixes = JSON.parse(object.barcodePrefixes)

        return FormBuilder.returnUpdates(obj, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ];
        let errors = {};
        let method;

        for (let i in params) {
            method = StoreSettingController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", id),
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new StoreSetting(response.data) : null;
        this._callback(object, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idStore}", object.store_id),
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idStore}", object.store_id),
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }

    histoTarget(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", id) + '/histos',
            {},
            this.handleHistoTarget);
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(var item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }
}

export default StoreSettingController
