import Entity from "../../../class/abstract/Entity"

class Period extends Entity {
    type = ""
    source = ""
    timeSlotBegin = ""
    timeSlotEnd = ""
    begin = ""
    end = ""
    store_id = null
    daily_period_id = null
    monthly_period_id = null
    annualy_period_id = null
    fiscal_year_period_id = null
    sub_periods = []
    closingDatas = null
    archive = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default Period