import React, { useEffect, useState } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder";

const SecureBox = props => {
    const { obj, title, text, loading, textBack, textConfirm, textConfirming, handleClose, handleConfirm } = props
    const [ values, setValues ] = useState({
        word: ""
    })
    const [ errors, setErrors ] = useState([])
    const [ globalError, setGlobalError ] = useState("")
    const [ wordToCompare, setWordToCompare ] = useState("")
    const rows = [{
        attribute: "word",
        inputType: "text",
        returnType: "string",
        classnameLabel: "label",
        classnameInput: "uppercase center",
        classnameNoInput: "",
        placeholder: "",
        emptyText: ""
    }]

    const defineWordToCompare = (length = 5) => {
        let result = ''
        const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789'
        const charactersLength = characters.length
        let counter = 0

        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
            counter += 1
        }

        setWordToCompare(result)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val).toLocaleUpperCase()
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id)

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const check = () => {
        if (wordToCompare === "" || loading) return

        if (values.word.trim() === wordToCompare)
            handleConfirm()
        else {
            setGlobalError("Le code est incorrect")
        }
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        check()
    }

    useEffect(() => {
        defineWordToCompare()
    }, [])

    return (
        <div className="overlayer hover">
            <div className="wrapOverbox">
                <div className="overbox validation">
                    <div className="form noPadding">
                        {
                            title !== undefined
                            && <p className="titleForm center">{ title }</p>
                        }
                        {
                            globalError !== ""
                            && <p className="globalError">{globalError}</p>
                        }
                        {
                            text !== undefined
                            && <p className="description">{ text }</p>
                        }
                        <p className="inputLabelSecure">Pour confirmer l'opération, veuillez saisir <b>{ wordToCompare }</b> :</p>
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <button className="submit noMarginBottom" onClick={ check }>
                            { (loading !== undefined && loading) ? (textConfirming !== undefined ? textConfirming : textConfirm) : textConfirm }
                            <LoaderCircle display="loader submitLogin" hide={(loading === undefined || !loading) ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel align" onClick={ handleClose }>{ textBack }</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecureBox
