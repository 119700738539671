class CustomKey {
    name = ""

    constructor(datas = {}) {
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default CustomKey
