import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import packageJson from "../../../../package.json";
import StringTools from "../../../class/tool/StringTools";
import CancelIcon from "../../icons/CancelIcon";
import AnalyzeIcon from "../../icons/AnalyzeIcon";
import SaleIcon from "../../icons/SaleIcon";
import ClosingIcon from "../../icons/ClosingIcon";
import CategoryIcon from "../../icons/CategoryIcon";
import SubCategoryIcon from "../../icons/SubCategoryIcon";
import ProductIcon from "../../icons/ProductIcon";
import SellerGroupIcon from "../../icons/SellerGroupIcon";
import SellerIcon from "../../icons/SellerIcon";
import ScreenIcon from "../../icons/ScreenIcon";
import PricelistIcon from "../../icons/PricelistIcon";
import PaymentIcon from "../../icons/PaymentIcon";
import PrinterIcon from "../../icons/PrinterIcon";
import DisplayIcon from "../../icons/DisplayIcon";
import BarcodeReaderIcon from "../../icons/BarcodeReaderIcon";
import HelpIcon from "../../icons/HelpIcon";
import StoreIcon from "../../icons/StoreIcon";
import CatalogIcon from "../../icons/CatalogIcon";
import PaymentTerminalIcon from "../../icons/PaymentTerminalIcon";
import LoaderCircle from "../../loader/LoaderCircle";
//import DashboardIcon from "../../icons/DashboardIcon";
import logo from "../../../image/logo/sigle-color.png";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import SessionContext from "../../../context/SessionContext";
import "../../../css/page/Menu.css";

const Menu = props => {
    const { page } = props;
    const { handleSwitch } = useContext(SessionContext);
    const [ showSubMenuStats, setShowSubMenuStats ] = useState(false);
    const [ showSubMenuCatalog, setShowSubMenuCatalog ] = useState(false);
    const [ showSubMenuClient, setShowSubMenuClient ] = useState(false);
    const [ showSubMenuSeller, setShowSubMenuSeller ] = useState(false);
    const [ showSubMenuSetting, setShowSubMenuSetting ] = useState(false);
    const [ showSubMenuPeripheral, setShowSubMenuPeripheral ] = useState(false);
    const [ showSubMenuHelp, setShowSubMenuHelp ] = useState(false);
    const [ showSubMenuStores, setShowSubMenuStores ] = useState(false);
    const [ navItems, setNavItems ] = useState([]);
    const [ switching, setSwitching ] = useState(false);
    const history = useHistory();
    const _navItems = [
        /*
        {
            "flag": "dashboard",
            "pages": ["dashboard"],
            "title": "",
            "showSubMenu": showSubMenuStats,
            "subItems": [
                {
                    "flag": "dashboard",
                    "title": "Tableau de bord",
                    "icon": <DashboardIcon />,
                    "searches": ["dashboard"]
                }
            ]
        }
        */
    ];
    const env = JSON.parse(localStorage.getItem("env"));

    const initNavItems = () => {
        let navItemsTmp = navItems.splice();
        let subItems = _navItems;

        if (AuthController.hasRules(["admin", "skytech", "accountant", "analyst"], env.type, env.id)) {
            if (AuthController.hasRules(["admin", "skytech", "accountant", "analyst"], env.type, env.id)) {
                subItems.push(
                    {
                        "flag": "analyze",
                        "title": "Analyse des ventes",
                        "icon": <AnalyzeIcon />,
                        "searches": ["analyze"]
                    }
                )

                if (env.type === "store") {
                    subItems.push(
                        {
                            "flag": "sales",
                            "title": "Historique des ventes",
                            "icon": <SaleIcon />,
                            "searches": ["sales"]
                        }
                    )
                }
            }
            if (AuthController.hasRules(["admin", "skytech", "accountant"], env.type, env.id) && env.type === "store") {
                subItems.push(
                    {
                        "flag": "closing",
                        "title": "Clôtures",
                        "icon": <ClosingIcon />,
                        "searches": ["closing"]
                    }
                )
            }

            navItemsTmp.push(
                {
                    "flag": "stats",
                    "pages": ["analyze","sales","closing"],
                    "title": "Statistiques",
                    "showSubMenu": showSubMenuStats,
                    "subItems": subItems
                }
            )
        }
        if (AuthController.hasRules(["admin", "skytech", "analyst", "products"], env.type, env.id)) {
            let subItems = []

            //if (AuthController.hasRules(["skytech"], env.type, env.id)) {
                subItems.push({
                    "flag": "catalogs",
                    "title": "Catalogues",
                    "icon": <CatalogIcon/>,
                    "searches": ["catalogs"]
                })
            //}

            subItems.push(
                {
                    "flag": "pricelists",
                    "title": "Tarifs",
                    "icon": <PricelistIcon />,
                    "searches": ["pricelists"]
                }
            )
            subItems.push(
                {
                    "flag": "categories",
                    "title": "Catégories",
                    "icon": <CategoryIcon />,
                    "searches": ["categories"]
                }
            )
            subItems.push(
                {
                    "flag": "subcategories",
                    "title": "Sous-catégories",
                    "icon": <SubCategoryIcon />,
                    "searches": ["subcategories"]
                }
            )
            subItems.push(
                {
                    "flag": "products",
                    "title": "Produits",
                    "icon": <ProductIcon />,
                    "searches": ["products"]
                }
            )

            navItemsTmp.push(
                {
                    "flag": "catalog",
                    "pages": ["categories","subcategories","products"],
                    "title": "Catalogues",
                    "showSubMenu": showSubMenuCatalog,
                    "subItems": subItems
                }
            )
        }

        if (AuthController.hasRules(["admin", "skytech", "manager"], env.type, env.id)) {
            let subItems = []

            subItems.push(
                {
                    "flag": "sellergroups",
                    "title": "Droits vendeurs",
                    "icon": <SellerGroupIcon />,
                    "searches": ["sellergroups"]
                }
            )

            if (env.type === "store") {
                subItems.push({
                    "flag": "sellers",
                    "title": "Vendeurs",
                    "icon": <SellerIcon/>,
                    "searches": ["sellers"]
                })
            }

            navItemsTmp.push(
                {
                    "flag": "seller",
                    "pages": ["sellergroups","sellers"],
                    "title": "Utilisateurs",
                    "showSubMenu": showSubMenuSeller,
                    "subItems": subItems
                }
            )
        }

        if (AuthController.hasRules(["admin", "skytech", "tech", "products"], env.type, env.id)) {
            let subItems = []

            if (AuthController.hasRules(["admin", "skytech", "tech"], env.type, env.id)) {
                subItems.push(
                    {
                        "flag": "screens",
                        "title": "Écrans",
                        "icon": <ScreenIcon />,
                        "searches": ["screens", "screenCard"]
                    }
                )
            }
            if (AuthController.hasRules(["admin", "skytech", "tech"], env.type, env.id)) {
                subItems.push(
                    {
                        "flag": "paymentmethods",
                        "title": "Modes de règlement",
                        "icon": <PaymentIcon />,
                        "searches": ["paymentmethods"]
                    }
                )
            }

            navItemsTmp.push(
                {
                    "flag": "setting",
                    "pages": ["screens","pricelists","settings","paymentmethods"],
                    "title": "Caisses",
                    "showSubMenu": showSubMenuSetting,
                    "subItems": subItems
                }
            )
        }

        if (env.type === "store") {
            if (AuthController.hasRules(["admin", "skytech", "tech"], env.type, env.id)) {
                let subItems = []

                subItems.push(
                    {
                        "flag": "printers",
                        "title": "Imprimantes",
                        "icon": <PrinterIcon />,
                        "searches": ["printers"]
                    }
                )
                subItems.push(
                    {
                        "flag": "displays",
                        "title": "Afficheurs",
                        "icon": <DisplayIcon />,
                        "searches": ["displays"]
                    }
                )
                subItems.push(
                    {
                        "flag": "barcodereaders",
                        "title": "Lecteurs code barres",
                        "icon": <BarcodeReaderIcon />,
                        "searches": ["barcodereaders"]
                    }
                )
                subItems.push(
                    {
                        "flag": "paymentterminals",
                        "title": "Terminaux de paiement",
                        "icon": <PaymentTerminalIcon />,
                        "searches": ["paymentterminals"]
                    }
                )

                navItemsTmp.push(
                    {
                        "flag": "peripheral",
                        "pages": ["printers","displays"],
                        "title": "Périphériques",
                        "showSubMenu": showSubMenuPeripheral,
                        "subItems": subItems
                    }
                )
            }
        }

        if (env.type === "company") {
            let subItems = []

            for (let i in env.stores) {
                subItems.push(
                    {
                        "switch": true,
                        "id": env.stores[i].id,
                        "title": env.stores[i].shortName,
                        "titleShort": StringTools.getFirstChars(env.stores[i].shortName),
                        "icon": <StoreIcon />,
                        "searches": ["stores"],
                        "loading": false
                    }
                )
            }

            navItemsTmp.push(
                {
                    "flag": "stores",
                    "pages": ["stores"],
                    "title": "Boutiques",
                    "showSubMenu": showSubMenuStores,
                    "subItems": subItems
                }
            )
        }

        setNavItems(navItemsTmp)
    }
    const handleClickCancelMenuMobile = () => {
        $(".menu").removeClass("visible");
    }
    const handleShowSubMenu = type => {
        hideSubMenu();

        switch (type) {
            case "stats": setShowSubMenuStats(!showSubMenuStats); break;
            case "catalog": setShowSubMenuCatalog(!showSubMenuCatalog); break;
            case "client": setShowSubMenuClient(!showSubMenuClient); break;
            case "seller": setShowSubMenuSeller(!showSubMenuSeller); break;
            case "setting": setShowSubMenuSetting(!showSubMenuSetting); break;
            case "peripheral": setShowSubMenuPeripheral(!showSubMenuPeripheral); break;
            case "help": setShowSubMenuHelp(!showSubMenuHelp); break;
            case "stores": setShowSubMenuStores(!showSubMenuStores); break;
            default: break;
        }
    }
    const hideSubMenu = () => {
        setShowSubMenuStats(false);
        setShowSubMenuCatalog(false);
        setShowSubMenuClient(false);
        setShowSubMenuSeller(false);
        setShowSubMenuSetting(false);
        setShowSubMenuPeripheral(false);
        setShowSubMenuHelp(false);
    }
    const clickAction = subItem => {
        if (subItem.func !== undefined) {
            subItem.func();
        }
        else if (subItem.switch !== undefined) {
            switchToStore(subItem.id);
        }
        else {
            clickLink('/' + subItem.flag);
        }
    }
    const clickLink = link => {
        hideSubMenu();
        handleClickCancelMenuMobile();
        history.push(link);
    }
    const switchToStore = id => {
        if (switching)
            return;

        setSwitching(true);
        setLoaderToStoreIdSwitch(id);
        handleSwitch("store", id);
    }
    const setLoaderToStoreIdSwitch = id => {
        if (env.type !== "company")
            return;

        let navItemsTmp = navItems.slice();
        let indexStores = navItemsTmp.findIndex(_ => _.flag === "stores");
        if (indexStores < 0)
            return;

        let subItems = navItemsTmp[indexStores].subItems;

        for (let i in subItems)
            subItems[i].loading = subItems[i].id === id;

        navItemsTmp[indexStores].subItems = subItems;
        setNavItems(navItemsTmp);
    }

    useEffect(() => {
        initNavItems()
    }, []);

    return (
        <div id="menu" className={ "menu " + env.type }>
            <CancelIcon classname="cancelMenuMobile" click={ handleClickCancelMenuMobile } />
            <img className={"logo"} src={logo} alt={"SkyTill"} />
            <div className="navMenu">
                {
                    navItems.map((item, index) => (
                        <nav key={index} className="listItem">
                            {
                                item.title !== ""
                                && <p className={ "item " + item.flag + (item.pages.includes(page) ? " selected" : "") } onClick={() => handleShowSubMenu(item.flag)}>
                                    <span>
                                        { item.title }
                                    </span>
                                </p>
                            }
                            <ul className={"listSubItem " + (item.showSubMenu ? "visible" : "")}>
                                {
                                    item.subItems.map((subItem, index) => (
                                        <li key={index} className={ "subItem " + item.flag + (subItem.searches.indexOf(page) >= 0 ? " selected" : "") } onClick={ () => { clickAction(subItem) } }>
                                            <div className={"containerTitle"}>
                                                { subItem.icon }
                                                <span className={"title"}>{ subItem.title }</span>
                                            </div>
                                            {
                                                subItem.titleShort !== undefined
                                                && <span className={"titleShort"}>
                                                    {
                                                        subItem.loading === undefined || (subItem.loading !== undefined && !subItem.loading)
                                                            ? subItem.titleShort
                                                            : <LoaderCircle display="loader" hide={!subItem.loading ? "hidden" : ""} strokeWidth="12" stroke="#464646" />
                                                    }
                                                </span>
                                            }
                                            {
                                                subItem.loading !== undefined
                                                && <LoaderCircle display="loader" hide={!subItem.loading ? "hidden" : ""} strokeWidth="12" stroke="#FFFFFF" />
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>
                    ))
                }
            </div>
            <p className="legalVersion">
                <strong>{ packageJson.name } { packageJson.version }</strong><br />publiée le { packageJson.publish_date }
            </p>
            <p className={ "helpPart " + env.type } onClick={() => { window.open("https://help.skytill.fr", '_blank') }}>
                <HelpIcon />
                <span>Aide en ligne</span>
            </p>
        </div>
    )
}

export default Menu;
