import React, {useEffect, useState} from "react"
import $ from "jquery"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'

const StoreHeaderFormSheet = props => {
    const { values, errors, updated, handleChange, handleSave } = props
    const [ rowsInit, setRowsInit ] = useState(false)
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([])

    const initRows = () => {
        let rowsTmp = rows.slice()
        const receiptHeader = typeof values["receiptHeader"] === "object" ? values["receiptHeader"] : JSON.parse(values["receiptHeader"])
        const receiptFooter = typeof values["receiptFooter"] === "object" ? values["receiptFooter"] : JSON.parse(values["receiptFooter"])

        for (let i = 0; i < receiptHeader.length; i++) {
            rowsTmp.push(
                {
                    noLabel: true,
                    initialAtribute: "receiptHeader",
                    attribute: "header" + i,
                    inputType: "textHeader",
                    returnType: "stringHeader",
                    classnameInput: "headerText headerLine" + i,
                    placeholder: "",
                    maxChars: 42,
                    hidden: false,
                    focus: i === 1
                }
            )
        }

        rowsTmp.push(
            {
                noLabel: true,
                initialAtribute: "receiptHeader",
                attribute: "header" + receiptHeader.length,
                inputType: "textHeader",
                returnType: "stringHeader",
                classnameInput: "headerText headerLine" + receiptHeader.length,
                placeholder: "",
                maxChars: 42,
                hidden: false,
                focus: false
            }
        )

        for (let i = 0; i < receiptFooter.length; i++) {
            rowsTmp.push(
                {
                    noLabel: true,
                    initialAtribute: "receiptFooter",
                    attribute: "footer" + i,
                    inputType: "textHeader",
                    returnType: "stringFooter",
                    classnameInput: "headerText footerLine" + i + (i === 0 ? " interTicket" : ""),
                    placeholder: "",
                    maxChars: 42,
                    hidden: false,
                    focus: false
                }
            )
        }

        rowsTmp.push(
            {
                noLabel: true,
                initialAtribute: "receiptFooter",
                attribute: "footer" + receiptFooter.length,
                inputType: "textHeader",
                returnType: "stringFooter",
                classnameInput: "headerText footerLine" + receiptFooter.length + (receiptFooter.length === 0 ? " interTicket" : ""),
                placeholder: "",
                maxChars: 42,
                hidden: false,
                focus: false
            }
        )

        setRows(rowsTmp)
        setRowsInit(true)
    }
    const checkLines = () => {
        let rowsTmp = null
        const receiptHeader = typeof values["receiptHeader"] === "object" ? values["receiptHeader"] : JSON.parse(values["receiptHeader"])
        const receiptFooter = typeof values["receiptFooter"] === "object" ? values["receiptFooter"] : JSON.parse(values["receiptFooter"])
        let maxHeaderLine = 0
        let maxFooterLine = 0
        let indexLine = 0

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].returnType === "stringHeader") {
                indexLine = parseInt(rows[i].attribute.replace("header", ""))

                if (indexLine > maxHeaderLine)
                    maxHeaderLine = indexLine
            }
            else if (rows[i].returnType === "stringFooter") {
                indexLine = parseInt(rows[i].attribute.replace("footer", ""))

                if (indexLine > maxFooterLine)
                    maxFooterLine = indexLine
            }
        }

        if (receiptHeader.length > 0 && (maxHeaderLine + 1) === receiptHeader.length) {
            rowsTmp = rows.slice()

            rowsTmp.splice(
                rowsTmp.findIndex(_ => _.attribute === "footer0"),
                0,
                {
                    noLabel: true,
                    initialAtribute: "receiptHeader",
                    attribute: "header" + receiptHeader.length,
                    inputType: "textHeader",
                    returnType: "stringHeader",
                    classnameInput: "headerText headerLine" + receiptHeader.length,
                    placeholder: "",
                    maxChars: 42,
                    hidden: false,
                    focus: false
                }
            )
        }
        else if (receiptFooter.length > 0 && (maxFooterLine + 1) === receiptFooter.length) {
            rowsTmp = rows.slice()

            rowsTmp.push(
                {
                    noLabel: true,
                    initialAtribute: "receiptFooter",
                    attribute: "footer" + receiptFooter.length,
                    inputType: "textHeader",
                    returnType: "stringFooter",
                    classnameInput: "headerText footerLine" + receiptFooter.length,
                    placeholder: "",
                    maxChars: 42,
                    hidden: false,
                    focus: false
                }
            )
        }

        if (rowsTmp !== null)
            setRows(rowsTmp)
    }
    const buildRow = row => {
        if (edit === row.attribute || ["textHeader"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit, null, null, null, null, changeBold);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        if (returnType === "stringHeader") {
            let receiptHeader = typeof values["receiptHeader"] === "object" ? values["receiptHeader"] : JSON.parse(values["receiptHeader"])
            let indexLine = parseInt(attribute.replace("header", ""))

            if (receiptHeader[indexLine] === undefined) {
                receiptHeader[indexLine] = {
                    "bold": 0,
                    "text": ""
                }
            }

            receiptHeader[indexLine].text = $(".headerLine" + indexLine + " > input").val()
            handleChange(rows, "receiptHeader", "json", JSON.stringify(receiptHeader), strict)
        }
        else if (returnType === "stringFooter") {
            let receiptFooter = typeof values["receiptFooter"] === "object" ? values["receiptFooter"] : JSON.parse(values["receiptFooter"])
            let indexLine = parseInt(attribute.replace("footer", ""))

            if (receiptFooter[indexLine] === undefined) {
                receiptFooter[indexLine] = {
                    "bold": 0,
                    "text": ""
                }
            }

            receiptFooter[indexLine].text = $(".footerLine" + indexLine + " > input").val()
            handleChange(rows, "receiptFooter", "json", JSON.stringify(receiptFooter), strict)
        }
        else
            handleChange(rows, attribute, returnType, val, strict);
    }
    const changeBold = (attribute, returnType) => {
        if (returnType === "stringHeader") {
            let receiptHeader = typeof values["receiptHeader"] === "object" ? values["receiptHeader"] : JSON.parse(values["receiptHeader"])
            let indexLine = parseInt(attribute.replace("header", ""))

            if (receiptHeader[indexLine] === undefined) {
                receiptHeader[indexLine] = {
                    "bold": 0,
                    "text": ""
                }
            }

            receiptHeader[indexLine].bold = receiptHeader[indexLine].bold === 1 ? 0 : 1
            handleChange(rows, "receiptHeader", "json", JSON.stringify(receiptHeader))
        }
        else if (returnType === "stringFooter") {
            let receiptFooter = typeof values["receiptFooter"] === "object" ? values["receiptFooter"] : JSON.parse(values["receiptFooter"])
            let indexLine = parseInt(attribute.replace("footer", ""))

            if (receiptFooter[indexLine] === undefined) {
                receiptFooter[indexLine] = {
                    "bold": 0,
                    "text": ""
                }
            }

            receiptFooter[indexLine].bold = receiptFooter[indexLine].bold === 1 ? 0 : 1
            handleChange(rows, "receiptFooter", "json", JSON.stringify(receiptFooter))
        }
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        initRows()
    }, [])
    useEffect(() => {
        if (rowsInit)
            checkLines()
    }, [values])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            <div className="ticket">
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            {
                                row.noLabel === undefined
                                && <label className={ row.classnameLabel }>{ row.label }</label>
                            }
                            {
                                buildRow(row)
                            }
                        </div>
                    ))
                }
            </div>
        </form>
    )
}

export default StoreHeaderFormSheet
