import React, {useEffect, useState} from "react";
import FormBuilder from "../../../class/tool/FormBuilder";

const SellerGroupRulesFormSheet = props => {
    const { values, errors, userActions, updated, setGlobalError, handleChange } = props;
    const [ edit, setEdit ] = useState("");
    const [ rows, setRows ] = useState([
        {
            noLabel: true,
            labelLeft: "Actions autorisées",
            labelRight: "Actions interdites",
            labelButtonLeft: "Tout interdire",
            labelButtonRight: "Tout autoriser",
            inputType: "bidirectionalList",
            attribute: "forbiddenActions",
            list: userActions,
            emptyText: "Aucune",
            classnameLabel: "label marginBottom",
            classnameInput: "",
            classnameInputLiLeft: "",
            classnameInputLiRight: "red",
            classnameNoInput: "",
            maxHeight: "*",
            updated: updated
        }
    ]);

    const buildRow = row => {
        if (edit === row.attribute || ["bidirectionalList"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }

    useEffect(() => {
        let rowsTmp = rows.slice()
        rowsTmp[0].updated = updated
        setRows(rowsTmp)
    }, [updated])

    return (
        <form className={ "form fullHeight" + (updated ? " withSavingBar" : "") } id="formRules">
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            (row.hidden === undefined || !row.hidden)
                            && <div className="clearing">
                                {
                                    row.noLabel === undefined
                                    && <label className={ row.classnameLabel }>{ row.label }</label>
                                }
                                {
                                    buildRow(row)
                                }
                            </div>
                        }
                    </div>
                ))
            }
        </form>
    );
}

export default SellerGroupRulesFormSheet;
