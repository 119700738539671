import React, {useContext, useEffect, useState} from "react"
import { Route, Switch } from "react-router-dom"
import cookie from "react-cookies"
import NewObject from "../../../overbox/sheet/catalog/NewObject"
import ObjectSheet from "../../../sheet/ObjectSheet"
import Listing from "../../../table/Listing"
import ChoiceAddCatalog from "../../../overbox/catalog/ChoiceAddCatalog"
import ChoiceListCatalog from "../../../overbox/catalog/ChoiceListCatalog"
import ConfirmBox from "../../../overbox/asking/ConfirmBox"
import SecureBox from "../../../overbox/asking/SecureBox"
import ChoiceListStore from "../../../overbox/screen/ChoiceListStore";
import CatalogController from "../../../../stories/_catalog/Catalogs/CatalogController"
import ListingContext from "../../../../context/ListingContext"
import BackofficeContext from "../../../../context/BackofficeContext";
import '../../../../css/page/content/catalog/Catalog.css'

const Catalogs = props => {
    const item = "catalogs"
    const itemClass = "catalog"
    const titleWindow = "Catalogues"
    const placeholderSearch = "un catalogue"
    const titleNbItems = "catalogues"
    const emptyList = "Aucun catalogue"
    const textRemoveButton = "ce catalogue"
    const activeHistory = false
    const queryParams = new URLSearchParams(window.location.search)
    let object_per_page = cookie.load(item + "_per_page")
    let object_sorting_name = cookie.load(item + "_sorting_name")
    let object_sorting_value = cookie.load(item + "_sorting_value")
    const env = JSON.parse(localStorage.getItem("env"))
    const settings = JSON.parse(localStorage.getItem("storeSettings"))
    const company = JSON.parse(localStorage.getItem("company"));
    const { page } = props
    const { getCatalogDeploymentInProgress } = useContext(BackofficeContext);
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1)
    const [ loading, setLoading ] = useState(true)
    const [ loadingPostCatalog, setLoadingPostCatalog ] = useState(false)
    const [ savingStore, setSavingStore ] = useState(false)
    const [ model, setModel ] = useState([])
    const [ list, setList ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(object_per_page !== undefined ? object_per_page : 25)
    const [ sortingName, setSortingName ] = useState(object_sorting_name !== undefined ? object_sorting_name : "updated_at")
    const [ sortingValue, setSortingValue ] = useState(object_sorting_value !== undefined ? object_sorting_value : "desc")
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")
    const [ formChoiceAddCatalog, setFormChoiceAddCatalog ] = useState(false)
    const [ formChoiceListCatalog, setFormChoiceListCatalog ] = useState(false)
    const [ formChoiceListStore, setFormChoiceListStore ] = useState(null)
    const [ catalogToUp, setCatalogToUp ] = useState(null)
    const [ confirmUpCatalog, setConfirmUpCatalog ] = useState(false)
    const [ secureUpCatalog, setSecureUpCatalog ] = useState(false)
    let withRefreshDeployments = false;
    let states = [];

    const initModel = () => {
        let modelTmp = [
            {
                "class": "name",
                "sortingParam": "name",
                "title": "Nom",
                "attributes": ["name"],
                "type": "catalogName"
            }
        ]

        if (env.type === "company") {
            modelTmp.splice(1, 0, {
                "class": "licenses",
                "sortingParam": "",
                "title": "Boutiques",
                "attributes": ["storesString"],
                "type": "storesManagement",
                "action": handleChoiceListStore
            });
        }
        else if (env.type === "store") {
            modelTmp.push({
                "class": "origin",
                "sortingParam": "",
                "title": "Origine",
                "attributes": [],
                "type": "catalogOrigin"
            })
        }

        setModel(modelTmp)
    }
    const initOptions = () => {
        if (env.type === "company") {
            setOptions([{
                "class": "add",
                "title": "Créer " + placeholderSearch,
                "action": handleChoiceAddCatalog
            }])
        }
        else if (env.type === "store") {
        if (settings === null || company.socialReason === null || (company.socialReason !== null && settings.allowedProductCreation === 1)) {
                setOptions([{
                    "class": "add",
                    "title": "Créer " + placeholderSearch,
                    "link": "/" + item + "/new"
                }])
            }
        }
    }
    const setCookie = () => {
        if (object_per_page !== undefined) return;
        if (object_sorting_name !== undefined) return;
        if (object_sorting_value !== undefined) return;

        object_per_page = perPage;
        object_sorting_name = sortingName;
        object_sorting_value = sortingValue;

        cookie.save(item + "_per_page", object_per_page, { path: '/' });
        cookie.save(item + "_sorting_name", object_sorting_name, { path: '/' });
        cookie.save(item + "_sorting_value", object_sorting_value, { path: '/' });
    }
    const getCatalogs = (pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller = new CatalogController()
        let paramInput = pTextInput !== "" ? pTextInput : input
        let paramPage = pPage !== 1 ? pPage : pageSelect
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue

        setLoading(true)

        controller._callback = handleGetCatalogs
        controller.index(paramInput, paramPage, paramPerPage, false, paramSortingName, paramSortingValue)
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    localStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    localStorage.setItem("catalog", JSON.stringify({}))

                setList(list)
                setPagination(pagination !== undefined ? pagination : null )
                break
            default:
                console.log(error)
                break
        }

        if (withRefreshDeployments) {
            getCatalogDeploymentInProgress();
            withRefreshDeployments = false;
        }

        setLoading(false)
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = () => {
        getCatalogs(input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        let keys = Object.keys(listTmp[index]);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp);
    }
    const handleRemove = () => {
        handleRefresh()
    }
    const handleClose = (type, reinitCatalog = false) => {
        if (reinitCatalog)
            setCatalogToUp(null);

        switch (type) {
            case "choiceAddCatalog":
                setFormChoiceAddCatalog(false);
                break;
            case "choiceListCatalog":
                setFormChoiceListCatalog(false);
                break;
            case "confirmUpCatalog":
                setConfirmUpCatalog(false);
                break;
            case "secureUpCatalog":
                setSecureUpCatalog(false);
                break;
            case "choiceListStore":
                setFormChoiceListStore(null);
                break;
            default: break;
        }
    }
    const handleChoiceListStore = idCatalog => {
        setFormChoiceListStore(idCatalog)
    }
    const handleChoiceAddCatalog = () => {
        if (env.type === "company")
            setFormChoiceAddCatalog(true)
        else if (env.type === "store")
            window.location = "/" + item + "/new"
    }
    const handleChoiceListCatalog = () => {
        handleClose("choiceAddCatalog")
        setFormChoiceListCatalog(true)
    }
    const handleChoiceNewCatalog = () => {
        handleClose("choiceAddCatalog")
        window.location = item + "/new"
    }
    const handleConfirmUpCatalog = catalog => {
        handleClose("choiceListCatalog")
        setCatalogToUp(catalog)
        setConfirmUpCatalog(true)
    }
    const handleSecureUpCatalog = () => {
        handleClose("confirmUpCatalog")
        setSecureUpCatalog(true)
    }
    const upCatalog = () => {
        setLoadingPostCatalog(true)
        postCatalog(catalogToUp, false)
    }
    const postCatalog = (datas, needsPricelist = true) => {
        const controller = new CatalogController()
        controller._callback = handlePostCatalog
        controller.post(datas, needsPricelist)
    }
    const handlePostCatalog = (response, error, status) => {
        switch (status) {
            case 201:
                if (catalogToUp !== null)
                    riseCatalog(response.data.id)
                else
                    handleRefresh()

                break
            default:
                handleClose("secureUpCatalog", true)
                setLoadingPostCatalog(false)
                break
        }
    }
    const riseCatalog = idCompanyCatalog => {
        const controller = new CatalogController()
        controller._callback = handleRiseCatalog
        controller.rise(catalogToUp.id, idCompanyCatalog)
    }
    const handleRiseCatalog = (reponse, error, status) => {
        handleClose("secureUpCatalog", true)
        setLoadingPostCatalog(false)

        switch (status) {
            case 201:
                withRefreshDeployments = true;
                handleRefresh();
                break
            default:
                console.log(error)
                break
        }
    }
    const handleConfirmChoiceStore = stores => {
        setSavingStore(true);

        let catalog = list.find(_ => _.id === formChoiceListStore);
        let controller;

        let storesToAdd = [];
        states = [];

        for (let i in stores) {
            // check if already exist
            if (catalog.stores.find(_ => _.id === stores[i]) !== undefined) continue;

            storesToAdd.push(stores[i]);
            states.push({
                error: false,
                loading: true,
                store: stores[i]
            });
        }

        if (storesToAdd.length > 0) {
            for (let i in storesToAdd) {
                controller = new CatalogController();
                controller._callback = returnSavingStores;
                controller.post(
                    {
                        source_id: catalog.id,
                        store_id: storesToAdd[i],
                        sharedUuid: catalog.uuid
                    },
                    false,
                    storesToAdd[i]);
            }
        }
        else {
            setSavingStore(false);
            handleClose("choiceListStore");
        }
    }
    const returnSavingStores = (response, error, status, context) => {
        let index = states.findIndex(_ => _.store === context);
        if (index < 0)
            return;

        states[index].loading = false;

        switch (status) {
            case 201:
                states[index].error = false;
                break;
            default:
                states[index].error = true;
                break
        }

        if (states.length === states.filter(_ => _.loading === false).length) {
            setSavingStore(false);
            handleClose("choiceListStore");
            handleRefresh();
        }
    }

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        initModel()
        initOptions()
        setCookie()
    }, []);
    useEffect(() => {
        handleRefresh()
    }, [perPage, pageSelect, input, sortingName, sortingValue]);

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ [] }
                filters={ [] }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={ "/" + item + "/new" }>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={ "/" + item + "/:id/(information|setting)" }>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={ handleUpdate }
                        handleRemove={ handleRemove }
                    />
                </Route>
            </Switch>
            {
                formChoiceAddCatalog
                && <ChoiceAddCatalog handleClose={ () => { handleClose("choiceAddCatalog", true) } } handleChoiceList={ handleChoiceListCatalog } handleChoiceNew={ handleChoiceNewCatalog } />
            }
            {
                formChoiceListCatalog
                && <ChoiceListCatalog handleClose={ () => { handleClose("choiceListCatalog", true) } } handleChoice={ handleConfirmUpCatalog } />
            }
            {
                formChoiceListStore !== null
                && <ChoiceListStore screen={list.find(_ => _.id === formChoiceListStore)} handleClose={ () => { handleClose("choiceListStore", true) } } handleChoice={ handleConfirmChoiceStore } saving={ savingStore } />
            }
            {
                (catalogToUp !== null && confirmUpCatalog)
                && <ConfirmBox
                    title="Êtes-vous sûr de vouloir remonter le catalogue ?"
                    text="Attention, les actions sur ce catalogue seront autorisées UNIQUEMENT depuis le groupe."
                    textBack="Annuler"
                    textConfirm="Je suis sûr"
                    handleClose={ () => { handleClose("confirmUpCatalog", true) } }
                    handleConfirm={ handleSecureUpCatalog } />
            }
            {
                (catalogToUp !== null && secureUpCatalog)
                && <SecureBox
                    obj={ catalogToUp }
                    title="Veuillez saisir le code de sécurité"
                    textBack="Annuler"
                    textConfirm="Confirmer"
                    textConfirming="Remontée du catalogue..."
                    loading={ loadingPostCatalog }
                    handleClose={ () => { handleClose("secureUpCatalog", true) } }
                    handleConfirm={ upCatalog } />
            }
        </ListingContext.Provider>
    )
}

export default Catalogs;
