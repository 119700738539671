import React from "react"
import { Link, useParams } from "react-router-dom"
import List from "../../page/content/account/AccountList"

const AccountList = props => {
    const { handleClose } = props
    const env = JSON.parse(localStorage.getItem("env"))

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className={ "overbox accountList" }>
                    <List idStore={ env.id } />
                    <div className="wrapButtons">
                        <p className="cancel" onClick={ handleClose }>Fermer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountList
