import Entity from "../../../class/abstract/Entity";

class VatRate extends Entity {
    value = 0;
    name = "";
    deleted = 0;
    country = 0;
    code = "";

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }
    }
}

export default VatRate;
