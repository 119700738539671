import React from "react";
import FormStart from "../../../form/seller/FormStart";
import Seller from "../../../../stories/_setting/Sellers/Seller";
import '../../../../css/overbox/SellerInformation.css';

const NewObject = props => {
    const {handleIndex} = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Seller() } handleIndex={handleIndex} />
                </div>
            </div>
        </div>
    )
}

export default NewObject;
