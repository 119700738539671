import Rest from "../../../class/tool/Rest"
import PriceVariationRule from "./PriceVariationRule"

class PriceVariationRuleController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/categories/{idCategory}/pricevariationrules"

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }

    index(input) {
        this.#_rest.sendQuery(
            'GET',
            '/pricevariations/' + (input.length > 0 ? "?input=" + input : ""),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200 && response.data !== undefined && response.data.data !== undefined) {
            const data = response.data.data

            for(let item in data)
                listObjects.push( new PriceVariationRule(data[item]))
        }

        this._callback(listObjects, error)
    }

    post(idCatalog, idCategory, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(idCatalog, idCategory, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(idCatalog, idCategory, object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default PriceVariationRuleController
