import React, {useState} from "react";
import {Link} from "react-router-dom";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import LoaderCircle from "../../loader/LoaderCircle";

import '../../../css/form/Form.css';
import '../../../css/form/FormLogin.css';

import logo from '../../../image/logo/skytill-logo-central-color.png';

const FormForgotPwd = () => {
    const [ email, setEmail ] = useState("");
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ sending, setSending ] = useState(false);
    const [ sended, setSended ] = useState(false);
    const [ globalError, setGlobalError ] = useState(null);

    const checkEmail = () => {
        const regxp = /^[a-zA-Z0-9.-_]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

        if(email === "") {
            setErrorEmail("Vous devez saisir une adresse email");
            return false;
        }

        if (!regxp.test(email)) {
            setErrorEmail("Ce n'est pas une adresse email valide");
            return false;
        }

        setErrorEmail("");

        return true;
    }

    const submit = event => {
        if (event !== undefined)
            event.preventDefault();

        if(globalError !== null)
            setGlobalError(null);

        checkEmail();

        if(!checkEmail())
            return;

        setSending(true);

        send();
    }

    const send = () => {
        const auth = new AuthController();
        auth._callback = handleSend;

        try {
            auth.forgotPwd(email);
        }
        catch (error) {
            setGlobalError("Une erreur s'est produite : " + String(error));
        }
    }
    const handleSend = (response, error)  => {
        setSending(false);

        if(error) {
            if(error.response !== undefined && error.response.status !== undefined && error.response.status === 405)
                setGlobalError("L'adresse email ne correspond a aucun compte.");
            else {
                if(error.message === "Network Error")
                    setGlobalError("Le serveur SkyTill est injoignable pour le moment");
                else
                    setGlobalError(String(error));
            }

            return;
        }

        setSended(true);
    }

    const handleChangeMail = event => {
        setEmail(event.currentTarget.value);
    }

    return (
        <form className="form formLogin" onSubmit={submit}>
            <img src={logo} alt="SkyTill Manager" className="logo" />
            {
                globalError !== null && <p className="globalError">{globalError}</p>
            }
            {
                !sended
                    ? <div className="clearing">
                        <p className="explain">Saisir l'adresse email pour récupérer votre mot de passe</p>
                        <input type="text" className={errorEmail !== "" ? "wrong" : ""} value={email} onChange={handleChangeMail} placeholder="Adresse email" />
                        {
                            errorEmail !== "" && <p className="error">{errorEmail}</p>
                        }
                        <button className="submit">
                            {sending ? "Envoi en cours..." : "Envoyer"}
                            <LoaderCircle display="loader submitLogin" hide={!sending ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                    </div>
                    : <div className="clearing">
                        <p className="explain">Votre nouveau mot de passe vous à été envoyé par email</p>
                        <Link className="forgot" to="/login"><button className="submit">Je veux me connecter</button></Link>
                    </div>
            }
        </form>
    );
}

export default FormForgotPwd;
