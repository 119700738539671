import React, { useState } from "react"
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet"
import FormBuilder from "../../../class/tool/FormBuilder"

const AccountHeaderSheet = props => {
    const { values, errors, setGlobalError, handleChange, handleSave, handleAction } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "",
            attribute: "fullname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "",
            classnameInput: "noMarginTop noBackground whiteForeground high",
            classnameNoInput: "noMarginTop noBackground whiteForeground",
            placeholder: "nom",
            emptyText: "Aucun",
            readOnly: true,
            edit: false
        }
    ])
    const actions = []

    const buildRow = row => {
        if (edit === row.attribute || ["color"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    return (
        <div className="headerSheet">
            <div className="infos">
                <form
                    className="form noPaddingLR"
                    onSubmit={ handleSubmit } >
                    {
                        rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                {
                                    buildRow(row)
                                }
                            </div>
                        ))
                    }
                </form>
            </div>
            <ActionListHeaderSheet actions={ actions } handleAction={ handleAction } />
        </div>
    )
}

export default AccountHeaderSheet
