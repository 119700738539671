import React from "react"
import FormStart from "../../../form/catalog/FormStart"
import Catalog from "../../../../stories/_catalog/Catalogs/Catalog"
import '../../../../css/overbox/CategoryInformation.css'

const NewObject = props => {
    const { handleIndex } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Catalog() } handleIndex={handleIndex} />
                </div>
            </div>
        </div>
    )
}

export default NewObject
