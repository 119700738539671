import React from "react"
import CatalogIcon from "../../icons/CatalogIcon";
import ScreenIcon from "../../icons/ScreenIcon";

const TypeToDeploy = props => {
    const { cancel, save } = props

    const valid = code => {
        save(code)
    }

    return(
        <div className={"typeToDeploy"}>
            <div className={"box"}>
                <p className={"title"}>Que souhaitez-vous déployer ?</p>
                <div className={"nav double"}>
                    <div className={"choice"} onClick={() => { valid(1) }}>
                        <CatalogIcon />
                        <p className={"title"}>Catalogues</p>
                        <p className={"description"}>Les données concernant la base produits</p>
                    </div>
                    <div className={"choice"} onClick={() => { valid(2) }}>
                        <ScreenIcon />
                        <p className={"title"}>Écrans</p>
                        <p className={"description"}>Les données concernant les écrans de caisses</p>
                    </div>
                </div>
                <p className={"close"} onClick={cancel}>Annuler</p>
            </div>
        </div>
    )
}

export default TypeToDeploy
