import Entity from "../../../class/abstract/Entity"
import KartContent from "../KartContent/KartContent";
import KartPayment from "../KartPayment/KartPayment";
import KartVat from "../KartVat/KartVat";

class Kart extends Entity {
    store_id = null
    license_id = null
    seller_id = null
    client_id = null
    note_id = null
    sale_id = null
    licenseReference = ""
    licenseName = ""
    sellerReference = ""
    sellerName = ""
    clientReference = ""
    clientName = ""
    ean = ""
    number = ""
    identifier = ""
    guests = 0
    nbClient = 0
    nbArticle = 0
    nbLine = 0
    comment = ""
    amountInclTax = 0
    amountInclTaxUndiscounted = 0
    amountExclTax = 0
    amountExclTaxUndiscounted = 0
    amountTax = 0
    amountTaxUndiscounted = 0
    discountValue = 0
    discountType = 0
    lineDiscountAmount = 0
    saleDiscountAmount = 0
    totalDiscountAmount = 0
    lineOfferAmount = 0
    saleOfferAmount = 0
    amountOfferTax = 0
    quantityBack = 0
    amountInclTaxBack = 0
    amountExclTaxBack = 0
    amountTaxBack = 0
    logicielName = ""
    version = ""
    source = ""
    isCanceled = false
    isFinalized = false
    shared = false
    toLeonidas = false
    origin_id = ""
    origin_type = ""
    held_at = ""
    store = {}
    license = {}
    seller = {}
    contents = []
    payments = []
    vats = []

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            switch (key) {
                case "contents":
                    let contents = []

                    for (let index in datas[key]) {
                        contents.push(new KartContent(datas[key][index]))
                    }

                    Reflect.set(this, key, contents)

                    break
                case "payments":
                    let payments = []

                    for (let index in datas[key]) {
                        payments.push(new KartPayment(datas[key][index]))
                    }

                    Reflect.set(this, key, payments)

                    break
                case "vats":
                    let vats = []

                    for (let index in datas[key]) {
                        vats.push(new KartVat(datas[key][index]))
                    }

                    Reflect.set(this, key, vats)

                    break
                default:
                    if (datas[key] !== undefined) {
                        Reflect.set(this, key, datas[key])
                    }

                    break
            }
        }
    }
}

export default Kart
