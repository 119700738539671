import React from "react";

function DisplayIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path d="M21.25 2H2.75C1.23 2 0 3.23 0 4.75v14.5C0 20.77 1.23 22 2.75 22h18.5c1.52 0 2.75-1.23 2.75-2.75V4.75C24 3.23 22.77 2 21.25 2zM7.5 7H6c-.551 0-1 .448-1 1v1.5a1 1 0 11-2 0V8c0-1.654 1.346-3 3-3h1.5a1 1 0 110 2zM21 16c0 1.654-1.346 3-3 3h-1.5a1 1 0 110-2H18c.551 0 1-.448 1-1v-1.5a1 1 0 112 0z" />
        </svg>
    );
}

export default DisplayIcon;
