import React from "react"

const EmpireIcon = props => {
    const { className, fill, onClick } = props;

    return (
        <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_10312658" onClick={onClick !== undefined ? onClick : ()=>{}}>
            <g>
                <path clipRule="evenodd" d="m7.78735 2.21419c-.64161-.19249-1.28735.28796-1.28735.95782v18.82799h3.5v-3.5c0-.5523.4477-1 1-1h2c.5523 0 1 .4477 1 1v3.5h3.5v-16.12799c0-.44161-.2897-.83093-.7127-.95782zm2.21265 5.78581c-.27614 0-.5.22386-.5.5v1c0 .27614.22386.5.5.5h1c.2761 0 .5-.22386.5-.5v-1c0-.27614-.2239-.5-.5-.5zm-.5 3.5c0-.2761.22386-.5.5-.5h1c.2761 0 .5.2239.5.5v1c0 .2761-.2239.5-.5.5h-1c-.27614 0-.5-.2239-.5-.5zm.5 2.5c-.27614 0-.5.2239-.5.5v1c0 .2761.22386.5.5.5h1c.2761 0 .5-.2239.5-.5v-1c0-.2761-.2239-.5-.5-.5zm2.5-5.5c0-.27614.2239-.5.5-.5h1c.2761 0 .5.22386.5.5v1c0 .27614-.2239.5-.5.5h-1c-.2761 0-.5-.22386-.5-.5zm.5 2.5c-.2761 0-.5.2239-.5.5v1c0 .2761.2239.5.5.5h1c.2761 0 .5-.2239.5-.5v-1c0-.2761-.2239-.5-.5-.5zm-.5 3.5c0-.2761.2239-.5.5-.5h1c.2761 0 .5.2239.5.5v1c0 .2761-.2239.5-.5.5h-1c-.2761 0-.5-.2239-.5-.5z" fillRule="evenodd"></path>
                <path d="m5.5 22v-17h-1.5c-.55228 0-1 .44772-1 1v15h-.5c-.27614 0-.5.2239-.5.5s.22386.5.5.5z"></path>
                <path d="m21.5 22h-3v-13.21874l1.9961 1.14065c.3116.17809.5039.50939.5039.86829v10.2098h.5c.2761 0 .5.2239.5.5s-.2239.5-.5.5z"></path>
                <path d="m13 22v-3.5h-2v3.5z"></path>
            </g>
        </svg>
    )
}

export default EmpireIcon;
