import React from "react";

function SaleIcon (props) {
    const { fill } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.392 416.392" fill={ fill }>
            <path d="M290.245 62.67c-.357 0-.713.008-1.069.024H64.522c-12.394-.577-22.909 9.002-23.486 21.396-.017.356-.025.713-.024 1.069v323.918l30.302-28.212a13.583 13.583 0 0117.763 0l39.706 35.526 39.184-35.526a14.106 14.106 0 0117.763 0l39.184 35.526 39.706-35.526a13.583 13.583 0 0117.763 0l30.302 28.212V85.159c.014-12.407-10.033-22.476-22.44-22.489zm-178.18 78.391h66.873a7.837 7.837 0 010 15.674h-66.873a7.837 7.837 0 010-15.674zm129.568 151.51H112.065a7.837 7.837 0 010-15.674h129.567a7.837 7.837 0 01.001 15.674zm0-67.918H112.065a7.837 7.837 0 010-15.674h129.567a7.837 7.837 0 01.001 15.674z" />
            <path d="M351.869 0H127.216c-13.068.284-23.513 10.961-23.51 24.033V47.02h185.469c21.35-.29 38.893 16.782 39.184 38.132v233.542a13.584 13.584 0 0116.718.522l30.302 28.212V24.033C375.383 10.961 364.938.284 351.869 0z" />
        </svg>
    );
}

export default SaleIcon;
