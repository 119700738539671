import React, {useEffect, useState} from "react"
import moment from "moment"
import '../../../../css/page/content/screen/StatusBar.css'

const StatusBar = props => {
    const { classname } = props
    const [ date, setDate ] = useState("")
    const [ hour1, setHour1 ] = useState("")
    const [ hour2, setHour2 ] = useState("")
    const [ min1, setMin1 ] = useState("")
    const [ min2, setMin2 ] = useState("")
    const [ visible, setVisible ] = useState(true)
    moment.locale('fr')

    const timer = on => {
        let date = new Date()
        let hour = date.getHours()
        let min = date.getMinutes()

        if (hour < 10) hour = "0" + hour
        else hour = hour.toString()

        if (min < 10) min = "0" + min
        else min = min.toString()

        setDate(moment().format("Do MMMM"))
        setVisible(on)
        setHour1(hour[0])
        setHour2(hour[1])
        setMin1(min[0])
        setMin2(min[1])

        setTimeout(() => { timer(!on) }, 500)
    }

    useEffect(() => {
        timer(true)
    }, [])

    return(
        <div className={"statusBar " + classname}>
            <p className={"date " + classname}>{ date }</p>
            <div className={"timer " + classname}>
                <p className={"number"}>{hour1}</p>
                <p className={"number"}>{hour2}</p>
                <p className={"point" + (visible ? " visible" : "")}>:</p>
                <p className={"number"}>{min1}</p>
                <p className={"number"}>{min2}</p>
            </div>
        </div>)
}

export default StatusBar
