import React, { useState } from "react"
import { SketchPicker  } from 'react-color'
import TrashIcon from "../../../icons/TrashIcon"
import Color from "../../../../class/tool/Color";

const InputColor = props => {
    const { attribute, returnType, classname, value, handleChange, small } = props
    const [ box, setBox ] = useState(false)

    const showBoxColor = () => {
        setBox(true)
    }
    const handleClose = () => {
        setBox(false)
    }
    const handleRemove = () => {
        handleChange(attribute, returnType, "")
    }
    const changeEvent = (color, event) => {
        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, color.hex)
    }
    const changeWithPaletteColor = color => {
        handleChange(attribute, returnType, color)
    }

    return (
        <div className={ "inputColor " + (classname !== undefined ? classname : "") }>
            <div className="color extend">
                <div className="text">
                    <p>{ value }</p>
                    <span className="puce" style={ value !== "" ? { backgroundColor: value } : {} } onClick={ showBoxColor } />
                    <TrashIcon classname="trash" handleClick={ handleRemove } />
                    <div className="clearing" />
                </div>
                <div className="palette">
                    {
                        small !== undefined
                            ? Color.defaultColorsSmall.map((color, index) => (
                                <div key={ index } className="puce" style={{backgroundColor: color}} onClick={ () => { changeWithPaletteColor(color) } } />
                            ))
                            : Color.defaultColors.map((color, index) => (
                            <div key={ index } className="puce" style={{backgroundColor: color}} onClick={ () => { changeWithPaletteColor(color) } } />
                        ))
                    }
                    <div className="puce radial" onClick={ showBoxColor } />
                </div>
            </div>
            {
                box
                && <div className="popover">
                    <div className="cover" onClick={ handleClose }/>
                    <SketchPicker
                        color={ (value !== null && value !== "") ? value : "#00A1FF" }
                        onChange={ changeEvent }
                        disableAlpha={ true } />
                </div>
            }
            <div className="clearing" />
        </div>
    )
}

export default InputColor
