import React from "react"

const ReApplyIcon = props => {
    const { fill, classname, handleClick } = props

    return (
        <svg className={ (classname !== undefined ? classname : "") } width={512} height={512} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill={ fill } onClick={ handleClick !== undefined ? handleClick : () => {} }>
            <path d="M29.87 0H2.13C.96 0 0 .96 0 2.13v27.74C0 31.04.96 32 2.13 32h27.74c1.17 0 2.13-.96 2.13-2.13V2.13C32 .96 31.04 0 29.87 0zm-5.6 24.73c0 .59-.48 1.07-1.06 1.07-.59 0-1.07-.48-1.07-1.07V13.67H11.37l3.51 3.51c.98.99-.52 2.49-1.51 1.51l-5.33-5.34c-.2-.21-.33-.51-.3-.82.02-.25.12-.5.3-.68l5.33-5.34c.42-.41 1.1-.41 1.51 0 .42.42.42 1.09 0 1.51l-3.51 3.51h11.84c.58 0 1.06.48 1.06 1.07v12.13z" />
        </svg>
    )
}

export default ReApplyIcon
