import Rest from "../../../class/tool/Rest"

class VatRuleController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/categories/{idCategory}/vatrules"

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }

    post(idCatalog, idCategory, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    put(idCatalog, idCategory, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    delete(idCatalog, idCategory, object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idCategory}", idCategory) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }
}

export default VatRuleController
