import Entity from "../../../class/abstract/Entity"

class Action extends Entity {
    code = 0
    label = null
    keyLabel = null
    defaultImage = null
    useFunction = null
    getFunction = null
    getUrl = null
    keyboards = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default Action
