import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"

const ChoiceListStore = props => {
    const { screen, handleClose, handleChoice, saving } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ list, setList ] = useState([])
    const [ selected, setSelected ] = useState([])
    const env = JSON.parse(localStorage.getItem("env"))

    const initSelected = () => {
        let listStores = [];
        let selectedTmp = selected.slice();

        for (let index in env.stores) {
            listStores.push(env.stores[index]);
        }

        for (let index in screen.stores) {
            selectedTmp.push(screen.stores[index].id)
        }

        setList(listStores);
        setSelected(selectedTmp);
    }
    const selectStore = id => {
        let tmp = selected.slice()

        if (tmp.includes(id))
            tmp.splice(tmp.indexOf(id), 1)
        else
            tmp.push(id)

        setSelected(tmp)
    }
    const valid = () => {
        handleChoice(selected)
    }

    useEffect(() => {
        initSelected()
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <div className="form">
                        {error !== null && <p className="error">{error}</p>}
                        <p className="titleForm center">Sélectionner les boutiques à attacher</p>
                        <div className="listCatalogs">
                            {list.map((item, index) => (<div key={index} className={"store" + (selected.includes(item.id) ? " selected" : "")} onClick={() => { selectStore(item.id) }}>
                                    <p className="text">{item.name}</p>
                                </div>))}
                        </div>
                        <button className={"submit " + (saving ? "hide" : "")} onClick={valid}>
                            {saving ? "Enregistrement..." : "Enregistrer"}
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                        </button>
                        <button className="cancel align" onClick={handleClose}>Annuler</button>
                        <div className="clearing"/>
                    </div>
                </div>
            </div>
        </div>)
}

export default ChoiceListStore
