import Entity from "../../../class/abstract/Entity"
import Key from "../Keys/Key"

class Keyboard extends Entity {
    zone = ""
    store_id = null
    screen_id = null
    screen_type = ""
    keyboardable_type = null
    keyboardable_id = null
    sorting = ""
    sortingDirection = ""
    labelShowing = 1
    priceShowing = 0
    stockShowing = 0
    type = ""
    name = ""
    nbLines = 0
    nbColumns = 0
    grid = 0
    fontSize = 12
    fontType = ""
    fontPolice = ""
    color = null
    keys = []

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""
        let exceptKeys = ["keys"]

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined && !exceptKeys.includes(key))
                Reflect.set(this, key, datas[key])
        }

        if (datas.keys !== undefined) {
            this.keys = []

            for (let item in datas.keys)
                this.keys.push(new Key(datas.keys[item]))
        }
    }
}

export default Keyboard
