import Rest from "../../../class/tool/Rest"

class SellerSettingController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "sellers/{idSeller}/settings"

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idSeller}", object.id),
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        if (this._callback === null)
            return;

        this._callback(response, error, status);
    }
}

export default SellerSettingController
