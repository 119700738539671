import React, {useEffect, useState} from "react";
import "../../../css/form/InputCheckbox.css";

function InputCheckbox (props) {
    const { classname, name, title, checked, handleChange } = props;
    const [ checkedAttr, setCheckedAttr ] = useState(checked ? "checked" : "");

    useEffect(() => {
        setCheckedAttr(checked ? "checked" : "");
    }, [checked]);

    return (
        <div className={ "containerCheckbox " + (classname !== undefined ? classname : "") } onClick={ handleChange }>
            <input type="checkbox" name={ name } checked={ checkedAttr } readOnly={true} />
            <span className="checkmark"></span>
            {
                title !== undefined
                && <span>{ title }</span>
            }
        </div>
    );
}

export default InputCheckbox;
