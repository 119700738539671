import React, { useEffect, useRef, useState } from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import LicenseController from "../../../stories/_account/Licenses/LicenseController";
import PaymentTerminalController from "../../../stories/_setting/PaymentTerminals/PaymentTerminalController";
import SerialDatas from "../../../class/tool/SerialDatas";

const PaymentTerminalFormSheet = props => {
    const env = JSON.parse(localStorage.getItem("env"));
    const ref = useRef();
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props;
    const [ loadingLicenses, setLoadingLicenses ] = useState(true);
    const [ licenses, setLicenses ] = useState([]);
    const [ edit, setEdit ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            noLabel: true,
            label: "État",
            attribute: "active",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            label: "Marque",
            attribute: "brand",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Modèle",
            attribute: "model",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Interface",
            attribute: "interfaceType",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucune",
            hidden: true
        },
        {
            label: "Port USB",
            attribute: "usbPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Port IP",
            attribute: "ipPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Adresse IP",
            attribute: "ipAddress",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Port Bluetooh",
            attribute: "bltPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Numéro de port COM",
            attribute: "serialPort",
            inputType: "text",
            returnType: "int",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Port",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits par seconde",
            attribute: "serialBaudRate",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits de données",
            attribute: "serialDataBits",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Parité",
            attribute: "serialParity",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits d'arrêt",
            attribute: "serialStopBits",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Contrôle de flux",
            attribute: "serialFlowControl",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Caisse",
            attribute: "license_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucune"
        },
        {
            noLabel: true,
            label: "Activer l'option TPE intelligent",
            attribute: "allowsSimultaneousOperation",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Autorise l'encaissement si injoignable",
            attribute: "allowsDisconnectedTerminal",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        }
    ]);

    const getLicenses = () => {
        const controller = new LicenseController()
        controller._callback = handleGetLicenses
        controller.index(env)
    }
    const handleGetLicenses = (list, error, status) => {
        switch (status) {
            case 200:
                let licensesTmp = [
                    {
                        id: null,
                        value: "Aucune",
                        object: {}
                    },
                    {
                        id: null,
                        value: "----------",
                        object: {}
                    }
                ];

                for (let item in list) {
                    licensesTmp.push({
                        id: list[item].id,
                        value: list[item].name + " (" + list[item].number + ")",
                        object: list[item]
                    })
                }

                setLicenses(licensesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer la liste des licences")
                break
        }

        setLoadingLicenses(false)
    }
    const getModelsByBrand = () => {
        let models = [
            {
                value: "Modèle",
                id: null
            },
            {
                value: "----------",
                id: null
            }
        ]
        let filtered = PaymentTerminalController._paymentTerminals.filter((m) => m.brand === values.brand)

        for(let index in filtered) {
            if (models.findIndex(_ => _.value === filtered[index].model) < 0) {
                models.push({
                    value: filtered[index].model
                });
            }
        }

        let rowsTmp = rows.slice()

        if (values.brand === null) {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].hidden = true
        }
        else {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].list = models
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].hidden = false
        }

        let columns = ["interfaceType", "usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true

        setRows(rowsTmp)
    }
    const getInterfaceTypesByModel = () => {
        let interfaceTypes = [
            {
                value: "Interface",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ]
        let peripheral = PaymentTerminalController._paymentTerminals.find((m) => m.model === values.model)

        if (peripheral !== undefined) {
            let interfaces = peripheral.interfaceTypes.split(",");

            for(let index in interfaces) {
                interfaceTypes.push({
                    value: interfaces[index]
                });
            }
        }

        let rowsTmp = rows.slice()

        if (values.model === null) {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].hidden = true
        }
        else {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].list = interfaceTypes
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].hidden = false
        }

        let columns = ["usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true;

        setRows(rowsTmp);
    }
    const formInterfaceType = () => {
        let rowsTmp = rows.slice()
        let display = PaymentTerminalController._paymentTerminals.filter(_ => _.model === values.model)

        let columns = ["usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true

        if (values.interfaceType !== null) {
            switch (values.interfaceType.toLowerCase()) {
                case "usb":
                    rowsTmp[rows.findIndex(_ => _.attribute === "usbPort")].hidden = false

                    if (display.length > 0 && (values.usbPort === null || values.usbPort === "") && display[0].defaultUsbPort !== undefined) {
                        change("usbPort", "string", display[0].defaultUsbPort)
                    }

                    break
                case "serial":
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialPort")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialBaudRate")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialDataBits")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialParity")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialStopBits")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialFlowControl")].hidden = false
                    break
                case "ip":
                    rowsTmp[rows.findIndex(_ => _.attribute === "ipPort")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "ipAddress")].hidden = false
                    break
                case "bluetooth":
                    rowsTmp[rows.findIndex(_ => _.attribute === "bltPort")].hidden = false

                    if (display.length > 0 && (values.bltPort === null || values.bltPort === "") && display[0].defaultBltPort !== undefined) {
                        change("bltPort", "string", display[0].defaultBltPort)
                    }

                    break
                default: break
            }
        }

        setRows(rowsTmp);
    }
    const initRows = () => {
        let brands = [
            {
                value: "Marque",
                id: null
            },
            {
                value: "----------",
                id: null
            }
        ];
        let peripheral

        for(let index in PaymentTerminalController._paymentTerminals) {
            peripheral = PaymentTerminalController._paymentTerminals[index];

            if (brands.findIndex(_ => _.value === peripheral.brand) < 0)
                brands.push({ value: peripheral.brand });
        }

        let rowsTmp = rows.slice();

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "license_id")].list = licenses;
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "brand")].list = brands;
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialBaudRate")].list = SerialDatas.listBaudRates()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialDataBits")].list = SerialDatas.listDataBits()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialParity")].list = SerialDatas.listParities()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialStopBits")].list = SerialDatas.listStopBits()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialFlowControl")].list = SerialDatas.listFlowControls()

        setRows(rowsTmp);
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color", "tag", "switch", "multiline"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        getLicenses()
        getModelsByBrand()
        getInterfaceTypesByModel()
        formInterfaceType()
    }, []);
    useEffect(() => {
            initRows()
    }, [licenses]);
    useEffect(() => {
        if (ref.current !== undefined) {
            if (ref.current.brand !== undefined && ref.current.brand !== values.brand)
                getModelsByBrand()
            else if (ref.current.model !== undefined && ref.current.model !== values.model)
                getInterfaceTypesByModel()
            else if (ref.current.interfaceType !== undefined && ref.current.interfaceType !== values.interfaceType)
                formInterfaceType()
        }

        ref.current = values
    }, [values]);

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                loadingLicenses
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle display="loader restGETInForm" strokeWidth="5" />
                    </div>
                    : <div className="clearing">
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        (row.hidden === undefined || !row.hidden)
                                        && <div className="clearing">
                                            {
                                                row.noLabel === undefined
                                                && <label className={ row.classnameLabel }>{ row.label }</label>
                                            }
                                            {
                                                buildRow(row)
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
            }
        </form>
    );
}

export default PaymentTerminalFormSheet;
