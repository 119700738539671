import React, {useContext, useEffect, useState} from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import ScreenContext from "../../../context/ScreenContext"
import PosScreenController from "../../../stories/_setting/PosScreens/PosScreenController"
import "../../../css/form/Form.css"
import ConfirmBox from "../../overbox/asking/ConfirmBox";
import SecureBox from "../../overbox/asking/SecureBox";

const FormScreenObject = () => {
    const { screen, setScreen, inEditKey, setInEditKey, scale, setScale } = useContext(ScreenContext)
    const [ values, setValues ] = useState(null)
    const [ rows, setRows ] = useState([])
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ removing, setRemoving ] = useState(false)
    const [ confirmRemove, setConfirmRemove ] = useState(false)
    const [ secureRemove, setSecureRemove ] = useState(false)
    const [ saveScreen, setSaveScreen ] = useState({})
    const formats = [
        {
            value: "format16/9",
            id: 1
        },
        {
            value: "format4/3",
            id: 2
        }
    ]
    const dictFormats = {
        "format16/9": "Format 16/9",
        "format4/3": "Format 4/3"
    }
    const scales = [
        {
            value: "100%",
            id: 100
        },
        {
            value: "125%",
            id: 125
        },
        {
            value: "150%",
            id: 150
        },
        {
            value: "175%",
            id: 175
        },
        {
            value: "200%",
            id: 200
        },
        {
            value: "225%",
            id: 225
        }
    ]
    const positions = [
        {
            value: "left",
            id: 1
        },
        {
            value: "right",
            id: 2
        }
    ]
    const dictPositions = {
        "left": "Panier à gauche",
        "right": "Panier à droite"
    }

    const initSaveKey = () => {
        setRows([])
        setValues(null)
        setSaveScreen(screen)
    }
    const initValues = () => {
        if (Object.keys(saveScreen).length === 0) return

        setValues({
            name: saveScreen.name,
            format: saveScreen.format,
            scale: scale,
            displayPosition: saveScreen.displayPosition
        })
    }
    const initRows = () => {
        let rowsTmp = [
            {
                label: "Nom",
                attribute: "name",
                inputType: "text",
                returnType: "string",
                classnameLabel: "label noMarginTop white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Nom de l'écran",
                emptyText: "Aucun",
                edit: true
            },
            {
                label: "Format de l'écran",
                attribute: "format",
                inputType: "select",
                returnType: "string",
                list: formats,
                dictionary: dictFormats,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            },
            /*
            {
                label: "Échelle d'affichage",
                attribute: "scale",
                inputType: "select",
                returnType: "int",
                list: scales,
                dictionary: null,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            },
            */
            {
                label: "Position du panier",
                attribute: "displayPosition",
                inputType: "select",
                returnType: "string",
                list: positions,
                dictionary: dictPositions,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            }
        ]

        setRows(rowsTmp)
    }
    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, errors, change)
    }
    const change = (attribute, returnType, val, strict = false) => {
        setInEditKey(true)
        let result = FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)

        if (attribute === "scale") {
            setScale(result.value)
        }
    }
    const updateKey = () => {
        let screenTmp = Object.assign({}, screen)
        screenTmp.name = values.name
        screenTmp.format = values.format
        screenTmp.displayPosition = values.displayPosition
        screenTmp.licenses = values.licenses
        screenTmp.sellers = values.sellers
        setScreen(screenTmp)
    }
    const reinit = () => {
        initValues()
        setInEditKey(false)
    }
    const saveScreenDatas = () => {
        setSaving(true)

        const controller = new PosScreenController()
        let datas = controller.returnUpdatesFromCompare(saveScreen, values)

        controller._callback = returnSaveScreenDatas
        controller.put(screen, datas)
    }
    const returnSaveScreenDatas = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 204:
                setInEditKey(false)
                initSaveKey()
                break
            default:
                console.error("Error", status, error)
                break
        }
    }
    const callRemove = () => {
        setConfirmRemove(true)
    }
    const callSecureRemove = () => {
        handleClose("confirmRemove")
        setSecureRemove(true)
    }
    const remove = () => {
        setRemoving(true)

        const controller = new PosScreenController()
        controller._callback = returnRemove
        controller.delete(screen)
    }
    const returnRemove = (response, error, status) => {
        setRemoving(false)

        switch (status) {
            case 204:
                window.location = "/screens"
                break
            default:
                console.error("Error", status, error)
                break
        }
    }
    const handleClose = type => {
        switch (type) {
            case "confirmRemove":
                setConfirmRemove(false)
                break
            case "secureRemove":
                setSecureRemove(false)
                break
            default: break
        }
    }

    useEffect(() => {
        initSaveKey()
    }, [])
    useEffect(() => {
        initValues()
    }, [saveScreen])
    useEffect(() => {
        if (values !== null) {
            if (rows.length === 0)
                initRows()
            else
                updateKey()
        }
    }, [values])

    return (
        <>
            <form className="form">
                {
                    rows.map((row, index) => (
                        <div key={index} className="clearing">
                        <label className={row.classnameLabel}>{row.label}</label>
                        {buildRow(row)}
                    </div>
                    ))
                }
                {
                    inEditKey
                    && <div className="savingBar">
                    <p className="reinit" onClick={reinit}>Réinitialiser</p>
                    <p className="save" onClick={saveScreenDatas}>
                        {saving ? <LoaderCircle display="loader inDeploymentButton" stroke={"#FFFFFF"} strokeWidth="8"/> : "Enregistrer"}
                    </p>
                </div>
                }
                <p className={"removeButton"} onClick={callRemove}>Supprimer</p>
            </form>
            {
                confirmRemove
                && <ConfirmBox
                    title="Êtes-vous sûr de vouloir supprimer cet écran ?"
                    text="Attention, cet écran peut être lié à un ou plusieurs points de vente."
                    textBack="Annuler"
                    textConfirm="Je suis sûr"
                    handleClose={() => { handleClose("confirmRemove") }}
                    handleConfirm={callSecureRemove}/>
            }
            {
                secureRemove
                && <SecureBox
                    obj={screen}
                    title="Veuillez saisir le code de sécurité"
                    textBack="Annuler"
                    textConfirm="Confirmer"
                    textConfirming="Suppression de l'écran..."
                    loading={removing}
                    handleClose={() => { handleClose("secureRemove") }}
                    handleConfirm={remove}/>
            }
        </>
    )
}

export default FormScreenObject
