import React, {useContext} from "react"
import MagicIcon from "../../../icons/MagicIcon"
import HandPointIcon from "../../../icons/HandPointIcon"
import ScreenContext from "../../../../context/ScreenContext"
import Keys from "../../../../class/tool/Keys";

const ActionDefined = props => {
    const { object, cancel, save } = props
    const { products } = useContext(ScreenContext)

    const valid = code => {
        save(object, code)
    }

    const buildTitle = () => {
        let constructorName = Keys.returnConstructorName(object.target_object)

        switch (constructorName) {
            case "Category":
                return <p className={"title"}>Quelle action voulez-vous définir ?</p>
            default: break
        }
    }
    const buildContent = () => {
        let constructorName = Keys.returnConstructorName(object.target_object)

        switch (constructorName) {
            case "Category":
                let nbProductsFiltered = products.filter(_ => _.category_id === object.target_object.id).length
                let limit = 48

                return <div className={"nav double"}>
                    <div className={"choice"} onClick={() => { valid(501) }}>
                        <MagicIcon />
                        <p className={"title"}>Non personnalisable</p>
                        <p className={"description"}>Afficher automatiquement la liste du contenu de la catégorie</p>
                    </div>
                    <div className={"choice" + (nbProductsFiltered > limit ? " disable" : "")} onClick={(nbProductsFiltered <= limit ? () => { valid(500) } : () => {})}>
                        <HandPointIcon/>
                        <p className={"title"}>Personnalisable</p>
                        <p className={"description"}>Pré-remplir avec le contenu actuel de la catégorie</p>
                    </div>
                </div>
            default: break
        }
    }

    return(
        <div className={"actionDefined"}>
            <div className={"box"}>
                { buildTitle() }
                { buildContent() }
                <p className={"close"} onClick={cancel}>Annuler</p>
            </div>
        </div>
    )
}

export default ActionDefined
