import Entity from "../../../class/abstract/Entity"
import Kart from "../Kart/Kart";

class Sale extends Entity {
    address = ""
    amountAsset = 0
    amountExclTax = 0
    amountExclTaxBack = 0
    amountExclTaxUndiscounted = 0
    amountInclTax = 0
    amountInclTaxBack = 0
    amountInclTaxUndiscounted = 0
    amountOfferTax = 0
    amountProof = 0
    amountTax = 0
    amountTaxBack = 0
    amountTaxUndiscounted = 0
    annualy_period_id = 1
    ape = ""
    cancelation = null
    canceled_at = null
    cancellationNote = null
    city = ""
    clientName = null
    clientReference = null
    client_id = null
    country = ""
    daily_period_id = 0
    discountType = null
    discountValue = 0
    ean = ""
    fiscal_year_period_id = 0
    hasPreviousSigning = 0
    identifier = null
    intraCommunityVat = ""
    isCanceled = 0
    licenseName = ""
    licenseReference = ""
    license_id = 0
    lineDiscountAmount = 0
    lineOfferAmount = 0
    monthly_period_id = 0
    nbPrint = 0
    note = null
    nbArticle = 0
    number = ""
    postalCode = ""
    previousSigning = ""
    quantityBack = 0
    saleDiscountAmount = 0
    saleOfferAmount = 0
    sellerName = ""
    sellerReference = ""
    seller_id = 0
    signKey = ""
    signing = ""
    siret = ""
    socialReason = ""
    source = ""
    started_at = ""
    store_id = 0
    synced_at = null
    totalDiscountAmount = 0
    logicielName = ""
    version = ""
    operationType = ""
    shortSigning = ""
    contents = {}
    vats = {}
    payments = {}
    grandTotal = {}
    kart = {}
    barcode = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            switch (key) {
                case "kart":
                    if (datas[key] === null)
                        continue;

                    Reflect.set(this, key, new Kart(datas[key]))
                    Reflect.set(this, "amountInclTax", this.kart.amountInclTax)
                    Reflect.set(this, "nbArticle", this.kart.nbArticle)
                    break
                default:
                    if (datas[key] !== undefined) {
                        Reflect.set(this, key, datas[key])
                    }

                    break
            }
        }
    }
}

export default Sale
