import React, { useState } from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import FormPwd from "./FormPwd";

const AccountFormSheet = props => {
    const rules = [
        { type: "text", value: "ADMIN", master: true },
        { type: "text", value: "MANAGER" },
        { type: "text", value: "ACCOUNTANT" },
        { type: "text", value: "ANALYST" },
        { type: "text", value: "PRODUCTS" },
        { type: "text", value: "TECH" }
    ]
    const rulesDictionary = {
        "ADMIN": "Administrateur",
        "MANAGER": "Manager",
        "ACCOUNTANT": "Comptable",
        "ANALYST": "Analyste",
        "PRODUCTS": "Catalogue",
        "TECH": "Technicien"
    }
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "lastname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun",
            readOnly: true,
            edit: false
        },
        {
            label: "Prénom",
            attribute: "firstname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun",
            readOnly: true,
            edit: false
        },
        {
            label: "Email",
            attribute: "email",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun",
            readOnly: true,
            edit: false
        },
        {
            label: "Droits sur le groupe",
            attribute: "companyRule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        },
        {
            label: "Droits sur la boutique",
            attribute: "rule",
            inputType: "tag",
            returnType: "array",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun droit",
            list: rules,
            dictionary: rulesDictionary,
            titleButton: "Modifier"
        }
    ])
    const [ pwdForm, setPwdForm ] = useState(false)

    const buildRow = row => {
        if (edit === row.attribute || ["color"].includes(row.inputType) || ["tag"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }
    const showPasswordForm = () => {
        setPwdForm(!pwdForm)
    }

    return (
        <div className="clearing">
            <form
                className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
                onSubmit={ handleSubmit } >
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                buildRow(row)
                            }
                        </div>
                    ))
                }
            </form>
            {
                pwdForm
                && <FormPwd object={ object } closeView={ showPasswordForm } />
            }
        </div>
    )
}

export default AccountFormSheet
