import React from "react";

const ArrowTopIcon = props => {
    const { classname } = props;

    return (
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={classname !== undefined ? classname : ""}>
            <path d="m41.2 17.3-16.5-16.5c-.4-.4-1-.4-1.4 0l-16.5 16.5c-.3.3-.4.7-.2 1.1.1.3.5.6.9.6h9.1v27.5c0 .6.4 1 1 1h12.7c.6 0 1-.4 1-1v-27.5h9.1c.4 0 .8-.2.9-.6.3-.4.2-.8-.1-1.1z"></path>
        </svg>
    )
}

export default ArrowTopIcon;
