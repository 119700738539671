import React from "react";

function SellerIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <g data-name="Layer 2">
                <circle cx={16} cy={9} r={7} />
                <path d="M14.174 21.759L16 23.586l1.827-1.827L16.323 18h-.646z" />
                <path d="M29.771 26.473a13.97 13.97 0 00-8.293-10.352 8.995 8.995 0 01-3.129 1.557l1.335 3.338a2 2 0 01-.442 2.157L17.414 25a2 2 0 01-2.828 0l-1.827-1.827a2 2 0 01-.443-2.156l1.335-3.339a8.995 8.995 0 01-3.129-1.557 13.97 13.97 0 00-8.293 10.352 2.968 2.968 0 00.645 2.442A3.007 3.007 0 005.188 30h21.624a3.007 3.007 0 002.314-1.085 2.968 2.968 0 00.645-2.442z" />
            </g>
        </svg>
    );
}

export default SellerIcon;
