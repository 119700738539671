import React from "react";

function PrinterIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path
                d="M56 18H17V8a2 2 0 012-2h25a2 2 0 012 2v6a2 2 0 004 0V8a6 6 0 00-6-6H19a6 6 0 00-6 6v10H8a6 6 0 00-6 6v18a6 6 0 006 6h5v7a6 6 0 006 6h26a6 6 0 006-6v-7h5a6 6 0 006-6V24a6 6 0 00-6-6zm-4 14a4 4 0 114-4 4 4 0 01-4 4zm-5 23a2 2 0 01-2 2H19a2 2 0 01-2-2V44a2 2 0 012-2h26a2 2 0 012 2z"
                data-name="Layer 10"
            />
        </svg>
    );
}

export default PrinterIcon;
