import Rest from "../../../class/tool/Rest"
import AuthController from "../../_auth/Auth/AuthController"
import Account from "./Account"

class AccountController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "accounts"
    _baseURLStore = "stores/{idTarget}/accounts"
    _baseURLCompany = "companies/{idTarget}/accounts"

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            fullname: object.fullname + " - " + object.email.toLocaleLowerCase(),
            firstname: this.capitalizeFLetter(object.firstname.toLocaleLowerCase()),
            lastname: this.capitalizeFLetter(object.lastname.toLocaleLowerCase()),
            email: object.email.toLocaleLowerCase(),
            companyRule: this.buildRules(object.companyRules),
            rule: this.buildRules(object.rules)
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ];
        let errors = {}
        let method

        for (let i in params) {
            method = AccountController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }
    capitalizeFLetter(str) {
        return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : "";
    }
    buildRules(strRules) {
        if (strRules === null || strRules === undefined || strRules.length === 0)
            return []
        else
            return strRules.split('|')
    }

    index(targetType, targetId) {
        let uri= ""

        switch (targetType) {
            case "company":
                uri = '/' + this._baseURLCompany.replace('{idTarget}', targetId)
                break
            case "store":
                uri = '/' + this._baseURLStore.replace('{idTarget}', targetId)
                break
            default: return
        }

        this.#_rest.sendQuery(
            'GET',
            uri,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let list = []

        switch (status) {
            case 200:
                for (let i in response.data)
                    list.push(new Account(response.data[i]))

                break

            default: break
        }

        this._callback(list, error, status)
    }

    show() {
        const authController = new AuthController()
        authController._callback = this.handleShow

        authController.me()
    }
    handleShow = (response, error, status) => {
        switch (status) {
            case 200:
                this._callback(new Account(response.data), error, status)
                break

            default:
                this._callback(null, error, status)
                break
        }
    }

    showAccount(targetType, targetId, idAccount) {
        let uri= ""

        switch (targetType) {
            case "company":
                uri = '/' + this._baseURLCompany.replace('{idTarget}', targetId)
                break
            case "store":
                uri = '/' + this._baseURLStore.replace('{idTarget}', targetId)
                break
            default: return
        }

        this.#_rest.sendQuery(
            'GET',
            uri + "/" + idAccount,
            {},
            this.handleShowAccount)
    }
    handleShowAccount = (response, error, status) => {
        switch (status) {
            case 200:
                this._callback(new Account(response.data), error, status)
                break

            default:
                this._callback(null, error, status)
                break
        }
    }

    post(targetType, targetId, datas) {
        let uri= ""

        switch (targetType) {
            case "company":
                uri = '/' + this._baseURLCompany.replace('{idTarget}', targetId)
                break
            case "store":
                uri = '/' + this._baseURLStore.replace('{idTarget}', targetId)
                break
            default: return
        }

        this.#_rest.sendQuery(
            'POST',
            uri,
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(targetType, targetId, account, datas) {
        let uri= ""

        switch (targetType) {
            case "company":
                uri = '/' + this._baseURLCompany.replace('{idTarget}', targetId) + '/' + account.id
                break
            case "store":
                uri = '/' + this._baseURLStore.replace('{idTarget}', targetId) + '/' + account.id
                break
            default: return
        }

        this.#_rest.sendQuery(
            'PUT',
            uri,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    resetPwd(account, datas) {
        datas.bigBisoux = account.bigBisoux === 1

        if (datas.bigBisoux)
            delete datas.oldPassword

        this.#_rest.sendQuery(
            'POST',
            '/resetpwd',
            datas,
            this.handleResetPwd)
    }
    handleResetPwd = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default AccountController
