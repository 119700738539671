import React from "react";

const PaymentTerminalIcon = props => {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill}>
            <g id="Icon">
                <path d="m15.75 4c0-.729-.29-1.429-.805-1.945-.516-.515-1.216-.805-1.945-.805-2.444 0-6.556 0-9 0-.729 0-1.429.29-1.945.805-.515.516-.805 1.216-.805 1.945v16c0 .729.29 1.429.805 1.945.516.515 1.216.805 1.945.805h9c.729 0 1.429-.29 1.945-.805.515-.516.805-1.216.805-1.945zm-10.75 14c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5-3.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5-3.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4.75-6.5c0-.414-.336-.75-.75-.75h-8c-.414 0-.75.336-.75.75v4.5c0 .414.336.75.75.75h8c.414 0 .75-.336.75-.75z"></path>
                <path d="m16.75 12.25v7.5h4.25c.966 0 1.75-.783 1.75-1.75v-5.75zm0-5v3.5h6v-1.75c0-.967-.784-1.75-1.75-1.75z"></path>
            </g>
        </svg>
    );
}

export default PaymentTerminalIcon;
