import React from "react";

function ClosingIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <path d="M3.4 24.5h12.4c-2.2-1.2-3.7-3.5-3.7-6.2 0-3.9 3.2-7.1 7.1-7.1h.1V3.1c0-1.4-1.2-2.6-2.6-2.6H3.4C2 .5.8 1.7.8 3.1v18.8c0 1.4 1.1 2.6 2.6 2.6zm2.3-20h8.6c.6 0 1 .5 1 1 0 .6-.4 1-1 1H5.7c-.6 0-1-.4-1-1 0-.5.5-1 1-1zm0 4.7h5.6c.6 0 1 .5 1 1 0 .6-.4 1-1 1H5.7c-.6 0-1-.4-1-1s.5-1 1-1z" />
            <path d="M19.2 13.3c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1zm2.5 4.6l-2.5 2.4c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L16.6 19c-.4-.4-.4-1 .1-1.4.4-.4 1-.4 1.4 0l.5.6 1.8-1.7c.4-.4 1-.4 1.4 0 .3.3.3 1-.1 1.4z" />
        </svg>
    );
}

export default ClosingIcon;
