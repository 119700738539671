import React from "react"

function ForbiddenIcon (props) {
    const { classname, fill } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            className={(classname !== undefined ? classname : "")}
            fill={fill}
        >
            <path d="M9.362 54.638C15.602 60.88 23.802 64 31.999 64c8.2 0 16.397-3.12 22.639-9.362 12.482-12.483 12.482-32.794 0-45.276-12.482-12.483-32.794-12.483-45.276 0-12.482 12.482-12.482 32.793 0 45.276m42.191-8.744L18.106 12.447A23.967 23.967 0 0 1 32 8c6.15 0 12.298 2.34 16.98 7.02 8.396 8.397 9.238 21.503 2.574 30.874M12.447 18.106l33.447 33.446c-9.372 6.663-22.478 5.823-30.873-2.573-8.396-8.396-9.238-21.502-2.574-30.873"/>
        </svg>
    )
}

export default ForbiddenIcon
