import Rest from "../../../class/tool/Rest"

class VatController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/products/{idProduct}/vats"

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }
    checkValues(object) {
        const params = []
        let errors = {}
        let method

        for (var i in params) {
            method = VatController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    // vat

    post(idCatalog, idProduct, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(idCatalog, idProduct, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(idCatalog, idProduct, object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default VatController
