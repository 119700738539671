import React from "react"
import FormStart from "../../../form/category/FormStart"
import Category from "../../../../stories/_catalog/Categories/Category"
import '../../../../css/overbox/CategoryInformation.css'

const NewObject = props => {
    const { list, handleIndex } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart list={ list } object={ new Category() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject
