import React, {useEffect, useState} from "react";
import $ from "jquery";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import StringTools from "../../../../class/tool/StringTools";
import Store from "../../../../stories/_account/Stores/Store";
import '../../../../css/page/content/analyze/HourlyChart.css';
import Color from "../../../../class/tool/Color";

const HourlyChart = props => {
    moment.locale('fr');
    Chart.register(...registerables);
    const { refreshStats, setRefreshStats, ca, sales } = props;
    const [ hourlyChart, setHourlyChart ] = useState(null);
    const env = JSON.parse(localStorage.getItem("env"));

    const refreshDatas = () => {
        if (!refreshStats || ca === null || sales === null)
            return;

        setRefreshStats(false);

        let chartDatas = extractChartDatas();

        if (hourlyChart === null) { // initialize
            let chartObj = new Chart("hourlyChart", {
                type: 'line',
                data: chartDatas,
                options: {
                    parsing: {
                        xAxisKey: 'key',
                        yAxisKey: 'date.ca.inclTax'
                    },
                    responsive: true,
                    lineTension: 0.15,
                    pointRadius: 4,
                    hoverRadius: 6,
                    animation: {
                        duration: 200,
                        easing: 'linear'
                    },
                    responsiveAnimationDuration: 0,
                    scales: {
                        x: {
                            display: true,
                            ticks: {
                                padding: 15,
                                font: {
                                    size: 12,
                                }
                            },
                            border: {
                                display: true
                            },
                            grid: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        },
                        y: {
                            beginAtZero: true,
                            display: true,
                            ticks: {
                                padding: 10,
                                font: {
                                    size: 12,
                                }
                            },
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: true
                            }
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    events: ['mousemove', 'click', 'touchstart', 'touchmove'],
                    plugins: {
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler
                        }
                    }
                }
            });

            setHourlyChart(chartObj);
        }
        else { // update
            hourlyChart.data = chartDatas;
            hourlyChart.update();

            animChart(chartDatas.length <= 0);
        }
    }
    const extractChartDatas = () => {
        let chartDatas = {
            datasets: []
        };
        let store;
        let datasStore;
        let datasVersus;
        let color;

        if (
            /*sales["date"] !== undefined
            && sales["date"]["subperiods"] !== undefined
            && Object.keys(sales["date"]["subperiods"]).length === 0
            && sales["versus"] !== undefined
            && sales["versus"]["subperiods"] !== undefined
            && Object.keys(sales["versus"]["subperiods"]).length === 0
            && sales["delta"] !== undefined
            && sales["delta"]["subperiods"] !== undefined
            && Object.keys(sales["delta"]["subperiods"]).length === 0
            && */ca["date"] !== undefined
            && ca["date"]["subperiods"] !== undefined
            && Object.keys(ca["date"]["subperiods"]).length === 0
            && ca["versus"] !== undefined
            && ca["versus"]["subperiods"] !== undefined
            && Object.keys(ca["versus"]["subperiods"]).length === 0
            && ca["delta"] !== undefined
            && ca["delta"]["subperiods"] !== undefined
            && Object.keys(ca["delta"]["subperiods"]).length === 0
        ) {
            return chartDatas;
        }

        switch (env.type) {
            case "company":
                for (let i in env.stores) {
                    store = new Store(env.stores[i]);

                    if (
                        ca[store.id]["date"]["subperiods"] === undefined
                        || ca[store.id]["versus"]["subperiods"] === undefined
                        || ca[store.id]["delta"]["subperiods"] === undefined
                        /*|| sales[store.id]["date"]["subperiods"] === undefined
                        || sales[store.id]["versus"]["subperiods"] === undefined
                        || sales[store.id]["delta"]["subperiods"] === undefined*/
                    )
                        continue;

                    datasStore = [];

                    for (let index in ca[store.id]["date"]["subperiods"]) {
                        datasStore.push({
                            key: ca[store.id]["date"]["subperiods"][index]["def"],
                            date: {
                                ca: ca[store.id]["date"]["subperiods"][index]["datas"],
                                //sales: sales[store.id]["date"]["subperiods"][index]["datas"]
                            },
                            versus: {
                                ca: ca[store.id]["versus"]["subperiods"][index]["datas"],
                                //sales: sales[store.id]["versus"]["subperiods"][index]["datas"]
                            },
                            delta: {
                                ca: ca[store.id]["delta"]["subperiods"][index]["datas"],
                                //sales: sales[store.id]["delta"]["subperiods"][index]["datas"]
                            }
                        })
                    }

                    color = getColor(parseInt(i) + 1);

                    chartDatas.datasets.push({
                        label: store.shortName,
                        data: datasStore,
                        borderColor: color,
                        backgroundColor: color
                    });
                }

                break;
            case "store":
                store = new Store(JSON.parse(localStorage.getItem("store")));

                datasStore = [];
                datasVersus = [];

                for (let index in ca["date"]["subperiods"]) {
                    datasStore.push({
                        key: ca["date"]["subperiods"][index]["def"],
                        date: {
                            ca: ca["date"]["subperiods"][index]["datas"],
                            //sales: sales["date"]["subperiods"][index]["datas"]
                        },
                        versus: {
                            ca: ca["versus"]["subperiods"][index]["datas"],
                            //sales: sales["versus"]["subperiods"][index]["datas"]
                        },
                        delta: {
                            ca: ca["delta"]["subperiods"][index]["datas"],
                            //sales: sales["delta"]["subperiods"][index]["datas"]
                        }
                    })
                    datasVersus.push({
                        key: ca["date"]["subperiods"][index]["def"],
                        date: {
                            ca: ca["versus"]["subperiods"][index]["datas"],
                            //sales: sales["versus"]["subperiods"][index]["datas"]
                        }
                    })
                }

                color = getColor(1);

                chartDatas.datasets.push({
                    label: ca["date"]["period"]["def"],
                    data: datasStore,
                    borderColor: color,
                    backgroundColor: color
                });

                // Versus
                chartDatas.datasets.push({
                    versus: true,
                    label: ca["versus"]["period"]["def"],
                    data: datasVersus,
                    borderColor: "#DDDDDD",
                    backgroundColor: "#DDDDDD",
                    fill: true
                });

                break;
            default: break;
        }

        return chartDatas;
    }
    const getColor = index => {
        if (index <= Color.defaultColors.length) {
            return Color.defaultColors[(index - 1)];
        }
        else {
            let multiplicator = Math.floor(index / Color.defaultColors.length);
            let base = Color.defaultColors.length * multiplicator;
            return Color.defaultColors[(index - base) - 1];
        }
    }
    const animChart = anim => {
        if (hourlyChart === null) return;

        if (anim) {
            hourlyChart.options.animation = {
                duration: 500
            }
        }
        else {
            hourlyChart.options.animation = {
                duration: 0
            }
        }
    }
    const getOrCreateTooltip = id => {
        if ($(id).length === 0) {
            $(".backoffice > .main").append("<div id='" + id.replace('#', '') + "' class='toolTipHourlyChart'></div>");
        }
    }
    const externalTooltipHandler = (context) => {
        const {chart, tooltip} = context;
        const idToolTip = "#toolTipHourlyChart";

        getOrCreateTooltip(idToolTip);

        if ($(idToolTip).css('opacity') === 0) {
            $(idToolTip).css('opacity', 0);
            return;
        }

        const titleLines = tooltip.title || [];

        $(idToolTip).empty();

        if (titleLines.length === 0)
            return;

        let datas = [];
        let indexFound;

        for (let i in chart.data.datasets) {
            if (chart.data.datasets[i].versus !== undefined)
                continue;

            indexFound = chart.data.datasets[i].data.findIndex(_ => _.key === titleLines[0]);

            if (indexFound >= 0) {
                datas.push({
                    label: chart.data.datasets[i].label,
                    stats: chart.data.datasets[i].data[indexFound]
                });
            }
        }

        $(idToolTip).append(
            "<h1>" + titleLines[0] + "</h1>" +
            "<div class='containerTable'>" +
                "<table class='table'></table>" +
            "</div>"
        );

        datas.forEach((data, i) => {
            $(idToolTip + " > .containerTable > table").append(
                "<tr class='line'>" +
                    "<td class='title'>" + data.label + "</td>" +
                    "<td>" +
                        "<div class='value'>" +
                            "<svg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' class='arrow" + (data.stats.delta.ca.inclTaxValue < 0 ? " down" : (data.stats.delta.ca.inclTaxValue > 0 ? " up" : "")) + "'><path d='m41.2 17.3-16.5-16.5c-.4-.4-1-.4-1.4 0l-16.5 16.5c-.3.3-.4.7-.2 1.1.1.3.5.6.9.6h9.1v27.5c0 .6.4 1 1 1h12.7c.6 0 1-.4 1-1v-27.5h9.1c.4 0 .8-.2.9-.6.3-.4.2-.8-.1-1.1z'></path></svg>" +
                            "<span class='value'>" + StringTools.number_format(data.stats.date.ca.inclTax, 2, ',', ' ') + "</span>" +
                        "</div>" +
                    "</td>" +
                    "<td><span class='versus'>" + StringTools.number_format(data.stats.versus.ca.inclTax, 2, ',', ' ') + "</span></td>" +
                    "<td><span class='deltaValue " + (data.stats.delta.ca.inclTaxValue > 0 ? "more" : (data.stats.delta.ca.inclTaxValue < 0 ? "less" : "")) + "'>" + StringTools.number_format(data.stats.delta.ca.inclTaxValue, 2, ',', ' ') + "</span></td>" +
                    "<td><span class='deltaPercent " + (data.stats.delta.ca.inclTaxPercent > 0 ? "more" : (data.stats.delta.ca.inclTaxPercent < 0 ? "less" : "")) + "'>" + (data.stats.delta.ca.inclTaxPercent >= 0 ? "+ " : "- ") + StringTools.number_format(Math.abs(data.stats.delta.ca.inclTaxPercent), 2, ',', ' ') + " %</span></td>" +
                "</tr>"
            );
        })

        $(idToolTip).css('opacity', 1);
    }
    const disappearToolTip = e => {
        if (e.type !== "mouseout")
            return;

        let id = "#toolTipHourlyChart";

        if ($(id).length > 0) {
            $(id).remove();
        }
    }

    useEffect(() => {
        refreshDatas();
    }, [ca, sales, refreshStats]);

    return (<div className={"containerHourlyChart"}>
        <canvas id={"hourlyChart"} className={"HourlyChart"} onMouseOut={disappearToolTip}/>
    </div>);
}

export default HourlyChart;
