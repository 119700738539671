import Rest from "../../../class/tool/Rest"
import Product from "./Product"
import Price from "../Prices/Price"
import Vat from "../Vats/Vat"
import FormBuilder from "../../../class/tool/FormBuilder";

class ProductController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/products"
    _baseURLWithoutCatalog = "products"
    _baseURLPrice = "catalogs/{idCatalog}/products/{idProduct}/prices"
    _baseURLPriceVariation = "catalogs/{idCatalog}/products/{idProduct}/pricevariations"
    _baseURLVat = "catalogs/{idCatalog}/products/{idProduct}/vats"
    _baseURLBarcode = "catalogs/{idCatalog}/products/{idProduct}/barcodes"

    // Checking

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g

        if(val === "") throw new Error("EMPTY")
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }
    static checkLabel(val) {
        //const regxp = /^[0-9]+$/g

        //if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkColor(val) {
        const regxp = /^\#[a-fA-F0-9]{3,8$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkReference(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }
    static checkReferenceProvider(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }
    static checkProvider(val) {
        const regxp = /^[0-9]+$/g

        if(val === 0) throw new Error("EMPTY")
        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkBrand(val) {
        const regxp = /^[0-9]+$/g

        if (val > 0 && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkCategory(val) {
        const regxpId = /^[0-9]+$/g
        const regxpType = /^categories|subcategories/g

        if(val.id < 0 || val.type === "") throw new Error("EMPTY")
        if (!regxpId.test(val.id) || !regxpType.test(val.type)) throw new Error("WRONG")

        return true
    }
    static checkDescription(val) {
        //const regxp = /^[0-9]+$/g

        //if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkNullStockTrigger(val) {
        const regxp = /^0|1$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkPrintDescription(val) {
        const regxp = /^0|1$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkSoldOut(val) {
        const regxp = /^0|1$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkStockManagement(val) {
        const regxp = /^0|1$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkStockThresholdTrigger(val) {
        const regxp = /^[0-9]{1,}$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkUnit(val) {
        const regxp = /^ml|cl|l|dl|mg|cg|g|dg|kg$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkVariablePrice(val) {
        const regxp = /^0|1$/g

        if (!regxp.test(val)) throw new Error("WRONG")

        return true
    }

    // perform

    setFormValues(object, category, pricelists, vatrates, setValues, newObj = false) {
        let defaultCatalogId = object.catalog_id

        if (localStorage.getItem("defaultCatalogIdInForm") !== null) {
            defaultCatalogId = parseInt(localStorage.getItem("defaultCatalogIdInForm"))
        }
        else {
            const catalog = JSON.parse(localStorage.getItem("catalog"))

            if (Object.keys(catalog).length > 0)
                defaultCatalogId = parseInt(catalog.id)
        }

        if (newObj) {
            setValues(prev => ({
                ...prev,
                name: object.name,
                reference: object.reference,
                catalog_id: defaultCatalogId,
                category_id: object.category_id,
                category_type: object.category_type,
                vat_rate_id: object.vat_rate_id !== null ? object.vat_rate_id : (object.vat !== null ? object.vat.id : null),
                variablePrice: object.variablePrice !== null ? object.variablePrice : false,
                price: null
            }))
        }
        else {
            const env = JSON.parse(localStorage.getItem("env"))
            const catalogs = JSON.parse(localStorage.getItem("catalogs"))
            let lines = []
            let barcodes = []
            let line = {}

            for (let key in object.barcodes) {
                barcodes.push({
                    toAdd: false,
                    toUpdate: false,
                    toDelete: false,
                    id: object.barcodes[key].id,
                    barcode: object.barcodes[key].barcode,
                    conditioning: object.barcodes[key].conditioning,
                    created_at: object.barcodes[key].created_at,
                    updated_at: object.barcodes[key].updated_at,
                    deleted_at: object.barcodes[key].deleted_at
                })
            }

            if (category !== null && pricelists !== null && vatrates !== null) {
                let idStores = []
                let idStore = null

                if (env.type === "company") {
                    let catalog = catalogs[catalogs.findIndex(_ => _.id === object.catalog_id)]

                    for (let i in catalog.stores)
                        idStores.push(env.stores[env.stores.findIndex(_ => _.shortName === catalog.stores[i].shortName)].id)
                }
                else
                    idStores.push(env.id)

                for (let key in pricelists) {
                    line = {}
                    line.pricelist = pricelists[key]
                    line.toUpdate = {}
                    line.categoryPriceRule = {}
                    line.categoryVatRule = {}
                    line.categoryVatRate = {}
                    line.productVat = {}
                    line.productVatRate = {}
                    line.productPriceVariation = {}
                    line.productPrice = {}

                    for (let indexStore in idStores) {
                        idStore = idStores[indexStore]

                        line.toUpdate[idStore] = false
                        line.categoryPriceRule[idStore] = null
                        line.categoryVatRule[idStore] = null
                        line.categoryVatRate[idStore] = null
                        line.productVat[idStore] = null
                        line.productVatRate[idStore] = null
                        line.productPriceVariation[idStore] = null
                        line.productPrice[idStore] = null

                        for (let i in category.priceRules) {
                            if ((category.priceRules[i].store_id === null || category.priceRules[i].store_id === idStore) && category.priceRules[i].price_list_id === line.pricelist.id) {
                                line.categoryPriceRule[idStore] = Object.assign({}, category.priceRules[i])
                                break
                            }
                        }

                        for (let i in category.vatRules) {
                            if ((category.vatRules[i].store_id === null || category.vatRules[i].store_id === idStore) && category.vatRules[i].price_list_id === line.pricelist.id) {
                                line.categoryVatRule[idStore] = Object.assign({}, category.vatRules[i])
                                let categoryVatRateFound = vatrates.filter(_ => _.id === line.categoryVatRule[idStore].vat_rate_id)
                                line.categoryVatRate[idStore] = categoryVatRateFound.length > 0 ? categoryVatRateFound[0] : null
                                break
                            }
                        }

                        for (let i in object.vats) {
                            if ((object.vats[i].store_id === null || object.vats[i].store_id === idStore) && object.vats[i].price_list_id === line.pricelist.id) {
                                line.productVat[idStore] = Object.assign({}, object.vats[i])
                                let productVatRateFound = vatrates.filter(_ => _.id === line.productVat[idStore].vat_rate_id)
                                line.productVatRate[idStore] = productVatRateFound.length > 0 ? productVatRateFound[0] : null
                                break
                            }
                        }

                        for (let i in object.price_variations) {
                            if ((object.price_variations[i].store_id === null || object.price_variations[i].store_id === idStore) && object.price_variations[i].price_list_id === line.pricelist.id) {
                                line.productPriceVariation[idStore] = Object.assign({}, object.price_variations[i])
                                break
                            }
                        }

                        for (let i in object.prices) {
                            if ((object.prices[i].store_id === null || object.prices[i].store_id === idStore) && object.prices[i].price_list_id === line.pricelist.id) {
                                line.productPrice[idStore] = Object.assign({}, object.prices[i])
                                break
                            }
                        }
                    }

                    lines.push(line)
                }
            }

            setValues(prev => ({
                ...prev,
                name: object.name,
                reference: object.reference,
                label: object.label,
                catalog_id: defaultCatalogId,
                category_id: object.category_id,
                category_type: object.category_type,
                description: object.description,
                barcode: barcodes,
                pricelist: lines
            }))
        }
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = ProductController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }
    checkMinimumManyValues(values) {
        if (Object.keys(values).length === 0) return false;
        if (values.name === null || values.name.length <= 1) return false;
        if (values.catalog_id === null || values.catalog_id <= 0) return false;
        if (values.category_id === null || values.category_id <= 0) return false;
        if (values.category_type === null || values.category_type.length <= 1) return false;
        if (values.vat_rate_id === null || values.vat_rate_id <= 0) return false;
        if ((values.price === null || values.price.length === 0) && !values.variablePrice) return false;

        return true;
    }

    // product

    index(idCatalog = null, input = "", page = 1, nb = 25, sortingName = "", sortingValue = "", tree = false) {
        let catalog = JSON.parse(localStorage.getItem("catalog"))
        let url = ""
        let params = ""
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree="
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue

        if (Object.keys(catalog).length > 0)
            url = '/' + this._baseURL.replace("{idCatalog}", catalog.id) + params
        else if (idCatalog !== undefined && idCatalog !== null)
            url = '/' + this._baseURL.replace("{idCatalog}", idCatalog) + params
        else
            url = '/' + this._baseURLWithoutCatalog + params

        this.#_rest.sendQuery(
            'GET',
            url,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Product(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    show(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Product(response.data) : null
        this._callback(object, error, status)
    }

    post(idCatalog, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status = 200) => {
        this._callback(response, error, status)
    }

    put(idCatalog, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", object.catalog_id) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto)
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(let item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error)
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget)
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(var item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    // price

    indexPrice(idCatalog, idProduct) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURLPrice.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            {},
            this.handleIndexPrice)
    }
    handleIndexPrice = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200 && response.data !== undefined && response.data.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push(new Price(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    postPrice(idCatalog, idProduct, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLPrice.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            datas,
            (this._callback !== null ? this._callback : this.handlePostPrice))
    }
    handlePostPrice(response, error, status) {}

    putPrice(idCatalog, idProduct, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLPrice.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutPrice))
    }
    handlePutPrice(response, error, status) {}

    deletePrice(idCatalog, idProduct, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLPrice.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeletePrice))
    }
    handleDeletePrice(response, error, status) {}

    postPriceVariation(idCatalog, idProduct, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLPriceVariation.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            datas,
            (this._callback !== null ? this._callback : this.handlePostPriceVariation))
    }
    handlePostPriceVariation(response, error, status) {}

    putPriceVariation(idCatalog, idProduct, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLPriceVariation.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutPriceVariation))
    }
    handlePutPriceVariation(response, error, status) {}

    deletePriceVariation(idCatalog, idProduct, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLPriceVariation.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeletePriceVariation))
    }
    handleDeletePriceVariation(response, error, status) {}

    // vat

    indexVat(idCatalog, idProduct) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURLVat.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            {},
            this.handleIndexPrice)
    }
    handleIndexVat = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200 && response.data !== undefined && response.data.data !== undefined) {
            const data = response.data;

            for(let item in data)
                listObjects.push(new Vat(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    postVat(idCatalog, idProduct, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLVat.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            datas,
            (this._callback !== null ? this._callback : this.handlePostVat))
    }
    handlePostVat(response, error, status) {}

    putVat(idCatalog, idProduct, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLVat.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutVat))
    }
    handlePutVat(response, error, status) {}

    deleteVat(idCatalog, idProduct, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLVat.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeleteVat))
    }
    handleDeleteVat(response, error, status) {}

    // Barcode

    postBarcode(idCatalog, idProduct, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURLBarcode.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct),
            datas,
            (this._callback !== null ? this._callback : this.handlePostBarcode))
    }
    handlePostBarcode(response, error, status) {}

    putBarcode(idCatalog, idProduct, id, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURLBarcode.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            datas,
            (this._callback !== null ? this._callback : this.handlePutBarcode))
    }
    handlePutBarcode(response, error, status) {}

    deleteBarcode(idCatalog, idProduct, id) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURLBarcode.replace("{idCatalog}", idCatalog).replace("{idProduct}", idProduct) + "/" + id,
            {},
            (this._callback !== null ? this._callback : this.handleDeleteBarcode))
    }
    handleDeleteBarcode(response, error, status) {}
}

export default ProductController
