import Entity from "../../../class/abstract/Entity";
import License from "../../_account/Licenses/License";
import Printer from "./Printer";

class PrinterLicense extends Entity {
    printer_id = null;
    printer = {};
    license_id = null;
    license = {};

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "printer")
                    Reflect.set(this, key, new Printer(datas[key]));
                else if(key === "license")
                    Reflect.set(this, key, new License(datas[key]));
                else
                    Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default PrinterLicense;
