import React, {useState} from "react";
import InputText from "../../../form/input/generated/InputText";
import InputSelect from "../../../form/input/generated/InputSelect";
import "../../../../css/form/Form.css";

const ActionSpecify = props => {
    const { object, cancel, save } = props;
    const [ input, setInput ] = useState("");

    const valid = () => {
        save(object, input);
    }

    const change = (attr, returnT, inputValue) => {
        let regexDecimal = /^\d+(\.(\d{1,2})?)?$/;
        inputValue = inputValue.replace(",", ".");

        switch (object.action_code) {
            case 205:
            case 702:
                if(inputValue.length > 0 && !regexDecimal.test(inputValue))
                    return;

                break;
            case 206:
                if(inputValue.length > 0 && !regexDecimal.test(inputValue))
                    return;

                if (parseFloat(inputValue) <= 0 || parseFloat(inputValue) >= 100)
                    return;

                break;
            default: break;
        }

        setInput(inputValue);
    }

    const buildTitle = () => {
        switch (object.action_code) {
            case 205:
                return <p className={"title"}>Préciser la valeur de la remise en euros</p>
            case 206:
                return <p className={"title"}>Préciser la valeur de la remise en pourcentage</p>
            case 309:
                return <p className={"title"}>Préciser la montant</p>
            case 502:
                return <p className={"title"}>Sélectionner les tags</p>
            case 503:
                return <p className={"title"}>Sélectionner la marque</p>
            case 702:
                return <p className={"title"}>Préciser la quantité</p>
            default: break
        }
    }
    const buildContent = () => {
        switch (object.action_code) {
            case 205:
            case 206:
            case 702:
                return <InputText
                    type={ "text" }
                    attribute={ "input" }
                    returnType={ "string" }
                    variablePrice={ null }
                    classname={ "" }
                    classError={ "" }
                    value={ input }
                    placeholder={ "" }
                    readonly={ null }
                    autoComplete={ null }
                    toUppercase={ null }
                    autoFocus={ true }
                    handleChange={ change }
                    handleBlur={ () => {} }
                    handleSubmit={ null } />
            case 309:
                let list = [
                    {
                        value: "5 €",
                        id: 5
                    },
                    {
                        value: "10 €",
                        id: 10
                    },
                    {
                        value: "20 €",
                        id: 20
                    },
                    {
                        value: "50 €",
                        id: 50
                    },
                    {
                        value: "100 €",
                        id: 100
                    }
                ]

                return <InputSelect
                    attribute={ "input" }
                    returnType={ "int" }
                    value={ input }
                    list={ list }
                    newItem={ true }
                    handleChange={ change } />
            case 502:
                return <></>
            case 503:
                return <></>
            default: break
        }
    }

    return(
        <div className={"actionDefined"}>
            <div className={"box"}>
                {buildTitle()}
                <div className={"form"}>
                    {buildContent()}
                </div>
                <div className={"buttonsContainer"}>
                    <p className={"cancel"} onClick={cancel}>Annuler</p>
                    <p className={"submit"} onClick={valid}>Enregistrer</p>
                </div>
            </div>
        </div>)
}

export default ActionSpecify;
