import React from "react"
import FormStart from "../../../form/account/FormStart"
import Account from "../../../../stories/_account/Accounts/Account"
import '../../../../css/overbox/AccountInformation.css'

const NewObject = props => {
    const { idStore, handleIndex } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart idStore={ idStore } object={ new Account() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject
