import Rest from "../../../class/tool/Rest"
import License from "./License"
import PosScreen from "../../_setting/PosScreens/PosScreen";
import Store from "../Stores/Store";

class LicenseController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "stores/{idStore}/licenses"
    _baseURLListForCompany = "companies/{idCompany}/licenses"

    // Checking

    static checkNumber(val) {
        const regxp = /^[0-9]{6,7}$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkType(val) {
        const regxp = /^POS_WINDOWS$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }

    // Licenses

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }

    checkValues(object) {
        let errors = {};

        try { LicenseController.checkNumber(object.number); }
        catch (e) { errors.number = e.message; }

        try { LicenseController.checkType(object.type); }
        catch (e) { errors.type = e.message; }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }

    index(store) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", store.id),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push(new License(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    indexForCompany(idCompany) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURLListForCompany.replace("{idCompany}", idCompany),
            {},
            this.handleIndexForCompany)
    }
    handleIndexForCompany = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data
            let store
            let obj

            for(let key in data) {
                store = new Store(data[key])

                obj = {
                    store: store,
                    licenses: []
                }

                for (let i in data[key].licenses) {
                    obj.licenses.push(new License(data[key].licenses[i]))
                }

                listObjects.push(obj)
            }
        }

        if (this._callback !== null)
            this._callback(listObjects, error, status)
    }

    show(store, id) {
        this.#_rest._contentType = 'application/json';
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idStore}", store.id) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status) => {
        const object = !error ? new License(response.data) : null;
        this._callback(object, error, status);
    }

    post(store, datas) {
        this.#_rest._contentType = 'application/json';
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idStore}", store.id),
            datas,
            this.handlePost
        );
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(store, object, datas) {
        this.#_rest._contentType = 'application/json';
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idStore}", store.id) + '/' + object.id,
            datas,
            this.handlePut
        );
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default LicenseController
