import React from "react";

function OutOfStockIcon (props) {
    const { fill, classname, handleClick } = props;

    return (
        <svg viewBox="0 0 510 510" xmlns="http://www.w3.org/2000/svg" fill={ fill } className={ classname !== undefined ? classname : "" } onClick={ (handleClick !== undefined ? handleClick : () => {}) }>
            <path d="M202.564 266.204c-5.863-5.862-15.35-5.863-21.213 0-5.849 5.849-5.849 15.365 0 21.214l42.426 42.426c5.849 5.848 15.365 5.848 21.214 0 5.849-5.849 5.849-15.364 0-21.213zM361.664 107.105c-5.849-5.849-15.365-5.847-21.214 0l-10.606 10.607 63.64 63.64 10.606-10.606c5.862-5.863 5.863-15.35 0-21.213z" />
            <path d="M416.36 0h-97.279L0 319.081v97.279L93.64 510h97.279L510 190.919V93.64zM186.655 430.606l-26.517 26.517-21.214-21.213 26.517-26.517a7.498 7.498 0 000-10.606l-10.607-10.607c-2.922-2.924-7.682-2.924-10.607.001-14.653 14.653-38.375 14.656-53.032-.001l-10.606-10.606c-14.654-14.652-14.657-38.377 0-53.032l26.517-26.517 21.213 21.213-26.517 26.517a7.501 7.501 0 000 10.607l10.606 10.606a7.5 7.5 0 0010.606 0c14.622-14.62 38.411-14.622 53.033 0l10.607 10.607c7.083 7.082 10.983 16.499 10.983 26.516s-3.899 19.432-10.982 26.515zm79.549-79.549c-17.546 17.546-46.094 17.546-63.64 0l-42.426-42.426c-17.545-17.546-17.545-46.094-.001-63.64 17.546-17.546 46.096-17.546 63.64 0l42.427 42.427c17.546 17.545 17.546 46.094 0 63.639zm53.033-53.034L213.172 191.958l21.213-21.213 84.853 84.853 31.82-31.82 21.213 21.213zm106.066-106.065l-31.819 31.82-106.066-106.066 31.819-31.82c17.545-17.545 46.095-17.546 63.64 0l42.426 42.427c8.499 8.499 13.181 19.799 13.181 31.819s-4.681 23.32-13.181 31.82z" />
        </svg>
    );
}

export default OutOfStockIcon;
