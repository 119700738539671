import React from "react"
import moment from "moment"
import "../../../../css/page/content/ticket/Ticket.css"

/*
    ---
    Applying style list

    width: auto|fixed|numberOfchar <- fixed: text lengthed, auto: autofill
    min-width: empty|numberOfchar <- in case of paperWidth 80|57
    max-width: empty|numberOfchar <- in case of paperWidth 80|57
    text-align: left|right|center
    font-width: 1|2
    font-height: 1|2
    font-weight: normal|bold
    hide: false|true
    highlight: false|true
    underline: false|true
    upperline: false|true
    ---
*/

const Ticket = props => {
    moment.locale('fr')
    const { datas } = props

    const convert = () => {
        return <div className="ticket">
            {
                datas.map((line, index) => newLine(line, index))
            }
        </div>
    }
    const newLine = (line, index) => {
        return buildLine(line, index)
    }
    const buildLine = (line, index) => {
        let contents = {
            "text": "",
            "width": "",
            "min-width": "",
            "max-width": "",
            "text-align": "",
            "font-width": "",
            "font-height": "",
            "font-weight": "",
            "hide": "",
            "highlight": "",
            "underline": "",
            "upperline": ""
        }

        if (line.length === 1)
            contents = singleLine(contents, line[0])
        else
            contents = columnsLine(contents, line)

        return writeLine(contents, index)
    }
    const singleLine = (contents, line) => {
        return objectToPrint(contents, line)
    }
    const columnsLine = (contents, line) => {
        let loop = 0

        for (let index in line) {
            if (loop > 0) {
                contents["text"] += "|"
                contents["width"] += "|"
                contents["min-width"] += "|"
                contents["max-width"] += "|"
                contents["text-align"] += "|"
                contents["font-width"] += "|"
                contents["font-height"] += "|"
                contents["font-weight"] += "|"
                contents["hide"] += "|"
                contents["highlight"] += "|"
                contents["underline"] += "|"
                contents["upperline"] += "|"
            }

            contents = objectToPrint(contents, line[index])

            loop++
        }

        return contents
    }
    const objectToPrint = (contents, objectLine) => {
        for (let key in objectLine) {
            let value = objectLine[key]

            switch (key) {
                case "option":
                    contents = applyOption(contents, value)
                    break
                case "text":
                    contents[key] += value
                    break
                default: break
            }
        }

        return contents
    }
    const applyOption = (contents, object) => {
        let defaultOptions = {
            "width": "auto",
            "min-width": "",
            "max-width": "",
            "text-align": "left",
            "font-width": "1",
            "font-height": "1",
            "font-weight": "normal",
            "hide": "false",
            "highlight": "false",
            "underline": "false",
            "upperline": "false"
        }

        for (let key in object) {
            defaultOptions[key] = object[key]
        }

        for (let key in defaultOptions) {
            contents[key] += defaultOptions[key]
        }

        return contents
    }
    const applyStyle = (contents) => {
        let style = {}

        style["textAlign"] = contents["text-align"]
        style["fontWeight"] = contents["font-weight"]
        style["fontSize"] = "10pt"
        style["lineHeight"] = "20px"
        style["background"] = "transparent"
        style["color"] = "black"

        switch (contents["font-width"]) {
            case "1":
                style["fontSize"] = "10pt"
                style["lineHeight"] = "20px"
                break
            case "2":
                style["fontSize"] = "14pt"
                style["lineHeight"] = "32px"
                break
            default: break
        }

        switch (contents["font-height"]) {
            case "1":
                style["fontSize"] = "10pt"
                style["lineHeight"] = "20px"
                break
            case "2":
                style["fontSize"] = "14pt"
                style["lineHeight"] = "32px"
                break
            default: break
        }

        switch (contents["highlight"]) {
            case "true":
                style["padding"] = "5px 0"
                style["background"] = "black"
                style["color"] = "white"
                break
            case "false":
                style["background"] = "transparent"
                style["color"] = "black"
                break
            default: break
        }

        switch (contents["underline"]) {
            case "true":
                style["borderBottom"] = "1px solid black"
                break
            case "false":
                style["borderBottom"] = "none"
                break
            default: break
        }

        switch (contents["upperline"]) {
            case "true":
                style["borderTop"] = "1px solid black"
                break
            case "false":
                style["borderTop"] = "none"
                break
            default: break
        }

        return style
    }
    const writeLine = (contents, index) => {
        if (!contents["text"].includes("|")) {
            return <p key={index} className="line" style={ applyStyle(contents) }>{ contents["text"] }</p>
        }
        else {
            let datas = []
            let texts = contents["text"].split('|')
            let widths = contents["width"].split('|')
            let minWidths = contents["min-width"].split('|')
            let maxWidths = contents["max-width"].split('|')
            let textAligns = contents["text-align"].split('|')
            let fontWidths = contents["font-width"].split('|')
            let fontHeights = contents["font-height"].split('|')
            let fontWeights = contents["font-weight"].split('|')
            let hides = contents["hide"].split('|')
            let highlights = contents["highlight"].split('|')
            let underlines = contents["underline"].split('|')
            let upperlines = contents["upperline"].split('|')

            if (
                texts.length === 0
                || texts.length !== widths.length
                || texts.length !== minWidths.length
                || texts.length !== maxWidths.length
                || texts.length !== textAligns.length
                || texts.length !== fontWidths.length
                || texts.length !== fontHeights.length
                || texts.length !== fontWeights.length
                || texts.length !== hides.length
                || texts.length !== highlights.length
                || texts.length !== underlines.length
                || texts.length !== upperlines.length
            ) return // Control datas integrity

            for (let i = 0; i < texts.length; i++) {
                datas.push({
                    "text": texts[i],
                    "width": widths[i],
                    "min-width": minWidths[i],
                    "max-width": maxWidths[i],
                    "text-align": textAligns[i],
                    "font-width": fontWidths[i],
                    "font-height": fontHeights[i],
                    "font-weight": fontWeights[i],
                    "hide": hides[i],
                    "highlight": highlights[i],
                    "underline": underlines[i],
                    "upperline": upperlines[i]
                })
            }

            return <table key={index} className="table">
                <tbody>
                    <tr>
                        {
                            datas.map((data, id) => (
                                <td key={id} style={ applyStyle(data) }>{ data.text }</td>
                            ))
                        }
                    </tr>
                </tbody>
            </table>
        }
    }

    return(convert())
}

export default Ticket
