import React from "react"

const ChoiceAddCatalog = props => {
    const { handleClose, handleChoiceList, handleChoiceNew } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox choices">
                    <div className="form margin">
                        <p className="titleForm center">Que souhaitez-vous faire ?</p>
                        <button className="buttonChoice" onClick={ handleChoiceNew }>Créer un nouveau catalogue</button>
                        <button className="buttonChoice" onClick={ handleChoiceList }>Créer depuis un catalogue existant</button>
                        <button className="cancel block" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoiceAddCatalog
