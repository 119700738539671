import React, {useEffect} from "react"
import FormDate from "../../form/deployment/FormDate"
import '../../../css/overbox/ExportStatsBox.css'

const CustomDate = props => {
    const { value, handleSelect, handleClose } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox date">
                    <p className="title">SÉLECTIONNER LA DATE</p>
                    <FormDate value={ value } handleSelect={ handleSelect } handleClose={ handleClose } />
                </div>
            </div>
        </div>
    )
}

export default CustomDate
