import React from "react"
import LoaderCircle from "../../loader/LoaderCircle"

const ConfirmBox = props => {
    const { title, text, loading, textBack, textConfirm, textConfirming, handleClose, handleConfirm } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox validation">
                    <div className="form noPadding">
                        {
                            title !== undefined
                            && <p className="titleForm center">{ title }</p>
                        }
                        {
                            text !== undefined
                            && <p className="description">{ text }</p>
                        }
                        <button className="submit noMarginBottom" onClick={ handleConfirm }>
                            { (loading !== undefined && loading) ? (textConfirming !== undefined ? textConfirming : textConfirm) : textConfirm }
                            <LoaderCircle display="loader submitLogin" hide={(loading === undefined || !loading) ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <button className="cancel align" onClick={ handleClose }>{ textBack }</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmBox
