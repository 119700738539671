import Rest from "../../../class/tool/Rest";
import Sale from "./Sale";
import moment from "moment"

class SaleController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "sales"

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Sale(response.data) : null
        this._callback(object, error, status)
    }

    #_parseFloat(value) {
        switch (typeof value) {
            case "string": return parseFloat(value.toString().replace(",", "."))
            case "object": return value !== null ? parseFloat(value.toString().replace(",", ".")) : 0
            default: return parseFloat(value)
        }
    }

    datasToPrint = (sale, type = "ticket") => {
        moment.locale('fr')

        let datas = []
        const kart = sale.kart

        // Header ticket
        //
        // ----- PREVIEW -----
        // Caisse                       PW00000
        // Vendeur            (V00000) Thomas P
        // Date             01/01/2000 00:00:00
        // Numero de ticket              100001
        // Nombre d'articles       2 (2 lignes)
        // Nombre de clients                  1
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Caisse"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": sale.licenseReference
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Vendeur"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": ("(" + kart.sellerReference + ") " + kart.sellerName).trim()
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Date"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": moment(sale.created_at).format("L")
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Numéro de ticket"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": sale.number
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Nombre d'articles"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": kart.nbArticle + " (" + kart.nbLine + " ligne" + (kart.nbLine > 1 ? "s" : "") + ")"
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Nombre de clients"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": kart.nbClient
            }
        ])
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        // Type operation
        //
        // ----- PREVIEW -----
        //               VENTE
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "center",
                    "font-height": "2",
                    "highlight": "true"
                },
                "text": sale.operationType.toUpperCase()
            }
        ])
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        // Header content ticket
        //
        // ----- PREVIEW -----
        // Article                 PU         PT T
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": "Article"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "8"
                },
                "text": "PU"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "9"
                },
                "text": "PT"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "2"
                },
                "text": "T"
            }
        ])

        // Contents ticket
        //
        // ----- PREVIEW -----
        // 2x Baguette             1,85      3,70 A
        //
        //
        let contents = kart.contents
        contents = contents.sort((c1, c2) => (c1.price_list_id > c2.price_list_id) ? 1 : (c1.price_list_id < c2.price_list_id) ? -1 : 0); // sorting contents by pricelist
        let lastPriceList = "";

        for (let i in contents) {
            let content = kart.contents[i]

            // pricelist sorting
            if (lastPriceList !== content.pricelistReference)
            {
                lastPriceList = content.pricelistReference;

                datas.push([
                    {
                        "option": {},
                        "text": ""
                    }
                ]) // line feed
                datas.push([
                    {
                        "option": {},
                        "text": "--- " + content.pricelistName + " ---"
                    }
                ])
            }

            const quantity = this.#_parseFloat(content.quantity)
            const unitPriceInclTaxUndiscounted = this.#_parseFloat(content.unitPriceInclTaxUndiscounted)
            const amountInclTaxUndiscounted = this.#_parseFloat(content.amountInclTaxUndiscounted)
            const discountValue = this.#_parseFloat(content.discountValue)
            const lineDiscountAmount = this.#_parseFloat(content.lineDiscountAmount)

            // content
            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "auto"
                    },
                    "text": (quantity === Math.ceil(quantity) ? quantity.toFixed() : quantity.toFixed(3).toString().replace('.', ',')) + "x " + content.productName
                },
                {
                    "option": {
                        "text-align": "right",
                        "font-weight": "bold",
                        "width": "8"
                    },
                    "text": unitPriceInclTaxUndiscounted.toFixed(2).toString().replace('.', ',')
                },
                {
                    "option": {
                        "text-align": "right",
                        "font-weight": "bold",
                        "width": "9"
                    },
                    "text": amountInclTaxUndiscounted.toFixed(2).toString().replace('.', ',')
                },
                {
                    "option": {
                        "text-align": "right",
                        "font-weight": "bold",
                        "width": "2"
                    },
                    "text": content.vatRateReference
                }
            ])

            // discount
            if (content.discountType !== null && content.discountType !== "") {
                let valueDiscount = ""

                switch (content.discountType) {
                    case "PERCENT":
                        valueDiscount = discountValue.toFixed() + " %"
                        break
                    case "DEVISE":
                        valueDiscount = discountValue.toFixed(2).replace('.', ',') + " EUR"
                        break
                    case "OFFERED":
                        valueDiscount = "OFFERT"
                        break
                    default: break
                }

                datas.push([
                    {
                        "option": {
                            "text-align": "left",
                            "width": "auto"
                        },
                        "text": ""
                    },
                    {
                        "option": {
                            "text-align": "right",
                            "font-weight": "bold",
                            "width": "8"
                        },
                        "text": "Remise " + valueDiscount + " soit"
                    },
                    {
                        "option": {
                            "text-align": "right",
                            "font-weight": "bold",
                            "width": "9"
                        },
                        "text": lineDiscountAmount.toFixed(2).toString().replace('.', ',')
                    },
                    {
                        "option": {
                            "text-align": "right",
                            "font-weight": "bold",
                            "width": "2"
                        },
                        "text": ""
                    }
                ])
            }
        }
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        const kartDiscountValue = this.#_parseFloat(kart.discountValue)
        const kartLineDiscountAmount = this.#_parseFloat(kart.lineDiscountAmount)
        const kartSaleDiscountAmount = this.#_parseFloat(kart.saleDiscountAmount)
        const kartAmountInclTaxUndiscounted = this.#_parseFloat(kart.amountInclTaxUndiscounted)

        // Sale discount
        //
        // ----- PREVIEW -----
        // Montant sous-total                 3,00
        // Remise de 10 % soit               -0,30
        //
        //
        if (kart.discountType !== null && kart.discountType !== "") {
            let valueDiscount = ""

            switch (kart.discountType) {
                case "PERCENT":
                    valueDiscount = kartDiscountValue.toFixed() + " %"
                    break;
                case "DEVISE":
                    valueDiscount = kartDiscountValue.toFixed(2).replace('.', ',') + " EUR"
                    break
                case "OFFERED":
                    valueDiscount = "OFFERT";
                    break;
                default: break
            }

            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "fixed"
                    },
                    "text": "Montant sous-total"
                },
                {
                    "option": {
                        "text-align": "right",
                        "width": "auto"
                    },
                    "text": (kartAmountInclTaxUndiscounted + kartLineDiscountAmount).toFixed(2).replace('.', ',')
                }
            ])
            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "fixed"
                    },
                    "text": "Remise " + valueDiscount + " soit"
                },
                {
                    "option": {
                        "text-align": "right",
                        "width": "auto"
                    },
                    "text": kartSaleDiscountAmount.toFixed(2).replace('.', ',')
                }
            ])
            datas.push([
                {
                    "option": {},
                    "text": ""
                }
            ]) // line feed
        }

        const totalDiscountAmount = this.#_parseFloat(kart.totalDiscountAmount)

        // Discount information
        //
        // ----- PREVIEW -----
        // Total remise                   1,00 EUR
        //
        //
        if (kart.totalDiscountAmount !== 0) {
            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "fixed"
                    },
                    "text": "Total remise"
                },
                {
                    "option": {
                        "text-align": "right",
                        "font-weight": "bold",
                        "width": "auto"
                    },
                    "text": totalDiscountAmount.toFixed(2).replace('.', ',') + " EUR"
                }
            ])
        }

        const amountExclTax = this.#_parseFloat(kart.amountExclTax)
        const amountInclTax = this.#_parseFloat(kart.amountInclTax)

        // Excl tax
        //
        // ----- PREVIEW -----
        // Total H.T.                     3,70 EUR
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": "Total H.T"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": amountExclTax.toFixed(2).replace('.', ',') + " EUR"
            }
        ])

        // Total content ticket
        //
        // ----- PREVIEW -----
        // Total                          3,00 EUR
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "font-height": "2",
                    "width": "fixed",
                    "underline": "true"
                },
                "text": "Total"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-height": "2",
                    "width": "auto",
                    "underline": "true"
                },
                "text": amountInclTax.toFixed(2).replace('.', ',') + " EUR"
            }
        ])
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        let payment
        let theoricalAmount
        let paymentAmount

        // Contents payment
        //
        // ----- PREVIEW -----
        // CB                             3,70 EUR
        //
        //
        for (let i in kart.payments) {
            payment = kart.payments[i]
            theoricalAmount = this.#_parseFloat(payment.theoricalAmount)
            paymentAmount = this.#_parseFloat(payment.amount)

            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "fixed"
                    },
                    "text": payment.paymentMethodName
                },
                {
                    "option": {
                        "text-align": "right",
                        "font-weight": "bold",
                        "width": "auto"
                    },
                    "text": theoricalAmount.toFixed(2).replace('.', ',') + " EUR"
                }
            ])

            if (theoricalAmount !== paymentAmount) {
                datas.push([
                    {
                        "option": {
                            "text-align": "left",
                            "width": "fixed"
                        },
                        "text": "Rendu monnaie"
                    },
                    {
                        "option": {
                            "text-align": "right",
                            "font-weight": "bold",
                            "width": "auto"
                        },
                        "text": ((theoricalAmount - paymentAmount) * -1).toFixed(2).replace('.', ',') + " EUR"
                    }
                ])
            }
        }
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        // Header content vats
        //
        // ----- PREVIEW -----
        // T TVA        Montant       HT       TTC
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "font-weight": "bold",
                    "width": "1"
                },
                "text": "T"
            },
            {
                "option": {
                    "text-align": "left",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": "TVA"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "9"
                },
                "text": "Montant"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "10"
                },
                "text": "HT"
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "10"
                },
                "text": "TTC"
            }
        ])

        let vat
        let vatAmountTax
        let vatAmountExclTax
        let vatAmountInclTax

        // Contents vat
        //
        // ----- PREVIEW -----
        // A 20%           0,63     3,08      3,70
        //
        //
        for (let i in kart.vats) {
            vat = kart.vats[i]
            vatAmountTax = this.#_parseFloat(vat.amountTax)
            vatAmountExclTax = this.#_parseFloat(vat.amountExclTax)
            vatAmountInclTax = this.#_parseFloat(vat.amountInclTax)

            datas.push([
                {
                    "option": {
                        "text-align": "left",
                        "width": "1"
                    },
                    "text": vat.vatRateReference
                },
                {
                    "option": {
                        "text-align": "left",
                        "width": "auto"
                    },
                    "text": vat.vatRateName
                },
                {
                    "option": {
                        "text-align": "right",
                        "width": "9"
                    },
                    "text": vatAmountTax.toFixed(2).replace('.', ',')
                },
                {
                    "option": {
                        "text-align": "right",
                        "width": "10"
                    },
                    "text": vatAmountExclTax.toFixed(2).replace('.', ',')
                },
                {
                    "option": {
                        "text-align": "right",
                        "width": "10"
                    },
                    "text": vatAmountInclTax.toFixed(2).replace('.', ',')
                }
            ])
        }
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        // barcode if setting
        if (sale.barcode !== null) {
            datas.push([
                {
                    "option": {},
                    "barcode1D": sale.barcode.barcode
                }
            ])
        }

        // Sign
        //
        // ----- PREVIEW -----
        // SkyTill X.X.X                 B0019EyiT
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "left",
                    "width": "fixed"
                },
                "text": sale.logicielName + " " + sale.version
            },
            {
                "option": {
                    "text-align": "right",
                    "font-weight": "bold",
                    "width": "auto"
                },
                "text": sale.shortSigning
            }
        ])
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        // Legal info
        //
        // ----- PREVIEW -----
        // 61 Rue de l'Universite
        // 93160, Noisy-Le-Grand
        // France
        // SIRET : XXX XXX XXX XXXX
        // TVA : FR XXX XXX XXX XXXX
        // APE : XXXXX
        //
        //
        datas.push([
            {
                "option": {
                    "text-align": "center"
                },
                "text": sale.address
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "center"
                },
                "text": sale.postalCode + " " + sale.city + ", " + sale.country
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "center"
                },
                "text": "SIRET : " + sale.siret
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "center"
                },
                "text": "TVA : " + sale.intraCommunityVat
            }
        ])
        datas.push([
            {
                "option": {
                    "text-align": "center"
                },
                "text": "APE : " + sale.ape
            }
        ])
        datas.push([
            {
                "option": {},
                "text": ""
            }
        ]) // line feed

        return datas
    }
}

export default SaleController
