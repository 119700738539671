import React, { useContext, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import HeaderAccess from "../../header/HeaderAccess"
import CompanyItem from "./item/CompanyItem"
import ObjectSheet from "../../../sheet/ObjectSheet"
import AccountList from "../../../overbox/account/AccountList"
import SessionContext from "../../../../context/SessionContext"
import '../../../../css/page/content/access/Access.css'

const Access = () => {
    const { handleCheckBigBisoux } = useContext(SessionContext)

    useEffect(() => {
        document.title = "Back office - Mes boutiques"
        handleCheckBigBisoux()
    }, [])

    return (
        <div className="main">
            <HeaderAccess />
            <div className="subHeaderAccessFiller" />
                <CompanyItem />
            <Switch>
                <Route path="/access/stores/:id/informations">
                    <ObjectSheet objectType="store" />
                </Route>
                <Route path="/access/stores/:id/accounts">
                    <AccountList />
                </Route>
            </Switch>
        </div>
    )
}

export default Access
