import React from "react";

function SubCategoryIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <g data-name="Layer 2">
                <rect height={5.5} rx={1.75} width={14.5} x={8.25} y={2.25} />
                <rect height={5.5} rx={1.75} width={5.5} x={1.25} y={2.25} />
                <rect height={5.5} rx={1.75} width={14.5} x={8.25} y={16.25} />
                <rect height={5.5} rx={1.75} width={5.5} x={1.25} y={16.25} />
                <rect height={5.5} rx={1.75} width={10.5} x={8.25} y={9.25} />
                <rect height={5.5} rx={1.75} width={5.5} x={1.25} y={9.25} />
            </g>
        </svg>
    );
}

export default SubCategoryIcon;
