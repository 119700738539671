import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import PosScreenController from "../../../stories/_setting/PosScreens/PosScreenController"

const ChoiceListScreen = props => {
    const { handleClose, handleChoice } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ list, setList ] = useState([])
    const company = JSON.parse(localStorage.getItem("company"))

    const getScreens = () => {
        const controller = new PosScreenController()
        controller._callback = handleGetScreens
        controller.indexForCompany(company.id)
    }
    const handleGetScreens = (list, error, status) => {
        switch (status) {
            case 200:
                setList(list)
                setLoading(false)
                break
            default:
                setError("Une erreur s'est produite lors de la récupération des catalogues")
                setLoading(false)
                break
        }
    }
    const buildScreen = (object, index) => {
        return <div key={ index } className="catalog" onClick={ () => { handleChoice(object) } }>
            <p className="text">{ object.name }</p>
        </div>
    }

    useEffect(() => {
        getScreens()
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <div className="form">
                        {
                            error !== null
                            && <p className="error">{ error }</p>
                        }
                        <p className="titleForm center">Quel écran souhaitez-vous importer ?</p>
                        {
                            loading
                                ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                                : <div className="clearing">
                                    {
                                        list.map((item, index) => (
                                            <div key={ index } className="clearing" style={{margin: "25px 0"}}>
                                                <p className="storeCatalogs">{ item.store.name }</p>
                                                <div className="listCatalogs">
                                                    {
                                                        item.screens.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).length > 0
                                                            ? item.screens.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).map((screen, id) => buildScreen(screen, id))
                                                            : <p className="empty">Aucun écran disponible</p>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                        <button className="cancel block" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoiceListScreen
