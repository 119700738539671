import React from "react"
import InformationIcon from "../../icons/InformationIcon"

const Informations = props => {
    const { handleClick } = props

    return (
        <div className="iconInformation" onClick={ handleClick }>
            <InformationIcon />
        </div>
    )
}

export default Informations
