import Entity from "../../../class/abstract/Entity"

class Store extends Entity {
    additional = null
    address = null
    apeCode = null
    apiStatus = null
    api_auth_id = null
    city = null
    code = null
    company_id = null
    country = null
    hivy_id = null
    intraTva = null
    main_license_id = null
    name = null
    shortName = null
    postalCode = null
    siret = null
    socialReason = null
    status = null
    synced_at = null
    timezone = null
    sectors = null
    url = null
    urlSync = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default Store
