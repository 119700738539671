import React from "react";
import StringTools from "../../class/tool/StringTools";

const LineHistory = (props) => {
    const { object } = props;

    const buildAuthor = () => {
        const obj = JSON.parse(object.author);
        return (StringTools.capitalizeFirstLetter(obj.firstname) + " " + obj.lastname.toLocaleUpperCase()).trim();
    }
    const buildDate = () => {
        let dateDetails = object.date.split(" ");
        return dateDetails[0] + " à " + dateDetails[1];
    }
    const buildSource = () => {
        switch (object.source.toLocaleLowerCase()) {
            case "manager": return "le back office";
            case "pos": return "la caisse";
            case "api": return "l'API";
            default: return "inconnu";
        }
    }
    const buildDatas = () => {
        if (object.datas.indexOf('{') >= 0) {
            const obj = JSON.parse(object.datas);
            let datas = "";

            for( let key in obj ) {
                datas += (datas.length > 0 ? <br /> : "");
                datas += key + " : " + obj[key].new + " (ancienne valeur : " + obj[key].old + ")";
            }

            return datas;
        }
        else
            return object.datas;
    }

    return (
        <li>
            <p className="author">Par <strong>{ buildAuthor() }</strong></p>
            <p className="date">Le <strong>{ buildDate() }</strong> depuis  <strong>{ buildSource() }</strong></p>
            <p className="datas"><strong>{ buildDatas() }</strong></p>
        </li>
    );
}

export default LineHistory;
