import React, { useState } from "react"
import FormManySubCategory from "../../form/subcategory/FormManySubCategory"
import FormChoiceCatalog from "../../form/catalog/FormChoiceCatalog"

const ManyBoxSubCategory = props => {
    const itemClass = "manyForm"
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"))
    const { handleClose } = props
    const [ globalError, setGlobalError ] = useState(null)
    const [ catalogId, setCatalogId ] = useState(Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null)

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className={ "overbox " + itemClass }>
                    {
                        globalError !== null
                        && <p className="error">{ globalError }</p>
                    }
                    {
                        catalogId === null
                            ? <FormChoiceCatalog setCatalogId={ setCatalogId } setGlobalError={ setGlobalError } handleClose={ handleClose } />
                            : <FormManySubCategory catalogId={ catalogId } handleClose={ handleClose } />
                    }
                </div>
            </div>
        </div>
    )
}

export default ManyBoxSubCategory;
