import React, { useEffect} from "react"
import FormLogin from "../../../form/login/FormLogin"
import '../../../../css/Init.css'

const Login = () => {
    useEffect(() => {
        document.title = "Back office - Connexion"
    })

    return (
        <div className="wrapInit">
            <div className="box">
                <FormLogin />
            </div>
        </div>
    )
}

export default Login
