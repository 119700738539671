import Rest from "../../../class/tool/Rest"
import Account from "../../_account/Accounts/Account"

class AuthController {
    #_rest = new Rest()
    _callback = null

    authenticate(email, password) {
        this.#_rest.sendQuery(
            'POST',
            '/login',
            {
                email: email,
                password: password
            },
            this._callback)
    }
    forgotPwd(email) {
        this.#_rest.sendQuery(
            'POST',
            '/forgotpwd',
            {
                email: email
            },
            this._callback)
    }
    refresh() {
        this.#_rest.sendQuery(
            'POST',
            '/refresh',
            {},
            this._callback)
    }
    switch(type, id) {
        let datas = {
            "type": type,
            "id": id
        }

        this.#_rest.sendQuery(
            'POST',
            '/switch',
            datas,
            this.handleSwitch)
    }
    handleSwitch = (response, error, status) => {
        this._callback(response, error, status)
    }
    me() {
        this.#_rest.sendQuery(
            'GET',
            '/me',
            {},
            this._callback)
    }
    logout() {
        this.#_rest.sendQuery(
            'POST',
            '/logout',
            {},
            this._callback)
    }

    static hasRules (expected, rulesScope = "store", idTarget = null) {
        if (localStorage.getItem("env") === null) return

        let environment = JSON.parse(localStorage.getItem("env"))
        let rules = []

        switch (environment.type) {
            case "empire":
                if (rulesScope === "company") {
                    for (let index in environment.companies) {
                        if (environment.companies[index].id === idTarget)
                            rules = environment.companies[index].rule.split('|')
                    }
                }
                else if (rulesScope === "store") {
                    for (let indexCompany in environment.companies) {
                        for (let index in environment.companies[indexCompany].stores) {
                            if (environment.companies[indexCompany].stores[index].id === idTarget)
                                rules = environment.companies[indexCompany].stores[index].rule.split('|')
                        }
                    }
                }

                break
            case "company":
                if (rulesScope === "company")
                    rules = environment.rule.split('|')
                else if (rulesScope === "store") {
                    for (let index in environment.stores) {
                        if (environment.stores[index].id === idTarget)
                            rules = environment.stores[index].rule.split('|')
                    }
                }

                break
            case "store":
                if (idTarget === environment.id) {
                    switch (rulesScope) {
                        case "company":
                            rules = environment.companyRule.split('|')
                            break
                        case "store":
                            rules = environment.rule.split('|')
                            break
                        default: return false
                    }
                }

                break
            default: break
        }

        for (let i in rules)
            if(expected.indexOf(rules[i].toLowerCase()) >= 0)
                return true

        return false
    }
}

export default AuthController
