import React from "react"

class SerialDatas {
    static listBaudRates = () => {
        let dBaudRates = [75, 110, 134, 150, 300, 600, 1200, 1800, 2400, 4800, 7200, 9600, 14400, 19200, 38400, 57600, 115200, 128000]
        let listBaudRates = []

        for(let item in dBaudRates)
            listBaudRates.push({
                value: dBaudRates[item],
                id: dBaudRates[item]
            })

        return listBaudRates
    }
    static listDataBits = () => {
        let dDataBits = [4, 5, 6, 7, 8]
        let listDataBits = []

        for(let item in dDataBits)
            listDataBits.push({
                value: dDataBits[item],
                id: dDataBits[item]
            })

        return listDataBits
    }
    static listParities = () => {
        let dParities = ["Paire", "Impaire", "Aucune", "Marque", "Espace"]
        let listParities = []

        for(let item in dParities)
            listParities.push({
                value: dParities[item]
            })

        return listParities
    }
    static listStopBits = () => {
        let dStopBits = [1, 1.5, 2]
        let listStopBits = []

        for(let item in dStopBits)
            listStopBits.push({
                value: dStopBits[item],
                id: dStopBits[item]
            })

        return listStopBits
    }
    static listFlowControls = () => {
        let dFlowControls = ["Xon / Xoff", "Materiel", "Aucun"]
        let listFlowControls = []

        for(let item in dFlowControls)
            listFlowControls.push({
                value: dFlowControls[item]
            })

        return listFlowControls
    }
}

export default SerialDatas;
