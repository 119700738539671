import React, { useEffect } from "react"
import FormCompanies from "../../../form/login/FormCompanies"
import '../../../../css/Init.css'

const Companies = () => {
    useEffect(() => {
        document.title = "Back office - Connexion"
    })

    return (
        <div className="wrapInit">
            <div className="box">
                <FormCompanies />
            </div>
        </div>
    )
}

export default Companies
