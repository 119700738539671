import React, { useEffect, useState } from "react";
import FormBuilder from "../../../class/tool/FormBuilder";
import DisplayController from "../../../stories/_setting/Displays/DisplayController";

const DisplayFormSheet = props => {
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            noLabel: true,
            initialAtribute: "welcomeText",
            attribute: "line1",
            inputType: "textDisplay",
            returnType: "stringDisplay",
            classnameInput: "displayText",
            placeholder: "Ligne 1",
            maxChars: 20,
            hidden: true,
            focus: true
        },
        {
            noLabel: true,
            initialAtribute: "welcomeText",
            attribute: "line2",
            inputType: "textDisplay",
            returnType: "stringDisplay",
            classnameInput: "displayText",
            placeholder: "Ligne 2",
            maxChars: 20,
            hidden: true
        },
        {
            noLabel: true,
            initialAtribute: "welcomeText",
            attribute: "line3",
            inputType: "textDisplay",
            returnType: "stringDisplay",
            classnameInput: "displayText",
            placeholder: "Ligne 3",
            maxChars: 20,
            hidden: true
        },
        {
            noLabel: true,
            initialAtribute: "welcomeText",
            attribute: "line4",
            inputType: "textDisplay",
            returnType: "stringDisplay",
            classnameInput: "displayText",
            placeholder: "Ligne 4",
            maxChars: 20,
            hidden: true
        }
    ])

    const initRows = () => {
        let display = DisplayController._displays.filter(_ => _.model === values.model)
        if (display.length === 0) return

        let rowsTmp = rows.slice()

        for (let i = 1; i < 5; i++) {
            if (i <= display[0].nbLines) {
                rowsTmp[i-1].hidden = false
                rowsTmp[i-1].maxChars = display[0].maxChars
            }
        }

        setRows(rowsTmp)
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color", "tag", "switch", "multiline", "textDisplay"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        if (returnType === "stringDisplay") {
            let display = DisplayController._displays.filter(_ => _.model === values.model)
            let lines = JSON.parse(values.welcomeText)
            let indexLine = parseInt(attribute.replace("line", "")) - 1

            for (let i = 0; i < display[0].nbLines; i++) {
                if (i === indexLine)
                    lines[indexLine] = val
                else
                    lines[i] = lines[i] !== undefined ? lines[i] : ""
            }

            handleChange(rows, "welcomeText", "json", JSON.stringify(lines), strict)
        }
        else
            handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        initRows()
    }, [])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            (row.hidden === undefined || !row.hidden)
                            && <div className="clearing">
                                {
                                    row.noLabel === undefined
                                    && <label className={ row.classnameLabel }>{ row.label }</label>
                                }
                                {
                                    buildRow(row)
                                }
                            </div>
                        }
                    </div>
                ))
            }
        </form>
    );
}

export default DisplayFormSheet;
