import React, {useEffect} from "react"
import FormForgotPwd from "../../../form/login/FormForgotPwd"

import '../../../../css/Init.css';

function ForgotPwd(props) {
    useEffect(() => {
        document.title = "Back office - Retrouver mon mot de passe";
    })

    return (
        <div className="wrapInit">
            <div className="box">
                <FormForgotPwd error={props.error} />
            </div>
        </div>
    );
}

export default ForgotPwd;
