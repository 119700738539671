import React, { useState } from "react";
import FormBuilder from "../../../class/tool/FormBuilder";
import '../../../css/form/Form.css';

const StoreBasketFormSheet = props => {
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Cumuler les quantités",
            attribute: "accumulateQuantities",
            inputType: "select",
            returnType: "int",
            list: [
                {
                    "value": "Non",
                    "id": 0
                },
                {
                    "value": "Oui",
                    "id": 1
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ])

    const buildRow = row => {
        if (edit === row.attribute || ["color", "switch"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            row.noLabel === undefined
                            && <label className={ row.classnameLabel }>{ row.label }</label>
                        }
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default StoreBasketFormSheet
