import React, {useEffect, useState} from "react";
import FormBuilder from "../../../class/tool/FormBuilder";
import '../../../css/form/Form.css';

const StoreClosingFormSheet = props => {
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Heure d'ouverture d'une journée",
            attribute: "timeSlotBegin",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Ne pas pouvoir clôturer la journée avant",
            attribute: "closingMinimalHour",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Mois de l'année fiscal",
            attribute: "fiscalYearMonth",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Emails comptables (séparer par des virgules)",
            attribute: "accountantEmail",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "jean@dupont.fr, michel@dupont.fr",
            emptyText: "Aucun"
        }
    ])

    const initRows = () => {
        let rowsTmp = rows.slice()
        let openingHours = []
        let closingLimitHours = [{ value: "Aucune contrainte", id: null }]
        let fiscalMonth = []

        for (let i = 0; i <= 9; i++) {
            openingHours.push({
                value: "0" + i + "h",
                id: i
            })
        }

        for (let i = 15; i <= 23; i++) {
            closingLimitHours.push({
                value: i + "h",
                id: i
            })
        }

        for (let i = 1; i <= 12; i++) {
            fiscalMonth.push({
                value: getMonthFromString(i),
                id: i
            })
        }

        rowsTmp[0].list = openingHours
        rowsTmp[1].list = closingLimitHours
        rowsTmp[2].list = fiscalMonth
        setRows(rowsTmp)
    }
    const getMonthFromString = (monthNumber) => {
        const date = new Date()
        date.setMonth(monthNumber - 1)
        const month = date.toLocaleString('fr-FR', { month: 'long' })

        return month.charAt(0).toUpperCase() + month.slice(1)
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color", "switch"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        initRows()
    }, [])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            row.noLabel === undefined
                            && <label className={ row.classnameLabel }>{ row.label }</label>
                        }
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default StoreClosingFormSheet
