import React from "react";
import Account from "../../../stories/_account/Accounts/Account";
import "../../../css/page/UserPreview.css";

const UserPreview = props => {
    const { setAccountForm } = props;
    const env = JSON.parse(localStorage.getItem("env"));

    const defineHello = () => {
        let date = new Date();
        let hour = date.getHours();

        if (hour < 18)
            return "Bonjour"
        else
            return "Bonsoir"
    }
    const buildUserName = () => {
        if (localStorage.getItem("user") === null) return "-";

        const user = new Account(JSON.parse(localStorage.getItem("user")))
        return user.fullname
    }

    return (
        <div className={"UserPreview " + env.type} onClick={setAccountForm}>
            <p className={"hello"}>{defineHello()},</p>
            <p className={"name"}>{buildUserName()}</p>
        </div>
    );
}

export default UserPreview;
