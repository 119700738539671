import Entity from "../../../class/abstract/Entity";
import Pricelist from "../Pricelists/Pricelist";

class Price extends Entity {
    value = null
    stringValue = ""
    store_id = null
    catalog_id = null
    parentItem_id = null
    price_list_id = null
    priceable_id = null
    priceable_type = ""
    variation_id = null
    product_id = null
    discount_id = null
    priceList = {}

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if (key === "priceList")
                    Reflect.set(this, key, new Pricelist(datas[key]))
                else
                    Reflect.set(this, key, datas[key])
            }
        }

        Reflect.set(this, 'stringValue', new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.value).replace(' €', ''))
    }
}

export default Price
