import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import NewObject from "../../../overbox/sheet/paymentmethod/NewObject"
import ObjectSheet from "../../../sheet/ObjectSheet"
import Listing from "../../../table/Listing"
import usePrevious from "../../../../class/tool/usePrevious"
import FormBuilder from "../../../../class/tool/FormBuilder"
import PaymentMethodController from "../../../../stories/_setting/PaymentMethods/PaymentMethodController"
import ListingContext from "../../../../context/ListingContext"
import '../../../../css/page/content/paymentmethod/PaymentMethod.css'

const PaymentMethods = props => {
    const { page } = props
    const item = "paymentmethods"
    const itemClass = "paymentmethod"
    const titleWindow = "Modes de règlement"
    const placeholderSearch = "un mode de règlement"
    const titleNbItems = "modes de règlement"
    const emptyList = "Aucun mode de règlement"
    const textRemoveButton = "ce mode de règlement"
    const activeHistory = false
    const env = JSON.parse(localStorage.getItem("env"))
    const queryParams = new URLSearchParams(window.location.search)
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1)
    const [ loading, setLoading ] = useState(true)
    const [ model, setModel ] = useState([])
    const [ list, setList ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(25)
    const [ sortingName, setSortingName ] = useState("updated_at")
    const [ sortingValue, setSortingValue ] = useState("desc")
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")
    const [ filterRows, setFilterRows ] = useState([])
    const [ filterValues, setFilterValues ] = useState({})
    const prevPerPage = usePrevious(perPage)
    const prevInput = usePrevious(input)

    const existGroupEnv = () => {
        if (env.type === "company")
            return false;
        else {
            const company = JSON.parse(localStorage.getItem("company"));
            return (Object.keys(company).length > 0 && company.socialReason !== null && company.socialReason.length > 0);
        }
    }
    const initFilters = () => {
        let filtersTmp = []

        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "text marginTop",
            placeholder: "Rechercher " + placeholderSearch,
        })

        setFilterValues(prev => ({
            ...prev,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const initModel = () => {
        let modelTmp = [
            {
                "class": "name",
                "sortingParam": "name",
                "title": "Nom",
                "attributes": ["name"],
                "type": "text"
            },
            {
                "class": "reference",
                "sortingParam": "",
                "title": "Référence",
                "attributes": ["reference"],
                "type": "text"
            }
        ];

        if (env.type === "store") {
            modelTmp.splice(2, 0, {
                "class": "origin",
                "sortingParam": "",
                "title": "Origine",
                "attributes": [],
                "type": "catalogOrigin"
            })
        }

        setModel(modelTmp);
    }
    const initOptions = () => {
        let optionsTmp = [];

        if (env.type === "company" || !existGroupEnv()) {
            optionsTmp.push({
                "class": "add",
                "title": "Créer " + placeholderSearch,
                "action": "",
                "link": "/" + item + "/new"
            })
        }

        setOptions(optionsTmp);
    }
    const getPaymentMethods = (pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller = new PaymentMethodController();
        let paramInput = pTextInput !== "" ? pTextInput : input;
        let paramPage = pPage !== 1 ? pPage : pageSelect;
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage;
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName;
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue;

        setLoading(true);

        controller._callback = handleGetPaymentMethods;
        controller.index(paramInput, paramPage, paramPerPage, false, paramSortingName, paramSortingValue);
    }
    const handleGetPaymentMethods = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setList(list)
                setPagination(pagination !== undefined ? pagination : null )
                break
            default:
                console.log(error)
                break
        }

        setLoading(false)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "input":
                setInput(obj.value)
                break
            default: break
        }
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || input !== prevInput) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getPaymentMethods(input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        let keys = Object.keys(listTmp[index]);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh()
    }

    const secondaryOptions = [];

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        initModel();
        initFilters();
    }, [])
    useEffect(() => {
        initOptions();
    }, [list]);
    useEffect(() => {
        handleRefresh(false)
    }, [perPage, pageSelect, sortingName, sortingValue])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ !existGroupEnv() }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={"/" + item + "/new"}>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={"/" + item + "/:id/(information)"}>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={ handleUpdate }
                        handleRemove={ handleRemove }
                    />
                </Route>
            </Switch>
        </ListingContext.Provider>
    );
}

export default PaymentMethods;
