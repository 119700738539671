import Entity from "../../../class/abstract/Entity";

class Dashboard extends Entity {
    bestCategories = "";
    bestProducts = "";
    bestSubcategories = "";
    ca = "";
    categories = "";
    payments = "";
    pricelists = "";
    products = "";
    sales = "";
    salesStats = "";
    subcategories = "";
    vats = "";

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }
    }
}

export default Dashboard;
