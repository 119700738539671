import Entity from "../../../class/abstract/Entity";

class License extends Entity {
    number = "";
    name = "";
    auth_id = 0;
    store_id = 0;
    type = "";

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }
    }
}

export default License;
