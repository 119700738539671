import React, {useEffect, useState} from "react"
import moment from "moment"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'
moment.locale("fr")

const FormDates = props => {
    const { value, handleSelect, handleClose } = props
    const [ values, setValues ] = useState({})
    const errors = []
    const rows = [
        {
            label: "Date de déploiement",
            attribute: "to",
            minDate: new Date(),
            inputType: "datetime",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Sélectionner une date",
            emptyText: "Aucune",
            focus: true
        }
    ]

    const initValues = () => {
        setValues(prev => ({
            ...prev,
            to: value
        }))
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const selectEvent = () => {
        handleSelect(FormBuilder.buildVal("datetime", values.to))
    }

    useEffect(() => {
        initValues()
    }, [])

    return (
        <div className="clearing">
            <div className="form">
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                FormBuilder.buildInputByType(row, values, errors, handleChange)
                            }
                        </div>
                    ))
                }
                <button className="submit" onClick={ selectEvent }>Valider</button>
                <button className="cancel align" onClick={ handleClose }>Fermer</button>
                <div className="clearing" />
            </div>
        </div>
    )
}

export default FormDates
