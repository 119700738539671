import Entity from "../../../class/abstract/Entity";

class SellerSetting extends Entity {
    password = null;
    screen_id = null;
    screen_type = null;
    seller_id = null;
    url = "";
    urlSync = "";

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default SellerSetting;
