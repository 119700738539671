import Entity from "../../../class/abstract/Entity"
import Pricelist from "../Pricelists/Pricelist"

class Vat extends Entity {
    name = ""
    store_id = null
    catalog_id = null
    vat_rate_id = null
    vat_rule_id = null
    price_list_id = null
    applyable_id = null
    applyable_type = ""
    priceList = {}

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if (key === "priceList")
                    Reflect.set(this, key, new Pricelist(datas[key]))
                else
                    Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default Vat
