import Entity from "./Entity";

class Shareable extends Entity {
    sharedUuid = null

    constructor(datas) {
        super(datas);

        if(this.constructor === Shareable)
            throw new TypeError('Abstract class "Shareable" cannot be instantiated directly');

        this.sharedUuid = datas.sharedUuid
    }
}

export default Shareable;
