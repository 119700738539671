import React, { useState } from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'

const StoreSettingFormSheet = props => {
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom de la boutique",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            readOnly: true,
            edit: false
        },
        {
            label: "Raison sociale",
            attribute: "socialReason",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "TVA Intracommunautaire",
            attribute: "intraTva",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Code APE",
            attribute: "apeCode",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Adresse",
            attribute: "address",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Complément d'adresse",
            attribute: "additional",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Code postal",
            attribute: "postalCode",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Ville",
            attribute: "city",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Ville",
            emptyText: "Aucun"
        },
        {
            label: "Pays",
            attribute: "country",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    "value": "France",
                    "id": 1
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        }/*,
        {
            label: "Secteurs d'activités",
            attribute: "sectors",
            inputType: "tag.ordered",
            returnType: "array",
            list: [
                { "value": "BAR" },
                { "value": "BOULANGERIE" },
                { "value": "CHOCOLATERIE" },
                { "value": "COIFFEUR" },
                { "value": "COMMERCE DE DÉTAIL" },
                { "value": "ÉPICERIE" },
                { "value": "HÔTELLERIE" },
                { "value": "INSTITUT DE BEAUTÉ" },
                { "value": "RESTAURANT" },
                { "value": "SANDWICHERIE" },
                { "value": "SERVICES" },
                { "value": "TRAITEUR" }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Sélectionner au moins 1 secteur",
            emptyText: "Aucun"
        }*/
    ])

    const buildRow = row => {
        if (edit === row.attribute || ["color", "switch"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            row.noLabel === undefined
                            && <label className={ row.classnameLabel }>{ row.label }</label>
                        }
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default StoreSettingFormSheet
