import React from "react";

function EmptyIcon (props) {
    const { fill, classname } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 510 510" fill={ fill } className={ (classname !== undefined ? classname : "") }>
            <g fill="#dadada">
                <path
                    d="M192.111 339.102c40.861 30.689 99.498 27.781 137.135-9.855 37.611-37.611 40.563-96.248 9.855-137.135zM255 150c-58.031 0-105 46.962-105 105 0 22.982 7.338 44.834 20.898 62.889l146.99-146.99C299.834 157.338 277.982 150 255 150z"
                    data-original="#000000"
                />
                <path
                    d="M255 0C114.069 0 0 114.05 0 255c0 140.931 114.05 255 255 255 140.932 0 255-114.049 255-255C510 114.069 395.951 0 255 0zm95.459 350.459c-49.226 49.227-126.815 52.457-179.726 10.02l-32.406 32.406-21.213-21.213 32.406-32.406C130.395 315.42 120 286.011 120 255c0-74.611 60.38-135 135-135 31.012 0 60.42 10.395 84.267 29.52l32.406-32.406 21.213 21.213-32.406 32.406c42.362 52.818 39.303 130.404-10.021 179.726z"
                    data-original="#000000"
                />
            </g>
        </svg>
    );
}

export default EmptyIcon;
