import Entity from "../../../class/abstract/Entity"

class PredefinedPaymentMethod extends Entity {
    name = ""
    openCashRegister = 0
    assetManagement = 0
    order = 0
    hidden = 0

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default PredefinedPaymentMethod
