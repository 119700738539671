import React from "react"

export default React.createContext({
    screen: null,
    setScreen: () => {},
    categories: [],
    products: [],
    pricelists: [],
    sellers: [],
    paymentMethods: [],
    functions: [],
    keyboards: [],
    setKeyboards: () => {},
    listKeys: [],
    setListKeys: () => {},
    keyPress: null,
    setKeyPress: () => {},
    categoryKeySelected: null,
    setCategoryKeySelected: () => {},
    takeItem: () => {},
    releaseItemClearTake: () => {},
    removeKeyContext: () => {},
    keyboardToPost: [],
    setKeyboardToPost: () => {},
    keyboardToPut: [],
    setKeyboardToPut: () => {},
    keyToPost: [],
    setKeyToPost: () => {},
    keyToPut: [],
    setKeyToPut: () => {},
    keyToRanks: [],
    setKeyToRanks: () => {},
    keyToRemove: [],
    setKeyToRemove: () => {},
    scale: 100,
    setScale: () => {},
    setFontSizeDeploy: () => {},
    setPriceShowingDeploy: () => {},
    fontSizeRatio: 1,
    setFontSizeRatio: () => {},
    setObjectSheetOpened: () => {}
})
