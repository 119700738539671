import React from "react";
import Printer from "../../../../stories/_setting/Printers/Printer";
import FormStart from "../../../form/printer/FormStart";
import '../../../../css/overbox/PrinterInformation.css';

const NewObject = props => {
    const { handleIndex } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Printer() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject;
