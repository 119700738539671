import React from "react"
import '../../../css/overbox/AlertBox.css'

const AlertBox = props => {
    const { title, text, handleClose } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox alertBox">
                    <div className="form noPadding">
                        <p className="title red">{ title }</p>
                        <p className="text">{ text }</p>
                        <button className="cancel noMarginRight noMarginTop" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertBox
