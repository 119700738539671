import React from 'react'
import CacheBuster from 'react-cache-buster'
import packageJson from '../package.json'
import Main from "./Main"
import './css/App.css'

function App() {
    return (
        <CacheBuster
            currentVersion={ packageJson.version }
            isEnabled={ ['docker', 'dev', 'staging', 'production'].includes(process.env.REACT_APP_ENV) } // If false, the library is disabled.
            isVerboseMode={ ['local', 'docker', 'dev', 'staging'].includes(process.env.REACT_APP_ENV) } // If true, the library writes verbose logs to console.
            loadingComponent={ <div /> } // If not pass, nothing appears at the time of new version check.
            metaFileDirectory={ '.' } // If public assets are hosted somewhere other than root on your server.
        >
            <Main />
        </CacheBuster>
    )
}

export default App