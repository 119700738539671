import React from "react";

import '../../../../css/page/content/screen/Basket.css';

const Basket = props => {
    const { classname } = props;


    // Render

    return(
        <div className={ "basket " + classname }>
            <div className="button" />
            <div className="button" />
            <div className="clearing" />
            <p className="subtotal">0,00</p>
            <table className="pad">
                <tbody>
                    <tr>
                        <td>7</td>
                        <td>8</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>,</td>
                        <td>0</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Basket;
