import React, {useContext} from "react"
import UpIcon from "../../../icons/UpIcon"
import DownIcon from "../../../icons/DownIcon"
import ScreenContext from "../../../../context/ScreenContext";

const InputGrid = props => {
    const {attribute, returnType, classname, value, minLines, minColumns, maxLines, maxColumns, idKeyboard, allAllowed, setError, handleChange} = props
    const {keyboards} = useContext(ScreenContext)
    const limitMaxLines = maxLines !== undefined ? maxLines : null
    const limitMaxColumns = maxColumns !== undefined ? maxColumns : null
    const limitMinLines = minLines !== undefined ? minLines : null
    const limitMinColumns = minColumns !== undefined ? minColumns : null

    const add = type => {
        setError(null)

        let toChange = false

        switch (type) {
            case "line":
                if (limitMaxLines !== null) {
                    if ((value.nbLines + 1) <= limitMaxLines) {
                        value.nbLines++
                        toChange = true
                    }
                }
                else {
                    value.nbLines++
                    toChange = true
                }

                break
            case "column":
                if (limitMaxColumns !== null) {
                    if ((value.nbColumns + 1) <= limitMaxColumns) {
                        value.nbColumns++
                        toChange = true
                    }
                }
                else {
                    value.nbColumns++
                    toChange = true
                }

                break
            default: break
        }

        if (toChange)
            handleChange(attribute, returnType, value)
    }
    const remove = type => {
        let toChange = false
        let allowedRemove = 0

        switch (type) {
            case "line":
                if (limitMinLines !== null) {
                    if ((value.nbLines - 1) > 0 && (value.nbLines - 1) >= limitMinLines) {
                        if (allowed("line") || allAllowed)
                            allowedRemove = 1
                        else
                            allowedRemove = -1
                    }
                }
                else {
                    if ((value.nbLines - 1) > 0) {
                        if (allowed("line") || allAllowed)
                            allowedRemove = 1
                        else
                            allowedRemove = -1
                    }
                }

                if (allowedRemove > 0) {
                    setError(null)
                    value.nbLines--
                    toChange = true
                }
                else if (allowedRemove < 0) {
                    setError("Une ou plusieurs touches sont présentes sur la ligne")
                }

                break
            case "column":
                if (limitMinColumns !== null) {
                    if ((value.nbColumns - 1) > 0 && (value.nbColumns - 1) >= limitMinColumns) {
                        if (allowed("column") || allAllowed)
                            allowedRemove = 1
                        else
                            allowedRemove = -1
                    }
                }
                else {
                    if ((value.nbColumns - 1) > 0) {
                        if (allowed("column") || allAllowed)
                            allowedRemove = 1
                        else
                            allowedRemove = -1
                    }
                }

                if (allowedRemove > 0) {
                    setError(null)
                    value.nbColumns--
                    toChange = true
                }
                else if (allowedRemove < 0) {
                    setError("Une ou plusieurs touches sont présentes sur la colonne")
                }

                break
            default: break
        }

        if (toChange)
            handleChange(attribute, returnType, value)
    }
    const allowed = type => {
        let keyboard = keyboards.find(_ => _.id === idKeyboard)
        let max = keyboard.nbLines * keyboard.nbColumns
        let rankReserved = [max]

        switch (type) {
            case "line":
                for (let i = (max - (keyboard.nbColumns - 1)); i < max; i++) {
                    rankReserved.push(i)
                }

                break
            case "column":
                for (let i = (keyboard.nbLines - 1); i > 0; i--) {
                    rankReserved.push(max - (keyboard.nbColumns * (keyboard.nbLines - i)))
                }

                break
            default: break
        }

        return keyboard.keys.find(_ => rankReserved.includes(_.rank)) === undefined
    }

    return (
        <div className={"inputGrid" + (classname !== undefined ? " " + classname : "")}>
            <p className={"labelGrid left"}>Lignes</p>
            <div className={"containerQuantifier"}>
                <UpIcon classname={value.nbLines === limitMaxLines ? "disable" : ""} action={() => { add("line") }}/>
                <p className={"value" + (limitMaxLines === limitMinLines ? " disable" : "")}>{value.nbLines}</p>
                <DownIcon classname={value.nbLines === limitMinLines ? "disable" : ""} action={() => { remove("line") }}/>
            </div>
            <div className={"containerQuantifier"}>
                <UpIcon classname={value.nbColumns === limitMaxColumns ? "disable" : ""} action={() => { add("column") }}/>
                <p className={"value" + (limitMaxColumns === limitMinColumns ? " disable" : "")}>{value.nbColumns}</p>
                <DownIcon classname={value.nbColumns === limitMinColumns ? "disable" : ""} action={() => { remove("column") }}/>
            </div>
            <p className={"labelGrid"}>Colonnes</p>
        </div>
    )
}

export default InputGrid
