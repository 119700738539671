import React, { useState } from "react"
import { Link } from "react-router-dom"
import Options from "./Options"
import History from "../history/History"
import FormBuilder from "../../class/tool/FormBuilder"
import '../../css/toolsBar/provider/ToolsBar.css'
import '../../css/toolsBar/brand/ToolsBar.css'
import '../../css/toolsBar/category/ToolsBar.css'
import '../../css/toolsBar/subcategory/ToolsBar.css'
import '../../css/toolsBar/menu/ToolsBar.css'
import '../../css/toolsBar/product/ToolsBar.css'
import '../../css/toolsBar/productionmessage/ToolsBar.css'
import '../../css/toolsBar/filter/ToolsBar.css'
import '../../css/toolsBar/group/ToolsBar.css'
import '../../css/toolsBar/client/ToolsBar.css'
import '../../css/toolsBar/sellergroup/ToolsBar.css'
import '../../css/toolsBar/seller/ToolsBar.css'
import '../../css/toolsBar/paymentmethod/ToolsBar.css'
import '../../css/toolsBar/pricelist/ToolsBar.css'
import '../../css/toolsBar/printer/ToolsBar.css'
import '../../css/toolsBar/display/ToolsBar.css'
import '../../css/toolsBar/barcodereader/ToolsBar.css'

const ToolsBar = props => {
    const { item, title, options, secondaryOptions, filters, filterValues, changeFilters, activeHistory, handleOption } = props
    const [ showOption, setShowOption ] = useState(false)

    const handleShowOption = () => {
        setShowOption(!showOption);
    }
    const handleAction = action => {
        handleShowOption();
        handleOption(action);
    }
    const buildFilter = (row, index) => {
        return FormBuilder.buildInputByType(row, filterValues, [], changeFilters, null, null, null, null, null, null, false, index)
    }

    return(
        <>
            {((title !== undefined && title !== null) || options.length > 0 || filters.length > 0) && <div className="toolsBar">
                {(title !== undefined && title !== null) && <p className="titleToolsBar">{title}</p>}
                {secondaryOptions.length > 0 && <Options item={item} show={showOption} options={secondaryOptions} handleAction={handleAction}/>}
                {secondaryOptions.length > 0 && <div className="optionButton add" onClick={handleShowOption}/>}
                {options.map((option, index) => ((option.action !== undefined && option.action !== "") ? <button key={index} className={"button " + option.class} onClick={option.action}><span>{option.title}</span></button> : <Link key={index} to={option.link}>
                        <button className={"button " + option.class}><span>{option.title}</span></button>
                    </Link>))}
                {activeHistory && <History type={item} classname="history"/>}
                <div className="clearing"/>
                {filters.length > 0 && <div className="form noPadding">
                    {filters.map((row, index) => (buildFilter(row, index)))}
                    <div className="clearing"/>
                </div>}
            </div>}
        </>);
}

export default ToolsBar
