import Rest from "../../../class/tool/Rest"
import Keyboard from "./Keyboard"

class KeyboardController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "posscreens/{idScreen}/keyboards"

    index(idPosScreen, types) {
        let params = ""

        for (let i in types)
            params += (params.length === 0 ? "?" : "&") + types[i]

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen) + params,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(!error) {
            if(response.data !== undefined) {
                const data = response.data

                for(let item in data)
                    listObjects.push(new Keyboard(data[item]))
            }
        }

        this._callback(listObjects, error, status)
    }

    show(idPosScreen, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen) + "/" + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Keyboard(response.data) : null
        this._callback(object, error, status)
    }

    post(idPosScreen, object, context = null) {
        let datas = Object.assign({}, object)
        datas.noSync = true
        delete datas.store_id
        delete datas.screen_id
        delete datas.screen_type
        delete datas.created_at
        delete datas.updated_at
        delete datas.deleted_at
        delete datas.keys
        delete datas.id
        delete datas.uuid

        if (datas.color === null)
            delete datas.color

        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen),
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }

    put(idPosScreen, object, datas, context = null) {
        datas.noSync = true

        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen) + "/" + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }

    delete(idPosScreen, id, context = null) {
        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen) + "/" + id + '?noSync=true',
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }

    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined)
                if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
        }

        return updates
    }
}

export default KeyboardController
