import React from "react";

function WarningBubble (props) {
    const { fill, classname } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490.667 490.667"
            width="1em"
            height="1em"
            fill={ fill }
            className={ classname }
        >
            <path d="M416 0H74.667C33.493 0 0 33.493 0 74.667v234.667C0 350.507 33.493 384 74.667 384h230.251L408.47 487.552a10.66 10.66 0 007.531 3.115c1.365 0 2.773-.256 4.075-.811a10.667 10.667 0 006.592-9.856v-96.768c36.139-5.184 64-36.352 64-73.899V74.667C490.667 33.493 457.173 0 416 0zM245.333 320c-17.643 0-32-14.357-32-32s14.357-32 32-32 32 14.357 32 32-14.357 32-32 32zm42.582-221.696l-9.195 105.429c-1.216 17.259-15.915 30.933-33.387 30.933-17.664 0-32.341-13.675-33.557-31.125l-9.003-105.109c-.64-8.96 2.432-17.749 8.448-24.213C217.28 67.712 225.813 64 234.667 64H256c8.853 0 17.387 3.712 23.445 10.219 6.016 6.485 9.11 15.253 8.47 24.085z" />
        </svg>

    );
}

export default WarningBubble;
