import React from "react";

function DownloadIcon (props) {
    const { fill, classname, onclick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 537.794 537.795" fill={ fill } className={ (classname !== undefined ? classname : "") } onClick={ (onclick !== undefined ? onclick : () => {}) }>
            <path d="M463.091 466.114H74.854c-11.857 0-21.497 9.716-21.497 21.497v28.688c0 11.857 9.716 21.496 21.497 21.496h388.084c11.857 0 21.496-9.716 21.496-21.496v-28.688c.231-11.934-9.485-21.497-21.343-21.497zM253.94 427.635c4.208 4.208 9.716 6.35 15.147 6.35
a21.197 21.197 0 0015.147-6.35l147.033-147.033c8.339-8.338 8.339-21.955 0-30.447l-20.349-20.349c-8.339-8.339-21.956-8.339-30.447 0l-75.582 75.659V21.497C304.889 9.639 295.173 0 283.393 0h-28.688c-11.857 0-21.497 9.562-21.497 21.497v284.044l-75.658-75.659c-8.339-8.338-22.032-8.338-30.447 0l-20.349 20.349c-8.338 8.338-8.338 22.032 0 30.447L253.94 427.635z" />
        </svg>
    );
}

export default DownloadIcon;
