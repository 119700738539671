import React, { useContext } from "react"
import { Link } from "react-router-dom"
import AuthController from "../../../../../stories/_auth/Auth/AuthController"
import storeImg from "../../../../../image/ressource/store.svg"
import SessionContext from "../../../../../context/SessionContext"
import '../../../../../css/page/content/access/StoreItem.css'

const StoreItem = props => {
    const { store } = props
    const { handleSwitch } = useContext(SessionContext)

    const handleSelectStore = () => {
        handleSwitch('store', store.id)
    }
    const displayButtons = () => {
        let hasCompanyRules = false

        if (AuthController.hasRules(["admin", "skytech"], "store", store.id) || AuthController.hasRules(["manager"], "store", store.id))
            hasCompanyRules = true

        return <div className="clearing">
            {
                AuthController.hasRules(["admin", "skytech"], "company")
                && <Link to={ "/access/stores/" + store.id + "/informations" }>
                    <button className={ "button marginTop marginBottom information" /*+ (AuthController.hasRules(["admin", "skytech", "manager"], "company") ? " left" : "")*/ }>Gérer les informations</button>
                </Link>
            }
            {
                /*
                AuthController.hasRules(["admin", "skytech", "manager"], "company")
                && <Link to={ "/access/stores/" + store.id + "/accounts" }>
                    <button className={ "button right marginTop marginBottom account" + (AuthController.hasRules(["admin", "skytech"], "company") ? " right" : "") }>Comptes utilisateurs</button>
                </Link>
                */
            }
            <button className={ "button access" + (hasCompanyRules ? " alone" : "") } onClick={ handleSelectStore }>Accéder à la boutique</button>
        </div>
    }

    return (
        <div className="storeItem">
            <img className="picture" src={ storeImg } alt="store" />
            <p className="title">{ store.name }</p>
            <p className="address">
                { store.address }<br />
                { store.postalCode + " " + store.city }
            </p>
            {
                displayButtons()
            }
        </div>
    )
}

export default StoreItem
