import React from "react";
import NotificationIcon from "../../icons/NotificationIcon";

const NotificationCenter = () => {
    return (
        <div className="iconNotification">
            <NotificationIcon />
        </div>
    );
}

export default NotificationCenter;
