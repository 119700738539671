import React, {useContext, useEffect, useState} from "react"
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet"
import FormBuilder from "../../../class/tool/FormBuilder"
import LoaderCircle from "../../loader/LoaderCircle";
import CategoryController from "../../../stories/_catalog/Categories/CategoryController";
import BackofficeContext from "../../../context/BackofficeContext";
import StringTools from "../../../class/tool/StringTools";

const ProductHeaderSheet = props => {
    const { object, values, moving, handleAction } = props;
    const { setErrorText } = useContext(BackofficeContext);
    const [ actions, setActions ] = useState([]);
    const [ moveWindow, setMoveWindow ] = useState(null);
    const [ categories, setCategories ] = useState(null);
    const [ categoryToMoved, setCategoryToMoved ] = useState(null);
    const [ confirmWindow, setConfirmWindow ] = useState(false);
    const env = JSON.parse(localStorage.getItem("env"));
    const settings = JSON.parse(localStorage.getItem("storeSettings"));
    const company = JSON.parse(localStorage.getItem("company"));
    const rulesToUpdate = (env.type === "company" || (env.type === "store" && company.socialReason !== null && settings.allowedProductCreation) || (env.type === "store" && company.socialReason === null) || object.sharedUuid === null);

    const buildActions = () => {
        let actionsTmp = []

        if (rulesToUpdate) {
            actionsTmp.push({
                title: "Supprimer",
                type: "remove"
            })
        }

        setActions(actionsTmp)
    }
    const showMoveWindow = value => {
        if (value === null)
            setCategoryToMoved(null);

        setMoveWindow(value);
    }
    const confirmMove = () => {
        if (categoryToMoved === null) {
            setErrorText("Vous devez sélectionner une catégorie");
            return;
        }

        setConfirmWindow(true);
    }
    const getCategories = () => {
        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(object.catalog_id, "", 0, 0, true, "name", "asc")
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                list = list.sort((s1, s2) => {
                    if (StringTools.removeAccents(s1.name.toLocaleLowerCase()) > StringTools.removeAccents(s2.name.toLocaleLowerCase())) return 1
                    else if (StringTools.removeAccents(s1.name.toLocaleLowerCase()) < StringTools.removeAccents(s2.name.toLocaleLowerCase())) return -1
                    else return 0
                });
                setCategories(list);
                break;
            default:
                setErrorText("Impossible de récupérer les catégories");
                showMoveWindow(null);
                break;
        }
    }
    const getCategoryBySubCategory = () => {
        for (let i in categories) {
            if (categories[i].subCategories.find(_ => _.id === object.category_id) !== undefined)
                return categories[i];
        }

        return null;
    }
    const checkItemStyle = (type, category) => {
        let style = "";

        if (object.category_type === type && object.category_id === category.id) {
            style = " actu";
        }

        if (categoryToMoved !== null && categoryToMoved.type === type && categoryToMoved.id === category.id) {
            style += " selected";
        }

        return style;
    }
    const buildListCategories = () => {
        if (categories === null) {
            return <LoaderCircle display="loader logWait smaller marginTop" strokeWidth="8" stroke="#9C9C9C" />;
        }

        switch (moveWindow) {
            case "cat":
                return <div className={"listItemInOverbox"}>
                    {
                        categories.map((category, indexItem) => (
                            <div key={indexItem} className={"containerItem"}>
                                <p className={"item" + checkItemStyle("categories", category)} onClick={ () => { selectCategoryToMoved({ type: "categories", id: category.id, name: category.name }) } }>{category.name}</p>
                                <div className={"containerSubItem"}>
                                    {
                                        category.subCategories.map((subCat, indexSubItem) => (
                                            <p key={indexSubItem} className={"subItem" + checkItemStyle("subcategories", subCat)} onClick={ () => { selectCategoryToMoved({ type: "subcategories", id: subCat.id, name: subCat.name }) } }>{subCat.name}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>;
            case "subcat":
                let category = getCategoryBySubCategory();
                if (category === null) return <></>;

                return <div className={"listItemInOverbox"}>
                    <div className={"containerItem"}>
                        <p className={"item" + checkItemStyle("categories", category)} onClick={ () => { selectCategoryToMoved({ type: "categories", id: category.id, name: category.name }) } }>{category.name}</p>
                        <div className={"containerSubItem"}>
                            {
                                category.subCategories.map((subCat, index) => (
                                    <p key={index} className={"subItem" + checkItemStyle("subcategories", subCat)} onClick={ () => { selectCategoryToMoved({ type: "subcategories", id: subCat.id, name: subCat.name }) } }>{ subCat.name }</p>
                                ))
                            }
                        </div>
                    </div>
                </div>;
            default:
                return <></>;
        }
    }
    const selectCategoryToMoved = datas => {
        if (object.category_type === datas.type && object.category_id === datas.id)
            return;

        datas.action = "move";

        setCategoryToMoved(datas);
    }
    const okToMove = () => {
        setConfirmWindow(false);

        if (categoryToMoved === null) {
            setErrorText("Vous devez sélectionner une catégorie");
            return;
        }

        handleAction("move", categoryToMoved);
    }
    const abortMove = () => {
        setConfirmWindow(false);
    }

    useEffect(() => {
        console.log("RULE", rulesToUpdate);
        buildActions()
    }, []);
    useEffect(() => {
        if (moveWindow !== null)
            getCategories();
        else
            setCategories(null);
    }, [moveWindow]);
    useEffect(() => {
        if (!moving && categoryToMoved !== null)
            setCategoryToMoved(null);
    }, [moving]);
    useEffect(() => {
        if (moveWindow !== null && moveWindow.type === "subcategories" && (object.subcategory_name === null || object.subcategory_name.length === 0))
            setMoveWindow(null);
    }, [object]);

    return (<>
        <div className="headerSheet">
            <div className="infos">
                <p className={"titleSheet"}>{values.name}</p>
                <div className={"ariane"}>
                    <p className={"link" + (!rulesToUpdate ? " disable" : "")} onClick={ (rulesToUpdate ? () => { showMoveWindow("cat") } : ()=>{}) }>{object.category_name}</p>
                    {
                        (object.subcategory_name !== null && object.subcategory_name.length > 0)
                        && <>
                            <p className={"text"}>></p>
                            <p className={"link" + (!rulesToUpdate ? " disable" : "")} onClick={ (rulesToUpdate ? () => { showMoveWindow("subcat") } : ()=>{}) }>{object.subcategory_name}</p>
                        </>
                    }
                </div>
            </div>
            <ActionListHeaderSheet actions={actions} handleAction={handleAction} />
        </div>
        {
            moveWindow !== null
            && <div className="overlayer hover">
                <div className="wrapOverbox">
                    <div className="overbox hover choices extend">
                        <div className="form">
                            <p className="titleForm center">Déplacer le produit</p>
                            <p className={"warning"}>
                                <strong>Attention</strong> : Déplacer le produit dans une autre catégorie modifiera automatiquement ses règles de TVA et de prix si celle-ci sont différentes.
                            </p>
                            {
                                buildListCategories()
                            }
                            <button className={"submit " + (moving ? "hide" : "")} onClick={confirmMove}>
                                {moving ? "Déplacement..." : "Déplacer"}
                                <LoaderCircle display="loader submitForm " hide={!moving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                            </button>
                            <button className="cancel align" onClick={() => { showMoveWindow(null) }}>Fermer</button>
                            <div className="clearing"/>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            (confirmWindow && categoryToMoved !== null)
            && <div className="overlayer hover">
                <div className="wrapOverbox">
                    <div className="overbox hover validation">
                        <div className="form noPadding">
                            <p className="titleForm center">Confirmer le déplacement</p>
                            <p className={"text"}>
                                Êtes-vous sûr de vouloir déplacer le produit dans la { (categoryToMoved.type === "categories" ? "catégorie" : "sous-catégorie") } "<strong>{ categoryToMoved.name }</strong>" ?
                            </p>
                            <button className={"submit " + (moving ? "hide" : "")} onClick={okToMove}>Confirmer</button>
                            <button className="cancel align" onClick={abortMove}>Annuler</button>
                            <div className="clearing"/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>);
}

export default ProductHeaderSheet
