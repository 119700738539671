import Entity from "../../class/abstract/Entity";

class Barcode extends Entity {
    barcode = "";
    conditioning = 1;
    target_type = "";
    target_id = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = [];

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default Barcode;
