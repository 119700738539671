import Entity from "../../../class/abstract/Entity"

class KartVat extends Entity {
    kart_id = null
    store_id = null
    vat_rate_id = null
    vatRateReference = ""
    vatRateName = ""
    vatRateValue = 0
    quantity = 0
    amountInclTax = 0
    amountInclTaxUndiscounted = 0
    amountExclTax = 0
    amountExclTaxUndiscounted = 0
    amountTax = 0
    amountTaxUndiscounted = 0
    offeredAmountInclTax = 0
    offeredAmountExclTax = 0
    offeredAmountTax = 0
    dataWelled = false
    dataWelled_at = ""

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default KartVat
