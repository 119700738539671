import React, {useContext, useEffect, useState} from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import KeyController from "../../../stories/_setting/Keys/KeyController"
import KeyboardController from "../../../stories/_setting/Keyboards/KeyboardController"
import Keys from "../../../class/tool/Keys"
import ScreenContext from "../../../context/ScreenContext"
import "../../../css/form/Form.css"
import LoaderCircle from "../../loader/LoaderCircle";

const FormKeyObject = () => {
    const { keyboards, setKeyboards, listKeys, setListKeys, keyPress, inEditKey, setInEditKey, removeKeyContext, keyboardToPut, setKeyboardToPut, keyToPut, setKeyToPut, keyToRanks, setKeyToRanks, keyToRemove, setKeyToRemove, setFontSizeDeploy, setPriceShowingDeploy } = useContext(ScreenContext)
    const [ values, setValues ] = useState(null)
    const [ rows, setRows ] = useState([])
    const [ globalError, setGlobalError ] = useState(null)
    const [ saveKeyPress, setSaveKeyPress ] = useState({})
    const [ updatedRanks, setUpdatedRanks ] = useState(null)
    const [ removePopup, setRemovePopup ] = useState(false)
    const fontSizes = [
        {
            value: "8",
            id: 8
        },
        {
            value: "9",
            id: 9
        },
        {
            value: "10",
            id: 10
        },
        {
            value: "11",
            id: 11
        },
        {
            value: "12",
            id: 12
        },
        {
            value: "13",
            id: 13
        },
        {
            value: "14",
            id: 14
        },
        {
            value: "15",
            id: 15
        },
        {
            value: "16",
            id: 16
        },
        {
            value: "17",
            id: 17
        },
        {
            value: "18",
            id: 18
        },
        {
            value: "19",
            id: 19
        },
        {
            value: "20",
            id: 20
        }
    ]
    const yesOrNo = [
        {
            value: "Oui",
            id: 1
        },
        {
            value: "Non",
            id: 0
        }
    ]
    const sortingProducts = [
        {
            value: "a-z",
            id: 1
        },
        {
            value: "z-a",
            id: 2
        }
    ]
    const dictSortingProducts = {
        "a-z": "Alphabétique : A->Z",
        "z-a": "Alphabétique inversé : Z->A"
    }

    const initSaveKey = () => {
        setRows([])
        setValues(null)

        if (keyPress.id !== saveKeyPress.id) {
            setInEditKey(false)
        }

        setSaveKeyPress({
            keyboard: Object.assign({}, keyboards.find(_ => _.id === keyPress.target_id)),
            key: Object.assign({}, keyPress)
        })
    }
    const initValues = () => {
        if (Object.keys(saveKeyPress).length === 0) return

        setValues({
            label: saveKeyPress.key.label,
            color: saveKeyPress.key.color,
            image: saveKeyPress.key.image,
            dimensions: { nbLines: saveKeyPress.keyboard.nbLines, nbColumns: saveKeyPress.keyboard.nbColumns },
            fontSize: saveKeyPress.keyboard.fontSize,
            priceShowing: saveKeyPress.keyboard.priceShowing,
            sorting: saveKeyPress.keyboard.sorting
        })
    }
    const initRows = () => {
        let keyboard = keyboards.find(_ => _.id === keyPress.keyboard_id)
        let keyboardTargeted = keyboards.find(_ => _.id === keyPress.target_id)

        if (keyboardTargeted === undefined && keyPress.target_uuid !== null)
            keyboardTargeted = keyboards.find(_ => _.uuid === keyPress.target_uuid)

        let rowsTmp = [
            {
                label: "Libellé",
                attribute: "label",
                inputType: "text",
                returnType: "string",
                classnameLabel: "label noMarginTop white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Libellé",
                emptyText: "Aucun",
                toUppercase: true,
                edit: true
            }
        ]

        if (!["M", "S"].includes(keyboard.zone)) {
            rowsTmp.push({
                label: "Couleur",
                attribute: "color",
                inputType: "color",
                returnType: "string",
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucune",
                small: true,
                edit: true
            })
        }

        if (["R", "M", "S"].includes(keyboard.zone)) {
            let type = "function"

            if (keyboard.zone === "R")
                type = "catalog"
            else if (saveKeyPress.key.action_code === 305 || saveKeyPress.key.action_code === 306)
                type = "payment"

            rowsTmp.push({
                label: "Icône",
                attribute: "image",
                inputType: "library",
                type: type,
                code: keyPress.action_code,
                returnType: "string",
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Sélectionner une icône"
            })
        }

        if (["R"].includes(keyboard.zone)) {
            rowsTmp.push({
                label: "Grille",
                attribute: "dimensions",
                inputType: "grid",
                returnType: "dimensions",
                minLines: 1,
                minColumns: 1,
                maxLines: 10,
                maxColumns: 10,
                idKeyboard: keyboardTargeted.id,
                allAllowed: (keyPress.action_code === 501),
                setError: setGlobalError,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: ""
            })
            rowsTmp.push({
                label: "Taille du texte",
                attribute: "fontSize",
                inputType: "select",
                returnType: "int",
                list: fontSizes,
                dictionary: null,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            })
            rowsTmp.push({
                label: "Afficher les prix",
                attribute: "priceShowing",
                inputType: "select",
                returnType: "int",
                list: yesOrNo,
                dictionary: null,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            })

            if (keyPress.action_code === 501) {
                rowsTmp.push({
                    label: "Tri des produits",
                    attribute: "sorting",
                    inputType: "select",
                    returnType: "string",
                    list: sortingProducts,
                    dictionary: dictSortingProducts,
                    classnameLabel: "label white",
                    classnameInput: "",
                    classnameNoInput: "",
                    placeholder: "",
                    emptyText: "Aucun"
                })
            }
        }

        setRows(rowsTmp)
    }
    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, [], change)
    }
    const change = (attribute, returnType, val, strict = false) => {
        setInEditKey(true)
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const updateKey = () => {
        let indexKeyboard = keyboards.findIndex(_ => _.id === keyPress.target_id)
        let indexKey = listKeys.findIndex(_ => _.id === keyPress.id)

        if (indexKey < 0) return

        let keyboardsTmp = keyboards.slice()
        let keysTmp = listKeys.slice()

        if (indexKeyboard >= 0) {
            let keyboard = keyboardsTmp[indexKeyboard]
            let reRankNeeded = keyboard.nbColumns > values.dimensions.nbColumns ? -1 : (keyboard.nbColumns < values.dimensions.nbColumns ? 1 : null)

            if (keyPress.action_code === 500) {
                keyboard.color = values.color

                if (reRankNeeded !== null) {
                    let result = Keys.reRank(reRankNeeded, keyboard, keysTmp)

                    keyboard = result.keyboard
                    keysTmp = result.keys

                    if (result.updatedKeys.length > 0) {
                        setUpdatedRanks(result.updatedKeys)
                    }
                }
            }

            keyboard.nbLines = values.dimensions.nbLines
            keyboard.nbColumns = values.dimensions.nbColumns
            keyboard.fontSize = values.fontSize
            keyboard.sorting = values.sorting
            keyboardsTmp[indexKeyboard] = keyboard
        }

        let key = keysTmp[indexKey]
        key.label = values.label
        key.color = values.color
        key.image = values.image
        keysTmp[indexKey] = key

        indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyPress.keyboard_id)

        if (indexKeyboard >= 0) {
            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.id === keyPress.id)

            if (indexKey >= 0) {
                keyboardsTmp[indexKeyboard].keys[indexKey] = key
            }
        }

        setKeyboards(keyboardsTmp)
        setListKeys(keysTmp)
    }
    const reinit = () => {
        initValues()
        setInEditKey(false)
    }
    const saveKeyDatas = () => {
        const controller = new KeyController()
        let datas = controller.returnUpdatesFromCompare(saveKeyPress.key, values)

        if (Object.keys(datas).length > 0) {
            let tmp = keyToPut.slice()
            tmp.push({
                object: saveKeyPress.key,
                datas: datas
            })
            setKeyToPut(tmp)
            setInEditKey(false)
        }

        initSaveKey()
        saveKeyboardDatas()
        saveRanks()
    }
    const saveKeyboardDatas = () => {
        if (keyboards.find(_ => _.id === keyPress.target_id) === undefined) return

        const controller = new KeyboardController()
        let datas = controller.returnUpdatesFromCompare(saveKeyPress.keyboard, {
            nbLines: values.dimensions.nbLines,
            nbColumns: values.dimensions.nbColumns,
            fontSize: values.fontSize,
            priceShowing: values.priceShowing,
            sorting: values.sorting,
            color: values.color
        })

        if (Object.keys(datas).length === 0) return

        let tmp = keyboardToPut.slice()
        tmp.push({
            object: saveKeyPress.keyboard,
            datas: datas
        })
        setKeyboardToPut(tmp)

        initSaveKey()

        if (datas.fontSize !== undefined) {
            setFontSizeDeploy(datas.fontSize)
        }
        else if (datas.priceShowing !== undefined) {
            setPriceShowingDeploy(datas.priceShowing)
        }
    }
    const saveRanks = () => {
        if (updatedRanks === null) return

        let tmp = keyToRanks.slice()
        tmp.push(updatedRanks)
        setKeyToRanks(tmp)
        setUpdatedRanks(null)
    }
    const removeAction = (force = false) => {
        if (!force && keyPress.action_code === 500) {
            stateRemovePopup()
        }
        else {
            if (force)
                stateRemovePopup();

            let tmp = keyToRemove.slice();
            tmp.push({
                object: saveKeyPress.key
            });
            setKeyToRemove(tmp);

            removeKeyContext(saveKeyPress.key);
        }
    }
    const stateRemovePopup = () => {
        setRemovePopup(!removePopup)
    }

    useEffect(() => {
        if (keyPress !== null) {
            initSaveKey()
        }
    }, [keyPress])
    useEffect(() => {
        initValues()
    }, [saveKeyPress])
    useEffect(() => {
        if (values !== null) {
            if (rows.length === 0)
                initRows()
            else
                updateKey()
        }
    }, [values])

    return (
        <>
            <div className={"scroller"}>
                <form className="form">
                    {globalError !== null && <p className={"globalError"}>{globalError}</p>}
                    {rows.map((row, index) => (<div key={index} className="clearing">
                        <label className={row.classnameLabel}>{row.label}</label>
                        {buildRow(row)}
                    </div>))}
                    {inEditKey && <div className="savingBar">
                        <p className="reinit" onClick={reinit}>Réinitialiser</p>
                        <p className="save" onClick={saveKeyDatas}>Enregistrer</p>
                    </div>}
                    <p className={"removeButton"} onClick={removeAction}>Supprimer</p>
                </form>
            </div>
            {
                removePopup
                && <div className="overlayer hover">
                    <div className="wrapOverbox">
                        <div className="overbox hover validation">
                            <div className="form noPadding">
                                <p className="titleForm center">Supprimer définitivement cette touche ?</p>
                                <p className="description">
                                    Attention, c'est un appel de clavier personnalisée sur laquelle vous avez placer des touches à la main.
                                    <br />
                                    <br />
                                    <strong>Cette action est irréversible.</strong>
                                </p>
                                <button className={"submit red"} onClick={() => { removeAction(true) }}>Supprimer</button>
                                <button className="cancel align" onClick={stateRemovePopup}>Fermer</button>
                                <div className="clearing"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default FormKeyObject
