import Entity from "../../../class/abstract/Entity"

class PriceVariation extends Entity {
    store_id = null
    catalog_id = null
    parentItem_id = null
    parent_rule_id = null
    price_list_id = null
    rule_id = null
    variable_id = null
    variable_type = null
    sharedUuid = null
    synced_at = null
    variationOperator = ""
    value = ""
    type = ""

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }
    }
}

export default PriceVariation
