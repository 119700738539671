import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController"
import '../../../css/form/Form.css'

const FormStart = props => {
    const item = "catalogs"
    const controller = new CatalogController()
    const env = JSON.parse(localStorage.getItem("env"))

    const { object, handleIndex } = props
    const [ reload, setReload ] = useState(false)
    const [ loadingStores, setLoadingStores ] = useState(true)
    const [ stores, setStores ] = useState([])
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ])

    const searchCatalogs = () => {
        const company = JSON.parse(localStorage.getItem("company"))
        const controller = new CatalogController()
        controller._callback = returnSearchCatalogs
        controller.indexForCompany(company.id)
    }
    const returnSearchCatalogs = (list, error, status) => {
        switch (status) {
            case 200:
                let storesTmp = []

                for (let i in list) {
                    storesTmp.push({
                        type: "text",
                        value: list[i].store.name,
                        obj: list[i].store
                    })
                }

                setStores(storesTmp)
                setLoadingStores(false)

                break
            default:
                setGlobalError("Une erreur s'est produite lors de la récupération des catalogues")
                break
        }
    }
    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const initRows = () => {
        let rowsTmp = rows.slice()

        if (env.type === "company") {
            rowsTmp.push({
                label: "Boutiques",
                attribute: "stores",
                inputType: "tag",
                returnType: "array",
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                list: stores,
                dictionary: null,
                titleButton: "Modifier",
                loading: loadingStores
            })
        }

        setRows(rowsTmp)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()
        let indexStoreRow = rowsTmp.findIndex(_ => _.attribute === "stores")

        if (indexStoreRow < 0) return

        rowsTmp[indexStoreRow].list = stores
        rowsTmp[indexStoreRow].loading = loadingStores

        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id)

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values)
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const save = () => {
        const datas = returnUpdates()
        delete datas.stores

        if(Object.keys(datas).length === 0) return

        setSaving(true)

        controller._callback = handleReturnSave
        controller.post(datas)
    }
    const handleReturnSave = (response, error, status) => {
        switch (status) {
            case 201:
                if (values.stores.length > 0) {
                    for (let i in values.stores) {
                        saveInStore(getStoreByName(values.stores[i]), response.data)
                    }
                }

                setReload(true)
                handleIndex()
                break
            case 422:
                setSaving(false)
                check422Errors(error.response.data)
                break
            default:
                setSaving(false)
                setGlobalError("Une erreur s'est produite lors de l'enregistrement")
                break
        }
    }
    const saveInStore = (id, datasToSend) => {
        if (id === null) return

        const controllerToPost = new CatalogController()
        const datas = {
            store_id: id,
            source_id: datasToSend.id
        }

        controllerToPost.post(datas)
    }
    const getStoreByName = name => {
        for (let i in stores) {
            if (stores[i].obj.name === name)
                return stores[i].obj.id
        }

        return null
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        callToSave()
    }

    useEffect(() => {
        initRows()
        initValues()

        if (env.type === "company")
            searchCatalogs()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [stores, loadingStores])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            <form className="form" onSubmit={handleSubmit}>
                {
                    globalError !== null
                    && <p className="globalError">{ globalError }</p>
                }
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                            }
                        </div>
                    ))
                }
                <button className={"submit " + (saving ? "hide" : "")}>
                    {
                        saving
                            ? "Enregistrement..."
                            : "Enregistrer"
                    }
                    <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
                <Link to={ "/" + item }>
                    <button className="cancel align">Fermer</button>
                </Link>
                <div className="clearing" />
            </form>
        </div>
    )
}

export default FormStart
