import Entity from "../../../class/abstract/Entity"

class KartContent extends Entity {
    kart_id = null
    license_id = null
    seller_id = null
    price_list_id = null
    provider_id = null
    brand_id = null
    category_id = null
    sub_category_id = null
    menu_id = null
    product_id = null
    declination_id = null
    vat_rate_id = null
    from_content_id = null
    licenseReference = ""
    licenseName = ""
    sellerReference = ""
    sellerName = ""
    pricelistReference = ""
    pricelistName = ""
    providerReference = ""
    providerName = ""
    brandReference = ""
    brandName = ""
    categoryReference = ""
    categoryName = ""
    subcategoryReference = ""
    subcategoryName = ""
    vatRateReference = ""
    vatRateName = ""
    vatRateValue = 0
    menuReference = ""
    menuName = ""
    productReference = ""
    productName = ""
    declinationReference = ""
    declinationName = ""
    roundedQuantity = 0
    quantity = 0
    unit = ""
    isPaused = false
    comment = ""
    discountValue = 0
    discountType = ""
    discountRate = 0
    lineDiscountUnitAmount = 0
    linceDiscountTotalAmount = 0
    saleDiscountUnitAmount = 0
    saleDiscountTotalAmount = 0
    discountUnitAmount = 0
    discountTotalAmount = 0
    unitPriceInclTax = 0
    unitPriceInclTaxUndiscounted = 0
    unitPriceExclTax = 0
    unitPriceExclTaxUndiscounted = 0
    unitAmountTax = 0
    unitAmountTaxUndiscounted = 0
    amountInclTax = 0
    amountInclTaxUndiscounted = 0
    amountExclTax = 0
    amountExclTaxUndiscounted = 0
    amountTax = 0
    amountTaxUndiscounted = 0
    unitPurchasePrice = 0
    purchasePrice = 0
    warrantlyStatement = ""
    warrantlyDuration = ""
    lineNumber = 0
    isReturned = false
    isCorrected = false
    dataWelled = false
    operationType = ""
    source = ""
    dataWelled_at = ""

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default KartContent
