import Rest from "../../../class/tool/Rest"
import Category from "./Category"
import Product from "../Products/Product"
import FormBuilder from "../../../class/tool/FormBuilder";

class CategoryController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/categories"
    _baseURLWithoutCatalog = "categories"

    // Checking

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g

        if(val === "") throw new Error("EMPTY")
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }
    static checkVatRate(val) {
        const regxp = /^[0-9]+$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkColor(val) {
        const regxp = /^\#[a-fA-F0-9]{3,8$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkReference(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }

    // perform

    setFormValues(object, setValues) {
        let defaultCatalogId = object.catalog_id

        if (localStorage.getItem("defaultCatalogIdInForm") !== null) {
            defaultCatalogId = parseInt(localStorage.getItem("defaultCatalogIdInForm"))
        }
        else {
            const catalog = JSON.parse(localStorage.getItem("catalog"))

            if (Object.keys(catalog).length > 0)
                defaultCatalogId = parseInt(catalog.id)
        }

        setValues(prev => ({
            ...prev,
            name: object.name,
            reference: object.reference,
            catalog_id: defaultCatalogId,
            vat_rate_id: object.vat_rate_id !== null ? object.vat_rate_id : (object.vat !== null ? object.vat.id : null),
            color: object.color
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name }
        ];
        let errors = {};
        let method;

        for (let i in params) {
            method = CategoryController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }
    checkMinimumManyValues(values) {
        if (Object.keys(values).length === 0) return false;
        if (values.name === null || values.name.length <= 1) return false;
        if (values.catalog_id === null || values.catalog_id <= 0) return false;
        if (values.vat_rate_id === null || values.vat_rate_id <= 0) return false;

        return true;
    }

    // Controllers

    index(idCatalog = null, input = "", page = 1, nb = 25, tree = false, sortingName = "", sortingValue = "") {
        let catalog = JSON.parse(localStorage.getItem("catalog"))
        let url = ""
        let params = ""
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree="
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue

        if (Object.keys(catalog).length > 0)
            url = '/' + this._baseURL.replace("{idCatalog}", catalog.id) + params
        else if (idCatalog !== undefined && idCatalog !== null)
            url = '/' + this._baseURL.replace("{idCatalog}", idCatalog) + params
        else
            url = '/' + this._baseURLWithoutCatalog + params

        this.#_rest.sendQuery(
            'GET',
            url,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Category(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    indexList(idCatalog, input, withSubCategories = false) {
        let getParams = ""

        if (input !== undefined && input.length > 0)
            getParams += "?input=" + input

        if (withSubCategories)
            getParams += (getParams.length === 0 ? "?" : "") + "withsubcategories="

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + "/list" + getParams,
            {},
            this.handleIndexList)
    }
    handleIndexList = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200 && response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push( new Category(data[item]))
        }

        this._callback(listObjects, error, status)
    }

    show(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Category(response.data) : null
        this._callback(object, error, status)
    }

    post(idCatalog, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog),
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(idCatalog, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    transfer(idCatalog, idCategory, datas, context = null) {
        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + "/" + idCategory + "/products/transfer",
            datas,
            this.handleTransfert
        )
    }
    handleTransfert = (response, error, status, context) => {
        if (this._callback !== null)
            this._callback(response, error, status, context);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", object.catalog_id) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto)
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(var item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget)
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(var item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    // Products

    handleIndexProducts = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Product(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }
    indexProducts(idCatalog, idCategory, input = "", page = 1, nb = 25, sortingName = "", sortingValue = "") {
        let params = ""
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + idCategory + '/products' + params,
            {},
            this.handleIndexProducts)
    }
}

export default CategoryController
