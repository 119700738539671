import Rest from "../../../class/tool/Rest"
import VatRate from "./VatRate"

class VatRateController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "vatrates"

    index() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200 && response.data !== undefined) {
            const data = response.data

            for(let item in data)
                listObjects.push( new VatRate(data[item]))
        }

        this._callback(listObjects, error, status)
    }
}

export default VatRateController
