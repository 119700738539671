import React from "react";
import FormStart from "../../../form/sellergroup/FormStart";
import SellerGroup from "../../../../stories/_setting/SellerGroups/SellerGroup";
import '../../../../css/overbox/ProductInformation.css';

const NewObject = props => {
    const { handleIndex } = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new SellerGroup() } handleIndex={handleIndex} />
                </div>
            </div>
        </div>
    )
}

export default NewObject;
