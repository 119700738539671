import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController"

const ChoiceListCatalog = props => {
    const { handleClose, handleChoice } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ list, setList ] = useState([])
    const company = JSON.parse(localStorage.getItem("company"))

    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.indexForCompany(company.id)
    }
    const handleGetCatalogs = (list, error, status) => {
        switch (status) {
            case 200:
                setList(list)
                setLoading(false)
                break
            default:
                setError("Une erreur s'est produite lors de la récupération des catalogues")
                setLoading(false)
                break
        }
    }
    const buildCatalog = (object, index) => {
        let color = "#AAAAAA"

        if (object["color"] !== undefined && object["color"] !== null)
            color = object["color"]
        else if (object["catalog"] !== undefined && object["catalog"] !== null && object["catalog"].color !== null)
            color = object["catalog"].color

        return <div key={ index } className="catalog" style={ {borderColor: color} } onClick={ () => { handleChoice(object) } }>
            <div className="color" style={ {backgroundColor: color} }></div>
            <p className="text">{ object.name }</p>
        </div>
    }

    useEffect(() => {
        getCatalogs()
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <div className="form">
                        {
                            error !== null
                            && <p className="error">{ error }</p>
                        }
                        <p className="titleForm center">Quel catalogue souhaitez-vous importer ?</p>
                        {
                            loading
                                ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                                : <div className="clearing">
                                    {
                                        list.map((item, index) => (
                                            <div key={ index } className="clearing" style={{margin: "25px 0"}}>
                                                <p className="storeCatalogs">{ item.store.name }</p>
                                                <div className="listCatalogs">
                                                    {
                                                        item.catalogs.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).length > 0
                                                            ? item.catalogs.filter(_ => _.sharedUuid === null || _.sharedUuid.length === 0).map((catalog, id) => buildCatalog(catalog, id))
                                                            : <p className="empty">Aucun catalogue disponible</p>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                        <button className="cancel block" onClick={ handleClose }>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoiceListCatalog
