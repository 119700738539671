import React, {useState} from "react"
import FormBuilder from "../../../class/tool/FormBuilder"

const CatalogFormSheet = props => {
    const company = JSON.parse(localStorage.getItem("company"));
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, updated, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || company.socialReason === null || (object.sharedUuid !== null && storeSettings !== null && company.socialReason !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || company.socialReason === null || (object.sharedUuid !== null && storeSettings !== null && company.socialReason !== null && storeSettings.allowedProductCreation === 1))
        }
    ])

    const buildRow = row => {
        if (edit === row.attribute || ["tag"].includes(row.inputType) || (["color"].includes(row.inputType) && row.edit === undefined))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        <label className={ row.classnameLabel }>{ row.label }</label>
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default CatalogFormSheet
