import React from "react";

const BronzeCupIcon = () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                d="m137.914 274.383c-4.146 0-8.354-.566-12.534-1.753-7.968-2.264-12.589-10.549-10.322-18.506s10.569-12.57 18.532-10.308c8.415 2.39 17.2-2.498 19.593-10.896 2.393-8.399-2.502-17.175-10.912-19.565-29.498-8.274-56.167-28.74-75.046-57.615-18.986-29.038-29.021-64.042-29.021-101.227v-.116c.052-14.62 5.781-28.295 16.132-38.559 10.305-10.218 23.963-15.838 38.476-15.838.067 0 .131.001.198.001 30.114.107 54.527 24.658 54.421 54.728-.029 8.254-6.74 14.926-14.999 14.926-.018 0-.036 0-.054 0-8.284-.029-14.977-6.759-14.947-15.031.048-13.553-10.955-24.618-24.527-24.666-6.591-.044-12.764 2.511-17.429 7.137-4.666 4.626-7.248 10.789-7.271 17.354v.064c0 30.906 8.573 61.04 24.141 84.85 14.906 22.799 35.535 38.839 58.087 45.165 24.37 6.925 38.525 32.305 31.604 56.591-5.729 20.112-24.149 33.262-44.122 33.264z"
                                fill="#e4624c"></path>
                            <path
                                d="m374.086 274.383c-19.977.002-38.392-13.15-44.124-33.265-6.921-24.285 7.235-49.666 31.556-56.577 22.601-6.34 43.23-22.379 58.136-45.178 15.568-23.811 24.141-53.945 24.141-84.85v-.116c-.023-6.512-2.605-12.675-7.271-17.302-4.644-4.605-10.799-7.137-17.34-7.137-.03 0-.059 0-.089 0-13.572.048-24.574 11.113-24.526 24.666.029 8.272-6.663 15.002-14.947 15.031-.018 0-.036 0-.054 0-8.26 0-14.97-6.671-14.999-14.926-.106-30.07 24.307-54.621 54.42-54.728.066 0 .132-.001.198-.001 14.515 0 28.171 5.62 38.476 15.838 10.352 10.264 16.081 23.939 16.132 38.506v.169c0 37.185-10.035 72.189-29.021 101.227-18.878 28.875-45.547 49.341-75.095 57.629-8.361 2.376-13.256 11.153-10.863 19.551s11.182 13.286 19.593 10.897c7.963-2.264 16.265 2.35 18.532 10.308 2.267 7.957-2.354 16.242-10.322 18.506-4.176 1.186-8.39 1.752-12.533 1.752z"
                                fill="#cb3b28"></path>
                        </g>
                        <g>
                            <g>
                                <path d="m331.754 422.663h-151.508c-8.284 0-15-6.706-15-14.979 0-32.803 17.664-62.704 45.754-78.709v-62.635c-6.485-2.755-12.7-6.1-18.566-9.995-32.081-21.303-51.234-56.989-51.234-95.46v-145.906c0-8.272 6.716-14.979 15-14.979h199.602c8.284 0 15 6.706 15 14.979v145.906c0 38.472-19.153 74.158-51.234 95.46-5.867 3.895-12.082 7.24-18.566 9.995v62.635c28.09 16.005 45.754 45.906 45.754 78.709-.002 8.273-6.717 14.979-15.002 14.979z" fill="#ff9377"></path>
                                <path d="m319.566 256.345c32.081-21.302 51.234-56.988 51.234-95.46v-145.906c0-8.272-6.716-14.979-15-14.979h-99.8v422.662h75.754c8.284 0 15-6.706 15-14.979 0-32.803-17.664-62.704-45.754-78.709v-62.634c6.485-2.755 12.7-6.1 18.566-9.995z" fill="#e4624c"></path>
                            </g>
                            <g>
                                <path d="m296 311.372h-80c-8.284 0-15-6.706-15-14.979s6.716-14.979 15-14.979h80c8.284 0 15 6.706 15 14.979s-6.716 14.979-15 14.979z" fill="#e4624c"></path>
                                <path d="m296 281.414h-40v29.957h40c8.284 0 15-6.706 15-14.979s-6.716-14.978-15-14.978z" fill="#cb3b28"></path>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="m395.631 512h-279.262c-8.284 0-15-6.706-15-14.979v-89.337c0-8.272 6.716-14.979 15-14.979h279.263c8.284 0 15 6.706 15 14.979v89.337c-.001 8.273-6.717 14.979-15.001 14.979z" fill="#ba602b"></path>
                            <path d="m395.631 392.706h-139.631v119.294h139.631c8.284 0 15-6.706 15-14.979v-89.337c0-8.272-6.716-14.978-15-14.978z" fill="#964c22"></path>
                        </g>
                        <g>
                            <path d="m415.631 512h-319.262c-8.284 0-15-6.706-15-14.979s6.716-14.979 15-14.979h319.263c8.284 0 15 6.706 15 14.979s-6.717 14.979-15.001 14.979z" fill="#964c22"></path>
                            <path d="m415.631 482.043h-159.631v29.957h159.631c8.284 0 15-6.706 15-14.979s-6.716-14.978-15-14.978z" fill="#6f3716"></path>
                        </g>
                    </g>
                </g>
                <g>
                    <path
                        d="m294.964 110.765c0-21.454-17.479-38.909-38.964-38.909-18.578 0-34.657 13.199-38.232 31.384-1.597 8.118 3.699 15.99 11.829 17.584 8.12 1.595 16.013-3.694 17.608-11.812.82-4.171 4.52-7.199 8.795-7.199 4.942 0 8.964 4.015 8.964 8.951s-4.021 8.951-8.964 8.951c-8.284 0-15 6.706-15 14.979s6.716 14.979 15 14.979c4.942 0 8.964 4.015 8.964 8.951s-4.021 8.951-8.964 8.951c-4.448 0-8.265-3.303-8.876-7.682-.059-.416-.088-.844-.088-1.269 0-8.272-6.716-14.979-15-14.979s-15 6.706-15 14.979c0 1.805.126 3.624.375 5.407 2.669 19.099 19.259 33.501 38.589 33.501 21.484 0 38.964-17.454 38.964-38.908 0-9.019-3.098-17.324-8.275-23.93 5.177-6.605 8.275-14.91 8.275-23.929z"
                        fill="#e4624c"></path>
                    <path d="m294.964 110.765c0-21.454-17.479-38.909-38.964-38.909v29.957c4.942 0 8.964 4.015 8.964 8.951s-4.021 8.951-8.964 8.951v29.957c4.942 0 8.964 4.015 8.964 8.951s-4.021 8.951-8.964 8.951v29.957c21.484 0 38.964-17.454 38.964-38.908 0-9.019-3.098-17.324-8.275-23.93 5.177-6.604 8.275-14.909 8.275-23.928z" fill="#cb3b28"></path>
                </g>
            </g>
        </svg>
    )
}

export default BronzeCupIcon;
