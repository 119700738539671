import React, {useContext, useEffect, useState} from "react"
import $ from "jquery"
import Keyboard from "./Keyboard"
import Basket from "./Basket"
import StatusBar from "./StatusBar"
import ScreenContext from "../../../../context/ScreenContext"
import "../../../../css/page/content/screen/POSScreen.css"

const POSScreen = props => {
    const { keyboardSelect, keySelect, handleAddKey, handleOpenOptions, handleUpdateKeyboards } = props
    const { screen, keyboards, setKeyPress, categoryKeySelected, setCategoryKeySelected, inEditKey, scale, setFontSizeRatio } = useContext(ScreenContext)
    const [ rightKeyboard, setRightKeyboard ] = useState(null)

    const defineRightKeyboard = () => {
        setRightKeyboard(getKeyboard("R"))
    }
    const resize = () => {
        let widthWindow = $(window).width()
        let heightWindow = $(window).height()
        let widthLimit = widthWindow - 100
        let heightLimit = heightWindow - 150
        let initialWidth, width, height, left, top
        let constraints

        switch (screen.format) {
            case "format4/3":
                initialWidth = 1024
                width = 1024
                height = 768

                break
            case "format16/9":
                initialWidth = 1920
                width = 1920
                height = 1080

                break
            default: break
        }

        if (width > widthLimit) {
            constraints = getDimensionConstraints("width", width, height, widthLimit, heightLimit)

            width = constraints.width
            height = constraints.height
        }

        if (height > heightLimit) {
            constraints = getDimensionConstraints("height", width, height, widthLimit, heightLimit)

            width = constraints.width
            height = constraints.height
        }

        setFontSizeRatio(width / initialWidth)

        left = (widthWindow - width) / 2
        top = (heightWindow - height) / 2

        $("#POS").css({width: width + "px"}).css({height: height + "px"}).css({left: left + "px"}).css({top: top + "px"})
    }
    const getDimensionConstraints = (type, width, height, widthLimit, heightLimit) => {
        let newW, newH

        switch (type) {
            case "width":
                newW = widthLimit
                newH = (newW * height) / width
                break
            case "height":
                newH = heightLimit
                newW = (newH * width) / height
                break
            default: break
        }

        return { width: newW, height: newH }
    }
    const getKeyboard = zone => {
        if (zone === "P") {
            if (categoryKeySelected === null) return null

            let keyboard

            if (categoryKeySelected.target_id === null)
                keyboard = keyboards.find(_ => _.uuid === categoryKeySelected.target_uuid)
            else
                keyboard = keyboards.find(_ => _.id === categoryKeySelected.target_id)

            return keyboard !== undefined ? keyboard : null
        }
        else
            return keyboards.find(_ => _.zone === zone) ?? null
    }
    const openAction = key => {
        switch (key.action_code) {
            case 500:
            case 501:
            case 502:
            case 503:
            case 504:
                if (inEditKey) {
                    launchWizzAlert()
                    return
                }

                if (keyboards.find(_ => _.id === key.keyboard_id).zone === "R" && categoryKeySelected !== key) {
                    setCategoryKeySelected(key)
                    setKeyPress(null)
                }
                else
                    setKeyPress(key)

                break
            default:
                if (inEditKey) {
                    launchWizzAlert()
                    return
                }

                setKeyPress(key)

                break
        }
    }
    const launchWizzAlert = () => {
        $("#searchBox .savingBar").addClass("wizz")

        setTimeout(() => {
            $("#searchBox .savingBar").removeClass("wizz")
        }, 1200)
    }

    useEffect(() => {
        window.addEventListener('resize', resize)
        defineRightKeyboard()

        return () => window.removeEventListener('resize', resize)
    }, [])
    useEffect(() => {
        if (rightKeyboard !== null)
            resize()
    }, [screen, rightKeyboard])

    return(
        rightKeyboard !== null
        && <div id={"POS"} className={"POS " + screen.format.replace('/', '')}>
            <Basket classname={ screen.displayPosition } />
            <StatusBar classname={ screen.displayPosition } />
            <div className= { "keyboards " + screen.displayPosition }>
                <Keyboard classname="top" select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "T" } keySelect={ keySelect } keyboard={ getKeyboard("T") } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } />
                {
                    screen.displayPosition === "right"
                        ? <div className="middle">
                            <Keyboard classname={ "right column" + rightKeyboard.nbColumns.toString() } select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "R" } keySelect={ keySelect } keyboard={ rightKeyboard } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } />
                            {
                                getKeyboard("P") !== null
                                    ? <Keyboard classname={ "center column" + rightKeyboard.nbColumns.toString() } select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "P" } keySelect={ keySelect } keyboard={ getKeyboard("P") } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } handleUpdateKeyboards={ handleUpdateKeyboards } />
                                    : <div id={"keyboard-P"} className={"keyboard center column" + rightKeyboard.nbColumns.toString()}><span className="highlightedBg"/></div>
                            }
                        </div>
                        : <div className="middle">
                            {
                                getKeyboard("P") !== null
                                    ? <Keyboard classname={ "center column" + rightKeyboard.nbColumns.toString() } select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "P" } keySelect={ keySelect } keyboard={ getKeyboard("P") } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } handleUpdateKeyboards={ handleUpdateKeyboards } />
                                    : <div id={"keyboard-P"} className={"keyboard center column" + rightKeyboard.nbColumns.toString()}><span className="highlightedBg"/></div>
                            }
                            <Keyboard classname={"right column" + rightKeyboard.nbColumns.toString()} select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "R" } keySelect={ keySelect } keyboard={ rightKeyboard } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } />
                        </div>
                }
                <Keyboard classname="mainBottom" select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "M" } keySelect={ keySelect } keyboard={ getKeyboard("M") } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } />
                <Keyboard classname="secondaryBottom" select={ keyboardSelect !== null && keySelect === null && keyboardSelect.zone === "S" } keySelect={ keySelect } keyboard={ getKeyboard("S") } handleAddKey={ handleAddKey } handleOpenOptions={ handleOpenOptions } handleOpenAction={ openAction } />
            </div>
        </div>
    )
}

export default POSScreen
