import Entity from "../../../class/abstract/Entity"

class KartPayment extends Entity {
    kart_id = null
    store_id = null
    license_id = null
    seller_id = null
    payment_method_id = null
    predefined_payment_method_id = null
    original_payment_id = null
    licenseReference = ""
    licenseName = ""
    sellerReference = ""
    sellerName = ""
    paymentMethodReference = ""
    paymentMethodName = ""
    theoricalAmount = 0
    amountOrigin = 0
    amount = 0
    readOnly = false
    isCanceled = false
    isHidden = false
    source = ""
    dataWelled = false
    canceled_at = ""
    dataWelled_at = ""

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default KartPayment
