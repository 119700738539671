import React, { useState } from "react"
import ActionListHeaderSheet from "../../sheet/ActionListHeaderSheet"
import FormBuilder from "../../../class/tool/FormBuilder"

const StoreHeaderSheet = props => {
    const { values, errors, setGlobalError, handleChange, handleSave, handleAction } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "",
            classnameInput: "noMarginTop noBackground whiteForeground high",
            classnameNoInput: "noMarginTop noBackground whiteForeground",
            placeholder: "nom",
            emptyText: "Aucun",
            edit: false
        }
    ])
    const actions = []

    const buildRow = row => {
        return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }

    return (
        <div className="headerSheet">
            <div className="infos">
                <form className="form noPaddingLR">
                    {
                        rows.map((row, index) => (
                            <div key={ index } className="clearing">
                                {
                                    buildRow(row)
                                }
                            </div>
                        ))
                    }
                </form>
            </div>
            <ActionListHeaderSheet actions={ actions } handleAction={ handleAction } />
        </div>
    )
}

export default StoreHeaderSheet
