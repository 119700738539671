import Entity from "../../../class/abstract/Entity";
import Store from "../../_account/Stores/Store";
import PrinterLicense from "./PrinterLicense";

class Printer extends Entity {
    name = null;
    reference = null;
    target = null;
    interfaceType = null;
    brand = null;
    model = null;
    ipAddress = null;
    ipPort = null;
    bltPort = null;
    usbPort = null;
    serialPort = null;
    serialBaudRate = null;
    serialDataBits = null;
    serialParity = null;
    serialStopBits = null;
    serialFlowControl = null;
    autoPrint = null;
    paperWidth = null;
    nbCharPerLine = null;
    active = null;
    store_id = null;
    store = {};
    rescue_printer_id = null;
    last_histo_author = null;
    rescue_printer = {};
    licenses = []

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";
        let exceptKeys = ["licenses"];

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined && !exceptKeys.includes(key)) {
                if(key === "store")
                    Reflect.set(this, key, new Store(datas[key]));
                else if(key === "rescue_printer")
                    Reflect.set(this, key, new Printer(datas[key]));
                else
                    Reflect.set(this, key, datas[key]);
            }
        }

        if (datas.licenses !== undefined) {
            this.licenses = [];

            for (let item in datas.licenses)
                this.licenses.push(new PrinterLicense(datas.licenses[item]));
        }
    }
}

export default Printer;
