import React, {useEffect} from "react";
import WarningBubble from "../../../icons/WarningBubble";

import '../../../../css/page/content/404/NotFound.css';

const NotFound = () => {

    // PERFORMS

    useEffect(() => {
        document.title = "Back office - Page introuvable";
    })


    // RENDER

    return(
        <div className="main">
            <WarningBubble classname="warning" />
            <p className="titleWarning">NOM D'UNE COCCINELLE, IL SEMBLE QUE VOUS NE SOYEZ PAS AU BON ENDROIT !</p>
        </div>
    );

}

export default NotFound;
