import React, {useState} from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'

const StorePrefixFormSheet = props => {
    const { values, errors, updated, handleChange, handleSave } = props
    const [ rows, setRows ] = useState([{
        noLabel: true,
        attribute: "barcodePrefixes",
        inputType: "barcodePrefixes",
        returnType: "json",
        classnameInput: "marginTop"
    }])

    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit, null, null, null, null, null)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {}
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        handleSave()
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            <label className={"label marginBottom"}>Préfixes des codes barres EAN 13 générés</label>
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default StorePrefixFormSheet
