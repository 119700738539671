import React from "react";
import AnalyzeIcon from "../icons/AnalyzeIcon";
import TrashIcon from "../icons/TrashIcon";
import OutOfStockIcon from "../icons/OutOfStockIcon";

const ActionListHeaderSheet = props => {
    const {
        actions,
        handleAction
    } = props;

    const buildSvg = action => {
        switch (action.type) {
            case "remove": return <TrashIcon fill={ action.color !== undefined ? action.color : "#EB4343" } />
            case "analyze": return <AnalyzeIcon fill={ action.color !== undefined ? action.color : "#ffffff" } />
            case "soldout": return <OutOfStockIcon fill={ action.color !== undefined ? action.color : "#ffffff" } />
            default: return <div />
        }
    }

    return (
        <div className="actions">
            {
                actions.map((action, index) => (
                    <div key={ index } className={ "item " + action.type } onClick={ () => { handleAction(action.type) } }>
                        {
                            buildSvg(action)
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default ActionListHeaderSheet;
