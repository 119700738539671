import React, {useEffect, useState} from "react";
import FormBuilder from "../../../class/tool/FormBuilder";
import SellerController from "../../../stories/_setting/Sellers/SellerController";
import PosScreenController from "../../../stories/_setting/PosScreens/PosScreenController";
import LicenseController from "../../../stories/_account/Licenses/LicenseController";
import LoaderCircle from "../../loader/LoaderCircle";

const SellerFormSheet = props => {
    const { values, errors, sellerGroups, updated, handleChange, handleSave } = props;
    const [ edit, setEdit ] = useState("");
    const [ rows, setRows ] = useState([]);
    const [ loadingMaxPosition, setLoadingMaxPosition ] = useState(true);
    const [ loadingLicenses, setLoadingLicenses ] = useState(true);
    const [ loadingScreens, setLoadingScreens ] = useState(true);
    const [ maxPosition, setMaxPosition ] = useState(null);
    const [ licenses, setLicenses ] = useState(null);
    const [ screens, setScreens ] = useState(null);
    const store = JSON.parse(localStorage.getItem("store"));

    const getMaxPosition = () => {
        const controller = new SellerController();
        controller._callback = returnGetMaxPosition;
        controller.maxPosition();
    }
    const returnGetMaxPosition = (list, error, pagination, status) => {
        setLoadingMaxPosition(false)

        let max = 1;

        switch (status) {
            case 200:
                max = list.length;
                break;
            default:
                console.log("Impossible de récupérer la max position")
                break;
        }

        setMaxPosition(max);
    }
    const getScreens = () => {
        let controller = new PosScreenController()

        controller._callback = handleGetScreens
        controller.index()
    }
    const handleGetScreens = (list, error, status) => {
        setLoadingScreens(false)

        switch (status) {
            case 200:
                setScreens(list)
                break
            default:
                console.log("Impossible de récupérer la liste des écrans")
                break
        }
    }
    const getLicenses = () => {
        const controller = new LicenseController()
        controller._callback = handleGetLicenses
        controller.index(store)
    }
    const handleGetLicenses = (list, error, status) => {
        switch (status) {
            case 200:
                let licensesTmp = []

                for (let item in list) {
                    licensesTmp.push({
                        id: list[item].id,
                        type: "license",
                        object: list[item]
                    })
                }

                setLicenses(licensesTmp)
                break
            default:
                console.log("Impossible de récupérer la liste des licences")
                break
        }

        setLoadingLicenses(false)
    }
    const initRows = () => {
        let positions = [];

        for (let i = 1; i <= maxPosition; i++) {
            positions.push({
                value: i,
                id: i
            });
        }

        let rowsTmp = [
            {
                label: "Genre",
                attribute: "gender",
                inputType: "select",
                returnType: "string",
                list: [
                    {
                        value: "I"
                    },
                    {
                        value: "W"
                    },
                    {
                        value: "M"
                    },
                    {
                        value: "O"
                    }
                ],
                dictionary: {
                    "I": "Indifférent",
                    "W": "Femme",
                    "M": "Homme",
                    "O": "Autre"
                },
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            },
            {
                label: "Nom",
                attribute: "lastname",
                inputType: "text",
                returnType: "string",
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Nom",
                emptyText: "Aucun"
            },
            {
                label: "Prénom",
                attribute: "firstname",
                inputType: "text",
                returnType: "string",
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Prénom",
                emptyText: "Aucun"
            },
            {
                label: "Groupe",
                attribute: "seller_group_id",
                inputType: "select",
                returnType: "int",
                list: sellerGroups,
                dictionary: null,
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            },
            {
                label: "Code",
                attribute: "code",
                inputType: "code",
                maxLength: 4,
                alphanumeric: false,
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                emptyText: "Aucun"
            },
            {
                label: "Réference",
                attribute: "reference",
                inputType: "text",
                returnType: "string",
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "Réference",
                emptyText: "Aucune"
            },
            {
                label: "Sélectionné par défaut sur",
                attribute: "licenses",
                returnType: "array",
                inputType: "tag",
                inputText: false,
                list: licenses,
                classnameLabel: "label",
                classname: "",
                placeholder: ""
            }
        ];

        const company = JSON.parse(localStorage.getItem("company"));

        if (Object.keys(company).length > 0 && company.socialReason !== null && company.socialReason.length > 0) {
            rowsTmp.splice(5, 0, {
                label: "Position sur le clavier",
                attribute: "keyboardPosition",
                inputType: "select",
                list: positions,
                dictionary: null,
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            })
        }
        if (screens.length > 1) {
            let screensList = [
                {
                    value: "Aucun écran",
                    id: null
                }
            ];

            for (let i in screens) {
                screensList.push({
                    value: screens[i].name,
                    id: screens[i].id
                })
            }

            rowsTmp.push({
                label: "Attribuer un écran",
                attribute: "screen_id",
                inputType: "select",
                returnType: "int",
                list: screensList,
                dictionary: null,
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            })
        }

        setRows(rowsTmp);
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color", "tag"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        reinitEdit()
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    useEffect(() => {
        getLicenses();
        getScreens();
        getMaxPosition();
    }, []);
    useEffect(() => {
        if (maxPosition !== null && screens !== null && licenses !== null)
            initRows();
    }, [maxPosition, screens, licenses]);

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                (loadingMaxPosition || loadingLicenses || loadingScreens)
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle display="loader restGETInForm" strokeWidth="5" />
                    </div>
                    : <>
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        (row.hidden === undefined || !row.hidden)
                                        && <div className="clearing">
                                            {
                                                row.noLabel === undefined
                                                && <label className={ row.classnameLabel }>{ row.label }</label>
                                            }
                                            {
                                                buildRow(row)
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </>
            }
        </form>
    )
}

export default SellerFormSheet
