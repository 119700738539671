import React, { useEffect, useState } from "react"
import ChoiceMultiLine from "./ChoiceMultiLine"

const InputMultiLinesChoices = props => {
    const { attribute, returnType, classname, selectType, list, listSelected, smartList, handleChange } = props
    const [ listDisplayed, setListDisplayed ] = useState(list)
    const [ search, setSearch ] = useState("")

    const filterList = () => {
        if (search.length > 0) {
            let listFiltred = []

            for(let index in list)
                if (list[index].main.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()))
                    listFiltred.push(list[index])

            setListDisplayed(listFiltred)
        }
        else
            setListDisplayed(list)
    }
    const handleSearch = event => {
        setSearch(event.currentTarget.value)
    }
    const change = id => {
        let selection = listSelected.slice()
        let indexFound = selection.findIndex(_ => _ === id)

        if (selectType === "singlechoice") {
            if (indexFound >= 0)
                selection.splice(indexFound, 1)
            else
                selection = [id]
        }
        else {
            if (indexFound >= 0)
                selection.splice(indexFound, 1)
            else
                selection.push(id)
        }

        handleChange(attribute, returnType, selection)
    }

    useEffect(() => {
        filterList();
    }, [list, search])

    return (
        <div className={ "inputMultiLinesChoices" + (classname !== undefined ? " " + classname : "") }>
            {
                (smartList !== undefined && smartList)
                && <input type="text" className="extend" value={ search } placeholder="Rechercher..." onChange={ handleSearch } />
            }
            {
                listDisplayed.map((item, index) => (
                    <ChoiceMultiLine key={ index } item={ item } selected={ listSelected.includes(item.id) } handleSelect={ change } />
                ))
            }
        </div>
    );
}

export default InputMultiLinesChoices
