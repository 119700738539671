import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import $ from "jquery";
import {v4 as uuidv4} from 'uuid';
import LoaderCircle from "../../../loader/LoaderCircle";
import ScreenToolsBar from "./ScreenToolsBar";
import POSScreen from "./POSScreen";
import SearchBox from "./SearchBox";
import ActionDefined from "./ActionDefined";
import KeyDecision from "./KeyDecision";
import ObjectSheet from "../../../sheet/ObjectSheet";
import ActionSpecify from "./ActionSpecify";
import PosScreenController from "../../../../stories/_setting/PosScreens/PosScreenController";
import KeyboardController from "../../../../stories/_setting/Keyboards/KeyboardController";
import CategoryController from "../../../../stories/_catalog/Categories/CategoryController";
import ProductController from "../../../../stories/_catalog/Products/ProductController";
import PricelistController from "../../../../stories/_catalog/Pricelists/PricelistController";
import SellerController from "../../../../stories/_setting/Sellers/SellerController";
import PaymentMethodController from "../../../../stories/_setting/PaymentMethods/PaymentMethodController";
import ActionController from "../../../../stories/_setting/Actions/ActionController";
import KeyController from "../../../../stories/_setting/Keys/KeyController";
import Keyboard from "../../../../stories/_setting/Keyboards/Keyboard";
import CustomKey from "../../../../stories/_setting/CustomKeys/CustomKey";
import Key from "../../../../stories/_setting/Keys/Key";
import Keys from "../../../../class/tool/Keys";
import ScreenContext from "../../../../context/ScreenContext";
import '../../../../css/page/content/screen/ScreenCard.css';

const ScreenCard = () => {
    const [ loading, setLoading ] = useState(true)
    const [ globalError, setGlobalError ] = useState(null)
    const [ globalWarning, setGlobalWarning ] = useState(null)
    const [ fontSizeDeploy, setFontSizeDeploy ] = useState(null)
    const [ priceShowingDeploy, setPriceShowingDeploy ] = useState(null)
    const [ scale, setScale ] = useState(100)
    const [ fontSizeRatio, setFontSizeRatio ] = useState(1)
    const [ screen, setScreen ] = useState(null)
    const [ keyboards, setKeyboards ] = useState(null)
    const [ listKeys, setListKeys ] = useState(null)
    const [ syncing, setSyncing ] = useState(false)
    const [ categories, setCategories ] = useState(null)
    const [ products, setProducts ] = useState(null)
    const [ pricelists, setPricelists ] = useState(null)
    const [ sellers, setSellers ] = useState(null)
    const [ paymentMethods, setPaymentMethods ] = useState(null)
    const [ functions, setFunctions ] = useState(null)
    const [ searchBoxVisible, setSearchBoxVisible ] = useState(null)
    const [ keyPress, setKeyPress ] = useState(null)
    const [ categoryKeySelected, setCategoryKeySelected ] = useState(null)
    const [ objectSheetOpened, setObjectSheetOpened ] = useState(null)
    const [ defineActionNeeded, setDefineActionNeeded ] = useState(null)
    const [ specifyActionNeeded, setSpecifyActionNeeded ] = useState(null)
    const [ defineDecisionNeeded, setDefineDecisionNeeded ] = useState(null)
    const [ defineDecisionType, setDefineDecisionType ] = useState(null)
    const [ stateKeyboardSelected, setStateKeyboardSelected ] = useState(null)
    const [ stateKeySelected, setStateKeySelected ] = useState(null)
    const [ availableDeployment, setAvailableDeployment ] = useState(false)
    const [ inEditKey, setInEditKey ] = useState(false)
    const [ keyboardToPost, setKeyboardToPost ] = useState([])
    const [ keyboardToPut, setKeyboardToPut ] = useState([])
    const [ keyToPost, setKeyToPost ] = useState([])
    const [ keyToPut, setKeyToPut ] = useState([])
    const [ keyToRanks, setKeyToRanks ] = useState([])
    const [ keyToRemove, setKeyToRemove ] = useState([])
    const INTERVAL_DEPLOY = 5000
    const urlParams = useParams()
    const env = JSON.parse(localStorage.getItem("env"))
    let keyboardSelected = null
    let keySelected = null
    let itemSelected = null
    let offsets = null
    let timeoutDnD = null
    let timerFontSizeDeploy = null
    let timerPriceShowingDeploy = null

    const resize = () => {
        const screenToolsBar = $("#screenToolsBar")
        const searchBox = $("#searchBox")
        const searchBoxScroller = $("#searchBox > .scroller")

        if (screenToolsBar.length === 0) return

        let offsetToolsBar = screenToolsBar.offset()

        if (offsetToolsBar.left < 380) {
            searchBox.css({top: "85px"})
            searchBox.css({maxHeight: "calc(100vh - 110px)"})
            searchBoxScroller.css({maxHeight: "calc(100vh - 160px)"})
        }
        else {
            searchBox.css({top: "25px"})
            searchBox.css({maxHeight: "calc(100vh - 50px)"})
            searchBoxScroller.css({maxHeight: "calc(100vh - 100px)"})
        }

        defineOffsets()
    }
    const refreshScreen = () => {
        window.location.reload()
    }
    const endTimerFontSizeDeploy = () => {
        setFontSizeDeploy(null)
    }
    const endTimerPriceShowingDeploy = () => {
        setPriceShowingDeploy(null)
    }
    const applyGlobalFontSize = fontSize => {
        let keyboardsTmp = keyboards.slice()
        let keyboardToPutTmp = keyboardToPut.slice()

        for (let i in keyboardsTmp) {
            keyboardsTmp[i].fontSize = fontSize
            keyboardToPutTmp.push({
                object: Object.assign({}, keyboardsTmp[i]),
                datas: { fontSize: keyboardsTmp[i].fontSize }
            })
        }

        setKeyboards(keyboardsTmp)
        setKeyboardToPut(keyboardToPutTmp)

        setFontSizeDeploy(null)
    }
    const applyGlobalPriceShowing = priceShowing => {
        let keyboardsTmp = keyboards.slice()
        let keyboardToPutTmp = keyboardToPut.slice()

        for (let i in keyboardsTmp) {
            if (keyboardsTmp[i].zone !== "P") continue

            keyboardsTmp[i].priceShowing = priceShowing
            keyboardToPutTmp.push({
                object: Object.assign({}, keyboardsTmp[i]),
                datas: { priceShowing: keyboardsTmp[i].priceShowing }
            })
        }

        setKeyboards(keyboardsTmp)
        setKeyboardToPut(keyboardToPutTmp)

        setPriceShowingDeploy(null)
    }
    const actionToolsBar = type => {
        switch (type) {
            case "categories":
            case "products":
            case "pricelists":
            case "sellers":
            case "paymentMethods":
            case "functions":
            case "settings":
                setKeyPress(null)

                if (searchBoxVisible === type)
                    setSearchBoxVisible(null)
                else
                    setSearchBoxVisible(type)

                break
            case "TSettings":
            case "RSettings":
            case "MSettings":
            case "SSettings":
            case "keySettings":
                setSearchBoxVisible(type)
                break
            default:
                setSearchBoxVisible(null)
                break
        }
    }
    const closeToolsBar = () => {
        setKeyPress(null)
        setSearchBoxVisible(null)
    }
    const defineAction = (key, pKeyboardSelected, pKeySelected) => {
        keyboardSelected = pKeyboardSelected
        keySelected = pKeySelected
        setStateKeySelected(pKeySelected)

        setDefineActionNeeded(key)
    }
    const saveDefineAction = (key, code) => {
        closeDefineAction(false)
        pushKeyboardForCategoryAction(key, code)
    }
    const specifyAction = (key, pKeyboardSelected, pKeySelected) => {
        keyboardSelected = pKeyboardSelected
        keySelected = pKeySelected
        setStateKeySelected(pKeySelected)

        setSpecifyActionNeeded(key)
    }
    const saveSpecifyAction = (key, value) => {
        closeSpecifyAction(false)
        pushKeyForAction(key, value)
    }
    const pushKeyboardForCategoryAction = (key, code, delayNeeded = false) => {
        key.action_code = code

        let tmp
        let keyboardableType = null
        let keyboardableId = null
        let nbLines = 6
        let nbColumns = 5
        let keyboardUuid = uuidv4()
        let keyboardKeys = []

        if (key.target_object !== null) {
            let productsFiltered = products.filter(_ => _.category_type === "categories" && _.category_id === key.target_object.id)
            let nbProductsFiltered = productsFiltered.length

            switch (key.action_code) {
                case 500:
                    productsFiltered = productsFiltered.sort((o1, o2) => {
                        const nameA = o1.name.toLocaleUpperCase()
                        const nameB = o2.name.toLocaleUpperCase()
                        const compare = nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: "base"})

                        if (compare > 0)
                            return 1
                        else if (compare < 0)
                            return -1
                        else
                            return 0
                    })

                    for (let i in productsFiltered) {
                        keyboardKeys.push(new Key({
                            uuid: uuidv4(),
                            keyboard_uuid: keyboardUuid,
                            rank: parseInt(i) + 1,
                            action_code: 701,
                            color: key.color,
                            label: productsFiltered[i].label,
                            target_id: productsFiltered[i].id,
                            target_object: productsFiltered[i]
                        }))
                    }

                    break
                case 501:
                    keyboardableType = "categories"
                    keyboardableId = key.target_object.id
                    break
                default: break
            }

            if (nbProductsFiltered > 42) {
                nbColumns = 6
                nbLines = 8
            }
            else if (nbProductsFiltered > 30) {
                nbColumns = 6
                nbLines = 7
            }
        }

        let keyboard = new Keyboard({
            uuid: keyboardUuid,
            zone: "P",
            keyboardable_type: keyboardableType,
            keyboardable_id: keyboardableId,
            sorting: "a-z",
            sortingDirection: "line",
            labelShowing: 1,
            priceShowing: 0,
            stockShowing: 0,
            type: "P",
            nbLines: nbLines,
            nbColumns: nbColumns,
            grid: 0,
            fontSize: 12,
            fontType: "",
            fontPolice: "",
            color: null,
            keys: keyboardKeys
        })
        key.target_uuid = keyboard.uuid

        if (delayNeeded) {
            setTimeout(() => {
                tmp = keyboards.slice()
                tmp.push(keyboard)
                let index = tmp.findIndex(_ => _.id === key.keyboard_id)

                if (index >= 0) {
                    tmp[index].keys.push(key)
                    setKeyboards(tmp)
                }

                tmp = listKeys.slice()
                tmp.push(key)

                for (let i in keyboardKeys)
                    tmp.push(keyboardKeys[i])

                setListKeys(tmp)

                tmp = keyboardToPost.slice()
                tmp.push({
                    object: keyboard,
                    context: {
                        keyToPost: [key],
                        ownerKeysToPost: keyboardKeys
                    }
                })
                setKeyboardToPost(tmp)
            }, 600)
        }
        else {
            tmp = keyboards.slice()
            tmp.push(keyboard)
            setKeyboards(tmp)

            tmp = listKeys.slice()
            let index = tmp.findIndex(_ => _.uuid === key.uuid)

            if (index >= 0) {
                tmp[index].target_uuid = keyboard.uuid
            }

            for (let i in keyboardKeys)
                tmp.push(keyboardKeys[i])

            setListKeys(tmp)

            tmp = keyboardToPost.slice()
            tmp.push({
                object: keyboard,
                context: {
                    keyToPost: [key],
                    ownerKeysToPost: keyboardKeys
                }
            })
            setKeyboardToPost(tmp)
        }
    }
    const pushKeyForAction = (key, value) => {
        switch (key.action_code) {
            case 205:
                key.target_value = JSON.stringify({
                    "type": "decimal",
                    "value": value
                });
                key.label = "Remise " + (value > Math.floor(value) ? Math.round(value * 100) / 100 : Math.round(value)) + " €";
                break;
            case 206:
                key.target_value = JSON.stringify({
                    "type": "decimal",
                    "value": value
                });
                key.label = "Remise " + (value > Math.floor(value) ? Math.round(value * 100) / 100 : Math.round(value)) + " %";
                break;
            case 309:
                key.target_value = JSON.stringify({
                    "type": "integer",
                    "value": value
                });
                key.label = (value > Math.floor(value) ? Math.round(value * 100) / 100 : Math.round(value)) + " €";
                break;
            case 702:
                key.target_value = JSON.stringify({
                    "type": "decimal",
                    "value": value
                });
                key.label = "Ajouter " + (value > Math.floor(value) ? Math.round(value * 100) / 100 : Math.round(value)) + " quantité";
                break;
            default: break;
        }

        let keyboardsTmp = keyboards.slice()
        let listKeysTmp = listKeys.slice()

        let indexKeyboard = keyboardsTmp.findIndex(_ => _.id === key.keyboard_id)
        let indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === key.uuid)

        if (indexKeyboard >= 0 && indexKey >= 0) {
            keyboardsTmp[indexKeyboard].keys[indexKey].label = key.label
            keyboardsTmp[indexKeyboard].keys[indexKey].target_value = key.target_value
        }

        indexKey = listKeysTmp.findIndex(_ => _.uuid === key.uuid)

        if (indexKey >= 0) {
            listKeysTmp[indexKey].label = key.label
            listKeysTmp[indexKey].target_value = key.target_value
        }

        setListKeys(listKeysTmp)

        let tmp = keyToPut.slice()
        tmp.push({
            object: listKeysTmp[indexKey],
            datas: {
                label: key.label,
                target_value: key.target_value
            }
        })
        setKeyToPut(tmp)
    }
    const closeDefineAction = (resetPreview = true) => {
        setDefineActionNeeded(null)

        if (resetPreview)
            resetKeySelected()
    }
    const closeSpecifyAction = (resetPreview = true) => {
        setSpecifyActionNeeded(null)

        if (resetPreview) {
            let keyboardsTmp = keyboards.slice()
            let listKeysTmp = listKeys.slice()
            let keyToRemoveTmp = keyToRemove.slice()
            let indexKeyboard = keyboardsTmp.findIndex(_ => _.id === specifyActionNeeded.keyboard_id)
            if (indexKeyboard < 0) return

            let indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === specifyActionNeeded.uuid)
            if (indexKey < 0) return

            keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

            indexKey = listKeysTmp.findIndex(_ => _.uuid === specifyActionNeeded.uuid)
            if (indexKey < 0) return

            listKeysTmp.splice(indexKey, 1)

            keyToRemoveTmp.push({
                object: specifyActionNeeded,
                context: []
            })
            setKeyToRemove(keyToRemoveTmp)

            resetKeySelected()
        }
    }
    const defineDecision = (keyToErase, typeDecision, pKeyboardSelected, pKeySelected) => {
        keyboardSelected = pKeyboardSelected
        keySelected = pKeySelected
        setStateKeyboardSelected(pKeyboardSelected)
        setStateKeySelected(pKeySelected)

        setDefineDecisionNeeded(keyToErase)
        setDefineDecisionType(typeDecision)
    }
    const saveDefineDecision = (object, code) => {
        closeDefineDecision();

        let detailsId = $(stateKeySelected).attr("id").split("-");
        let keyToErase = new Key(Object.assign({}, keyboards.find(_ => _.id === stateKeyboardSelected.id).keys.find(_ => _.rank === parseInt(detailsId[3]))));

        switch (code) {
            case 1:
                replaceKey(keyToErase, object);
                break;
            case 2:
                reverseKey(keyToErase, object);
                break;
            default: break;
        }
    }
    const replaceKey = (keyToErase, keyToMove) => {
        let savedKeyToErase = Object.assign({}, keyToErase)
        let savedKeyToMove = Object.assign({}, keyToMove)

        keyToMove.rank = keyToErase.rank

        let keyboardsTmp = keyboards.slice()
        let keysTmp = listKeys.slice()
        let keyToRemoveTmp = keyToRemove.slice()
        let indexKeyboard, indexKey
        let context = {}

        indexKeyboard = keyboardsTmp.findIndex(_ => _.id === savedKeyToErase.keyboard_id)
        if (indexKeyboard < 0) return
        indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === savedKeyToErase.uuid)
        if (indexKey < 0) return
        keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

        if (keyToErase.keyboard_id === savedKeyToMove.keyboard_id) {
            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === savedKeyToMove.uuid)
            if (indexKey < 0) return

            keyboardsTmp[indexKeyboard].keys[indexKey] = keyToMove
            context = {
                keyToPut: [
                    {
                        object: keyToMove,
                        datas: {rank: keyToMove.rank}
                    }
                ]
            }

            keyToRemoveTmp.push({
                object: savedKeyToErase,
                context: context
            })
        }
        else {
            console.log("keyToErase", savedKeyToErase)
            console.log("keyToMove", savedKeyToMove)

            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === savedKeyToMove.keyboard_id)
            if (indexKeyboard < 0) return
            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === savedKeyToMove.uuid)
            if (indexKey < 0) return
            keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

            keyToMove.keyboard_id = savedKeyToErase.keyboard_id

            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToMove.keyboard_id)
            if (indexKeyboard < 0) return
            keyboardsTmp[indexKeyboard].keys.push(keyToMove)
            context = {
                keyToPost: [keyToMove]
            }

            keyToRemoveTmp.push({
                object: savedKeyToErase,
                context: context
            })
            keyToRemoveTmp.push({
                object: savedKeyToMove
            })
        }

        indexKey = keysTmp.findIndex(_ => _.uuid === savedKeyToErase.uuid)
        if (indexKey < 0) return
        keysTmp.splice(indexKey, 1)

        keysTmp.push(keyToMove)

        setKeyboards(keyboardsTmp)
        setListKeys(keysTmp)
        setKeyToRemove(keyToRemoveTmp)
    }
    const reverseKey = (keyToErase, keyToMove) => {
        let rankSaved = keyToMove.rank;
        keyToMove.rank = keyToErase.rank;
        keyToErase.rank = rankSaved;

        let keyboardsTmp = keyboards.slice();
        let keysTmp = listKeys.slice();
        let indexKeyboard, indexKey;

        if (keyToErase.keyboard_id === keyToMove.keyboard_id) {
            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToMove.keyboard_id)
            if (indexKeyboard < 0) return

            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === keyToErase.uuid)
            if (indexKey < 0) return
            keyboardsTmp[indexKeyboard].keys[indexKey] = keyToErase

            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === keyToMove.uuid)
            if (indexKey < 0) return
            keyboardsTmp[indexKeyboard].keys[indexKey] = keyToMove
        }
        else {
            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToErase.keyboard_id)
            if (indexKeyboard < 0) return
            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === keyToErase.uuid)
            if (indexKey < 0) return
            keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToMove.keyboard_id)
            if (indexKeyboard < 0) return
            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === keyToMove.uuid)
            if (indexKey < 0) return
            keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

            let keyboardSaved = keyToMove.keyboard_id
            keyToMove.keyboard_id = keyToErase.keyboard_id
            keyToErase.keyboard_id = keyboardSaved

            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToErase.keyboard_id)
            if (indexKeyboard < 0) return
            keyboardsTmp[indexKeyboard].keys.push(keyToErase)

            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === keyToMove.keyboard_id)
            if (indexKeyboard < 0) return
            keyboardsTmp[indexKeyboard].keys.push(keyToMove)
        }

        indexKey = keysTmp.findIndex(_ => _.uuid === keyToErase.uuid)
        if (indexKey < 0) return
        keysTmp[indexKey] = keyToErase

        indexKey = keysTmp.findIndex(_ => _.uuid === keyToMove.uuid)
        if (indexKey < 0) return
        keysTmp[indexKey] = keyToMove

        keysTmp.push(keyToMove)

        setKeyboards(keyboardsTmp)
        setListKeys(keysTmp)
        setKeyToRanks([
            [
                {id: keyToMove.id, keyboardId: keyToMove.keyboard_id, rank: keyToMove.rank},
                {id: keyToErase.id, keyboardId: keyToErase.keyboard_id, rank: keyToErase.rank}
            ]
        ]);
    }
    const closeDefineDecision = () => {
        setDefineDecisionNeeded(null)
        setDefineDecisionType(null)

        $(stateKeySelected).removeClass("highlighted")
    }
    const resetKeySelected = (pKey = null) => {
        let key = pKey === null ? (keySelected !== null ? keySelected : stateKeySelected) : pKey
        if (key === null) return

        $(key).removeClass("actionnable")
        $(key).find(".radiusBg").animate({width: 0, height: 0}, 150)
        $(key).find(".label").text("")
        $(key).find(".wrap > img").remove()
        $(key).find(".wrap > .contentInfos > .label").removeClass("withIcon")
    }
    const removeKeyContext = key => {
        let keyboardsTmp = keyboards.slice()
        let keysTmp = listKeys.slice()
        let keyboardParent = keyboardsTmp.find(_ => _.id === key.keyboard_id)
        let indexKeyboard = keyboardsTmp.findIndex(_ => _.id === key.keyboard_id)
        let indexKey = keysTmp.findIndex(_ => _.id === key.id)
        if (indexKeyboard < 0 || indexKey < 0) return

        // suppresion de la key dans la liste générale des keys
        keysTmp.splice(indexKey, 1)

        // suppresion de la key dans la liste des keys du keyboard parent
        indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.id === key.id)

        if (indexKey >= 0)
            keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)

        // suppression du keyboard et keys si la key appelle un pasteis
        if ([500, 501, 502, 503, 504].includes(key.action_code)) {
            indexKeyboard = keyboardsTmp.findIndex(_ => _.id === key.target_id)

            if (indexKeyboard >= 0) {
                for (let i in keyboardsTmp[indexKeyboard].keys) {
                    indexKey = keysTmp.findIndex(_ => _.id === keyboardsTmp[indexKeyboard].keys[i].id)

                    if (indexKey >= 0)
                        keysTmp.splice(indexKey, 1)
                }

                keyboardsTmp.splice(indexKeyboard, 1)
            }
        }

        setKeyboards(keyboardsTmp)
        setListKeys(keysTmp)

        resetKeySelected("#keyboard-" + keyboardParent.zone + "-key-" + key.rank)

        if (keyPress.uuid === key.uuid) {
            setKeyPress(null)
            setCategoryKeySelected(null)
        }
    }
    const defineOffsets = () => {
        if ($("#keyboard-S").length === 0) return

        if (offsets === null)
            offsets = {}

        let keyboards = [
            { key: "top", letter: "T" },
            { key: "right", letter: "R" },
            { key: "center", letter: "P" },
            { key: "main", letter: "M" },
            { key: "secondary", letter: "S" }
        ]
        let id

        for (let i in keyboards) {
            id = "#keyboard-" + keyboards[i].letter

            offsets[keyboards[i].key] = {
                letter: keyboards[i].letter,
                offset: $(id).offset(),
                size: {
                    width: $(id).width(),
                    height: $(id).height()
                }
            }
        }
    }
    const takeItem = (event, item, custom = false, force = false) => {
        if (event.type !== "mousedown") return

        let sidedSearchBox = true

        if (item !== null) {
            let contructorName = Keys.returnConstructorName(item)

            if (contructorName === "Key") {
                sidedSearchBox = false

                if (item.action_code === 701) { // impossible pour les touches auto
                    let keyboard = keyboards.find(_ => _.id === item.keyboard_id)

                    if (keyboard.keyboardable_id !== null) {
                        return
                    }
                }
                else if (item.action_code === 0) {
                    return
                }

                if (!force) {
                    timeoutDnD = setTimeout(() => { takeItem(event, item, custom, true) }, 300)
                    return
                }
                else {
                    itemSelected = item
                }
            }
            else {
                itemSelected = item
            }
        }
        else if (item === null && custom) {
            itemSelected = new CustomKey({name: "Catégorie"})
        }
        else {
            return
        }

        let id = "#dragItem"

        if (sidedSearchBox)
            $("#searchBox").addClass("side")

        $("#POS").append("<div id='dragItem'><p>" + returnName(itemSelected, true) + "</p></div>")
        $(id).css({left: (event.pageX - 40) + "px", top: (event.pageY - 40) + "px"})
        $(id).on( "mouseup", function(e) {
            releaseItem(e, itemSelected)
        })

        window.addEventListener('mousemove', mouseMove, false)
    }
    const mouseMove = event => {
        let constructorNameOrigin, constructorName
        let idDragItem = "#dragItem"
        let idPHiglighted = "#keyboard-P .highlightedBg"
        if ($(idDragItem).length === 0) return

        let posX = event.pageX
        let posY = event.pageY

        $(idDragItem).css({left: (posX - 40) + "px", top: (posY - 40) + "px"})

        if (offsets === null) {
            defineOffsets()
        }

        let toContinue = false

        for (let keyboard in offsets) {
            if (
                (posX >= offsets[keyboard].offset.left && posX <= (offsets[keyboard].offset.left + offsets[keyboard].size.width))
                &&
                (posY >= offsets[keyboard].offset.top && posY <= (offsets[keyboard].offset.top + offsets[keyboard].size.height))
            ) {
                toContinue = false
                constructorNameOrigin = Keys.returnConstructorName(itemSelected)
                constructorName = constructorNameOrigin

                if (constructorNameOrigin === "Key") {
                    switch (itemSelected.action_code) {
                        case 500:
                            constructorName = "CustomKey"
                            break
                        case 501:
                            constructorName = "Category"
                            break
                        case 504:
                            constructorName = "SubCategory"
                            break
                        case 701:
                            constructorName = "Product"
                            break
                        case 101:
                            constructorName = "Pricelist"
                            break
                        case 102:
                            constructorName = "Seller"
                            break
                        case 306:
                            constructorName = "PaymentMethod"
                            break
                        default:
                            constructorName = "Action"
                            break
                    }
                }

                switch (constructorName) {
                    case "CustomKey":
                        if (!['R'].includes(offsets[keyboard].letter)) {
                            toContinue = true
                        }

                        break
                    case "Category":
                        if (!['R', 'P'].includes(offsets[keyboard].letter)) {
                            toContinue = true
                        }

                        break
                    case "SubCategory":
                    case "Product":
                        if (!['P'].includes(offsets[keyboard].letter) || (['P'].includes(offsets[keyboard].letter) && categoryKeySelected !== null && categoryKeySelected.action_code === 501)) {
                            toContinue = true
                        }

                        break
                    case "Pricelist":
                    case "Seller":
                    case "PaymentMethod":
                    case "Action":
                        if (!['T', 'M', 'S'].includes(offsets[keyboard].letter)) {
                            toContinue = true
                        }

                        break
                    default:
                        return
                }

                if (toContinue) {
                    if (!$(idDragItem).hasClass("forbidden")) {
                        $(idDragItem).addClass("forbidden")
                    }

                    continue
                }
                else {
                    if ($(idDragItem).hasClass("forbidden")) {
                        $(idDragItem).removeClass("forbidden")
                    }
                }

                let keyOvered = false

                if (categoryKeySelected !== null && constructorName === "Category" && offsets[keyboard].letter === "P") {
                    keyboardSelected = keyboards.find(_ => _.id === categoryKeySelected.target_id)
                    let nbProducts = products.filter(_ => _.category_type === "categories" && _.category_id === itemSelected.id)
                    let totalKeys = keyboardSelected.nbLines * keyboardSelected.nbColumns

                    if (nbProducts.length > (totalKeys - keyboardSelected.keys.length)) {
                        if (!$(idDragItem).hasClass("forbidden")) {
                            $(idDragItem).addClass("forbidden")
                        }

                        setGlobalWarning("Il n'y a pas assez de touches disponibles (" + nbProducts.length + " produits)")
                    }
                    else {
                        setGlobalWarning(null)

                        let ranks = []

                        for (let i = 1; i <= totalKeys; i++) {
                            if (keyboardSelected.keys.find(_ => _.rank === i) === undefined) {
                                ranks.push(i)

                                if (ranks.length === nbProducts.length)
                                    break
                            }
                        }

                        $("#keyboard-" + offsets[keyboard].letter + " .key").each(function() {
                            if (ranks.includes(parseInt($(this).find(".rank").text()))) {
                                $(this).addClass("highlighted")
                            }
                            else {
                                $(this).removeClass("highlighted")
                            }
                        })
                    }

                    break
                }
                else {
                    setGlobalWarning(null)

                    if ($(idPHiglighted).hasClass("visible")) {
                        $(idPHiglighted).removeClass("visible")
                    }

                    // reset if category hover
                    if (constructorName === "Category") {
                        $("#keyboard-P .key").each(function() {
                            $(this).removeClass("highlighted")
                        })
                    }

                    $("#keyboard-" + offsets[keyboard].letter + " .key").each(function() {
                        let offset = $(this).offset()
                        let width = $(this).width()
                        let height = $(this).height()
                        let idKey
                        let okToContinue = true

                        if (
                            (posX >= offset.left && posX <= (offset.left + width))
                            &&
                            (posY >= offset.top && posY <= (offset.top + height))
                        ) {
                            if (constructorNameOrigin === "Key") { // impossible de survol sa propre origine
                                idKey = "keyboard-" + (keyboards.find(_ => _.id === itemSelected.keyboard_id).zone) + "-key-" + itemSelected.rank

                                if (idKey === $(this).attr("id")) {
                                    okToContinue = false
                                    $(idDragItem).addClass("forbidden")
                                }
                            }

                            if (okToContinue) {
                                if (offsets[keyboard].letter === "P") {
                                    keyboardSelected = keyboards.find(_ => _.id === categoryKeySelected.target_id)
                                }
                                else {
                                    keyboardSelected = keyboards.find(_ => _.zone === offsets[keyboard].letter)
                                }

                                keySelected = this
                                keyOvered = true

                                $(this).addClass("highlighted")
                            }
                        }
                        else {
                            $(this).removeClass("highlighted")
                        }
                    })

                    if (keyOvered) {
                        break
                    }
                }
            }
            else {
                setGlobalWarning(null)

                keySelected = null
                keyboardSelected = null

                if ($(idPHiglighted).hasClass("visible")) {
                    $(idPHiglighted).removeClass("visible")
                }

                $("#keyboard-" + offsets[keyboard].letter + " .key").removeClass("highlighted")
            }
        }
    }
    const releaseItemClearTake = event => {
        if (event.type !== "mouseup") return
        clearTimeout(timeoutDnD)
    }
    const releaseItem = (event, item) => {
        if (event.type !== "mouseup") return

        setGlobalWarning(null)
        clearTimeout(timeoutDnD)

        if (itemSelected === null || (keyboardSelected === null && keySelected === null)) {
            releaseState()
            return
        }

        let constructorName = Keys.returnConstructorName(itemSelected)

        if (constructorName === "Category" && keyboardSelected !== null && keySelected === null) {
            previewKeyboard(item)
        }
        else {
            // check erase/replace
            let key = keyboards.find(_ => _.id === keyboardSelected.id).keys.find(_ => _.rank === parseInt($(keySelected).find(".rank").text()))

            if (key === undefined) {
                if (constructorName === "Key") {
                    let originItem = getItemWithKey(item)

                    if (originItem === undefined) {
                        return
                    }

                    previewKey(item, keySelected, true, originItem)
                }
                else {
                    previewKey(item, keySelected)
                }
            }
            else {
                let detailsId = $(keySelected).attr("id").split("-");
                let keyToErase = new Key(Object.assign({}, keyboards.find(_ => _.id === keyboardSelected.id).keys.find(_ => _.rank === parseInt(detailsId[3]))));
                defineDecision(item, (constructorName === "Key" && keyToErase.keyboard_id === item.keyboard_id) ? "reverse" : "erase", keyboardSelected, keySelected)
            }
        }

        releaseState()
    }
    const previewKeyboard = item => {
        keyboardSelected = keyboards.find(_ => _.id === categoryKeySelected.target_id)
        let tmpKeyboards = keyboards.slice()
        let tmpKeys = listKeys.slice()
        let tmpKeysToPost = keyToPost.slice()
        let productsList = products.filter(_ => _.category_type === "categories" && _.category_id === itemSelected.id)
        let totalKeys = keyboardSelected.nbLines * keyboardSelected.nbColumns
        let key, constructorName, product
        let keysToAdd = []

        if (productsList.length === 0) return

        if (productsList.length <= (totalKeys - keyboardSelected.keys.length)) {
            setGlobalWarning(null)

            let ranks = []

            for (let i = 1; i <= totalKeys; i++) {
                if (keyboardSelected.keys.find(_ => _.rank === i) === undefined) {
                    ranks.push(i)

                    if (ranks.length === productsList.length)
                        break
                }
            }

            productsList = productsList.sort((o1, o2) => {
                const nameA = o1.name.toLocaleUpperCase()
                const nameB = o2.name.toLocaleUpperCase()
                const compare = nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: "base"})

                if (compare > 0)
                    return 1
                else if (compare < 0)
                    return -1
                else
                    return 0
            })

            for (let index in ranks) {
                product = productsList[parseInt(index)]
                constructorName = Keys.returnConstructorName(product)
                key = Keys.preview(categories, product, $("#keyboard-P-key-" + ranks[index]), keyboardSelected, categoryKeySelected, constructorName, false)
                keysToAdd.push(key)
            }

            setTimeout(() => {
                let index = tmpKeyboards.findIndex(_ => _.id === keysToAdd[0].keyboard_id)

                for (let i in keysToAdd) {
                    if (index >= 0) {
                        tmpKeyboards[index].keys.push(keysToAdd[i])
                    }

                    tmpKeys.push(keysToAdd[i])
                    tmpKeysToPost.push({
                        object: keysToAdd[i]
                    })
                }

                setKeyboards(tmpKeyboards)
                setListKeys(tmpKeys)
                setKeyToPost(tmpKeysToPost)
            }, 600)
        }
    }
    const previewKey = (item, pKey = null, newPlace = false, originItem = null) => {
        let savePrecItem = Object.assign({}, itemSelected)
        let constructorName = Keys.returnConstructorName(itemSelected)
        let key = Keys.preview(categories, item, pKey, keyboardSelected, categoryKeySelected, constructorName, newPlace)

        switch (constructorName) {
            case "Category":
                setTimeout(() => {
                    let tmp = keyboards.slice()
                    let index = tmp.findIndex(_ => _.id === key.keyboard_id)

                    if (index >= 0) {
                        tmp[index].keys.push(key)
                        setKeyboards(tmp)
                    }

                    tmp = listKeys.slice()
                    tmp.push(key)
                    setListKeys(tmp)
                }, 600)

                defineAction(key, keyboardSelected, keySelected)
                break
            case "CustomKey":
                pushKeyboardForCategoryAction(key, 500, true)
                break
            case "Key":
                setTimeout(() => {
                    // Cases
                    // 1: new place in same keyboard (PUT)
                    // 2: new place in other keyboard (DELETE -> POST)

                    let keyboardsTmp = keyboards.slice()
                    let keysTmp = listKeys.slice()
                    let keyToRemoveTmp = keyToRemove.slice()
                    let keyToPutTmp = keyToPut.slice()
                    let indexKeyboard, indexKey

                    if (key.keyboard_id === savePrecItem.keyboard_id) { // case 1
                        // Update key from keys keyboard
                        indexKeyboard = keyboardsTmp.findIndex(_ => _.id === key.keyboard_id)

                        if (indexKeyboard >= 0) {
                            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === key.uuid)

                            if (indexKey >= 0) {
                                keyboardsTmp[indexKeyboard].keys[indexKey] = key
                            }
                        }

                        keyToPutTmp.push({
                            object: key,
                            datas: {
                                rank: key.rank
                            }
                        })
                    }
                    else { // case 2
                        // Remove key from old keyboard
                        indexKeyboard = keyboardsTmp.findIndex(_ => _.id === savePrecItem.keyboard_id)

                        if (indexKeyboard >= 0) {
                            indexKey = keyboardsTmp[indexKeyboard].keys.findIndex(_ => _.uuid === savePrecItem.uuid)

                            if (indexKey >= 0) {
                                keyboardsTmp[indexKeyboard].keys.splice(indexKey, 1)
                            }
                        }

                        // Add key in new keyboard
                        indexKeyboard = keyboardsTmp.findIndex(_ => _.id === key.keyboard_id)

                        if (indexKeyboard >= 0) {
                            keyboardsTmp[indexKeyboard].keys.push(key)
                        }

                        keyToRemoveTmp.push({
                            object: savePrecItem,
                            context: {
                                keyToPost: [key]
                            }
                        })
                    }

                    indexKey = keysTmp.findIndex(_ => _.uuid === key.uuid)

                    if (indexKey >= 0) {
                        keysTmp[indexKey] = key
                    }

                    setKeyboards(keyboardsTmp)
                    setListKeys(keysTmp)
                    setKeyToRemove(keyToRemoveTmp)
                    setKeyToPut(keyToPutTmp)
                }, 600)

                break
            default:
                setTimeout(() => {
                    let tmp = keyboards.slice()
                    let index = tmp.findIndex(_ => _.id === key.keyboard_id)

                    if (index >= 0) {
                        tmp[index].keys.push(key)
                        setKeyboards(tmp)
                    }

                    tmp = listKeys.slice()
                    tmp.push(key)
                    setListKeys(tmp)

                    tmp = keyToPost.slice()
                    tmp.push({
                        object: key
                    })
                    setKeyToPost(tmp)
                }, 600)

                // Définir la précision de l'action dans certains cas
                if (constructorName === "Action" && [205, 206, 309, 502, 503, 702].includes(key.action_code)) {
                    specifyAction(key, keyboardSelected, keySelected)
                }

                break
        }
    }
    const releaseState = () => {
        $(".keyboard .highlightedBg").removeClass("visible")
        $("#searchBox").removeClass("side")
        $("#dragItem").remove()

        itemSelected = null
        keyboardSelected = null
        keySelected = null

        window.removeEventListener('mousemove', mouseMove, false)
    }
    const returnName = (item, dragItem = false) => {
        switch (searchBoxVisible) {
            case "sellers":
                if (dragItem) {
                    return item.fullnameLimited
                }
                else {
                    return item.fullname
                }
            case "functions":
                if (dragItem) {
                    return (item.keyLabel !== undefined && item.keyLabel.length > 0) ? item.keyLabel : item.label
                }
                else {
                    return item.label
                }
            default:
                return item.label !== undefined ? item.label : item.name
        }
    }
    const getItemWithKey = key => {
        switch (key.action_code) {
            case 101: return pricelists.find(_ => _.id === key.target_id)
            case 102: return sellers.find(_ => _.id === key.target_id)
            case 306: return paymentMethods.find(_ => _.id === key.target_id)
            case 500: return new CustomKey({name: key.label})
            case 501:
                let keyboard = keyboards.find(_ => _.id === key.target_id)
                if (keyboard === undefined) return undefined

                return categories.find(_ => _.id === keyboard.keyboardable_id)
            case 701:
                return products.find(_ => _.id === key.target_id)
            default: return functions.find(_ => _.code === key.action_code)
        }
    }
    const showObjectSheedTargeted = () => {
        let constructorName = Keys.returnConstructorName(objectSheetOpened)

        switch (constructorName.toLowerCase()) {
            case "category":
                return <ObjectSheet
                    objectType={ "category" }
                    context={{ idCatalog: objectSheetOpened.catalog_id, id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "cette catégorie" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            case "subcategory":
                return <ObjectSheet
                    objectType={ "subcategory" }
                    context={{ idCatalog: objectSheetOpened.catalog_id, id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "cette sous-catégorie" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            case "product":
                return <ObjectSheet
                    objectType={ "product" }
                    context={{ idCatalog: objectSheetOpened.catalog_id, id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "ce produit" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            case "pricelist":
                return <ObjectSheet
                    objectType={ "pricelist" }
                    context={{ idCatalog: objectSheetOpened.catalog_id, id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "ce tarif" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            case "seller":
                return <ObjectSheet
                    objectType={ "seller" }
                    context={{ id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "ce vendeur" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            case "paymentmethod":
                return <ObjectSheet
                    objectType={ "paymentmethod" }
                    context={{ id: objectSheetOpened.id }}
                    navigationItem={"information"}
                    previousAction={ () => { setObjectSheetOpened(null) } }
                    textRemoveButton={ "ce mode de règlement" }
                    handleUpdate={ () => {} }
                    handleRemove={ () => {} } />
            default: break
        }
    }

    const showScreen = () => {
        const controller = new PosScreenController()
        controller._callback = returnShowScreen
        controller.show(urlParams.idScreen)
    }
    const returnShowScreen = (object, error, status) => {
        switch (status) {
            case 200:
                setScreen(object)
                break
            default: break
        }
    }
    const availableToDeploy = () => {
        if (screen === null) return

        const controller = new PosScreenController()
        controller._callback = returnAvailableToDeploy
        controller.unsyncUpdates(screen.id)
    }
    const returnAvailableToDeploy = (response, error, status) => {
        switch (status) {
            case 200:
                setAvailableDeployment(response.data.updates.hasUpdates)
                break
            default: break
        }
    }
    const indexKeyboards = () => {
        const controller = new KeyboardController()
        controller._callback = returnIndexKeyboards
        controller.index(screen.id, ['function', 'menu', 'pasteis'])
    }
    const returnIndexKeyboards = (list, error, status) => {
        switch (status) {
            case 200:
                setKeyboards(list)

                let keysTmp = []

                for (let i in list) {
                    for (let j in list[i].keys) {
                        keysTmp.push(list[i].keys[j])
                    }
                }

                setListKeys(keysTmp)

                break
            default: break
        }
    }
    const getCategories = () => {
        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(null, "", 0, 0, true, "updated_at", "desc")
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setCategories(list)
                break
            default:
                setCategories([])
                break
        }
    }
    const getProducts = () => {
        const controller = new ProductController()
        controller._callback = returnGetProducts
        controller.index(null, "", 0, 0, "updated_at", "desc", true)
    }
    const returnGetProducts = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setProducts(list)
                break
            default:
                setProducts([])
                break
        }
    }
    const getPricelists = () => {
        const controller = new PricelistController()
        controller._callback = returnGetPricelists
        controller.index(null, "", 0, 0, true, "updated_at", "desc")
    }
    const returnGetPricelists = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setPricelists(list)
                break
            default:
                setPricelists([])
                break
        }
    }
    const getSellers = () => {
        const controller = new SellerController();
        controller._callback = returnGetSellers;

        if (env.type === "company") {
            controller.maxPosition();
        }
        else {
            controller.index("", 0, 0, "updated_at", "desc", true);
        }
    }
    const returnGetSellers = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setSellers(list)
                break
            default:
                setSellers([])
                break
        }
    }
    const getPaymentMethods = () => {
        const controller = new PaymentMethodController()
        controller._callback = returnGetPaymentMethods
        controller.index("", 0, 0, true, "updated_at", "desc")
    }
    const returnGetPaymentMethods = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setPaymentMethods(list)
                break
            default:
                setPaymentMethods([])
                break
        }
    }
    const getFunctions = () => {
        const controller = new ActionController()
        controller._callback = returnGetFuntions
        controller.index("function&hidden=false", false)
    }
    const returnGetFuntions = (list, error, status) => {
        switch (status) {
            case 200:
                setFunctions(list)
                break
            default:
                setFunctions([])
                break
        }
    }
    const postKeyboard = (keyboard, context) => {
        const controller = new KeyboardController()
        controller._callback = returnPostKeyboard

        if (context === null)
            context = {}

        context.object = keyboard

        controller.post(screen.id, keyboard, context)
    }
    const returnPostKeyboard = (response, error, status, context) => {
        switch (status) {
            case 201:
                let tmpKeyboards = keyboards.slice()
                let tmpKeys = listKeys.slice()
                let tmpKeysToPost = keyToPost.slice()
                let index = tmpKeyboards.findIndex(_ => _.uuid === context.object.uuid)
                let indexKey

                if (index >= 0) {
                    tmpKeyboards[index].id = response.data.id

                    for (let i in tmpKeyboards[index].keys) {
                        tmpKeyboards[index].keys[i].keyboard_id = tmpKeyboards[index].id
                    }
                }
                else {
                    console.log("[POST KEYBOARD] PAS TROUVE !!!", index, context.object.uuid)
                }

                if (context.keyToPost !== undefined) {
                    context.keyToPost[0].target_id = response.data.id

                    index = tmpKeyboards.findIndex(_ => _.id === context.keyToPost[0].keyboard_id)

                    if (index >= 0) {
                        indexKey = tmpKeyboards[index].keys.findIndex(_ => _.uuid === context.keyToPost[0].uuid)

                        if (indexKey >= 0) {
                            tmpKeyboards[index].keys[indexKey].target_id = response.data.id
                            setKeyboards(tmpKeyboards)
                        }
                    }

                    indexKey = tmpKeys.findIndex(_ => _.uuid === context.keyToPost[0].uuid)

                    if (indexKey >= 0) {
                        tmpKeys[indexKey].target_id = response.data.id
                    }

                    tmpKeysToPost.push({
                        object: context.keyToPost[0]
                    })
                }

                if (context.ownerKeysToPost !== undefined) {
                    for (let i in context.ownerKeysToPost) {
                        let key = context.ownerKeysToPost[i]
                        key.keyboard_id = response.data.id

                        tmpKeysToPost.push({
                            object: key
                        })
                    }
                }

                setKeyboards(tmpKeyboards)
                setListKeys(tmpKeys)
                setKeyToPost(tmpKeysToPost)

                break
            default:
                setGlobalError("Une erreur est survenue [PO-KB-" + status + "]")
                break
        }
    }
    const putKeyboard = (keyboard, datas, context) => {
        const controller = new KeyboardController()
        controller._callback = returnPutKeyboard

        if (context === null)
            context = {}

        context.object = keyboard

        controller.put(screen.id, keyboard, datas, context)
    }
    const returnPutKeyboard = (response, error, status, context) => {
        switch (status) {
            case 204:
                break
            default:
                setGlobalError("Une erreur est survenue [PU-KB-" + status + "]")
                break
        }
    }
    const postKey = (key, context) => {
        const controller = new KeyController()
        controller._callback = returnPostKey

        if (context === null)
            context = {}

        context.object = key

        controller.post(screen.id, key.keyboard_id, key, context)
    }
    const returnPostKey = (response, error, status, context) => {
        switch (status) {
            case 201:
                let tmpKeyboards = keyboards.slice()
                let tmpKeys = listKeys.slice()
                let index = tmpKeyboards.find(_ => _.id === context.object.keyboard_id).keys.findIndex(_ => _.uuid === context.object.uuid)

                if (index >= 0) {
                    tmpKeyboards.find(_ => _.id === context.object.keyboard_id).keys[index].id = response.data.id
                    setKeyboards(tmpKeyboards)
                }

                index = tmpKeys.findIndex(_ => _.uuid === context.object.uuid)

                if (index >= 0) {
                    tmpKeys[index].id = response.data.id
                    setListKeys(tmpKeys)
                }

                break
            default:
                setGlobalError("Une erreur est survenue [PO-K-" + status + "]")
                resetKeySelected()
                break
        }
    }
    const putKey = (key, datas, context) => {
        const controller = new KeyController()
        controller._callback = returnPutKey

        if (context === null)
            context = {}

        context.object = key

        controller.put(screen.id, key.keyboard_id, key, datas, context)
    }
    const returnPutKey = (response, error, status, context) => {
        switch (status) {
            case 204:
                break
            default:
                setGlobalError("Une erreur est survenue [PU-K-" + status + "]")
                resetKeySelected()
                break
        }
    }
    const putRanks = datas => {
        const controller = new KeyController()
        controller._callback = returnPutRanks

        let ranks = []
        for (let i in datas) {
            ranks.push({
                id: datas[i].id,
                rank: datas[i].rank
            })
        }

        controller.putRanks(screen.id, datas[0].keyboardId, ranks)
    }
    const returnPutRanks = (response, error, status, context) => {
        switch (status) {
            case 204:
                break
            default:
                setGlobalError("Une erreur est survenue [PU-RKS-" + status + "]")
                resetKeySelected()
                break
        }
    }
    const removeKey = (key, context) => {
        const controller = new KeyController()
        controller._callback = returnRemoveKey

        if (context === null)
            context = {}

        context.object = key

        controller.delete(screen.id, key.keyboard_id, key, context)
    }
    const returnRemoveKey = (response, error, status, context) => {
        switch (status) {
            case 204:
                if (context.keyToPost !== undefined) {
                    let tmpKeysToPost = keyToPost.slice()

                    for (let i in context.keyToPost) {
                        tmpKeysToPost.push({
                            object: context.keyToPost[i]
                        })
                    }

                    setKeyToPost(tmpKeysToPost)
                }

                if (context.keyToPut !== undefined) {
                    let tmpKeysToPut = keyToPut.slice()

                    for (let i in context.keyToPut) {
                        tmpKeysToPut.push({
                            object: context.keyToPut[i].object,
                            datas: context.keyToPut[i].datas
                        })
                    }

                    setKeyToPut(tmpKeysToPut)
                }

                break
            default:
                setGlobalError("Une erreur est survenue [R-K-" + status + "]")
                break
        }
    }
    const sync = () => {
        setSyncing(true)

        const controller = new PosScreenController()
        controller._callback = returnSync
        controller.sync(screen.id)
    }
    const returnSync = (response, error, status) => {
        setSyncing(false)

        switch (status) {
            case 201:
                setAvailableDeployment(false)
                break
            default:
                setGlobalError("Une erreur est survenue [S-S-" + status + "]")
                console.error("Error sync()", status, response, error)
                break
        }
    }

    const processPostKeyboards = () => {
        if (keyboardToPost.length === 0) return

        for (let i in keyboardToPost) {
            postKeyboard(keyboardToPost[i].object, keyboardToPost[i].context !== undefined ? keyboardToPost[i].context : null)
        }

        setKeyboardToPost([])
    }
    const processPutKeyboards = () => {
        if (keyboardToPut.length === 0) return

        for (let i in keyboardToPut) {
            putKeyboard(keyboardToPut[i].object, keyboardToPut[i].datas, keyboardToPut[i].context !== undefined ? keyboardToPut[i].context : null)
        }

        setKeyboardToPut([])
    }
    const processPostKeys = () => {
        if (keyToPost.length === 0) return

        for (let i in keyToPost) {
            postKey(keyToPost[i].object, keyToPost[i].context !== undefined ? keyToPost[i].context : null)
        }

        setKeyToPost([])
    }
    const processPutKeys = () => {
        if (keyToPut.length === 0) return

        for (let i in keyToPut) {
            putKey(keyToPut[i].object, keyToPut[i].datas, keyToPut[i].context !== undefined ? keyToPut[i].context : null)
        }

        setKeyToPut([])
    }
    const processPutRanks = () => {
        if (keyToRanks.length === 0) return

        for (let i in keyToRanks) {
            putRanks(keyToRanks[i])
        }

        setKeyToRanks([])
    }
    const processRemoveKeys = () => {
        if (keyToRemove.length === 0) return

        for (let i in keyToRemove) {
            removeKey(keyToRemove[i].object, keyToRemove[i].context !== undefined ? keyToRemove[i].context : null)
        }

        setKeyToRemove([])
    }

    const screenContextValue = {
        screen,
        setScreen,
        categories,
        products,
        pricelists,
        sellers,
        paymentMethods,
        functions,
        keyboards,
        setKeyboards,
        listKeys,
        setListKeys,
        keyPress,
        setKeyPress,
        categoryKeySelected,
        setCategoryKeySelected,
        inEditKey,
        setInEditKey,
        takeItem,
        releaseItemClearTake,
        removeKeyContext,
        keyboardToPost,
        setKeyboardToPost,
        keyboardToPut,
        setKeyboardToPut,
        keyToPost,
        setKeyToPost,
        keyToPut,
        setKeyToPut,
        keyToRanks,
        setKeyToRanks,
        keyToRemove,
        setKeyToRemove,
        setFontSizeDeploy,
        setPriceShowingDeploy,
        scale,
        setScale,
        fontSizeRatio,
        setFontSizeRatio,
        setObjectSheetOpened
    }

    useEffect(() => {
        document.title = "Back office - Modifier l'écran" + (screen !== null ? " \"" + screen.name + "\"" : "");
        window.addEventListener('resize', resize);

        showScreen();
        getCategories();
        getProducts();
        getPricelists();
        getSellers();
        getPaymentMethods();
        getFunctions();

        return () => window.removeEventListener('resize', resize);
    }, []);
    useEffect(() => {
        let intervalToDeploy

        if (screen !== null) {
            indexKeyboards()

            if (env.type === "store") {
                availableToDeploy()

                intervalToDeploy = setInterval(() => { availableToDeploy() }, INTERVAL_DEPLOY)
            }
        }

        return() => {
            if (env.type === "store")
                clearInterval(intervalToDeploy);
        }
    }, [screen]);
    useEffect(() => {
        if (keyboards !== null && categories !== null && products !== null && pricelists !== null && sellers !== null &&  paymentMethods !== null &&  functions !== null &&  listKeys !== null)
            setLoading(false)
    }, [keyboards, categories, products, pricelists, sellers, paymentMethods, functions, listKeys]);
    useEffect(() => {
        if (keyPress !== null) {
            actionToolsBar("keySettings")
        }
        else {
            if (searchBoxVisible === "keySettings") {
                actionToolsBar(null)
            }
        }
    }, [keyPress]);
    useEffect(() => {
        processPostKeyboards()
    }, [keyboardToPost]);
    useEffect(() => {
        processPutKeyboards()
    }, [keyboardToPut]);
    useEffect(() => {
        processPostKeys()
    }, [keyToPost]);
    useEffect(() => {
        processPutKeys()
    }, [keyToPut]);
    useEffect(() => {
        processPutRanks()
    }, [keyToRanks]);
    useEffect(() => {
        processRemoveKeys()
    }, [keyToRemove]);
    useEffect(() => {
        if (fontSizeDeploy !== null) {
            if (timerFontSizeDeploy !== null)
                clearTimeout(timerFontSizeDeploy)

            timerFontSizeDeploy = setTimeout(() => { endTimerFontSizeDeploy() }, 5000)
        }
    }, [fontSizeDeploy]);
    useEffect(() => {
        if (priceShowingDeploy !== null) {
            if (timerPriceShowingDeploy !== null)
                clearTimeout(timerPriceShowingDeploy)

            timerPriceShowingDeploy = setTimeout(() => { endTimerPriceShowingDeploy() }, 5000)
        }
    }, [priceShowingDeploy]);

    return(
        <ScreenContext.Provider value={screenContextValue}>
            <div className="screenCard">
                {
                    defineActionNeeded !== null
                    && <ActionDefined object={defineActionNeeded} cancel={closeDefineAction} save={saveDefineAction} />
                }
                {
                    (defineDecisionNeeded !== null && defineDecisionType !== null)
                    && <KeyDecision object={defineDecisionNeeded} type={defineDecisionType} cancel={closeDefineDecision} save={saveDefineDecision} />
                }
                {
                    specifyActionNeeded !== null
                    && <ActionSpecify object={specifyActionNeeded} cancel={closeSpecifyAction} save={saveSpecifyAction} />
                }
                {
                    <SearchBox type={searchBoxVisible} action={actionToolsBar} close={closeToolsBar} returnName={returnName} />
                }
                {
                    loading
                        ? <div className="wrapCenterLoader">
                            <LoaderCircle display="loader restGETInMain" strokeWidth="5" />
                        </div>
                        : <>
                            <ScreenToolsBar typeSelected={searchBoxVisible} action={actionToolsBar} />
                            {
                                (screen !== null && keyboards.length > 0)
                                && <POSScreen />
                            }
                        </>
                }
                <div className={"savingBar" + ((availableDeployment && globalError === null && globalWarning === null && fontSizeDeploy === null && priceShowingDeploy === null) ? " visible" : "")}>
                    <p className="text">Vous avez des modifications à déployer</p>
                    <p className="save" onClick={sync}>
                        {
                            syncing
                                ? <LoaderCircle display="loader inDeploymentButton" stroke={"#FFFFFF"} strokeWidth="8" />
                                : "Envoyer sur la caisse"
                        }
                    </p>
                </div>
                <div className={"errorBar" + (globalError !== null ? " visible" : "")}>
                    <p className="text">{ globalError }</p>
                    <p className="refresh" onClick={refreshScreen}>Recharger l'écran</p>
                </div>
                <div className={"warningBar" + (globalWarning !== null ? " visible" : "")}>
                    <p className="text">{ globalWarning }</p>
                </div>
                <div className={"globalApplyBar" + (fontSizeDeploy !== null ? " visible" : "")}>
                    <p className="text">Souhaitez-vous appliquer la taille de texte { fontSizeDeploy } sur toutes les touches ?</p>
                    <p className="apply" onClick={() => { applyGlobalFontSize(fontSizeDeploy) }}>Appliquer</p>
                </div>
                <div className={"globalApplyBar" + (priceShowingDeploy !== null ? " visible" : "")}>
                    <p className="text">Souhaitez-vous { priceShowingDeploy === 1 ? "afficher" : "masquer" } le prix des produits sur toutes les touches ?</p>
                    <p className="apply" onClick={() => { applyGlobalPriceShowing(priceShowingDeploy) }}>Appliquer</p>
                </div>
            </div>
            {
                objectSheetOpened !== null
                && showObjectSheedTargeted()
            }
        </ScreenContext.Provider>
    )
}

export default ScreenCard
