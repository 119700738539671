import React from "react"
import FormStart from "../../../form/paymentmethod/FormStart"
import PaymentMethod from "../../../../stories/_setting/PaymentMethods/PaymentMethod"
import '../../../../css/overbox/PaymentMethodInformation.css'

const NewObject = props => {
    const {handleIndex} = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new PaymentMethod() } handleIndex={handleIndex} />
                </div>
            </div>
        </div>
    )
}

export default NewObject
