import React, {useContext, useEffect} from "react";
import $ from "jquery";
import BackofficeContext from "../../../context/BackofficeContext";
import "../../../css/page/ValidMessage.css";

const ValidMessage = props => {
    const { setValidText } = useContext(BackofficeContext);

    const cleanError = () => {
        $(".backoffice > .validMessage").addClass("disappear");

        setTimeout(() => {
            setValidText(null);
        }, 500);
    }

    useEffect(() => {
        let timeout = setTimeout(() => { cleanError() }, 4000);

        return() => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <p className={"validMessage"}>
            {
                props.message
            }
        </p>
    )
}

export default ValidMessage;