import React, { useState } from "react"
import FormBuilder from "../../../class/tool/FormBuilder"

const SubCategoryFormSheet = props => {
    const company = JSON.parse(localStorage.getItem("company"));
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"));
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props;
    const [ edit, setEdit ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            focus: true,
            edit: (object.sharedUuid === null || company.socialReason === null || (object.sharedUuid !== null && storeSettings !== null && company.socialReason !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Référence",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Référence",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || company.socialReason === null || (object.sharedUuid !== null && storeSettings !== null && company.socialReason !== null && storeSettings.allowedProductCreation === 1))
        }
    ]);

    const buildRow = row => {
        if (edit === row.attribute || ["color"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        handleSave()
    }

    return (
        <form className={ "form fullHeight" + (updated ? " withSavingBar" : "") } onSubmit={ handleSubmit } >
            <div className="clearing">
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                buildRow(row)
                            }
                        </div>
                    ))
                }
            </div>
        </form>
    );
}

export default SubCategoryFormSheet;
