import Rest from "../../../class/tool/Rest"
import SellerGroup from "./SellerGroup"

class SellerGroupController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "sellergroups"

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g;

        if(val === "") throw new Error("EMPTY");
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkReference(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g;

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }

    setFormValues(object, setValues) {
        let forbiddentActions = [];

        for (let index in object.forbiddenActions) {
            forbiddentActions.push({
                id: object.forbiddenActions[index].code,
                value: "[" + object.forbiddenActions[index].code + "] " + object.forbiddenActions[index].label
            })
        }

        setValues(prev => ({
            ...prev,
            name: object.name,
            reference: object.reference,
            forbiddenActions: forbiddentActions
        }));
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (key === "forbiddenActions" && datas.forbiddenActions !== undefined) {
                        let toUpdate = false

                        if (datas.forbiddenActions.length !== Reflect.get(object, key).length)
                            toUpdate = true
                        else {
                            for (let index in datas.forbiddenActions) {
                                if (Reflect.get(object, key).findIndex(_ => _.code === datas.forbiddenActions[index].id) < 0) {
                                    toUpdate = true
                                    break
                                }
                            }
                        }

                        if (!toUpdate) continue

                        let actions = [];
                        for (let index in datas.forbiddenActions)
                            actions.push(datas.forbiddenActions[index].id);

                        Reflect.set(updates, "forbidden", actions);
                    }
                    else if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key])
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key])
                }
            }
        }

        return updates;
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name }
        ];
        let errors = {}
        let method

        for (let i in params) {
            method = SellerGroupController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index(input = "", page = 1, nb = 25, tree = false, sortingName = "", sortingValue = "") {
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree=";
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new SellerGroup(data[item]));
        }

        this._callback(listObjects, error, pagination, status);
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new SellerGroup(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }

    histo() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/histos',
            {},
            this.handleHisto);
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(let item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }

    histoTarget(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id + '/histos',
            {},
            this.handleHistoTarget);
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = [];

        if(status === 200) {
            let data = response.data;

            for(let item in data)
                listObjects.push(data[item]);
        }

        this._callback(listObjects, error, status);
    }
}

export default SellerGroupController
