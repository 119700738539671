import React from "react";

function CalendarIcon (props) {
    const { fill, classname, click } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} className={classname !== undefined ? classname : ""} onClick={click !== undefined ? click : () => {}}>
            <g>
                <path d="m16.7502 3.56v-1.56c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.5h-6.49997v-1.5c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.56c-2.7.25-4.01 1.86-4.21 4.25-.02.29.22.53.5.53h16.91997c.29 0 .53-.25.5-.53-.2-2.39-1.51-4-4.21-4.25z"></path>
                <path
                    d="m20 9.84009h-16c-.55 0-1 .45001-1 1.00001v6.16c0 3 1.5 5 5 5h8c3.5 0 5-2 5-5v-6.16c0-.55-.45-1.00001-1-1.00001zm-10.79 8.37001c-.05.04-.1.09-.15.12-.06.04-.12.07-.18.09-.06.03-.12.05-.18.06-.07.01-.13.02-.2.02-.13 0-.26-.03-.38-.08-.13-.05-.23-.12-.33-.21-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.1-.09.2-.16.33-.21.18-.08.38-.1.58-.06.06.01.12.03.18.06.06.02.12.05.18.09l.15.12c.18.19.29.45.29.71s-.11.52-.29.71zm0-3.5c-.19.18-.45.29-.71.29s-.52-.11-.71-.29c-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.28-.28.72-.37 1.09-.21.13.05.24.12.33.21.18.19.29.45.29.71s-.11.52-.29.71zm3.5 3.5c-.19.18-.45.29-.71.29s-.52-.11-.71-.29c-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.37-.37 1.05-.37 1.42 0 .18.19.29.45.29.71s-.11.52-.29.71zm0-3.5c-.05.04-.1.08-.15.12-.06.04-.12.07-.18.09-.06.03-.12.05-.18.06-.07.01-.13.02-.2.02-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.09-.09.2-.16.33-.21.37-.16.81-.07 1.09.21.18.19.29.45.29.71s-.11.52-.29.71zm3.5 3.5c-.19.18-.45.29-.71.29s-.52-.11-.71-.29c-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.37-.37 1.05-.37 1.42 0 .18.19.29.45.29.71s-.11.52-.29.71zm0-3.5c-.05.04-.1.08-.15.12-.06.04-.12.07-.18.09-.06.03-.12.05-.18.06-.07.01-.14.02-.2.02-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71s.11-.52.29-.71c.1-.09.2-.16.33-.21.18-.08.38-.1.58-.06.06.01.12.03.18.06.06.02.12.05.18.09.05.04.1.08.15.12.18.19.29.45.29.71s-.11.52-.29.71z"></path>
            </g>
        </svg>
    );
}

export default CalendarIcon;
