import React, {useContext, useEffect, useState} from "react";
import CategoryController from "../../stories/_catalog/Categories/CategoryController";
import ProductController from "../../stories/_catalog/Products/ProductController";
import LoaderCircle from "../loader/LoaderCircle";
import LineHistory from "./LineHistory";
import SessionContext from "../../context/SessionContext";

const ListHistory = (props) => {
    const { show, type } = props;
    const { catalog } = useContext(SessionContext);
    const [ loading, setLoading ] = useState(true);
    const [ first, setFirst ] = useState(true);
    const [ listHisto, setListHisto ] = useState([]);
    let intervalRefreshHisto;

    const launchInterval = () => {
        refreshHisto();

        intervalRefreshHisto = setInterval(
            () => { refreshHisto() },
            15000
        );
    }
    const handleGetHistos = (list, error) => {
        if(error) {
            console.log(error);
        }

        let listTmp = [];

        for (let i in list) {
            if (list[i].readable === 0) continue;
            listTmp.push(list[i]);
        }

        setFirst(false);
        setLoading(false);
        //setListHisto(listTmp);
    }
    const refreshHisto = () => {
        let controller;

        switch (type) {
            case "categories":
                controller = new CategoryController();
                controller._callback = handleGetHistos;
                controller.histo(catalog.id);
                break;
            case "products":
                controller = new ProductController();
                controller._callback = handleGetHistos;
                controller.histo(catalog.id);
                break;

            default: break;
        }
    }

    useEffect(() => {
        launchInterval();

        return() => {
            clearInterval(intervalRefreshHisto);
        }
    }, []);

    return (
        <ul className={ "list " + (show ? "visible" : "") }>
            {
                (first && loading)
                ? <LoaderCircle display="loader loaderHisto" strokeWidth="5" />
                : (
                    listHisto.length > 0
                    ? listHisto.map(histo => (<LineHistory key={ histo.id } object={ histo } />))
                    : <li className="empty">Aucun historique</li>
                )
            }
        </ul>
    );
}

export default ListHistory;
