import React from "react";
import FormStart from "../../../form/pricelist/FormStart";
import Pricelist from "../../../../stories/_catalog/Pricelists/Pricelist";
import '../../../../css/overbox/PricelistInformation.css';

const NewObject = props => {
    const {handleIndex} = props;

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Pricelist() } handleIndex={handleIndex} />
                </div>
            </div>
        </div>
    )
}

export default NewObject;
