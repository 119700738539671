import Rest from "../../../class/tool/Rest"
import Key from "./Key"
import FormBuilder from "../../../class/tool/FormBuilder"

class KeyController {
    #_rest = new Rest()
    _callback = null
    _context = null
    _baseURL = "posscreens/{idScreen}/keyboards/{idKeyboard}/keys"

    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ]
        let errors = {}
        let method

        for (var i in params) {
            method = KeyController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index(idPosScreen, idKeyboard) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(response.data === undefined || response.data.data === undefined) {
            this._callback(listObjects, "Impossible de récupérer les données")
            return
        }

        const data = response.data.data

        for(let item in data)
            listObjects.push( new Key(data[item]))

        this._callback(listObjects, error)
    }

    show(idPosScreen, idKeyboard, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard) + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Key(response.data) : null
        this._callback(object, error, status)
    }

    post(idPosScreen, idKeyboard, object, context = null) {
        let datas = Object.assign({}, object)
        datas.noSync = true
        delete datas.backgroundImage
        delete datas.created_at
        delete datas.updated_at
        delete datas.deleted_at
        delete datas.description
        delete datas.fontPolice
        delete datas.fontSize
        delete datas.fontType
        delete datas.id
        delete datas.keyboard_id
        delete datas.keyboard_object
        delete datas.labelShowing
        delete datas.sharedUuid
        delete datas.target_object
        delete datas.uuid

        if (datas.image === null || datas.image.length === 0)
            delete datas.image

        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard),
            datas,
            this.handlePost
        )
    }
    handlePost = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }

    put(idPosScreen, idKeyboard, object, datas, context = null) {
        datas.noSync = true

        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }

    putRanks(idPosScreen, idKeyboard, ranks) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard),
            {
                ranks: ranks
            },
            this.handlePutRanks)
    }
    handlePutRanks = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(idPosScreen, idKeyboard, object, context = null) {
        if (context !== null) {
            this.#_rest._context = context
        }

        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idScreen}", idPosScreen).replace("{idKeyboard}", idKeyboard) + '/' + object.id + '?noSync=true',
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status, context) => {
        this._callback(response, error, status, context)
    }
}

export default KeyController
