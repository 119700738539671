import React, {useContext} from "react"
import LogoutIcon from "../../icons/LogoutIcon"
import SessionContext from "../../../context/SessionContext"

const Logout = () => {
    const { handleLogout } = useContext(SessionContext)

    return (
        <div className="iconLogout" onClick={ handleLogout }>
            <LogoutIcon />
        </div>
    )
}

export default Logout
