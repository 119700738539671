import React, {useContext, useEffect, useState} from "react"
import $ from "jquery"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import KeyboardController from "../../../stories/_setting/Keyboards/KeyboardController"
import KeyController from "../../../stories/_setting/Keys/KeyController"
import ScreenContext from "../../../context/ScreenContext"
import "../../../css/form/Form.css"
import Keys from "../../../class/tool/Keys";

const FormKeyboardObject = props => {
    const { zone } = props
    const { keyboards, setKeyboards, listKeys, setListKeys, inEditKey, setInEditKey, keyboardToPut, setKeyboardToPut, keyToRanks, setKeyToRanks, setFontSizeDeploy } = useContext(ScreenContext)
    const [ values, setValues ] = useState(null)
    const [ rows, setRows ] = useState([])
    const [ globalError, setGlobalError ] = useState(null)
    const [ saveKeyboard, setSaveKeyboard ] = useState({})
    const [ updatedRanks, setUpdatedRanks ] = useState(null)
    const fontSizes = [
        {
            value: "8",
            id: 8
        },
        {
            value: "9",
            id: 9
        },
        {
            value: "10",
            id: 10
        },
        {
            value: "11",
            id: 11
        },
        {
            value: "12",
            id: 12
        },
        {
            value: "13",
            id: 13
        },
        {
            value: "14",
            id: 14
        },
        {
            value: "15",
            id: 15
        },
        {
            value: "16",
            id: 16
        },
        {
            value: "17",
            id: 17
        },
        {
            value: "18",
            id: 18
        },
        {
            value: "19",
            id: 19
        },
        {
            value: "20",
            id: 20
        }
    ]

    const initSaveKey = () => {
        setRows([])
        setValues(null)
        setSaveKeyboard(Object.assign({}, keyboards.find(_ => _.zone === zone)))
    }
    const initValues = () => {
        if (Object.keys(saveKeyboard).length === 0) return

        setValues({
            dimensions: { nbLines: saveKeyboard.nbLines, nbColumns: saveKeyboard.nbColumns },
            fontSize: saveKeyboard.fontSize
        })
    }
    const initRows = () => {
        let keyboard = keyboards.find(_ => _.zone === zone)
        let minLines = 1
        let minColumns = 1
        let maxLines = 1
        let maxColumns = 1

        switch (zone) {
            case "T":
                minLines = 1
                maxLines = 1
                minColumns = 1
                maxColumns = 12
                break
            case "R":
                minLines = 1
                maxLines = 10
                minColumns = 1
                maxColumns = 2
                break
            case "M":
            case "S":
                minLines = 1
                maxLines = 1
                minColumns = 1
                maxColumns = 12
                break
            default: break
        }

        let rowsTmp = [
            {
                label: "Grille",
                attribute: "dimensions",
                inputType: "grid",
                returnType: "dimensions",
                maxLines: maxLines,
                maxColumns: maxColumns,
                minLines: minLines,
                minColumns: minColumns,
                idKeyboard: keyboard.id,
                allAllowed: false,
                setError: setGlobalError,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: ""
            },
            {
                label: "Taille du texte",
                attribute: "fontSize",
                inputType: "select",
                returnType: "int",
                list: fontSizes,
                dictionary: null,
                classnameLabel: "label white",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            }
        ]

        setRows(rowsTmp)
    }
    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, [], change)
    }
    const change = (attribute, returnType, val, strict = false) => {
        setInEditKey(true)
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const updateKey = () => {
        let indexKeyboard = keyboards.findIndex(_ => _.zone === zone)
        if (indexKeyboard < 0) return

        let keyboardsTmp = keyboards.slice()
        let keysTmp = listKeys.slice()

        let keyboard = keyboardsTmp[indexKeyboard]
        let reRankNeeded = keyboard.nbColumns > values.dimensions.nbColumns ? -1 : (keyboard.nbColumns < values.dimensions.nbColumns ? 1 : null)

        if (keyboard.zone === "R" && (reRankNeeded !== null)) {
            let result = Keys.reRank(reRankNeeded, keyboard, keysTmp)

            keyboard = result.keyboard
            keysTmp = result.keys

            if (result.updatedKeys.length > 0) {
                setUpdatedRanks(result.updatedKeys)
            }
        }

        keyboard.nbLines = values.dimensions.nbLines
        keyboard.nbColumns = values.dimensions.nbColumns
        keyboard.fontSize = values.fontSize
        keyboardsTmp[indexKeyboard] = keyboard

        setKeyboards(keyboardsTmp)
        setListKeys(keysTmp)
    }
    const reinit = () => {
        initValues()
        setInEditKey(false)
    }
    const saveKeyboardDatas = () => {
        let datas = (new KeyboardController()).returnUpdatesFromCompare(saveKeyboard, {
            nbLines: values.dimensions.nbLines,
            nbColumns: values.dimensions.nbColumns,
            fontSize: values.fontSize,
            sorting: values.sorting
        })

        if (Object.keys(datas).length === 0) return

        let tmp = keyboardToPut.slice()
        tmp.push({
            object: saveKeyboard,
            datas: datas
        })
        setKeyboardToPut(tmp)
        setInEditKey(false)

        initSaveKey()
        saveRanks()

        if (datas.fontSize !== undefined) {
            setFontSizeDeploy(datas.fontSize)
        }
    }
    const saveRanks = () => {
        if (updatedRanks === null) return

        let tmp = keyToRanks.slice()
        tmp.push(updatedRanks)
        setKeyToRanks(tmp)
        setUpdatedRanks(null)
    }

    useEffect(() => {
        initSaveKey()
    }, [zone])
    useEffect(() => {
        initValues()
    }, [saveKeyboard])
    useEffect(() => {
        if (values !== null) {
            if (rows.length === 0)
                initRows()
            else
                updateKey()
        }
    }, [values])

    return (<div className={"scroller"}>
        <form className="form">
            {
                globalError !== null
                && <p className={"globalError"}>{ globalError }</p>
            }
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        <label className={ row.classnameLabel }>{ row.label }</label>
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
            {
                inEditKey
                && <div className="savingBar">
                    <p className="reinit" onClick={reinit}>Réinitialiser</p>
                    <p className="save" onClick={saveKeyboardDatas}>Enregistrer</p>
                </div>
            }
        </form>
    </div>)
}

export default FormKeyboardObject
