import Entity from "../../../class/abstract/Entity";
import Store from "../../_account/Stores/Store";
import License from "../../_account/Licenses/License";

class Display extends Entity {
    name = null
    reference = null
    interfaceType = null
    brand = null
    model = null
    ipAddress = null
    ipPort = null
    bltPort = null
    usbPort = null
    serialPort = null
    serialBaudRate = null
    serialDataBits = null
    serialParity = null
    serialStopBits = null
    serialFlowControl = null
    active = null
    store_id = null
    nbLines = null
    nbChars = null
    welcomeText = "{}"
    store = {}
    license_id = null
    license = {}
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if(key === "store")
                    Reflect.set(this, key, new Store(datas[key]))
                else if(key === "license")
                    Reflect.set(this, key, new License(datas[key]))
                else
                    Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default Display
