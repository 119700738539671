import React, { useState } from "react";
import FormBuilder from "../../../class/tool/FormBuilder";

const ProductBarcodesFormSheet = props => {
    const company = JSON.parse(localStorage.getItem("company"));
    const { object, values, storeSettings, errors, updated, setGlobalError, handleChange, handleRecovery, handleSave } = props
    const [ rows, setRows ] = useState([
        {
            label: "",
            attribute: "barcode",
            returnType: "barcode",
            inputType: "barcode",
            classnameLabel: "label",
            classnameNoInput: "",
            emptyText: "",
            storeSettings: storeSettings,
            readOnly: (company.socialReason !== null && (object.sharedUuid !== null || (object.sharedUuid === null && storeSettings !== null && storeSettings.allowedProductCreation === 0)))
        }
    ])

    const buildRow = row => {
        return FormBuilder.buildInputByType(row, values, errors, change, null, recovery)
    }
    const change = (attribute, returnType, val) => {
        handleChange(rows, attribute, returnType, val, false)
    }
    const recovery = (idPricelist, type, mainPrice) => {
        handleRecovery(idPricelist, type, mainPrice)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit }>
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default ProductBarcodesFormSheet
