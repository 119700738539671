import React from "react"
import FormStart from "../../../form/product/FormStart"
import Product from "../../../../stories/_catalog/Products/Product"

import '../../../../css/overbox/ProductInformation.css';

const NewObject = props => {
    const { handleIndex } = props

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox start">
                    <FormStart object={ new Product() } handleIndex={ handleIndex } />
                </div>
            </div>
        </div>
    )
}

export default NewObject
