import React from "react";
import SettingsIcon from "../../icons/SettingsIcon";

const Setting = props => {
    const { handleClick } = props

    return (
        <div className="iconSetting" onClick={ handleClick }>
            <SettingsIcon />
        </div>
    )
}

export default Setting
