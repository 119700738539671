import Rest from "../../../class/tool/Rest"
import Store from "./Store"

class StoreController {
    #_rest = new Rest()
    _callback = null

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name,
            socialReason: object.socialReason,
            //sectors: this.buildSectors(object.sectors),
            address: object.address,
            additional: object.additional,
            postalCode: object.postalCode,
            city: object.city,
            country: object.country,
            intraTva: object.intraTva,
            apeCode: object.apeCode
        }));
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        // clean header & footer receipt
        if (datas.receiptHeader !== undefined) {
            if (typeof datas.receiptHeader !== "object")
                datas.receiptHeader = JSON.parse(datas.receiptHeader)

            let header = []

            for (let i = 0; i < datas.receiptHeader.length; i++) {
                if (datas.receiptHeader[i].text !== "") {
                    header.push(datas.receiptHeader[i])
                }
            }

            datas.receiptHeader = JSON.stringify(header)
        }
        if (datas.receiptFooter !== undefined) {
            if (typeof datas.receiptFooter !== "object")
                datas.receiptFooter = JSON.parse(datas.receiptFooter)

            let footer = []

            for (let i = 0; i < datas.receiptFooter.length; i++) {
                if (datas.receiptFooter[i].text !== "") {
                    footer.push(datas.receiptFooter[i])
                }
            }

            datas.receiptFooter = JSON.stringify(footer)
        }

        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ];
        let errors = {};
        let method;

        for (let i in params) {
            method = StoreController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }
    buildSectors(sectors) {
        return JSON.parse(sectors)
    }

    index() {
        this.#_rest.sendQuery(
            'GET',
            '/stores',
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status) => {
        let listObjects = [];

        for(let item in response.data)
            listObjects.push(new Store(response.data[item]));

        if (this._callback !== null)
            this._callback(listObjects, error, status);
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/stores/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Store(response.data) : null

        if (this._callback !== null)
            this._callback(object, error, status)
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/stores/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status);
    }
}

export default StoreController