import Entity from "../../../class/abstract/Entity"

class Key extends Entity {
    color = null
    label = ""
    description = null
    backgroundImage = 0
    labelShowing = null
    image = null
    keyboard_id = null
    keyboard_uuid = null
    keyboard_object = null
    rank = 0
    action_code = 0
    target_id = null
    target_uuid = null
    target_object = null
    target_value = null
    sellerPosition = null

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }

        if (this.target_id === null && this.sellerPosition !== null) {
            this.target_id = this.sellerPosition
        }
    }
}

export default Key
