import Rest from "../../../class/tool/Rest";
import BarcodeReader from "./BarcodeReader";
import FormBuilder from "../../../class/tool/FormBuilder";

class BarcodeReaderController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "barcodereaders";
    static _barcodeReaders = [
        {
            brand: "DATALOGIC",
            model: "QD2130",
            interfaceTypes: "serial"
        }
    ];

    // Checking

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g;

        if(val === "") throw new Error("EMPTY");
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkReference(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g;

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkActive(val) {
        const regxp = /^0|1$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkBrand(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g;

        if(val === "") throw new Error("EMPTY");
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkModel(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g;

        if(val === "") throw new Error("EMPTY");
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkInterfaceType(val) {
        const regxp = /^usb|serie|ip|bluetooth/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkIpPort(val) {
        const regxp = /^[0-9]{1,6}$/g;

        if(val === "") throw new Error("EMPTY");
        if (val < 1 || !regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkIpAddress(val) {
        const regxp = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG");

        return true;
    }
    static checkBltPort(val) {
        if(val === "") throw new Error("EMPTY");

        return true;
    }
    static checkUsbPort(val) {
        if(val === "") throw new Error("EMPTY");

        return true;
    }
    static checkSerialPort(val) {
        const regxp = /^[0-9]{1,6}$/g;

        if(val === "") throw new Error("EMPTY");
        if (val < 1 || !regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkBaudRate(val) {
        const regxp = /^75|110|134|150|300|600|1200|1800|2400|4800|7200|9600|14400|19200|38400|57600|115200|128000$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkDataBits(val) {
        const regxp = /^4|5|6|7|8$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkParity(val) {
        const regxp = /^paire|impaire|aucune|marque|espace$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val.toLowerCase())) throw new Error("WRONG");

        return true;
    }
    static checkStopBits(val) {
        const regxp = /^1|1.5|2$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val)) throw new Error("WRONG");

        return true;
    }
    static checkFlowControl(val) {
        const regxp = /^xon|xoff|materiel|aucun$/g;

        if(val === "") throw new Error("EMPTY");
        if (!regxp.test(val.toLowerCase())) throw new Error("WRONG");

        return true;
    }
    static checkLicenses(val) {
        if (val === null || val === 0) throw new Error("EMPTY");

        return true;
    }

    // perform

    setFormValues(object, setValues, start = false) {
        setValues(prev => ({
            ...prev,
            name: object.name,
            reference: object.reference,
            interfaceType: object.interfaceType,
            brand: object.brand,
            model: object.model,
            ipAddress: object.ipAddress,
            ipPort: object.ipPort,
            bltPort: object.bltPort,
            usbPort: object.usbPort,
            serialPort: start ? 1 : object.serialPort,
            serialBaudRate: start ? 9600 : object.serialBaudRate,
            serialDataBits: start ? 8 : object.serialDataBits,
            serialParity: start ? "Aucune" : object.serialParity,
            serialStopBits: start ? 1 : object.serialStopBits,
            serialFlowControl: start ? "Aucun" : object.serialFlowControl,
            active: start ? 1 : object.active,
            license_id: object.license_id,
        }));
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let obj = Object.assign({}, object)

        return FormBuilder.returnUpdates(obj, datas, compare)
    }
    completeDatasToUpdate(object, datas) {
        let datasToUpdate = Object.assign({}, datas)
        const ipRow = ["ipAddress", "ipPort"]
        const serialRow = ["serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl"]
        const bltRow = ["bltPort"]
        const usbRow = ["usbPort"]

        if (datasToUpdate.interfaceType !== undefined) {
            switch (datasToUpdate.interfaceType) {
                case "ip":
                    for (let i in ipRow)
                        if (datasToUpdate[ipRow[i]] === undefined)
                            datasToUpdate[ipRow[i]] = object[ipRow[i]]

                    break
                case "serial":
                    for (let i in serialRow)
                        if (datasToUpdate[serialRow[i]] === undefined)
                            datasToUpdate[serialRow[i]] = serialRow[i] === "serialPort" ? object[serialRow[i]].toString() : object[serialRow[i]]

                    break
                case "bluetooth":
                    for (let i in bltRow)
                        if (datasToUpdate[bltRow[i]] === undefined)
                            datasToUpdate[bltRow[i]] = object[bltRow[i]]

                    break
                case "usb":
                    for (let i in usbRow)
                        if (datasToUpdate[usbRow[i]] === undefined)
                            datasToUpdate[usbRow[i]] = object[usbRow[i]]

                    break
                default: break
            }
        }
        else {
            let ipUpdate = false
            let serialUpdate = false
            let bltUpdate = false
            let usbUpdate = false

            for (let i in ipRow) {
                if (datasToUpdate[ipRow[i]] !== undefined) {
                    ipUpdate = true
                    break
                }
            }

            if (ipUpdate) {
                for (let i in ipRow) {
                    if (datasToUpdate[ipRow[i]] === undefined) {
                        datasToUpdate[ipRow[i]] = object[ipRow[i]]
                    }
                }
            }

            for (let i in serialRow) {
                if (datasToUpdate[serialRow[i]] !== undefined) {
                    serialUpdate = true
                    break
                }
            }

            if (serialUpdate) {
                for (let i in serialRow) {
                    if (datasToUpdate[serialRow[i]] === undefined) {
                        datasToUpdate[serialRow[i]] = serialRow[i] === "serialPort" ? object[serialRow[i]].toString() : object[serialRow[i]]
                    }
                    else {
                        if (serialRow[i] === "serialPort") {
                            datasToUpdate["serialPort"] = datasToUpdate["serialPort"].toString()
                        }
                    }
                }
            }

            for (let i in bltRow) {
                if (datasToUpdate[bltRow[i]] !== undefined) {
                    bltUpdate = true
                    break
                }
            }

            if (bltUpdate) {
                for (let i in bltRow) {
                    if (datasToUpdate[bltRow[i]] === undefined) {
                        datasToUpdate[bltRow[i]] = object[bltRow[i]]
                    }
                }
            }

            for (let i in usbRow) {
                if (datasToUpdate[usbRow[i]] !== undefined) {
                    usbUpdate = true
                    break
                }
            }

            if (usbUpdate) {
                for (let i in usbRow) {
                    if (datasToUpdate[usbRow[i]] === undefined) {
                        datasToUpdate[usbRow[i]] = object[usbRow[i]]
                    }
                }
            }
        }

        return datasToUpdate
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ];
        let errors = {};
        let method;

        for (var i in params) {
            method = BarcodeReaderController[params[i].method];

            try { method.apply(null, params[i].val); }
            catch (e) { errors.params[i].name = e.message; }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors));
        else return true;
    }

    // rest API

    index(input = "", page = 1, nb = 25, tree = false, sortingName = "", sortingValue = "") {
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree=";
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new BarcodeReader(data[item]));
        }

        this._callback(listObjects, error, pagination, status)
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new BarcodeReader(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datas,
            (this._callback !== null ? this._callback : this.handlePost));
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this._callback);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this._callback);
    }
}

export default BarcodeReaderController
