import React, { useState } from "react";
import ListHistory from "./ListHistory";
import "../../css/history/history.css";

const History = props => {
    const { type, classname } = props;
    const [ showHistory, setShowHistory ] = useState(false);

    const handleShowHistory = () => {
        setShowHistory(!showHistory);
    }

    return (
        <div className={ "containerHistory " + classname }>
            <button className="historyButton" onClick={ handleShowHistory } />
            <ListHistory type={ type } show={ showHistory } />
        </div>
    );
}

export default History;
