import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import ScreenController from "../../../stories/_setting/PosScreens/PosScreenController"
import LicenseController from "../../../stories/_account/Licenses/LicenseController"
import SellerController from "../../../stories/_setting/Sellers/SellerController";
import '../../../css/form/Form.css'

const FormStart = props => {
    const env = JSON.parse(localStorage.getItem("env"))
    const item = "screens"
    const controller = new ScreenController()
    const { object, handleIndex } = props
    const [ reload, setReload ] = useState(false)
    const [ loadingLicenses, setLoadingLicenses ] = useState(true)
    const [ loadingSellers, setLoadingSellers ] = useState(true)
    const [ licenses, setLicenses ] = useState([])
    const [ sellers, setSellers ] = useState([])
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        }
    ])

    const initValues = () => {
        controller.setFormValues(object, setValues, true)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas);
            let fields = ["reference"];

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            setReload(true);
            handleIndex();
        }

        setSaving(false);
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.post(datas);
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        initValues()
    }, [])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            <form className="form" onSubmit={handleSubmit}>
                {
                    globalError !== null && <p className="globalError">{globalError}</p>
                }
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            {
                                (row.hidden === undefined || !row.hidden)
                                && <div className="clearing">
                                    {
                                        row.noLabel === undefined
                                        && <label className={ row.classnameLabel }>{ row.label }</label>
                                    }
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
                <button className={"submit " + (saving ? "hide" : "")}>
                    {
                        saving
                            ? "Enregistrement..."
                            : "Enregistrer"
                    }
                    <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
                <Link to={ "/" + item }>
                    <button className="cancel align">Fermer</button>
                </Link>
                <div className="clearing" />
            </form>
        </div>
    )
}

export default FormStart
