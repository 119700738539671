import React from "react";

function ScreenIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={ fill }>
            <rect height={16.5} rx={3} width={21.5} x={1.25} y={1.75} />
            <path d="M15 19.25v2.25a.691.691 0 01-.6.75H9.6a.691.691 0 01-.6-.75v-2.25z" />
            <path d="M17 22.25H7a.75.75 0 010-1.5h10a.75.75 0 010 1.5z" />
        </svg>
    );
}

export default ScreenIcon;
