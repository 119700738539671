import React, {useEffect} from "react"
import LoaderCircle from "../../../loader/LoaderCircle"

const InputSelect = props => {
    const { attribute, returnType, backgroundColor, classname, classError, value, list, dictionary, readonly, autoFocus, loading, handleChange, handleBlur } = props

    const changeEvent = event => {
        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, event.currentTarget.value)
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return
        handleBlur(attribute)
    }
    const buildStyle = () => {
        if (attribute === "catalog_id") {
            if (value !== null && list[value] !== undefined && list[value].object !== undefined && list[value].object.color !== undefined) {
                return { backgroundColor: list[value].object.color + "22", color: list[value].object.color };
            }
        }
        else {
            if (backgroundColor !== undefined && backgroundColor !== null) {
                return { backgroundColor: backgroundColor + "22" };
            }
        }

        return {};
    }

    return (
        <div className={ "inputText " + (classname !== undefined ? classname : "") }>
            <select
                value={ value !== null ? value : "" }
                className={ "extend" + (classError !== undefined ? " " + classError : "") }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                disabled={ readonly }
                autoFocus={ autoFocus !== null ? autoFocus : true }
                style={ buildStyle() }>
            {
                list.map((object, index) => (
                    <option key={ index } value={ returnType === "string" ? object.value : index } disabled={ object.readonly !== undefined ? object.readonly : false }>
                        {
                            (dictionary !== null && dictionary !== undefined)
                            ? dictionary[object.value]
                            : object.value
                        }
                    </option>
                ))
            }
            </select>
            <div className="clearing" />
            {
                (loading !== undefined && loading)
                && <LoaderCircle display="loader inputForm" strokeWidth="12" stroke="#00406F" />
            }
        </div>
    )
}

export default InputSelect
