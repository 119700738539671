import Entity from "../../../class/abstract/Entity";
import Pricelist from "../Pricelists/Pricelist";

class VatRule extends Entity {
    store_id = null;
    catalog_id = null;
    parentItem_id = null;
    parent_rule_id = null;
    price_list_id = null;
    rulable_id = null;
    rulable_type = null;
    sharedUuid = null;
    synced_at = null;
    vat_rate_id = null;
    pricelist = {};

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if (key === "pricelist")
                    Reflect.set(this, key, new Pricelist(datas[key]));
                else
                    Reflect.set(this, key, datas[key]);
            }
        }
    }
}

export default VatRule;
