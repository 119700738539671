import React from "react";

function AnalyzeIcon (props) {
    const { fill } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478 478" fill={ fill }>
            <path d="M119.5 187.75H17.1c-9.4 0-17 7.6-17.1 17.1v256c0 9.5 7.7 17.1 17.1 17.1h102.4c9.5 0 17.1-7.7 17.1-17.1v-256c0-9.5-7.7-17.1-17.1-17.1zM290.2.05H187.8c-9.4 0-17.1 7.6-17.1 17v443.8c0 9.5 7.7 17.1 17.1 17.1h102.4c9.5 0 17.1-7.7 17.1-17.1V17.15c0-9.5-7.7-17.1-17.1-17.1zM460.9 136.55H358.5c-9.5 0-17.1 7.6-17.1 17.1v307.2c0 9.5 7.7 17.1 17.1 17.1h102.4c9.5 0 17.1-7.7 17.1-17.1v-307.2c0-9.5-7.7-17.1-17.1-17.1z" />
        </svg>
    );
}

export default AnalyzeIcon;
