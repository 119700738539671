import React from "react"
import {Link} from "react-router-dom"
import CategoryIcon from "../../../icons/CategoryIcon"
import ProductIcon from "../../../icons/ProductIcon"
import SellerIcon from "../../../icons/SellerIcon"
import PricelistIcon from "../../../icons/PricelistIcon"
import PaymentIcon from "../../../icons/PaymentIcon"
import CloseIcon from "../../../icons/CloseIcon"
import SettingsIcon from "../../../icons/SettingsIcon"
import FunctionIcon from "../../../icons/functionIcon"
import MagicIcon from "../../../icons/MagicIcon"
import '../../../../css/page/content/screen/ScreenToolsBar.css'

const ScreenToolsBar = props => {
    const { typeSelected, action } = props
    const links = [
        /*{
            item: "magicTool",
            label: "Baguette magique",
            icon: <MagicIcon />,
            action: () => { action('magicTool') },
            link: null
        },*/
        {
            item: "categories",
            label: "Catégories",
            icon: <CategoryIcon />,
            action: () => { action('categories') },
            link: null
        },
        {
            item: "products",
            label: "Produits",
            icon: <ProductIcon />,
            action: () => { action('products') },
            link: null
        },
        {
            item: "pricelists",
            label: "Tarifs",
            icon: <PricelistIcon />,
            action: () => { action('pricelists') },
            link: null
        },
        {
            item: "sellers",
            label: "Vendeurs",
            icon: <SellerIcon />,
            action: () => { action('sellers') },
            link: null
        },
        {
            item: "paymentMethods",
            label: "Règlements",
            icon: <PaymentIcon />,
            action: () => { action('paymentMethods') },
            link: null
        },
        {
            item: "functions",
            label: "Fonctions",
            icon: <FunctionIcon />,
            action: () => { action('functions') },
            link: null
        },
        {
            item: "settings",
            label: "Réglages",
            icon: <SettingsIcon />,
            action: () => { action('settings') },
            link: null
        },
        {
            item: "close",
            label: "Fermer",
            icon: <CloseIcon />,
            action: null,
            link: "/screens"
        }
    ]

    const buildItem = (link, index) => {
        if (link.action !== null) {
            return <div key={index} className={"item " + link.item + (typeSelected === link.item ? " selected" : "")} onClick={link.action}>
                { link.icon }
                <div className={"containerText"}>
                    <span>{link.label}</span>
                </div>
            </div>
        } else if (link.link !== null) {
            return <Link key={index} to={link.link}>
                <div className={"item " + link.item}>
                    {link.icon}
                    <div className={"containerText"}>
                        <span>{link.label}</span>
                    </div>
                </div>
            </Link>
        }
    }

    return (<div id={"screenToolsBar"} className={"screenToolsBar"}>
        {
                links.map((link, index) => buildItem(link, index))
            }
        </div>
    )
}

export default ScreenToolsBar
