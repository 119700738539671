import React from "react";
import FormManySeller from "../../form/seller/FormManySeller";

const ManyBoxSeller = props => {
    const { handleClose } = props;
    const itemClass = "manyForm";

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className={ "overbox " + itemClass }>
                    <FormManySeller handleClose={ handleClose } />
                </div>
            </div>
        </div>
    )
}

export default ManyBoxSeller;
